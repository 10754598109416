import React, { useState, useEffect } from 'react';
// import ReactPaginate from 'react-paginate';
import * as XLSX from 'xlsx';
import Dropzone from 'react-dropzone';
import axios from 'axios';

const RoughThree = () => {
  //   const rowsPerPage = 10; // Define rowsPerPage within the component

  // json conversion to excel

  // const data = [
  //     {"userName":"Venkateswaran ","userEmail":"svenky2011@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"MAASI\/மாசி","userNakshathra":"AYILYAM\/ஆயில்யம்","userDate":"-- Not Found --","firstuserid":"12"},
  //     {"userName":"Arjun","userEmail":"arjunuvacha@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":" ","userTithi":" ","userMonth":"AADI\/ஆடி","userNakshathra":"PURVABHADRA\/பூரட்டாதி","userDate":"25-07-2024","firstuserid":"14"},
  //     {"userName":"Vivek","userEmail":"viveksnk005@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"CHITHIRAI\/சித்திரை","userNakshathra":"KETTAI\/கேட்டை","userDate":"-- Not Found --","firstuserid":"17"},
  //     {"userName":"Vidya","userEmail":"vidyabalajigs@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"PURATTASI\/புரட்டாசி","userNakshathra":"REVATHI\/ரேவதி","userDate":"-- Not Found --","firstuserid":"18"},
  //     {"userName":"Kaushik Manikandan Kannan","userEmail":"kaushikshankar2@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AYPPASI\/ஐப்பசி","userNakshathra":"UTHIRADAM\/உத்திராடம்","userDate":"-- Not Found --","firstuserid":"19"},
  //     {"userName":"Bhuvana","userEmail":"bhuvanakannan1305@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"THAI\/தை","userNakshathra":"POORAM\/பூரம்","userDate":"-- Not Found --","firstuserid":"20"},
  //     {"userName":"Ashok","userEmail":"ashok.75@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"PURATTASI\/புரட்டாசி","userNakshathra":"CHITHIRAI\/சித்திரை","userDate":"-- Not Found --","firstuserid":"21"},
  //     {"userName":"Ankur Ghosh","userEmail":"ankurr369@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Hindi","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"BHADRAPADA\/भाद्रपद","userNakshathra":"KRITTIKA\/कृत्तिका","userDate":"26-08-2024","firstuserid":"24"},
  //     {"userName":"Vidya","userEmail":"vidhur12@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AADI\/ஆடி","userNakshathra":"MAGAM\/மகம்","userDate":"-- Not Found --","firstuserid":"25"},
  //     {"userName":"Shankar kannan ","userEmail":"karanshankar1@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"VAIGAASI\/வைகாசி","userNakshathra":"KETTAI\/கேட்டை","userDate":"-- Not Found --","firstuserid":"26"},
  //     {"userName":"Rathna ","userEmail":"rishirathna11@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"THAI\/தை","userNakshathra":"POOSAM\/பூசம்","userDate":"-- Not Found --","firstuserid":"27"},
  //     {"userName":"K.lakshmanan","userEmail":"lakshmanan237@yahoo.in","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"PURATTASI\/புரட்டாசி","userNakshathra":"POORATATHI\/பூரட்டாதி","userDate":"-- Not Found --","firstuserid":"28"},
  //     {"userName":"Vaishnav ","userEmail":"dytmotaop@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Malayalam","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"KARKADAKAM\/കാർകിഡകാം","userNakshathra":"UTTRA PHALGUNI\/ഉത്രം","userDate":"-- Not Found --","firstuserid":"29"},
  //     {"userName":"Sankaranarayanan kp","userEmail":"sankaranarayanankp91@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Malayalam","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"MITHUNAM\/മിഥുനം","userNakshathra":"ARDRA\/തിരുവാതിര","userDate":"-- Not Found --","firstuserid":"30"},
  //     {"userName":"BHARANIDHARAN ","userEmail":"iravuparavai96@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"PURATTASI\/புரட்டாசி","userNakshathra":"BARANI\/பரணி","userDate":"-- Not Found --","firstuserid":"31"},
  //     {"userName":"Aniruddh Sridharaan ","userEmail":"aniruddhsridharaan@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"MAASI\/மாசி","userNakshathra":"AVITTAM\/அவிட்டம்","userDate":"-- Not Found --","firstuserid":"32"},
  //     {"userName":"Varshni","userEmail":"varshni.murali09@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AAVANI\/ஆவணி","userNakshathra":"POORAM\/பூரம்","userDate":"-- Not Found --","firstuserid":"33"},
  //     {"userName":"Pooja ","userEmail":"poojapsad3@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AADI\/ஆடி","userNakshathra":"POOSAM\/பூசம்","userDate":"-- Not Found --","firstuserid":"36"},
  //     {"userName":"Pooja ","userEmail":"poojapsad3@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AANI\/ஆனி","userNakshathra":"POOSAM\/பூசம்","userDate":"-- Not Found --","firstuserid":"37"},
  //     {"userName":"Sivabarani ","userEmail":"vellurbarani@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AANI\/ஆனி","userNakshathra":"BARANI\/பரணி","userDate":"-- Not Found --","firstuserid":"38"},
  //     {"userName":"Sathya","userEmail":"sathyacm23@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AYPPASI\/ஐப்பசி","userNakshathra":"UTHIRATTATHI\/உத்திரட்டாதி","userDate":"-- Not Found --","firstuserid":"39"},
  //     {"userName":"Valarmathi ","userEmail":"valarmathi840567@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AYPPASI\/ஐப்பசி","userNakshathra":"ROHINI\/ரோகிணி","userDate":"21-10-2024","firstuserid":"40"},
  //     {"userName":"S.Vinoth","userEmail":"vinothsuresh1724@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"KARTHHIGAI\/கார்த்திகை","userNakshathra":"KETTAI\/கேட்டை","userDate":"-- Not Found --","firstuserid":"42"},
  //     {"userName":"Narayanan PS","userEmail":"narayanansridhar10@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"THAI\/தை","userNakshathra":"KETTAI\/கேட்டை","userDate":"-- Not Found --","firstuserid":"44"},
  //     {"userName":"Radhika Shetty","userEmail":"undoradhika@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Hindi","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"MAGH\/माघ","userNakshathra":"ROHINI\/रोहिणी","userDate":"18-02-2024","firstuserid":"48"},
  //     {"userName":"Radhika Shetty","userEmail":"undoradhika@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Hindi","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"MAGH\/माघ","userNakshathra":"ROHINI\/रोहिणी","userDate":"18-02-2024","firstuserid":"49"},
  //     {"userName":"Eliya patra","userEmail":"eliyapatra2003@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Hindi","nakshatratithi":"Tithi","userPaksham":"SHUKLA PAKSHA\/शुक्ल पक्ष","userTithi":"PRATHAMA\/प्रतिपदा","userMonth":"CHAITRA\/चैत्र","userNakshathra":null,"userDate":"-- Not Found --","firstuserid":"50"},
  //     {"userName":"Gokul raj","userEmail":"evilalwaysleads001@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AYPPASI\/ஐப்பசி","userNakshathra":"SADHAYAM\/சதயம்","userDate":"-- Not Found --","firstuserid":"52"},
  //     {"userName":"Jayashree ","userEmail":"jayanagshree@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"MAASI\/மாசி","userNakshathra":"SWATHI\/சுவாதி","userDate":"-- Not Found --","firstuserid":"53"},
  //     {"userName":"Sumedh","userEmail":"rajeshwariram77@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"VAIGAASI\/வைகாசி","userNakshathra":"MOOLAM\/மூலம்","userDate":"-- Not Found --","firstuserid":"54"},
  //     {"userName":"Vimarsini Murali","userEmail":"vimarsinimurali@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"THAI\/தை","userNakshathra":"KRUTHIKAI\/கார்த்திகை","userDate":"-- Not Found --","firstuserid":"55"},
  //     {"userName":"Sai varshan","userEmail":"llbjayashree81@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"KARTHHIGAI\/கார்த்திகை","userNakshathra":"ASWINI\/அஸ்வினி","userDate":"-- Not Found --","firstuserid":"56"},
  //     {"userName":"Jayashree ","userEmail":"llbjayashree81@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AAVANI\/ஆவணி","userNakshathra":"ASTHAM\/அஸ்தம்","userDate":"-- Not Found --","firstuserid":"57"},
  //     {"userName":"Dhakshith","userEmail":"maheswari.mca@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"PANGUNI\/பங்குனி","userNakshathra":"POORATATHI\/பூரட்டாதி","userDate":"-- Not Found --","firstuserid":"58"},
  //     {"userName":"Lalitha","userEmail":"lbarguru@yahoo.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"PURATTASI\/புரட்டாசி","userNakshathra":"ASTHAM\/அஸ்தம்","userDate":"-- Not Found --","firstuserid":"59"},
  //     {"userName":"Hariharan","userEmail":"v_hh69@hotmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AANI\/ஆனி","userNakshathra":"MIRUGASIRDAM\/மிருகசீரிடம்","userDate":"-- Not Found --","firstuserid":"60"},
  //     {"userName":"G S Raja","userEmail":"raja.gs@samantacomm.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AANI\/ஆனி","userNakshathra":"MAGAM\/மகம்","userDate":"-- Not Found --","firstuserid":"64"},
  //     {"userName":"abi","userEmail":"offlguy@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":" ","userTithi":" ","userMonth":"MAASI\/மாசி","userNakshathra":"THIRUVATHIRAI\/திருவாதிரை","userDate":"-- Not Found --","firstuserid":"65"},
  //     {"userName":"rishikesh","userEmail":"rishirvs1121@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AANI\/ஆனி","userNakshathra":"MIRUGASIRDAM\/மிருகசீரிடம்","userDate":"-- Not Found --","firstuserid":"66"},
  //     {"userName":"Rama","userEmail":"ramakannn@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AYPPASI\/ஐப்பசி","userNakshathra":"MIRUGASIRDAM\/மிருகசீரிடம்","userDate":"-- Not Found --","firstuserid":"67"},
  //     {"userName":"Surya","userEmail":"suryacbr@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":" ","userTithi":" ","userMonth":"PANGUNI\/பங்குனி","userNakshathra":"VISHAKA\/விசாகம்","userDate":"29-03-2024","firstuserid":"68"},
  //     {"userName":"Surya ","userEmail":"suryasundhar.sk@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AANI\/ஆனி","userNakshathra":"SADHAYAM\/சதயம்","userDate":"-- Not Found --","firstuserid":"69"},
  //     {"userName":"SIVAGURU","userEmail":"10.10.10dulkarsachin@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"KARTHHIGAI\/கார்த்திகை","userNakshathra":"AVITTAM\/அவிட்டம்","userDate":"-- Not Found --","firstuserid":"70"},
  //     {"userName":"Balasubrahmanyam ","userEmail":"sivaramabalu@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Telugu","nakshatratithi":"Tithi","userPaksham":"SHUKLA PAKSHA\/శుక్లపక్షం","userTithi":"ASHTAMI\/అష్టమి","userMonth":"Pushyamu\/పుష్యము","userNakshathra":null,"userDate":"-- Not Found --","firstuserid":"72"},
  //     {"userName":"Kiran","userEmail":"thinnaloorikiran7337@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Telugu","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"Ashadamu\/ఆషాఢము","userNakshathra":"Mrigashirsha\/మృగశిర","userDate":"-- Not Found --","firstuserid":"73"},
  //     {"userName":"Bhavani ","userEmail":"bhavanikrishnan69@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"CHITHIRAI\/சித்திரை","userNakshathra":"POORADAM\/பூராடம்","userDate":"-- Not Found --","firstuserid":"74"},
  //     {"userName":"Rishit ","userEmail":"vrishit2008@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Telugu","nakshatratithi":"Tithi","userPaksham":"SHUKLA PAKSHA\/శుక్లపక్షం","userTithi":"POURNAMI\/పౌర్ణమి\/పౌర్ణమి","userMonth":"Chaitramu\/చైత్రము","userNakshathra":null,"userDate":"-- Not Found --","firstuserid":"75"},
  //     {"userName":"Lakshmi","userEmail":"lakshmiramesh304@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AYPPASI\/ஐப்பசி","userNakshathra":"SADHAYAM\/சதயம்","userDate":"-- Not Found --","firstuserid":"76"},
  //     {"userName":"Akhila","userEmail":"akhi.lekha@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Telugu","nakshatratithi":"Tithi","userPaksham":"KRISHNA PAKSHA\/కృష్ణపక్షం","userTithi":"PANCHAMI\/పంచమి","userMonth":"Bhaadrapadamu\/భాద్రపదము","userNakshathra":null,"userDate":"-- Not Found --","firstuserid":"77"},
  //     {"userName":"Sarweswaran ","userEmail":"sarwesh.shiva003@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AANI\/ஆனி","userNakshathra":"UTHIRATTATHI\/உத்திரட்டாதி","userDate":"-- Not Found --","firstuserid":"78"},
  //     {"userName":"Shivani","userEmail":"shivgovind0013@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AYPPASI\/ஐப்பசி","userNakshathra":"ROHINI\/ரோகிணி","userDate":"21-10-2024","firstuserid":"79"},
  //     {"userName":"Govind","userEmail":"vittuvidhya@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"PURATTASI\/புரட்டாசி","userNakshathra":"MIRUGASIRDAM\/மிருகசீரிடம்","userDate":"-- Not Found --","firstuserid":"80"},
  //     {"userName":"gayathri","userEmail":"saibadrinath02@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AADI\/ஆடி","userNakshathra":"UTHIRADAM\/உத்திராடம்","userDate":"-- Not Found --","firstuserid":"81"},
  //     {"userName":"Nagalakshmi Govindraman ","userEmail":"bhavanig94@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AYPPASI\/ஐப்பசி","userNakshathra":"AYILYAM\/ஆயில்யம்","userDate":"-- Not Found --","firstuserid":"82"},
  //     {"userName":"Badhriprasath","userEmail":"raj0sathiya@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":" ","userTithi":" ","userMonth":"AADI\/ஆடி","userNakshathra":"POORADAM\/பூராடம்","userDate":"-- Not Found --","firstuserid":"83"},
  //     {"userName":"S.Govind","userEmail":"ritvikgovind2009@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"PURATTASI\/புரட்டாசி","userNakshathra":"MIRUGASIRDAM\/மிருகசீரிடம்","userDate":"-- Not Found --","firstuserid":"84"},
  //     {"userName":"Sujitha.G","userEmail":"rowdysuji75@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"THAI\/தை","userNakshathra":"ASWINI\/அஸ்வினி","userDate":"-- Not Found --","firstuserid":"85"},
  //     {"userName":"Sabarinathan","userEmail":"achusabarinathan@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AYPPASI\/ஐப்பசி","userNakshathra":"ASWINI\/அஸ்வினி","userDate":"-- Not Found --","firstuserid":"86"},
  //     {"userName":"Sabarinathan","userEmail":"achusabarinathan@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AYPPASI\/ஐப்பசி","userNakshathra":"ASWINI\/அஸ்வினி","userDate":"-- Not Found --","firstuserid":"87"},
  //     {"userName":"Ramya","userEmail":"sramya16071988@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AADI\/ஆடி","userNakshathra":"AYILYAM\/ஆயில்யம்","userDate":"-- Not Found --","firstuserid":"88"},
  //     {"userName":"Jayalakshmi ","userEmail":"sainethra29082017@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AANI\/ஆனி","userNakshathra":"ROHINI\/ரோகிணி","userDate":"03-07-2024","firstuserid":"89"},
  //     {"userName":"Bhavani","userEmail":"1971bhavanikumar@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"MARGAZHI\/மார்கழி","userNakshathra":"ASWINI\/அஸ்வினி","userDate":"-- Not Found --","firstuserid":"90"},
  //     {"userName":"Sreeman Sanjeevan","userEmail":"nsreeman4@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AAVANI\/ஆவணி","userNakshathra":"SWATHI\/சுவாதி","userDate":"-- Not Found --","firstuserid":"91"},
  //     {"userName":"Sreeman","userEmail":"nsreeman4@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AAVANI\/ஆவணி","userNakshathra":"SWATHI\/சுவாதி","userDate":"-- Not Found --","firstuserid":"92"},
  //     {"userName":"Surya","userEmail":"surya49official@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":" ","userTithi":" ","userMonth":"PANGUNI\/பங்குனி","userNakshathra":"VISAKAM\/விசாகம்","userDate":"-- Not Found --","firstuserid":"93"},
  //     {"userName":"Pooja Pophale","userEmail":"poojapophale@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Hindi","nakshatratithi":"Tithi","userPaksham":"KRISHNA PAKSHA\/कृष्ण पक्ष","userTithi":"ASHTAMI\/अष्टमी","userMonth":"CHAITRA\/चैत्र","userNakshathra":null,"userDate":"02-04-2024","firstuserid":"94"},
  //     {"userName":"Pragati Srinivasan","userEmail":"pragatis866@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AADI\/ஆடி","userNakshathra":"UTHIRADAM\/உத்திராடம்","userDate":"-- Not Found --","firstuserid":"95"}
  //     ]


  const [file, setFile] = useState(null);

  const onDrop = (acceptedFiles) => {
    const uploadedFile = acceptedFiles[0];
    setFile(uploadedFile);

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      // Send JSON data to the server
      axios.post('https://backend.shubadinam.com/upload', jsonData)
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error('Error uploading data:', error);
        });
    };

    reader.readAsArrayBuffer(uploadedFile);
  };





  return (
    //     <div>
    //       <input
    //         className="search-bar"
    //         type="search"
    //         placeholder="🔍 Search anything from the table..."
    //         value={search}
    //         onChange={(e) => setSearch(e.target.value)}
    //       />
    //       <table>
    //         <thead>
    //           <tr>
    //             <th>ID</th>
    //             <th>Name</th>
    //             <th>Age</th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {currentData.map((object, index) => (
    //             <tr key={index}>
    //               <td>{object.id}</td>
    //               <td>{object.name}</td>
    //               <td>{object.age}</td>
    //             </tr>
    //           ))}
    //         </tbody>
    //       </table>
    //       <div className="pagination-container">
    //         <ReactPaginate
    //           previousLabel={'Previous'}
    //           nextLabel={'Next'}
    //           pageCount={Math.ceil(filteredData.length / rowsPerPage)}
    //           onPageChange={handlePageChange}
    //           containerClassName={'pagination'}
    //           activeClassName={'active'}
    //           breakClassName={'break-me'}
    //         />
    //       </div>
    //     </div>
    <div>
      <div >
        <Dropzone onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
            </section>
          )}
        </Dropzone>
      </div></div>
  );
};

export default RoughThree;


// import React, { useState, useEffect } from 'react';
// import ReactPaginate from 'react-paginate';

// const RoughThree = () => {
//   const rowsPerPage = 8; // Define rowsPerPage within the component

//   const [search, setSearch] = useState('');
//   const [data, setData] = useState([]); // State to hold data from localStorage
//   const [filteredData, setFilteredData] = useState([]);
//   const [currentPage, setCurrentPage] = useState(0);

//   useEffect(() => {
//     const storedData = localStorage.getItem("myDataKey");
//     if (storedData) {
//       setData(JSON.parse(storedData));
//     }
//   }, []);

//   useEffect(() => {
//     // Filter data based on search term
//     const filtered = data.filter(item => {
//       const searchableFields = ['relName', 'userEmail', 'relGender', 'relRelation']; // Fields to search
//       return searchableFields.some(field =>
//         item[field]?.toString().toLowerCase().includes(search.toLowerCase())
//       );
//     });

//     setFilteredData(filtered);
//   }, [search, data]);

//   const handlePageChange = ({ selected }) => {
//     setCurrentPage(selected);
//   };

//   const offset = currentPage * rowsPerPage;
//   const currentData = filteredData.slice(offset, offset + rowsPerPage);


//   const [isOpen, setIsOpen] = useState(false);

//   const togglePopup = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <div>
//       <input
//         className="search-bar"
//         type="search"
//         placeholder="🔍 Search anything from the table..."
//         value={search}
//         onChange={(e) => setSearch(e.target.value)}
//       />
//       <table>
//         <thead>
//           <tr>
//             <th>ID</th>
//             <th>Name</th>
//             <th>Email</th>
//             <th>Gender</th>
//             <th>Relation</th>
//           </tr>
//         </thead>
//         <tbody>
//           {currentData.map((item, index) => (
//             <tr key={index}>
//               <td>{item.Id}</td>
//               <td>{item.relName}</td>
//               <td>{item.userEmail}</td>
//               <td>{item.relGender}</td>
//               <td>{item.relRelation}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <div className="pagination-container">
//         <ReactPaginate
//           previousLabel={'Previous'}
//           nextLabel={'Next'}
//           pageCount={Math.ceil(filteredData.length / rowsPerPage)}
//           onPageChange={handlePageChange}
//           containerClassName={'pagination'}
//           activeClassName={'active'}
//           breakClassName={'break-me'}
//         />
//       </div>



//       <div>
//       {/* Button to toggle the popup */}
//       <button onClick={togglePopup}>
//         {isOpen ? 'Close Popup' : 'Open Popup'}
//       </button>

//       {/* Popup */}
//       {isOpen && (
//         <div className="popup">
//           {/* Content of the popup */}
//           <p>This is a popup!</p>
//         </div>
//       )}
//     </div>




//     </div>
//   );
// };

// export default RoughThree;


// import * as React from 'react';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import { useState } from 'react';

// export default function RoughThree() {
//   const [age, setAge] = React.useState('');

//   const handleChange = (event) => {
//     setAge(event.target.value);
//   };


//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

//   const showDiv = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };

//   return (
//     <div>
//       <FormControl sx={{ m: 1, minWidth: 80 }}>
//         <InputLabel id="demo-simple-select-autowidth-label">Age</InputLabel>
//         <Select
//           labelId="demo-simple-select-autowidth-label"
//           id="demo-simple-select-autowidth"
//           value={age}
//           onChange={handleChange}
//           autoWidth
//           label="Age"
//         >
//           <MenuItem value="">
//             <em>None</em>
//           </MenuItem>
//           <MenuItem value={10}>Twenty</MenuItem>
//           <MenuItem value={21}>Twenty one</MenuItem>
//           <MenuItem value={22}>Twenty one and a half</MenuItem>
//         </Select>
//       </FormControl>

//    <div>
//       <div className='div1' onClick={showDiv}>
//         This is div one
//       </div>
//       <div className='div2' onClick={showDiv} style={{
//         backgroundColor: 'rebeccapurple',
//         padding: '20px',
//         transition: 'transform 2s ease-out',
//         transform: isDropdownOpen ? 'translateY(0)' : 'translateY(100%)'
//       }}>
//         This is div 2
//       </div>
//     </div>



//     </div>
//   );
// }