import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { NavLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';

import {
    CitySelect,
    CountrySelect,
    StateSelect,
    LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";


const PremiumAlert = () => {

    const [countryid, setCountryid] = useState(0);
    const [stateid, setstateid] = useState(0);
    const [nakTithi, SetNakTithi] = useState()
    const [isPremium, setPremium] = useState(true)


    const sentMail = localStorage.getItem("fkey")   // sentMail :this is the mail of the user,sent while first login
    const defaultEmail = atob(sentMail).replace(/"/g, '').trim();

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));


    const navigate = useNavigate();

    const [open, setOpen] = useState(true);



    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const gotoPricing = () => {
        navigate("/premium-checkout")
    }

    return (

        <>



            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2, fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", fontWeight: 600, color: "rgb(49, 159, 255)" }} id="customized-dialog-title">
                    <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" style={{ marginRight: "10px" }} viewBox="0 0 24 24">
                        <path fill="currentColor" d="M5 16L3 5l5.5 5L12 4l3.5 6L21 5l-2 11zm14 3c0 .6-.4 1-1 1H6c-.6 0-1-.4-1-1v-1h14z"></path>
                    </svg>
                    BUY PREMIUM
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>

                    <Typography gutterBottom>
                        This is a Premium feature. Use this Nakshatra/Tithi finder Assistance to get the details as per English Date of Birth. Experience many more exclusive benefits & Unlock the Full potential by upgrading to Premium.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    {/* <Button autoFocus onClick={handleClose}> */}
                    <button class="premium-button" onClick={() => gotoPricing()}>
                        <svg viewBox="0 0 576 512" height="1em" class="logoIcon"><path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"></path></svg>
                        GO PREMIUM
                    </button>
                    {/* </Button> */}
                </DialogActions>
            </BootstrapDialog >

            <div className="signup-content">

                <div className="signup-box-image">

                </div>
                <div className="signup-box">
                    <div className="title-profile">
                        <h2 className="letters-color-prf-head">Profile</h2>
                    </div>
                    <div className="title-name">
                        <div className="key-title letters-color">Name:</div>
                        <div className="value-input">
                            <input className="singup-input-box" type="text" name="userName" readOnly />
                        </div>
                    </div>
                    <div className="title-email">
                        <div className="key-title letters-color" >Email:</div>
                        <div className="value-input">
                            <input className="singup-input-box" type="email" name="userEmail" placeholder="please enter Email" readOnly />
                        </div>
                    </div>
                    <div className="title-gender">
                        <div className="key-title letters-color">Gender:</div>
                        <div className="value-input">
                            <input className="singup-input-box" type="text" name="userName" placeholder='select gender' readOnly />

                        </div>
                    </div>

                    <div className="title-gender">
                        <div className="key-title letters-color">Country:</div>
                        <div className="value-input" disabled>
                            <input className="singup-input-box" type="text" name="userName" placeholder='select country' readOnly />

                        </div>
                    </div>
                    <div className="title-gender">
                        <div className="key-title letters-color">State:</div>
                        <div className="value-input">
                            <input className="singup-input-box" type="text" name="userName" placeholder='select state' readOnly />


                        </div>
                    </div>

                    <div className="title-relation">
                        <div className="key-title letters-color">Relation:</div>
                        <div className="value-input">
                            <input className="singup-input-box" type="text" name="userName" placeholder='select relation' readOnly />

                        </div>
                    </div>

                    {

                        isPremium ?
                            <>
                                <div className="title-name">
                                    <div className="key-title letters-color">Date & Time:</div>
                                    <div className="value-input">
                                        <input className="singup-input-box" type='datetime-local' placeholder="--Select Date & Time" name="userDateTime"></input>
                                    </div>
                                </div>

                            </>
                            :

                            <>


                                <div className="title-nakshatra-tithi">
                                    <div className="key-title letters-color">What do you celebrate:</div>
                                    <div className="value-input">
                                        <select defaultValue={""} className="singup-input-box" name="nakshatraTithi" >
                                            <option disabled value="">--Nakshatra/Tithi--</option>
                                            <option value="Nakshatra">Nakshatra</option>
                                            <option value="Tithi">Tithi</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="title-calender">
                                    <div className="key-title letters-color">Panchang:</div>
                                    <div className="value-input">
                                        <select defaultValue={""} className="singup-input-box" name="userCalender" >
                                            <option disabled value={""}>--Panchang--</option>

                                        </select>
                                    </div>
                                </div>
                                <div className="title-month">
                                    <div className="key-title letters-color">Month:</div>
                                    <div className="value-input">
                                        <select defaultValue={""} className="singup-input-box" name="userMonth" >
                                            <option disabled value="">--Month--</option>

                                        </select>
                                    </div>
                                </div>


                                {
                                    nakTithi && nakTithi === "Nakshatra" ?

                                        <div className="title-nakshathra">
                                            <div className="key-title letters-color">Nakshatra:</div>
                                            <div className="value-input">
                                                <select defaultValue={""} className="singup-input-box" name="userNakshathra">
                                                    <option disabled value={""}>--Nakshatra--</option>

                                                </select>
                                            </div>
                                        </div>
                                        :
                                        <div></div>
                                }


                                {
                                    nakTithi && nakTithi === "Tithi" ?
                                        <div className="nakandtithi">
                                            <div className="title-Paksham">
                                                <div className="key-title letters-color">Paksha:</div>
                                                <div className="value-input">
                                                    <select defaultValue={""} className="singup-input-box" name="userPaksham">
                                                        <option disabled value={""}>--Paksha--</option>

                                                    </select>
                                                </div>
                                            </div>

                                            <div className="title-Tithiname">
                                                <div className="key-title letters-color">Tithi:</div>
                                                <div className="value-input">
                                                    <select defaultValue={""} className="singup-input-box" name="userTithi">
                                                        <option disabled value={""}>--Tithi--</option>

                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div></div>
                                }

                                <br />

                                <span style={{ fontSize: '14px', color: '#666', fontStyle: 'italic' }}>
                                    (Note: Please fill out all the fields to continue.) *
                                </span>


                                {/* <br />
                                <label className="letters-color profile-buy-link">If you don't know the above details ,
                                    <ProfilePremiumDialog />
                                </label>
                                <br /> */}


                            </>
                    }



                    <div className="title-signup-btn">
                        <button
                            // onClick={() => signInHome()}
                            className="signup-btn-style"

                            disabled
                        >Submit</button>
                    </div>


                </div>


            </div>
        </>
    );
};

export default PremiumAlert;