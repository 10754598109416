import React, { useState, useEffect } from 'react';
import SDadminHeader from './SDadminHeader';
import Top20px from '../screens/Top20px';
import { Stack, Typography, TextField, Button } from '@mui/material';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import * as XLSX from 'xlsx';




const HomamBookersReport = () => {
    const [selectedDate, setSelectedDate] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    // Effect to set today's date as the default value
    // useEffect(() => {
    //     const today = new Date();

    //     const day = String(today.getUTCDate()).padStart(2, '0');
    //     const month = String(today.getUTCMonth() + 1).padStart(2, '0');
    //     const year = today.getUTCFullYear();

    //     const formattedDate = `${year}-${month}-${day}`;

    //     setSelectedDate(formattedDate);
    // }, []);

    // Effect to fetch data once
    useEffect(() => {
        const url = `https://backend.shubadinam.com/getbookingsreport`;
        axios.get(url)
            .then((response) => {
                console.log(response.data);
                setData(response.data);
                setFilteredData(response.data); // Initialize filtered data with all fetched data
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    // Filter data based on selected date
    useEffect(() => {
        if (selectedDate) {
            const filtered = data.filter(item => {
                const entryDate = new Date(item.entry_time).toISOString().split('T')[0];
                return entryDate === selectedDate;
            });
            setFilteredData(filtered);
        }
    }, [selectedDate, data]);

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const getMonthName = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const options = { month: 'long' };
        return date.toLocaleDateString(undefined, options);
    };

    const selectedMonth = getMonthName(selectedDate);


    const downloadExcel = () => {
        const transformedData = filteredData.map((customer, index) => ({
            "S.No": index + 1,
            "Name": customer.customersName,
            "Nakshatra": customer.customersNakshatra,
        }));

        const worksheet = XLSX.utils.json_to_sheet(transformedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Homam Bookings");
        XLSX.writeFile(workbook, "Homam_Bookings_Report.xlsx");
    };


    return (
        <div className="sd-adminpage">
            <header className="sd-header">
                <SDadminHeader />
            </header>
            <Top20px />
            <Top20px />
            <Stack direction={{ sm: "column", md: "row" }} spacing={60} sx={{ p: 2, mt: 5 }}>
                <Typography variant='h6'>Homam Bookings Report</Typography>
                <Stack direction={{ sm: "column", md: "column" }} sx={{ p: 2, mt: 2 }}>
                    <TextField
                        label="Date"
                        type="date"
                        size='small'
                        defaultValue={selectedDate}
                        value={selectedDate}
                        onChange={handleDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ width: 250, textAlign: "end" }}
                    />
                    {selectedDate && (
                        <Typography variant="body1" sx={{ width: 250, textAlign: "end", mt: 1 }}>
                            Selected Month: {selectedMonth}
                        </Typography>
                    )}

                    <Button
                        variant="outline"
                        color="primary"
                        onClick={downloadExcel}
                        sx={{ mt: 2 }}
                    >
                        Download Excel
                    </Button>
                </Stack>
            </Stack>
            <TableContainer component={Paper} sx={{ margin: 'auto', }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: "50px" }}>S.No</TableCell>
                            <TableCell align='center'>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Nakshatra</TableCell>
                            <TableCell>Homam Date</TableCell>
                            <TableCell>Entry Time</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((customer, index) => (
                            <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{customer.uniqueId}</TableCell>
                                <TableCell>{customer.customersName}</TableCell>
                                <TableCell>{customer.customersNakshatra}</TableCell>
                                <TableCell>{customer.homam_date}</TableCell>
                                <TableCell>
                                    {new Date(customer.entry_time).toLocaleString("en-GB", { dateStyle: "short" })}

                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};


export default HomamBookersReport;