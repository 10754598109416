import React, { useState } from 'react';
import SDadminHeader from './SDadminHeader';
import Top20px from '../screens/Top20px';
import axios from "axios";
import { Button, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ManageCustomer = () => {



    const [age, setAge] = useState('');



    const [chngPwd, SetPwd] = useState(false);
    const [confirmPassword, SetConfirmPassword] = useState({
        // oldPwd: "",
        confirmPwd: "",
        reconfirmPwd: "",
    });


    const togglePassword = () => {
        SetPwd(true)
    }


    const pwdhandler = (evt) => {
        SetConfirmPassword({ ...confirmPassword, [evt.target.name]: evt.target.value });
    };

    const clickSubmit = () => {
        if (confirmPassword.confirmPwd !== "") {
            if (confirmPassword.confirmPwd === confirmPassword.reconfirmPwd) {

                const url = `https://backend.shubadinam.com/adminaccess/upt/password`;

                const adminId = sessionStorage.getItem("adminId")

                axios
                    .post(url, { ...confirmPassword, adminId: adminId })
                    .then((response) => {
                        // console.log(response);
                        alert(response.data.message)
                        // alert("Password updted successfully.");
                    })
                    .catch((error) => {
                        alert(error.response.data.message)
                        // console.log(error);
                    });

                SetPwd(false);
            } else {
                alert("not equal");
                SetPwd(false);
            }
        } else {
            alert("password field is empty");
            SetPwd(false);
        }

    };

    const clickClose = () => {
        SetPwd(false)
    };

    const handlePopupClick = (e) => {
        // Prevent the click event from reaching the pwd-container
        e.stopPropagation();
    };

    const [dynamicState, setdynamicState] = useState({
        // oldPwd: "",
        userDetail: "",
        searchType: "",
    });

    const handleChange = (event) => {
        setdynamicState({ ...dynamicState, [event.target.name]: event.target.value })
    };

    const handleClear = (event) => {
        setdynamicState({ ...dynamicState, [event.target.name]: "" })
        window.location.reload();
    };

    const handleSearch = (event) => {
        event.preventDefault();

        // console.log(dynamicState.searchType);

        const url = `https://backend.shubadinam.com/getuserdetails?userDetail=${dynamicState.userDetail}&searchType=${dynamicState.searchType}`;

        axios.get(url)
            .then((response) => {
                // console.log(response.data);

                if (
                    response.data.userEmailResult.length === 0 &&
                    response.data.userProfileResult.length === 0 &&
                    response.data.userRelResult.length === 0 &&
                    response.data.premiumUserResult.length === 0 &&
                    response.data.premTabResult.length === 0
                ) {
                    alert('No matches found in database !');
                    const element1 = document.getElementById("outlined-password-input");
                    if (element1) {
                        element1.value = '';
                    } else {
                        // console.log('Element with ID "outlined-password-input" not found.');
                    }
                    return;

                }

                const userCredent = response.data.userEmailResult[0]
                const userProfile = response.data.userProfileResult[0]
                const userRel = response.data.userRelResult
                const userPremiumtable = response.data.premTabResult
                const userPremiumuser = response.data.premiumUserResult[0]

                sessionStorage.setItem('userCredent', JSON.stringify(userCredent));
                sessionStorage.setItem('userProfile', JSON.stringify(userProfile));
                sessionStorage.setItem('userRel', JSON.stringify(userRel));
                sessionStorage.setItem('userPremiumtable', JSON.stringify(userPremiumtable));
                sessionStorage.setItem('userPremiumuser', JSON.stringify(userPremiumuser));

                if (userCredent, userProfile) {
                    window.location.href = '/manage-customers-details';
                }


            })
            .catch((error) => {
                // console.log(error);
                if (error.response && error.response.status === 500) {
                    alert("Invalid search type. Please try again!");
                } else {
                    alert("You are facing an unexpected issue. Please try again later!");
                }
            })
    };

    return (
        <div className="sd-adminpage">
            <header className="sd-header">
                <SDadminHeader clickPass={togglePassword} />
            </header>
            <Top20px />
            <div className="sd-body-database">

                <div className="sd-body-database-container">
                    <h3 style={{ textAlign: "center", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> Manage Customer  </h3>
                    <br />

                    <Box sx={{ width: 350, height: 350, border: "1px solid #eeeeee", display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>


                        <InputLabel id="demo-simple-select-label">User :</InputLabel>
                        <TextField
                            fullWidth
                            id="outlined-password-input"
                            label="User Details"
                            type="text"
                            name="userDetail"
                            onChange={(event) => handleChange(event)}
                            required
                        />

                        <InputLabel id="demo-simple-select-label">Search Type :</InputLabel>
                        <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={dynamicState.searchType}
                            label="Search Type"
                            name="searchType"
                            onChange={(event) => handleChange(event)}
                            required
                        >
                            <MenuItem value={10}>By Email</MenuItem>
                            <MenuItem value={20}>By Id</MenuItem>
                        </Select>

                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                            <Button variant="secondary" style={{ backgroundColor: "#c7c7c7ee" }} onClick={(event) => handleClear(event)}>Clear</Button>
                            <Button variant="primary" style={{ backgroundColor: "#0c0036", color: "white" }} onClick={(e) => handleSearch(e)}>Submit</Button>
                        </Box>
                    </Box>

                </div>
            </div>



            {
                chngPwd ?
                    <div className="pwd-container" onClick={clickClose}>
                        <div className="pwd-popup" onClick={handlePopupClick}>

                            <label>Enter your New Password :</label>
                            <input type="text" name="confirmPwd" onChange={pwdhandler} />
                            <br />
                            <label>Reconfirm your Password :</label>
                            <input type="text" name="reconfirmPwd" onChange={pwdhandler} />
                            <br />
                            <button onClick={() => clickSubmit()}>Submit</button>
                            <button style={{ marginLeft: "10px", backgroundColor: "#ffd7d7", color: "black" }} onClick={() => clickClose()}>Close</button>

                        </div>
                    </div>
                    :
                    <span></span>
            }
        </div>
    );
};

export default ManageCustomer;