import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Box, Button, TextField, Typography } from '@mui/material';
import SDadminHeader from '../admin/SDadminHeader';


const AdminAccess = () => {

    const navigate = useNavigate()


    const [adminDetails, SetAdminDetails] = useState({
        adminUserId: "",
        adminPassword: ""
    })

    const inputHandle = (event) => {
        SetAdminDetails({ ...adminDetails, [event.target.name]: event.target.value })
    }

    const handleEnterKey = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent default behavior (e.g., form submission)
            event.stopPropagation(); // Stop event propagation

            clickSubmit();
        }
    };

    const clickClose = () => {
        navigate
            ("/")
    }


    const clickSubmit = () => {
        // console.log(adminDetails);
        sessionStorage.setItem("adminId", adminDetails.adminUserId)

        const url = `https://backend.shubadinam.com/adminaccess?adminUserId=${adminDetails.adminUserId}&adminPassword=${adminDetails.adminPassword}`;

        axios.get(url)
            .then((response) => {
                const value = response.data
                if (value.message === "passcodefail") {
                    Swal.fire({
                        title: 'Authorization Revoked',
                        text: "Please provide valid password !",
                        icon: 'error'
                    });
                }
                else if (value.message === "no user") {
                    Swal.fire({
                        title: 'Authorization Revoked',
                        text: "User not found !",
                        icon: 'error'
                    });
                }
                else {
                    // console.log(value);
                    navigate("/adminpage")
                }

            })
            .catch((error) => {
                // console.log(error);
                Swal.fire({
                    // title: 'Email Verification',
                    text: "Authorization Revoked",
                    icon: 'error'
                });
            })
    }


    return (

        <div className="overall-admin-container">
            {/* <SDadminHeader /> */}
            {/* <div className="adaccess-form-container">
                <label for="adaccess-adminUserId">Enter your AdminId</label>
                <input type='text' id='adaccess-adminUserId' placeholder='Enter your AdminId' name='adminUserId' className='adaccess-input-field' onChange={inputHandle} onKeyPress={handleEnterKey} />
                <br />
                <br />
                <label for="adaccess-adminPassword">Enter your Password</label>
                <input type='password' id='adaccess-adminPassword' placeholder='Enter your Password' name='adminPassword' className='adaccess-input-field' onChange={inputHandle} onKeyPress={handleEnterKey} />
                <br />
                <button type='submit' className='adaccess-submit-button' onClick={() => clickSubmit()}>Submit</button>
            </div>
            <button style={{ backgroundColor: "#ffd7d7", padding: "10px", borderRadius: "7px" }} onClick={() => clickClose()}>Back to home</button>
            <br /> */}



            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '100vh',
                    p: 2,
                }}
            >
                <Box
                    sx={{
                        bgcolor: '#fff',
                        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)', // Subtle shadow
                        borderRadius: '15px', // More rounded corners
                        p: 5,
                        maxWidth: 400,
                        width: '100%',
                        textAlign: 'center',
                    }}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            mb: 4,
                            fontWeight: '600',
                            color: '#333',
                            fontFamily: 'Roboto, sans-serif',
                        }}
                    >
                        Admin Login
                    </Typography>

                    <TextField
                        fullWidth
                        id="adaccess-adminUserId"
                        label="Admin ID"
                        name="adminUserId"
                        placeholder="Enter your Admin ID"
                        variant="outlined"
                        margin="normal"
                        onChange={inputHandle}
                        onKeyPress={handleEnterKey}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '10px', // Rounded input fields
                            },
                        }}
                    />

                    <TextField
                        fullWidth
                        id="adaccess-adminPassword"
                        label="Password"
                        name="adminPassword"
                        placeholder="Enter your Password"
                        variant="outlined"
                        type="password"
                        margin="normal"
                        onChange={inputHandle}
                        onKeyPress={handleEnterKey}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '10px',
                            },
                        }}
                    />

                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{
                            mt: 3,
                            p: 1.5,
                            fontWeight: 'bold',
                            borderRadius: '10px',
                            backgroundColor: '#2193b0',
                            '&:hover': {
                                color: "white"
                            },
                        }}
                        onClick={clickSubmit}
                    >
                        Submit
                    </Button>

                    <Button
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        sx={{
                            mt: 2,
                            p: 1.5,
                            fontWeight: 'bold',
                            borderRadius: '10px',
                            color: '#2193b0',
                            borderColor: '#2193b0',
                            '&:hover': {
                                backgroundColor: '#fff2f2', // Light background on hover
                                borderColor: 'red',
                            },
                        }}
                        onClick={clickClose}
                    >
                        Back to Home
                    </Button>
                </Box>
            </Box>
        </div>
    );
};

export default AdminAccess;