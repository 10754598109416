import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const RoughOne = () => {
    // const [astrologyData, setAstrologyData] = useState(null);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch('/api/astrology');
    //             console.log('Received astrology API response:', response);

    //             if (!response.ok) {
    //                 throw new Error(`Failed to fetch astrology data. Status: ${response.status}`);
    //             }

    //             const data = await response.json();
    //             console.log('Received astrology API data:', data);
    //             setAstrologyData(data);
    //         } catch (error) {
    //             console.error('Error fetching astrology data:', error);
    //         }
    //     };

    //     fetchData();
    // }, []);

    const [birthDetails, setBirthDetails] = useState({
        datetime: '2012-01-01T12:00:00Z',
        latitude: '20.7128',
        longitude: '-74.0060',
        timezone: 'America/New_York',
    });


    const [astrologyData, setAstrologyData] = useState(null);


    const handleGetAstrologyData = async () => {

        try {
            const response = await axios.get('https://backend.shubadinam.com/getAstrologyData', {
                params: {
                    datetime: birthDetails.datetime,
                    ayanamsa: 1, // Replace with the appropriate value
                    coordinates: `${birthDetails.latitude},${birthDetails.longitude}`,
                },
            });

            console.log('Full Response:', response);
            setAstrologyData(response.data);
        } catch (error) {
            console.error('Frontend error:', error.message);
        }
    };

    // useEffect(() => {
    //     handleGetAstrologyData();
    //   }, [birthDetails]);

    const handleDatetimeChange = (e) => {
        // Format the date to (YYYY-MM-DDTHH:MM:SSZ)
        const formattedDatetime = new Date(e.target.value).toISOString();
        console.log(formattedDatetime);
        setBirthDetails({ ...birthDetails, datetime: formattedDatetime });
        console.log(e.target.value);
    };

    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };
    const settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        // <>
        //     <table class="table table-striped w-auto">
        //         <thead class="bg-light sticky-top">
        //             <tr>
        //                 <th>header</th>
        //                 <th>header</th>
        //                 <th>header</th>
        //                 <th>header</th>
        //                 <th>header</th>
        //                 <th>header</th>
        //                 <th>header</th>
        //                 <th>header</th>
        //                 <th>header</th>
        //                 <th>header</th>
        //             </tr>
        //         </thead>
        //         <tbody>
        //             <tr>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //             </tr>
        //             <tr>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //             </tr>
        //             <tr>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //             </tr>
        //             <tr>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //             </tr>
        //             <tr>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //             </tr>
        //             <tr>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //             </tr>
        //             <tr>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //             </tr>
        //             <tr>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //             </tr>
        //             <tr>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //             </tr>
        //             <tr>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //             </tr>
        //             <tr>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //             </tr>
        //             <tr>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //             </tr>
        //             <tr>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //             </tr>
        //             <tr>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //             </tr>
        //             <tr>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //             </tr>
        //             <tr>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //             </tr>
        //             <tr>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //             </tr>
        //             <tr>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //             </tr>
        //             <tr>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //             </tr>
        //             <tr>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //                 <td>Cell</td>
        //             </tr>
        //         </tbody>
        //     </table>
        // </>
        <>
            <button class="pricing-date-button">
                Get in touch
                <div class="pricing-date-button-hoverEffect">
                    <div>
                    </div>
                </div></button>
        </>

        // <div className="slider-container">
        //     <Slider
        //         ref={slider => {
        //             sliderRef = slider;
        //         }}
        //         {...settings}
        //     >
        //         <div key={1} >
        //             <h3>1</h3>
        //         </div>
        //         <div key={2}>
        //             <h3>2</h3>
        //         </div>
        //         <div key={3}>
        //             <h3>3</h3>
        //         </div>
        //         <div key={4}>
        //             <h3>4</h3>
        //         </div>
        //         <div key={5}>
        //             <h3>5</h3>
        //         </div>
        //         <div key={6}>
        //             <h3>6</h3>
        //         </div>
        //     </Slider>
        //     <div style={{ textAlign: "center" }}>
        //         <button className="button" onClick={previous}>
        //             Previous
        //         </button>
        //         <button className="button" onClick={next}>
        //             Next
        //         </button>
        //     </div>
        // </div>



        // <Carousel centerMode centerSlidePercentage={100}>
        //     {testimonials.map(testimonial => (
        //         <div className='testimonial-h' key={testimonial.id} style={{
        //             background: testimonial.background, // Use the background property
        //             backgroundSize: 'cover',
        //             backgroundPosition: 'center',
        //             // padding: '20px',
        //         }}>
        //             <img src={testimonial.profilePic} alt={testimonial.author} className="testimonial-profile-pic" onContextMenu={(e) => e.preventDefault()} />
        //             {/* <p className="testimonial-text">{testimonial.text}</p> */}
        //             {/* <p className="testimonial-author">- {testimonial.author}</p> */}
        //         </div>
        //     ))}
        // </Carousel>


    );

    // return (
    //     // <div>
    //     //     <h1>Prokerala API Example</h1>
    //     //     <div>
    //     //         <label>Date and Time:</label>
    //     //         <input
    //     //             type="datetime-local"
    //     //             value={birthDetails.datetime.substring(0, 16)} // Truncate seconds and timezone
    //     //             onChange={handleDatetimeChange}
    //     //         />
    //     //     </div>
    //     //     <div>
    //     //         <label>Latitude:</label>
    //     //         <input
    //     //             type="text"
    //     //             value={birthDetails.latitude}
    //     //             onChange={(e) => setBirthDetails({ ...birthDetails, latitude: e.target.value })}
    //     //         />
    //     //     </div>
    //     //     <div>
    //     //         <label>Longitude:</label>
    //     //         <input
    //     //             type="text"
    //     //             value={birthDetails.longitude}
    //     //             onChange={(e) => setBirthDetails({ ...birthDetails, longitude: e.target.value })}
    //     //         />
    //     //     </div>
    //     //     <div>
    //     //         <label>Timezone:</label>
    //     //         <input
    //     //             type="text"
    //     //             value={birthDetails.timezone}
    //     //             onChange={(e) => setBirthDetails({ ...birthDetails, timezone: e.target.value })}
    //     //         />
    //     //     </div>
    //     //     <button onClick={handleGetAstrologyData}>Get Astrology Data</button>
    //     //     {astrologyData && (
    //     //         <div>
    //     //             <h2>Astrology Data</h2>
    //     //             <pre>{JSON.stringify(astrologyData, null, 2)}</pre>
    //     //         </div>
    //     //     )}
    //     // </div>

    //     <></>
    // );
};

export default RoughOne;