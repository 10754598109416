// React Frontend

import React, { useEffect } from "react";
import axios from "axios";

const PaymentController = () => {
  useEffect(() => {
    const paymentPayload = {
      merchantId: "M2306160483220675579140",
      transactionId: "TX1234",
      merchantOrderId: "ORDERID",
      merchantUserId: "TXX",
      amount: 100,
      paymentSources: [
        {
          mode: "card",
          cardId: "C1234",
          cardIssuer: "VISA",
          type: "DEBIT_CARD",
          cvv: "123",
          cardHolderName: "PhonePe User",
        },
        {
          mode: "wallets",
          providers: ["PHONEPE"],
        },
      ],
    };

    const fetchData = async () => {
      try {
        const response = await axios.post(
          "/api/process-payment",
          paymentPayload
        );
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return <></>;
};

export default PaymentController;
