import React, { useState, useEffect, useRef } from "react";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, fa1, fa2, fa3, faUser, faL } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from "@fortawesome/fontawesome-free"

import ScrollToTop from "react-scroll-to-top";

import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

import FooterComponent from "../components/footer";
import HomeSignInComponent from "../components/home-signin";
import TestimonialScreen from "./testimonials";
import Swal from 'sweetalert2';
// import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import LanguageSwitcher from "./LanguageSwitcher";
import MenuBar from "./menubar";
import Blogs from "./blogs";
import Coverflow from "./Coverflow";
import CenteredSlider from "./CenteredSlider";





AOS.init({
    duration: 1000, // Notice the single quotes around '2s'
});




const Dashboard = () => {
    return (
        <div>
            
        </div>
    );
};

export default Dashboard;