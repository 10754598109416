import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useRef } from "react";
import Swal from "sweetalert2";
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import SecondDataSharing from "../context/second-data-sharing";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faTrashCan,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";

import ScrollToTop from "react-scroll-to-top";
import Top10px from "./Top10px";

import OtpInput from 'react-otp-input';
import LandingHeader from "../components/LandingHeader";
import ProfileHeader from "../components/ProfileHeader";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { Helmet, HelmetProvider } from 'react-helmet-async';


const MyProfile = () => {

  const [countryValue, setCountryValue] = useState(null);
  const [stateValue, setStateValue] = useState(null);

  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);

  const [userDetails, SetUserDetails] = useState();
  const [userDetailsTable, SetUserDetailsTable] = useState();

  const mainId = userDetails && userDetails.uniqueId
  // console.log(mainId);

  const userkeyid = localStorage.setItem("userkeyid", btoa(mainId))

  useEffect(() => {
    const fkey = localStorage.getItem("fkey");

    if (fkey) {
      const profileEmail = atob(fkey).replace(/"/g, "").trim();
      // console.log(profileEmail);

      const url = `https://backend.shubadinam.com/profiledetails?customerMail=${profileEmail}`;

      axios
        .get(url)
        .then((response) => {

          // console.log(response.data);
          // console.log(response.data.emailName[0].userEmail);
          // console.log(response.data.userTable);

          const fromMail = response.data.emailName[0];
          const usertable = response.data.userTable[0];

          setCountryValue(response.data.emailName[0].userCountry);
          setStateValue(response.data.emailName[0].userState);



          SetUserDetails(fromMail);
          SetUserDetailsTable(usertable);

          // console.log(fromMail);
          // console.log(userDetails);
          // console.log(userDetailsTable);
        })
        .catch((error) => {
          console.error("Error fetching profile details:", error);
        });
    } else {
      // alert("Please login again, some issue exists");
      signOut();
      Navigate("/");
    }
  }, []);

  const tableTwoData = useContext(SecondDataSharing);
  // console.log(tableTwoData);

  const Navigate = useNavigate();

  const signOut = () => {
    setSignOut(false);
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("fkey");
    localStorage.removeItem("homeauth")
    // Navigate("/");
  };

  const logoBtn = () => {
    Navigate("/");
  };

  // MENU BAR

  const [isOpenMenu, setOpenMenu] = useState(false);

  const [dashsignOut, setSignOut] = useState(false);

  const [selectedPic, setSelectedPic] = useState(""); // Initialize as an empty string
  const [userImage, setUserImage] = useState({});

  const handlePicChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataURL = reader.result;
        // console.log(dataURL);

        setSelectedPic(dataURL);

        const img = localStorage.setItem("Uimg", dataURL);

        // const fkey = localStorage.getItem("fkey");
        // const profileEmail = atob(fkey).replace(/"/g, "").trim();

        // const url = "https://backend.shubadinam.com/userprofilepic";

        // axios
        //   .post(url, { userprofilepic: dataURL, emailName: profileEmail })
        //   .then((response) => {
        //     console.log(response.data);
        //     console.log(response.data.res2[0]);
        //     const userPP = response.data.res2[0];
        //     setUserImage(userPP);
        //     console.log(userImage);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
      };
      reader.readAsDataURL(file);
    }

    window.location.reload();
  };

  useEffect(() => {
    const fkey = localStorage.getItem("fkey");
    const profileEmail = atob(fkey).replace(/"/g, "").trim();

    const userpic = localStorage.getItem("Uimg");

    if (userpic && fkey) {
      const url = "https://backend.shubadinam.com/userprofilepic";

      axios
        .post(url, { userprofilepic: userpic, emailName: profileEmail })
        .then((response) => {
          console.log(response.data);
          console.log(response.data.res2[0]);
          const userPP = response.data.res2[0];
          setUserImage(userPP);

          // window.location.reload();

          // console.log(userImage);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, []);

  const handleProfileClick = (event) => {
    event.stopPropagation();
    document.getElementById("profilePic").click();
  };

  const [sendEditedValue, SetEditValue] = useState({

  })

  // console.log(sendEditedValue);

  let mailfrom = userDetails && userDetails.userEmail
  // console.log(mailfrom);

  const [isEditing, setIsEditing] = useState(false);
  const [editedUserName, setEditedUserName] = useState(userDetailsTable && userDetailsTable.userName);
  const [editedUserGender, setEditedUserGender] = useState(userDetailsTable && userDetailsTable.userGender);
  const [editeduserPhone, setEditeduserPhone] = useState(userDetails && userDetails.userPhone);
  const [editedUserEmail, setEditedUserEmail] = useState(mailfrom);


  useEffect(() => {
    setEditedUserEmail(mailfrom);
  }, [mailfrom]);



  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleNextClick = () => {
    if (sendEditedValue && sendEditedValue.editemail) {

      const editemail = sendEditedValue && sendEditedValue.editemail;

      localStorage.setItem("editemail", editemail)

      const fkey = localStorage.getItem("fkey");
      const profileEmail = atob(fkey).replace(/"/g, "").trim();

      const url = "https://backend.shubadinam.com/profileedit/useremail";

      axios.post(url, { ...sendEditedValue, userEmail: profileEmail })
        .then((response) => {
          // console.log(response.data);
          alert(" verification mail sent")
          SetOptContent(true)
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  // const handleNextClick = () => {
  //   // SetOptContent(true)
  // };

  const handleCloseClick = () => {
    setIsEditing(false);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    // console.log('Updated Value:', editedUserGender);

    // if (sendEditedValue.userName === "" || sendEditedValue.userPhone === "") {
    //   alert("empty input field");
    // }
    // else

    const localId = localStorage.getItem("SD_Id");


    if (sendEditedValue && sendEditedValue.userCountry) {
      const fkey = localStorage.getItem("fkey");
      const profileEmail = atob(fkey).replace(/"/g, "").trim();


      const url = "https://backend.shubadinam.com/profileedit/usercountry";

      axios.post(url, { ...sendEditedValue, userEmail: profileEmail })
        .then((response) => {
          console.log(response.data);

        })
        .catch((error) => {
          // console.log(error);
        });
    }

    if (sendEditedValue && sendEditedValue.userState) {
      const fkey = localStorage.getItem("fkey");
      const profileEmail = atob(fkey).replace(/"/g, "").trim();

      const url = "https://backend.shubadinam.com/profileedit/userstate";

      axios.post(url, { ...sendEditedValue, userEmail: profileEmail })
        .then((response) => {
          console.log(response.data);

        })
        .catch((error) => {
          // console.log(error);
        });
    }


    if (sendEditedValue && sendEditedValue.userName) {
      const fkey = localStorage.getItem("fkey");
      const profileEmail = atob(fkey).replace(/"/g, "").trim();

      const url = "https://backend.shubadinam.com/profileedit/username";

      axios.post(url, { ...sendEditedValue, userEmail: profileEmail })
        .then((response) => {
          // console.log(response.data);

        })
        .catch((error) => {
          // console.log(error);
        });
    }



    if (sendEditedValue.userPhone && sendEditedValue.userPhone.length === 10) {
      const fkey = localStorage.getItem("fkey");
      const profileEmail = atob(fkey).replace(/"/g, "").trim();

      const url = "https://backend.shubadinam.com/profileedit/userphone";

      axios.post(url, { ...sendEditedValue, userEmail: profileEmail })
        .then((response) => {
          // console.log(response.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }


    if (sendEditedValue && sendEditedValue.userGender) {
      const fkey = localStorage.getItem("fkey");
      const profileEmail = atob(fkey).replace(/"/g, "").trim();

      const url = "https://backend.shubadinam.com/profileedit/userGender";

      axios.post(url, { ...sendEditedValue, userEmail: profileEmail })
        .then((response) => {
          // console.log(response.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }


    if (sendEditedValue && sendEditedValue.editemail) {
      const fkey = localStorage.getItem("fkey");
      const profileEmail = atob(fkey).replace(/"/g, "").trim();

      const url = "https://backend.shubadinam.com/profileedit/useremail";

      axios.post(url, { ...sendEditedValue, userEmail: profileEmail })
        .then((response) => {
          // console.log(response.data);
          alert(" verification mail sent")

        })
        .catch((error) => {
          // console.log(error);
        });
    }
    // if you want to remove you can
    window.location.reload()


    // else {

    //   const fkey = localStorage.getItem("fkey");

    //   const profileEmail = atob(fkey).replace(/"/g, "").trim();
    //   // console.log(profileEmail);

    //   const url = "https://backend.shubadinam.com/profileedit"

    //   axios.post(url, { ...sendEditedValue, userEmail: profileEmail })
    //   .then((response) => {
    //       console.log(response.data);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     })





    // };
  }



  const handleUserNameChange = (e) => {
    if (e && e.target === "") {
      alert("no")

    } else {
      setEditedUserName(e.target.value);
      // console.log(e.target.value);
      SetEditValue({ ...sendEditedValue, userName: e.target.value })

    }
  };

  const handleUserPhoneChange = (e) => {
    if (e && e.target === "") {
      alert("no")

    } else {
      setEditeduserPhone(e.target.value);
      // console.log(e.target.value);
      SetEditValue({ ...sendEditedValue, userPhone: e.target.value })
    }
  };

  const handleGenderChange = (e) => {
    if (e && e.target === "") {
      alert("no")

    } else {
      setEditedUserGender(e.target.value);
      // console.log(e.target.value);
      SetEditValue({ ...sendEditedValue, userGender: e.target.value })
    }
  };

  const handleUserEmailChange = (e) => {
    if (e && e.target === "") {
      alert("no")

    } else {
      setEditedUserEmail(e.target.value);
      // console.log(e.target.value);
      SetEditValue({ ...sendEditedValue, editemail: e.target.value })



    }
  };



  const [isemailEditing, setIsEmailEditing] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true)
    setIsEmailEditing(true)
  }
  const closeModal = () => {
    setModalOpen(false);
    SetOptContent(false)
  }


  const [optContent, SetOptContent] = useState(false);

  const [otp, setOtp] = useState('');

  const inputStyle = {
    width: '2em', // Adjust the width of each input box
    textAlign: 'center', // Center text within each input box
    // backgroundColor:"#062df717"
    // Additional input styles if needed
  };

  const handleVerifyClick = () => {

    const fkey = localStorage.getItem("fkey");
    const useremail = atob(fkey).replace(/"/g, "").trim();
    const editable = localStorage.getItem("editemail")

    const url = `https://backend.shubadinam.com/verifyuserotp?userotp=${otp}&useremail=${useremail}&editemail=${editable}`;

    axios.post(url)
      .then((response) => {
        // console.log(response.data);
        const message = response.data.message
        const reply = response.data.reply

        if (message && reply === "success") {
          Swal.fire({
            title: "Updated !",
            text: message,
            icon: "success",
            confirmButtonText: "Okay",
          }).then((result) => {
            if (result.isConfirmed) {
              // signOut();
              Navigate("/");
              localStorage.setItem("fkey", btoa(editable));
            }
          });

          // alert(" verification success, refresh")
          localStorage.removeItem("editemail")
        }
        else {
          Swal.fire({
            title: "Updated !",
            text: message,
            icon: "info",
            confirmButtonText: "Okay",
          })

          // alert(" verification success, refresh")
          // localStorage.removeItem("editemail")

        }


      })
      .catch((error) => {
        // console.log(error);
      });
  }




  return (
    <div className="overall-container-home">
      <HelmetProvider>
        <Helmet>
          <title>My Profile </title>
          <meta name="description" content="My Profile  - Janma Nakshatra/Tithi Birthday Reminder Service." />
        </Helmet>
      </HelmetProvider>
      <div className="overall-percent-home">
        <ScrollToTop smooth />

        <div className="container-header-profile">
          <LandingHeader />
        </div>

        <Top10px />

        <br />

        {/* <div className="profile-banner"> */}


        {/* if need remove the style display */}

        <div className="pr-profile" onClick={handleProfileClick} style={{ display: "none" }}>
          <label htmlFor="profilePic" className="camera-icon">
            <FontAwesomeIcon icon={faCamera} />
          </label>
          <input
            name="userimage"
            type="file"
            accept="image/*"
            id="profilePic"
            onChange={handlePicChange}
            style={{ display: "none" }}
          />
          {userImage && <img src={userImage.userprofilePic} alt=" '' Profile pic" />}
          {/* <FontAwesomeIcon className="camera" icon={faCamera} /> */}
          {/* {userImage.userprofilePic} */}
        </div>
        {/* </div> */}

        <br />

        <div className="profile-content">
          <div className="pr-sidebar"></div>
          <div className="pr-sidebar-container">
            <div className="pr-content">
              <div className="pr-header">
                <div className="pr-prof-title">
                  <p>PROFILE</p>
                </div>
              </div>
              <div className={isEditing ? "save-close" : "pr-edit"}>
                {isEditing ? (
                  <>
                    <button className="save-profile" onClick={handleSaveClick}>Save</button>
                    <button className="close-profile" onClick={handleCloseClick}>close</button>
                  </>

                ) : (
                  <button className="edit-profile" onClick={handleEditClick}>Edit</button>

                )}
              </div>
              <br />
              <div className="pr-cus-name">
                <label
                  style={{ width: "30%", height: "60%", textIndent: "30px", fontWeight: "500" }}
                >
                  Name
                </label>

                <input
                  className={isEditing ? 'editing edit-icon' : "profile-font"}
                  name="userName"
                  style={{
                    width: '60%',
                    height: '75%',
                    fontFamily: 'auto',
                    textIndent: '20px',
                    textTransform: 'capitalize',
                  }}
                  value={isEditing ? editedUserName : userDetailsTable && userDetailsTable.userName}
                  onChange={(e) => handleUserNameChange(e)}
                  readOnly={!isEditing}
                />
              </div>
              <div className="pr-cus-id">
                <label
                  style={{ width: "30%", height: "75%", textIndent: "30px", fontWeight: "500" }}
                >
                  User Id
                </label>
                <input className={isEditing ? 'sdid-disable' : "profile-font"}
                  name="userId"
                  style={{ width: "60%", height: "75%", fontFamily: "auto", textIndent: "20px" }}
                  value={isEditing ? `${userDetails && userDetails.uniqueId}` : userDetails && userDetails.uniqueId}
                  readOnly
                />
              </div>
              <div className="pr-cus-gen">
                <label
                  style={{ width: "30%", height: "75%", textIndent: "30px", fontWeight: "500" }}
                >
                  Gender
                </label>
                {
                  isEditing
                    ? (
                      <select
                        className={isEditing ? 'editing' : "profile-font"}
                        name="userGender"
                        defaultValue={userDetailsTable && userDetailsTable.userGender}
                        style={{ width: "60%", height: "75%", fontFamily: "auto", textIndent: "20px" }}
                        value={isEditing ? editedUserGender : userDetailsTable && userDetailsTable.userGender}
                        onChange={(e) => handleGenderChange(e)}
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="others">Others</option>
                      </select>
                    )
                    :
                    <input className="profile-font"
                      name="userGender"
                      style={{ width: "60%", height: "75%", fontFamily: "auto", textIndent: "20px" }}
                      value={userDetailsTable && userDetailsTable.userGender}
                      readOnly
                    />
                }

              </div>


              {/* <div> */}
              {/* <button onClick={openModal}>{userDetails && userDetails.userEmail}</button> */}

              {/* {isModalOpen && (
                  <div className="custom-modal">
                    <div className="modal-content-profile-email">
                      <span className="close-profile-email" onClick={closeModal}>&times;</span>
                      <h2>Modal Content</h2>
                      <p>Your modal content goes here.</p>
                      <button onClick={closeModal}>Close Modal</button>
                    </div>
                  </div>
                )}
              </div> */}


              {/* <div className="pr-cus-email">
                <label
                  style={{ width: "30%", height: "60%", textIndent: "30px", fontWeight: "500" }}
                >
                  Email
                </label>

                <button onClick={openModal}>{userDetails && userDetails.userEmail}</button>


                <input className={isEditing ? 'editing' : "profile-font"}
                  name="userEmail"
                  style={{ width: "60%", height: "60%", fontFamily: "auto", textIndent: "20px" }}
                  value={ ? editedUserEmail : userDetails && userDetails.userEmail}
                  onChange={(e) => handleUserEmailChange(e)}
                  readOnly={!isEditing}
                />
              </div> */}



              {/* ************************ uncomment to run the code for edit email *********************** */}

              {/* <div className="pr-cus-email">
                <label
                  style={{ width: "30%", height: "60%", textIndent: "30px", fontWeight: "500" }}
                >
                  Email
                </label>


                <button className="edit-email-button" onClick={openModal} readOnly>{mailfrom}<span style={{}} >➜</span></button>

                {isModalOpen && (
                  <div className="custom-modal">
                    <div className="modal-content-profile-email">
                      <div className="cancel-div">
                        <span style={{ width: "20px" }} className="cancel-icon" onClick={closeModal}>X</span>
                      </div>


                      <h2>Modal Content</h2>

                      <div className="email-description">

                      </div>
                      <br />
                      <div className="edit-email-otp">
                        <label
                          style={{
                            width: '82px',
                            height: '45px',
                            fontWeight: 500,
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}

                        >
                          Email
                        </label>
                        <input className={isemailEditing ? 'editing' : "profile-font"}
                          name="userEmail"
                          style={{ width: "60%", height: "94%", fontFamily: "auto", textIndent: "20px" }}
                          value={isModalOpen ? editedUserEmail : mailfrom}
                          onChange={(e) => handleUserEmailChange(e)}
                        />
                      </div>
                      <br />
                      {
                        optContent ?
                          <div className="otp-container">
                            <h5>Enter your otp to verify</h5>
                            <div className="otp-container2">
                              <OtpInput
                                className="otpstyle"
                                value={otp}
                                onChange={setOtp}
                                numInputs={4}
                                renderSeparator={<span>-</span>}
                                renderInput={(props) => <input {...props} />}
                                inputStyle={inputStyle}
                              />
                            </div>


                          </div>
                          :
                          <></>
                      }
                      <br />
                      <div className="save-modal">
                        {
                          optContent ?
                            <button className="otp-ver-btn" onClick={handleVerifyClick}>Verify</button>
                            :
                            <button className="next-profile" onClick={handleNextClick}>Next</button>
                        }
                      </div>

                    </div>
                  </div>
                )}



              </div> */}

              {/* ************************ uncomment to run the code for edit phone number ************************ */}

              {/* <div className="pr-cus-phone">
                <label
                  style={{ width: "30%", height: "60%", textIndent: "30px", fontWeight: "500" }}
                >
                  Phone
                </label>
                <input className={isEditing ? 'editing' : "profile-font"}
                  name="userPhone"
                  style={{ width: "60%", height: "60%", fontFamily: "auto", textIndent: "20px" }}
                  value={isEditing ? editeduserPhone : userDetails && userDetails.userPhone}
                  onChange={(e) => handleUserPhoneChange(e)}
                  readOnly={!isEditing}
                />
              </div> */}

              <div className="pr-cus-email">
                <label
                  style={{ width: "30%", height: "75%", textIndent: "30px", fontWeight: "500" }}
                >
                  Email
                </label>



                <input className={isEditing ? 'editing' : 'edit-email-button'}
                  style={isEditing ? {
                    height: "75%",
                    width: "60%",
                    textIndent: "20px",
                    border: '1px solid #767676b5',
                    color: '#767676b5',
                    borderRadius: '7px'
                  } : {
                    height: "75%",
                    width: "60%",
                    border: "1px solid #767676b",
                    textIndent: "20px",
                    backgroundColor: "white",
                    color: "black"
                  }
                  }
                  value={mailfrom}
                  disabled={!isEditing}
                  readOnly
                ></input>



                {/* <button
                  className={isEditing ? 'editing' : 'edit-email-button'}

                  style={isEditing ? {
                    height: "75%",
                    width: "60%",
                    textIndent: "2px",
                    border: '1px solid #767676b5',
                    color: '#767676b5',
                    borderRadius: '7px'
                  } : {
                    height: "75%",
                    width: "60%",
                    border: "1px solid #767676b",
                    textIndent: "2px",
                    backgroundColor: "white",
                    color: "black"
                  }
                  }

                  disabled={!isEditing}
                  readOnly
                >
                  {mailfrom}
                </button> */}


                {isModalOpen && (
                  <div className="custom-modal">
                    <div className="modal-content-profile-email">
                      <div className="cancel-div">
                        <span style={{ width: "20px" }} className="cancel-icon" onClick={closeModal}>X</span>
                      </div>

                      {/* <span className="save-profile" onClick={handleSaveClick}></span> */}

                      <h2>Modal Content</h2>

                      <div className="email-description">

                      </div>
                      <br />
                      <div className="edit-email-otp">
                        <label
                          style={{
                            width: '82px',
                            height: '45px',
                            fontWeight: 500,
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}

                        >
                          Email
                        </label>
                        <input className={isemailEditing ? 'editing' : "profile-font"}
                          name="userEmail"
                          style={{ width: "60%", height: "94%", fontFamily: "auto", textIndent: "20px" }}
                          value={isModalOpen ? editedUserEmail : mailfrom}
                          onChange={(e) => handleUserEmailChange(e)}
                        />
                      </div>
                      <br />
                      {
                        optContent ?
                          <div className="otp-container">
                            {/* <> */}
                            <h5>Enter your otp to verify</h5>
                            <div className="otp-container2">
                              <OtpInput
                                className="otpstyle"
                                value={otp}
                                // style={{width:"1.5em !important"}}
                                onChange={setOtp}
                                numInputs={4}
                                renderSeparator={<span>-</span>}
                                renderInput={(props) => <input {...props} />}
                                inputStyle={inputStyle}
                              />
                            </div>
                            {/* </> */}


                          </div>
                          :
                          <></>
                      }
                      <br />
                      <div className="save-modal">
                        {
                          optContent ?
                            <button className="otp-ver-btn" onClick={handleVerifyClick}>Verify</button>
                            /* <button className="close-profile-email" onClick={()=>closeModal()}>Close</button> */
                            :
                            <button className="next-profile" onClick={handleNextClick}>Next</button>
                        }
                      </div>

                    </div>
                  </div>
                )}



              </div>

              <div className="pr-cus-phone">
                <label
                  style={{ width: "30%", height: "60%", textIndent: "30px", fontWeight: "500" }}
                >
                  Phone
                </label>
                <input className={isEditing ? 'sdid-disable' : "profile-font"}
                  name="userPhone"
                  style={{ width: "60%", height: "75%", fontFamily: "auto", textIndent: "20px" }}
                  value={isEditing ? editeduserPhone : userDetails && userDetails.userPhone}
                  onChange={(e) => handleUserPhoneChange(e)}
                  readOnly
                />
              </div>

              {
                isEditing ?
                  <>
                    <div className="pr-cus-country">
                      <label
                        style={{ width: "30%", height: "75%", textIndent: "30px", fontWeight: "500" }}
                      >
                        Country
                      </label>
                      <CountrySelect
                        onChange={(e) => {
                          setCountryid(e.id);
                          console.log(e);
                          SetEditValue(prevState => ({
                            ...prevState,
                            userCountry: e.name
                          }));
                        }}
                        onTextChange={(e) => {
                          SetEditValue(prevState => ({
                            ...prevState,
                            userCountry: e.target.value
                          }));
                        }}
                        name="userCountry"
                        placeHolder="Select Country"

                      />
                    </div>

                    <div className="pr-cus-state">
                      <label
                        style={{ width: "30%", height: "75%", textIndent: "30px", fontWeight: "500" }}
                      >
                        State
                      </label>
                      <StateSelect
                        countryid={countryid}
                        onChange={(e) => {
                          setstateid(e.id);
                          console.log(e);
                          SetEditValue(prevState => ({
                            ...prevState,
                            userState: e.name
                          }));
                        }}
                        onTextChange={(e) => {
                          SetEditValue(prevState => ({
                            ...prevState,
                            userState: e.target.value
                          }));
                          console.log(e.target.value);
                        }}
                        name="userState"
                        placeHolder="Select State"
                        readOnly={!isEditing}
                      />
                    </div>
                  </>
                  :

                  <>
                    <div className="pr-cus-phone">
                      <label
                        style={{ width: "30%", height: "60%", textIndent: "30px", fontWeight: "500" }}
                      >
                        Country
                      </label>
                      <input className={isEditing ? 'sdid-disable' : "profile-font"}
                        name="userPhone"
                        style={{ width: "60%", height: "60%", fontFamily: "auto", textIndent: "20px" }}
                        value={countryValue}
                        onChange={(e) => handleUserPhoneChange(e)}
                        readOnly
                      />
                    </div>

                    <div className="pr-cus-phone">
                      <label
                        style={{ width: "30%", height: "60%", textIndent: "30px", fontWeight: "500" }}
                      >
                        State
                      </label>
                      <input className={isEditing ? 'sdid-disable' : "profile-font"}
                        name="userPhone"
                        style={{ width: "60%", height: "60%", fontFamily: "auto", textIndent: "20px" }}
                        value={stateValue}
                        onChange={(e) => handleUserPhoneChange(e)}
                        readOnly
                      />
                    </div>
                  </>

              }




            </div>
          </div>
        </div>

        <br />

        <FooterComponent></FooterComponent>
      </div>
    </div>
  );
};

export default MyProfile;


// import React, { useState, useEffect } from "react";
// import { TextField, Button, Avatar, Paper, Box } from '@mui/material';
// import { styled } from '@mui/system';
// import axios from "axios";
// import { Helmet, HelmetProvider } from 'react-helmet-async';

// const Background = styled('div')({
//   backgroundSize: 'cover',
//   backgroundPosition: 'center',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
// });

// const ProfileCard = styled(Paper)({
//   padding: '20px',
//   width: '400px',
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
// });

// const UploadButton = styled('label')({
//   cursor: 'pointer',
//   color: '#494949',
//   fontSize: '16px',
//   padding: '5px',
//   border: "1px solid grey",
//   borderRadius: "7px"
// });

// const MyProfile = () => {


//   useEffect(() => {
//     const fkey = localStorage.getItem("fkey");

//     if (fkey) {
//       const profileEmail = atob(fkey).replace(/"/g, "").trim();
//       // console.log(profileEmail);

//       const url = `https://backend.shubadinam.com/profiledetails?customerMail=${profileEmail}`;

//       axios
//         .get(url)
//         .then((response) => {

//           console.log(response.data);
//           // console.log(response.data.emailName[0].userEmail);
//           // console.log(response.data.userTable);

//           // const fromMail = response.data.emailName[0];
//           // const usertable = response.data.userTable[0];

//           // setCountryValue(response.data.emailName[0].userCountry);
//           // setStateValue(response.data.emailName[0].userState);



//           // SetUserDetails(fromMail);
//           // SetUserDetailsTable(usertable);

//           // console.log(fromMail);
//           // console.log(userDetails);
//           // console.log(userDetailsTable);
//         })
//         .catch((error) => {
//           console.error("Error fetching profile details:", error);
//         });
//     } else {
//       // alert("Please login again, some issue exists");
//       // signOut();
//       // Navigate("/");
//     }
//   }, []);



//   const defaultProfilePhoto = 'https://example.com/default-profile-photo.jpg';
//   const [profilePhoto, setProfilePhoto] = useState(defaultProfilePhoto);
//   const [formData, setFormData] = useState({
//     name: 'John Doe',
//     email: 'johndoe@example.com',
//     phoneNumber: '123-456-7890',
//     id: '001',
//     state: 'California',
//     country: 'USA',
//     city: 'Los Angeles',
//   });
//   const [isEditable, setIsEditable] = useState(false);
//   const [isPhotoUploaded, setIsPhotoUploaded] = useState(false);

//   const handlePhotoChange = (event) => {
//     if (event.target.files[0]) {
//       setProfilePhoto(URL.createObjectURL(event.target.files[0]));
//       setIsPhotoUploaded(true);
//     }
//   };

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       const response = await axios.put('/api/profile', formData);
//       if (response.status === 200) {
//         alert('Profile updated successfully!');
//         setIsEditable(false);
//       }
//     } catch (error) {
//       console.error('Error updating profile:', error);
//       alert('Failed to update profile.');
//     }
//   };

//   const handleEditToggle = () => {
//     setIsEditable(!isEditable);
//   };

//   return (
//     <div className="overall-container-home">
//       <HelmetProvider>
//         <Helmet>
//           <title>My Profile</title>
//           <meta name="description" content="My Profile - Janma Nakshatra/Tithi Birthday Reminder Service." />
//         </Helmet>
//       </HelmetProvider>
//       <div className="overall-percent-home">
//         <Background>
//           <ProfileCard>
//             <Avatar
//               src={profilePhoto}
//               sx={{ width: 100, height: 100, marginBottom: 2 }}
//             />
//             <input
//               type="file"
//               accept="image/*"
//               onChange={handlePhotoChange}
//               id="profile-photo-upload"
//               style={{ display: 'none' }}
//             />
//             <UploadButton htmlFor="profile-photo-upload">
//               Upload Photo
//             </UploadButton>
//             {isPhotoUploaded && !isEditable && (
//               <Button onClick={handleEditToggle} variant="contained" color="primary" fullWidth>
//                 Edit
//               </Button>
//             )}
//             <Box sx={{ width: "100%", textAlign: "end" }}>
//               {isEditable ? (
//                 <Button sx={{ width: 100, }} onClick={handleSubmit} variant="contained" color="primary">
//                   Save
//                 </Button>
//               ) : (
//                 <Button sx={{ width: 100 }} onClick={handleEditToggle} variant="outlined" color="primary">
//                   Edit
//                 </Button>
//               )}
//             </Box>
//             <Box style={{ width: '100%' }}>
//               <TextField
//                 label="Name"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleInputChange}
//                 fullWidth
//                 margin="normal"
//                 InputProps={{
//                   readOnly: !isEditable,
//                 }}
//               />
//               <TextField
//                 label="Email"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleInputChange}
//                 fullWidth
//                 margin="normal"
//                 InputProps={{
//                   readOnly: !isEditable,
//                 }}
//               />
//               <TextField
//                 label="Phone Number"
//                 name="phoneNumber"
//                 value={formData.phoneNumber}
//                 onChange={handleInputChange}
//                 fullWidth
//                 margin="normal"
//                 InputProps={{
//                   readOnly: !isEditable,
//                 }}
//               />
//               <TextField
//                 label="ID"
//                 name="id"
//                 value={formData.id}
//                 onChange={handleInputChange}
//                 fullWidth
//                 margin="normal"
//                 InputProps={{
//                   readOnly: true,
//                 }}
//               />
//               <TextField
//                 label="State"
//                 name="state"
//                 value={formData.state}
//                 onChange={handleInputChange}
//                 fullWidth
//                 margin="normal"
//                 InputProps={{
//                   readOnly: !isEditable,
//                 }}
//               />
//               <TextField
//                 label="Country"
//                 name="country"
//                 value={formData.country}
//                 onChange={handleInputChange}
//                 fullWidth
//                 margin="normal"
//                 InputProps={{
//                   readOnly: !isEditable,
//                 }}
//               />
//               <TextField
//                 label="City"
//                 name="city"
//                 value={formData.city}
//                 onChange={handleInputChange}
//                 fullWidth
//                 margin="normal"
//                 InputProps={{
//                   readOnly: !isEditable,
//                 }}
//               />
//             </Box>
//           </ProfileCard>
//         </Background>
//       </div>
//     </div>
//   );
// };

// export default MyProfile;
