import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import CenteredSlider from "./CenteredSlider";
import Top10px from "./Top10px";
import Top20px from "./Top20px";
import BottomSpace from "./BottomSpace"
import BlogsBannerAd from "../AdsFolder/BlogsBannerAd";

import LandingHeader from "../components/LandingHeader";


import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import TithiBlogsPhone from "./TithiBlogsPhone";
import { Helmet, HelmetProvider } from 'react-helmet-async';


const TithiBlogs = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    return (
        <div className="overall-container">
            <HelmetProvider>
                <Helmet>
                    <title>Tithi Blogs </title>
                    <meta name="description" content="Tithi Blogs  - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <div className="overall-percent">
                <LandingHeader />
                <Top10px />
                <br />
                <br />
                <br />
                <br />

                <div className="blog-title">
                    {/* <img src={require("../images/blogs-image.jpg")} alt="shubadinam image" /> */}
                    <h3 style={{ fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>Tithi Blogs</h3>
                </div>
                <br />
                {/* <div className="top20"> */}
                {/* <div className="blogs-banner-ad"> */}
                {/* <BlogsBannerAd data-ad-slot="6616774036" /> */}
                {/* </div> */}
                {/* </div> */}

                <div className="center-container-blogs">
                    <div className="blogs-area">

                        <div className="blog-trip-boxes">

                            <div className="blog-container1" onContextMenu={(e) => e.preventDefault()}>
                                <NavLink to="/tithi-foods" className="text-noblue">
                                    <Card sx={{ maxWidth: 345, height: "300" }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="160"
                                                src={require("../images/boxesImages/food2.jpg")} alt="shubadinam image"
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Tithi Food
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Here's an overview of the food preferences that individuals born on each Janma Tithi.
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Read more
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </NavLink>

                                <NavLink to="/tithi-characteristics" className="text-noblue">
                                    <Card sx={{ maxWidth: 345, height: "300" }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="160"
                                                src={require("../images/boxesImages/char2.jpg")} alt="shubadinam image" loading="lazy" />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Tithi Characteristics
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    This is a general overview of the characteristics associated with each Janma Tithi in Vedic astrology.
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Read more
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </NavLink>
                            </div>

                            <div className="blog-container2" onContextMenu={(e) => e.preventDefault()}>

                                <NavLink to="/tithi-deities" className="text-noblue">

                                    <Card sx={{ maxWidth: 345, height: "300" }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="160"
                                                src={require("../images/boxesImages/deity2.png")} alt="shubadinam image"
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Tithi Deity

                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    In Vedic astrology and Hindu tradition, each Janma Tithi is associated with a presiding deity.
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Read more
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </NavLink>

                                <NavLink to="/tithi-likes-dislikes" className="text-noblue">
                                    <Card sx={{ maxWidth: 345, height: "300" }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="160"
                                                src={require("../images/boxesImages/likes22.jpg")} alt="shubadinam image" loading="lazy" />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Tithi Likes and Dislikes

                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    This a general overview of the likes and dislikes that individuals born on each Janma Tithi might have, according to Vedic astrology.
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Read more
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </NavLink>

                            </div>

                        </div>

                        {/* <div className="blogs-ph-slider">
                            <div className="blogs-ph-slider-cnt">
                                <TithiBlogsPhone />
                            </div>

                        </div> */}
                    </div>

                    <div className="blogs-adspace">

                    </div>






                </div>

                <BottomSpace />
                <FooterComponent></FooterComponent>
            </div>
        </div>
    );
};

export default TithiBlogs;