import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Swal from "sweetalert2";
import { NavLink, useNavigate } from "react-router-dom";

import {
    CitySelect,
    CountrySelect,
    StateSelect,
    LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import axios from "axios";

const UserEditComponent = ({ object, onSaveUser, onClose }) => {


    const navigate = useNavigate();


    const [countryid, setCountryid] = useState(0);
    const [stateid, setstateid] = useState(0);
    const [cityid, setCityid] = useState(0);
    const formRef = useRef(null);
    const formRef2 = useRef(null);


    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }} >
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    const [value, setValue] = useState(0);
    const [showNakshatra, setshowNakshatra] = useState(true);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 1) {
            setshowNakshatra(false)
        } else {
            setshowNakshatra(true)
        }
        if (formRef.current) {
            formRef.current.reset();
        }
        if (formRef2.current) {
            formRef2.current.reset();
        }
    };

    // console.log(object);


    const [editUserState, SetRelFunction] = useState({
        // editUName: object.userName,
        editUGender: object.userGender,
        editURelation: "Myself",
        editUnakshatraTithi: object.nakshatratithi,
        editUrelNakshathra: "",
        editUPaksham: "",
        editUTithi: "",
        editUMonth: "",
        editUCalender: "",
        userCountry: "",
        userState: "",
        userCity: "",
        userDateTime: "",
        firstuserid: object.firstuserid
    })


    const [editedObject, setEditedObject] = useState(object);

    const handleInput = (event) => {
        setEditedObject({ ...editedObject, [event.target.name]: event.target.value });
        SetRelFunction({ ...editUserState, [event.target.name]: event.target.value });
    };

    const handleSave = () => {
        SetRelFunction({ ...editUserState, relationId: editedObject.firstuserid });

        // Call the onSave function to save changes
        onSaveUser(editUserState);
        // console.log(editedObject.userName);
        // console.log(editUserState);
    };


    const Calender2 = [
        {
            name: "Any"
        },
        {
            name: "Tamil",
            calenderCode: 1,
            month: [{
                name: "CHITHIRAI/சித்திரை",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]


            },
            {
                name: "VAIGAASI/வைகாசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]


            },
            {
                name: "AANI/ஆனி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "AADI/ஆடி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "AAVANI/ஆவணி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "PURATTASI/புரட்டாசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "AYPPASI/ஐப்பசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "KARTHIGAI/கார்த்திகை",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "MARGAZHI/மார்கழி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "THAI/தை",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "MAASI/மாசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "PANGUNI/பங்குனி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            }

            ]
        },
        {
            name: "Malayalam",
            calenderCode: 2,
            month: [
                {
                    name: "MEENAM/മീനം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]



                },
                {
                    name: "MEDAM/മെഡാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "EDAVAM/എദാവം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "MITHUNAM/മിഥുനം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "KARKADAKAM/കാർകിഡകാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "CHINGAM/ചിങ്കാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "KANNI/കാനി",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "THULAM/തുലാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "VRISHCHIKAM/വ്രിഷിക്കാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "DHANU/ഭാനു",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "MAKARAM/മകരം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "KUMBHAM/കുംഹാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                }
            ]
        },
        {
            name: "Telugu",
            calenderCode: 3,
            month: [
                {
                    name: "CHAITHRAMU/చైత్రము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]
                },
                {
                    name: "VAISAAKHAMU/వైశాఖము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "JYESTHAMU/జ్యేష్ఠము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "ASHADHAMU/ఆషాఢము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                // {
                //     name: "Sravanamu (Adhik)/శ్రావణము",
                //     nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                //     Paksham: [
                //         {
                //             name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                //             tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                //         },
                //         {
                //             name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                //             tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                //         }
                //     ]

                // },
                {
                    name: "SRAVANAMU/శ్రావణము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "BHADHRAPADAMU/భాద్రపదము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "AASVAYUJAMU/ఆశ్వయుజము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "KARTHIKAMU/కార్తీకము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "MAARGASEERSHAMU/మార్గశీర్షము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "PUSHYAMU/పుష్యము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "MAKHAMU/మఖము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]
                },
                {
                    name: "PHALGUNAMU/ఫాల్గుణము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]
                }
            ]
        },
        {
            name: "Kannada",
            calenderCode: 4,
            month: [{
                name: "CHAITRA/ಚೈತ್ರ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "VAISHAKHA/ವೈಶಾಖ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "JYESHTHA/ಜ್ಯೇಷ್ಠ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "ASHADHA/ಆಷಾಢ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "SAHRAVANA/ಶ್ರಾವಣ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "BHADRAPADA/ಭಾದ್ರಪದ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "ASHWEJA/ಆಶ್ವೇಜ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "KARTIKA/ಕಾರ್ತೀಕ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "MARGASHIRSHA/ಮಾರ್ಗಶೀರ್ಷ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "PAUSHA/ಪೌಷಾ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "MAGHA/ಮಾಘ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "PHALGUNA/ಫಲ್ಗುಣ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            ]
        },
        {
            name: "Hindi",
            calenderCode: 5,
            month: [
                {
                    name: "CHAITRA/चैत्र",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "VAISAKHA/वैशाख",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "ASHADHA/आषाढ़",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                // {
                //     name: "SHRAVANA(Adhik)/श्रावण",
                //     nakshathira:
                //         [
                //             "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                //     Paksham: [
                //         {
                //             name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                //             tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                //         },
                //         {
                //             name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                //             tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                //         }
                //     ]
                // },
                {
                    name: "SHRAVANA/श्रावण",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "BHADRAPADA/भाद्रपद",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "ASHVIN/अश्विन",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "KATIKA/कार्तिक",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "MARGASIRSA/मार्गशीर्ष",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "PAUSHA/पौष",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "MAGH/माघ",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "PHALGUNA/फाल्गुन",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                }
            ]
        },
        {
            name: "Gujarati",
            calenderCode: 6,
            month: [
                {
                    name: "CHAITRA/ચૈત્ર",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]




                },
                {
                    name: "VAISHAKHA/વૈશાખા",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "JETHA/જેઠા",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                // {
                //     name: "SHRAVANA adhik/શ્રાવણ",
                //     nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                //     Paksham: [
                //         {
                //             name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                //             tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                //         },
                //         {
                //             name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                //             tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                //         }
                //     ]


                // },
                {
                    name: "SHRAVANA/શ્રાવણ",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "BHADRAVO/ભાદરવો",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "ASO/આસો",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "KARKAT/કારતક",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "MAGSHAR/માગશર",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "POSH/પોષ",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "MAHA/મહા",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "PHAGUNA/ફાગુના",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },

            ]
        },
        {
            name: "Bengali",
            calenderCode: 7,
            month: [
                {
                    name: "BOISHAKH/বৈশাখ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]



                },
                {
                    name: "JOISHTHO/জ্যৈষ্ঠ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "ASHARH/আষাঢ়",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "SHRABON/শ্রাবণ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "BHADRO/ভাদ্র",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "ASHSHIN/আশ্বিন",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "KARTIK/কার্তিক",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "AGRAHAYAN/অগ্রহায়ণ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "POUSH/পৌষ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "MAGH/মাঘ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "FALGUN/ফাল্গুন",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "CHAITRA/চৈত্র",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },

            ]
        },
        {
            name: "Marathi",
            calenderCode: 8,
            month: [
                {
                    name: "CHAITRA/चैत्र",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "VAISAKHA/वैशाख",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "JYESTHA/ज्येष्ठा",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "ASHADHA/आषाढ़",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "SHRAVANA/श्रावण",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "BHADRAPADA/भाद्रपद",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "ASHVIN/अश्विन",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "KATIKA/कार्तिक/चैत्र",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "MARGASIRSA/मार्गशीर्ष",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "PAUSHA/पौष",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "MAGH/माघ",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "PHALGUNA/फाल्गुन",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
            ]
        }



    ]


    const Calender = [
        {
            name: "Tamil",
            calenderCode: 1,
            month: [{
                name: "CHITHIRAI/சித்திரை",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]


            },
            {
                name: "VAIGAASI/வைகாசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]


            },
            {
                name: "AANI/ஆனி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "AADI/ஆடி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "AAVANI/ஆவணி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "PURATTASI/புரட்டாசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "AYPPASI/ஐப்பசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "KARTHIGAI/கார்த்திகை",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "MARGAZHI/மார்கழி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "THAI/தை",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "MAASI/மாசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "PANGUNI/பங்குனி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            }

            ]
        },
        {
            name: "Malayalam",
            calenderCode: 2,
            month: [
                {
                    name: "MEENAM/മീനം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]



                },
                {
                    name: "MEDAM/മെഡാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "EDAVAM/എദാവം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "MITHUNAM/മിഥുനം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "KARKADAKAM/കാർകിഡകാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "CHINGAM/ചിങ്കാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "KANNI/കാനി",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "THULAM/തുലാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "VRISHCHIKAM/വ്രിഷിക്കാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "DHANU/ഭാനു",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "MAKARAM/മകരം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "KUMBHAM/കുംഹാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                }
            ]
        },
        {
            name: "Telugu",
            calenderCode: 3,
            month: [
                {
                    name: "CHAITHRAMU/చైత్రము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]
                },
                {
                    name: "VAISAAKHAMU/వైశాఖము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "JYESTHAMU/జ్యేష్ఠము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "ASHADHAMU/ఆషాఢము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                // {
                //     name: "Sravanamu (Adhik)/శ్రావణము",
                //     nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                //     Paksham: [
                //         {
                //             name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                //             tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                //         },
                //         {
                //             name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                //             tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                //         }
                //     ]

                // },
                {
                    name: "SRAVANAMU/శ్రావణము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "BHADHRAPADAMU/భాద్రపదము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "AASVAYUJAMU/ఆశ్వయుజము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "KARTHIKAMU/కార్తీకము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "MAARGASEERSHAMU/మార్గశీర్షము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "PUSHYAMU/పుష్యము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "MAKHAMU/మఖము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]
                },
                {
                    name: "PHALGUNAMU/ఫాల్గుణము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]
                }
            ]
        },
        {
            name: "Kannada",
            calenderCode: 4,
            month: [{
                name: "CHAITRA/ಚೈತ್ರ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "VAISHAKHA/ವೈಶಾಖ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "JYESHTHA/ಜ್ಯೇಷ್ಠ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "ASHADHA/ಆಷಾಢ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "SAHRAVANA/ಶ್ರಾವಣ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "BHADRAPADA/ಭಾದ್ರಪದ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "ASHWEJA/ಆಶ್ವೇಜ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "KARTIKA/ಕಾರ್ತೀಕ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "MARGASHIRSHA/ಮಾರ್ಗಶೀರ್ಷ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "PAUSHA/ಪೌಷಾ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "MAGHA/ಮಾಘ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "PHALGUNA/ಫಲ್ಗುಣ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            ]
        },
        {
            name: "Hindi",
            calenderCode: 5,
            month: [
                {
                    name: "CHAITRA/चैत्र",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "VAISAKHA/वैशाख",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "ASHADHA/आषाढ़",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                // {
                //     name: "SHRAVANA(Adhik)/श्रावण",
                //     nakshathira:
                //         [
                //             "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                //     Paksham: [
                //         {
                //             name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                //             tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                //         },
                //         {
                //             name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                //             tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                //         }
                //     ]
                // },
                {
                    name: "SHRAVANA/श्रावण",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "BHADRAPADA/भाद्रपद",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "ASHVIN/अश्विन",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "KATIKA/कार्तिक",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "MARGASIRSA/मार्गशीर्ष",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "PAUSHA/पौष",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "MAGH/माघ",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "PHALGUNA/फाल्गुन",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                }
            ]
        },
        {
            name: "Gujarati",
            calenderCode: 6,
            month: [
                {
                    name: "CHAITRA/ચૈત્ર",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]




                },
                {
                    name: "VAISHAKHA/વૈશાખા",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "JETHA/જેઠા",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                // {
                //     name: "SHRAVANA adhik/શ્રાવણ",
                //     nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                //     Paksham: [
                //         {
                //             name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                //             tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                //         },
                //         {
                //             name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                //             tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                //         }
                //     ]


                // },
                {
                    name: "SHRAVANA/શ્રાવણ",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "BHADRAVO/ભાદરવો",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "ASO/આસો",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "KARKAT/કારતક",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "MAGSHAR/માગશર",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "POSH/પોષ",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "MAHA/મહા",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "PHAGUNA/ફાગુના",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },

            ]
        },
        {
            name: "Bengali",
            calenderCode: 7,
            month: [
                {
                    name: "BOISHAKH/বৈশাখ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]



                },
                {
                    name: "JOISHTHO/জ্যৈষ্ঠ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "ASHARH/আষাঢ়",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "SHRABON/শ্রাবণ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "BHADRO/ভাদ্র",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "ASHSHIN/আশ্বিন",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "KARTIK/কার্তিক",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "AGRAHAYAN/অগ্রহায়ণ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "POUSH/পৌষ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "MAGH/মাঘ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "FALGUN/ফাল্গুন",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "CHAITRA/চৈত্র",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },

            ]
        },
        {
            name: "Marathi",
            calenderCode: 8,
            month: [
                {
                    name: "CHAITRA/चैत्र",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "VAISAKHA/वैशाख",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "JYESTHA/ज्येष्ठा",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "ASHADHA/आषाढ़",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "SHRAVANA/श्रावण",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "BHADRAPADA/भाद्रपद",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "ASHVIN/अश्विन",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "KATIKA/कार्तिक/चैत्र",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "MARGASIRSA/मार्गशीर्ष",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "PAUSHA/पौष",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "MAGH/माघ",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "PHALGUNA/फाल्गुन",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
            ]
        }
    ]




    const [calender, SetCalender] = useState("")


    const [nakTithi, SetNakTithi] = useState(object.nakshatratithi)


    const [Pakshams, SetPaksham] = useState([])

    const [tithiname, SettithiName] = useState([])

    const [month, SetMonth] = useState("")


    const [months, SetMonths] = useState([])
    const [nakTithis, SetNakTithis] = useState([])


    const [nakshathra, SetNakshathra] = useState("")

    const [nakshathras, SetNakshathras] = useState([])



    const handleCalender = (event) => {

        if (event.target.value === "Any") {
            SetRelFunction({ ...editUserState, [event.target.name]: event.target.value });
            return;
        }

        const calender = Calender.filter((cal, val) => {
            return cal.name == event.target.value
        });
        SetCalender(event.target.value)
        SetMonths(Calender.find(clr => clr.name === event.target.value).month)
        SetRelFunction({ ...editUserState, [event.target.name]: event.target.value });
    }

    const handleMonth = (event) => {
        SetRelFunction({ ...editUserState, [event.target.name]: event.target.value });
        SetMonth(event.target.value)
        SetNakshathras(months.find(mth => mth.name === event.target.value).nakshathira)
        SetNakTithis(months.find(tth => tth.name === event.target.value).Paksham)
    }

    const handleNakTithi = (event) => {
        SetRelFunction({ ...editUserState, [event.target.name]: event.target.value });
        SetNakTithi(event.target.value)
    }

    const handleNakshathra = (event) => {
        SetRelFunction({ ...editUserState, [event.target.name]: event.target.value });
        SetNakshathra(event.target.value)
    }

    const handlePaksham = (event) => {
        SetRelFunction({ ...editUserState, [event.target.name]: event.target.value });
        SetPaksham(event.target.value)
        SettithiName(nakTithis.find(pks => pks.name === event.target.value).tithiNames)
    }

    const handleTithi = (event) => {
        SetRelFunction({ ...editUserState, [event.target.name]: event.target.value });
    }

    const handleDateTime = (e) => {
        SetRelFunction({ ...editUserState, [e.target.name]: e.target.value })
    }

    useEffect(() => {

        const secureId = localStorage.getItem("premiumVerified");

        const url = `https://backend.shubadinam.com/premiumcheck/signinpopup?secureId=${secureId}`

        axios.get(url)
            .then((response) => {
                // console.log("111");
                setDatePicker(true)
            })
            .catch((error) => {
                // console.log(error);
                setDatePicker(false)
                if (error.response && error.response.status === 403) {
                    Swal.fire({
                        text: 'Something unexpected happen ! Please login again.',
                        icon: "info",
                        showConfirmButton: true,
                    })
                    localStorage.clear();
                    sessionStorage.clear();
                }

            })
    }, [])

    const [showDatePicker, setDatePicker] = useState(false)



    const alertBuyPremium = () => {

        Swal.fire({
            title: 'Premium Feature !',
            text: 'Please buy premium to get access.',
            html: `
                <p>This is a Premium feature. Use this Nakshatra/Tithi finder Assistance to get the details as per English Date of Birth. Experience many more exclusive benefits & Unlock the Full potential by upgrading to Premium.</p>
                <button class="premium-button" id="premium-button" style="display: block; margin: 0 auto;">
                    <svg viewBox="0 0 576 512" height="1em" class="logoIcon">
                        <path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"></path>
                    </svg>
                    GO PREMIUM
                </button>`,
            showConfirmButton: false,
            didOpen: () => {
                const premiumButton = document.getElementById('premium-button');
                premiumButton.addEventListener('click', navtoPricing);
            }
        });
        // Swal.fire({
        //     title: 'Access Denied',
        //     text: 'Please buy premium to get access.',
        //     html: `<button class="premium-button" id="premium-button">
        //     <svg viewBox="0 0 576 512" height="1em" class="logoIcon">
        //         <path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"></path>
        //     </svg>
        //     GO PREMIUM
        // </button>`,
        //     showConfirmButton: false,
        //     didOpen: () => {
        //         const premiumButton = document.getElementById('premium-button');
        //         premiumButton.addEventListener('click', navtoPricing);
        //     }

        // });
    }

    const navtoPricing = () => {
        Swal.close();
        navigate("/premium-checkout")
        window.location.reload()
    }

    const handleEnglishCalendarClick = (value) => {

        setValue(value);
        if (value === 1) {
            setshowNakshatra(false)
        } else {
            setshowNakshatra(true)
        }
        if (formRef.current) {
            formRef.current.reset();
        }
        if (formRef2.current) {
            formRef2.current.reset();
        }
    };



    return (
        <div className="edit-popup" style={{
            fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"
        }}>
            <div className="edit-title" style={{ overflow: "hidden", }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab sx={{ width: "150px", fontSize: "smaller !important" }} label="Find By Indian Calendar" {...a11yProps(0)} />
                        <Tab className='date-btn' sx={{ width: "150px", fontSize: "smaller !important" }} label="Find By English  Calendar" {...a11yProps(1)} />
                    </Tabs>
                </Box>
            </div>

            <div className="edit-content">
                {
                    showNakshatra ?
                        <>
                            <div className="edit-form">

                                <h4 className="edit-form-title"> Fill Janma Nakshatra/ Tithi details to get reminders. </h4>

                                <div className="form-group">
                                    <label htmlFor="relName" className="edit-label edit-keys">Name</label>
                                    <input
                                        className="edit-input"
                                        type="text"
                                        id="relName"
                                        name="editUName"
                                        onChange={handleInput}
                                        value={editedObject.userName}
                                        readOnly
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="relGender" className="edit-label edit-keys">Gender</label>
                                    <select value={editUserState.editUGender} className="edit-input" name="editUGender" onChange={handleInput}>
                                        <option disabled value="">Select your Gender</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="others">Others</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="relRelation" className="edit-label edit-keys">Relation:</label>

                                    <input
                                        className="edit-input"
                                        type="text"
                                        id="relName"
                                        name="editURelation"
                                        onChange={handleInput}
                                        value={editedObject.userRelation}
                                        readOnly
                                    />

                                </div>

                                <div className="form-group">
                                    <label htmlFor="relCelebrate" className="edit-label edit-keys">What do you celebrate</label>
                                    <select value={editUserState.editUnakshatraTithi} className="edit-input" onChange={handleNakTithi} name="editUnakshatraTithi" >
                                        <option disabled value="">--Nakshatra/Tithi--</option>
                                        <option value="Nakshatra">Nakshatra</option>
                                        <option value="Tithi">Tithi</option>
                                    </select>

                                </div>

                                <div className="form-group">
                                    <label htmlFor="relPanchang" className="edit-label edit-keys">Panchang</label>
                                    <select defaultValue={""} className="edit-input" onChange={handleCalender} name="editUCalender" >
                                        <option disabled value={""}>--Panchang--</option>
                                        {
                                            Calender.map((clr, index) => (
                                                <option key={index} value={clr.name} data-calendercode={clr.calenderCode}>{clr.name}</option>
                                            ))
                                        }
                                    </select>



                                </div>

                                <div className="form-group">
                                    <label htmlFor="relMonth" className="edit-label edit-keys">Month</label>
                                    <select defaultValue={""} className="edit-input" onChange={handleMonth} name="editUMonth" >
                                        <option disabled value="">--Month--</option>
                                        {
                                            months.map((mth, index) => (
                                                <option key={index} value={mth.name}>{mth.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>

                                {
                                    nakTithi && nakTithi === "Nakshatra" ?

                                        <div className="form-group">
                                            <label htmlFor="relNakshatra" className="edit-label edit-keys">Nakshatra</label>
                                            {/* <div className="value-input"> */}
                                            <select defaultValue={""} className="edit-input" onChange={handleNakshathra} name="editUrelNakshathra">
                                                <option disabled value={""}>--Nakshatra--</option>
                                                {
                                                    nakshathras.map((nakshathra, index) => (
                                                        <option key={index}>{nakshathra}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        // </div>
                                        :
                                        <div></div>
                                }


                                {
                                    nakTithi && nakTithi === "Tithi" ?
                                        <div className="nakandtithi">
                                            <div className="form-group">
                                                <label htmlFor="relPaksham" className="edit-label edit-keys">Paksha</label>
                                                {/* <div className="value-input"> */}
                                                <select defaultValue={""} className="edit-input" onChange={handlePaksham} name="editUPaksham">
                                                    <option disabled value={""}>--Paksha--</option>
                                                    {
                                                        nakTithis.map((Paksham, index) => (
                                                            <option key={index} value={Paksham.name}>{Paksham.name}</option>
                                                        ))
                                                    }
                                                </select>
                                                {/* </div> */}
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="relTithi" className="edit-label edit-keys">Tithi</label>
                                                {/* <div className="value-input"> */}
                                                <select defaultValue={""} className="edit-input" onChange={handleTithi} name="editUTithi">
                                                    <option disabled value={""}>--Tithi--</option>
                                                    {
                                                        tithiname.map((tithi, index) => (
                                                            <option key={index} value={tithi}>{tithi}</option>
                                                        ))
                                                    }
                                                </select>
                                                {/* </div> */}
                                            </div>
                                        </div>
                                        :
                                        <div></div>
                                }

                            </div>

                            <br />
                            <label className="letters-color profile-buy-link" style={{ textAlign: "center" }}>If you don’t know the above details, you can use <strong>
                                <NavLink style={{ textDecoration: "none", cursor: "pointer" }} onClick={() => handleEnglishCalendarClick(1)}>English Date of Birth </NavLink>   </strong>
                                to find the Janma Dinam details.</label>

                            <br />
                            <br />
                            <span style={{ fontSize: '14px', color: '#666', fontStyle: 'italic' }}>
                                (Note: Please fill out all fields to get edited.) *
                            </span>


                            <div className="edit-buttons">

                                <button className="edit-changes" onClick={handleSave}
                                    disabled={
                                        editUserState.editUCalender === "" ||
                                        editUserState.editUGender === "" ||
                                        editUserState.editUMonth === "" ||
                                        editUserState.editURelation === "" ||
                                        editUserState.editUnakshatraTithi === "" ||
                                        editUserState.editUrelNakshathra === "" && editUserState.editUTithi === ""
                                    }
                                >Edit Changes</button>
                                <button className="edit-close" onClick={onClose}>Close</button>
                            </div>

                        </>
                        :
                        <>


                            {
                                showDatePicker ?

                                    <>
                                        <div className="edit-form">

                                            <h4 className="edit-form-title"> Use this Nakshatra/Tithi finder Assistance to get the details as per English Date of Birth.  </h4>

                                            <div className="form-group">
                                                <label htmlFor="relName" className="edit-label edit-keys">Name</label>
                                                <input
                                                    className="edit-input"
                                                    type="text"
                                                    id="relName"
                                                    name="editUName"
                                                    onChange={handleInput}
                                                    value={editedObject.userName}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="relGender" className="edit-label edit-keys">Gender</label>
                                                <select value={editUserState.editUGender} className="edit-input" name="editUGender" onChange={handleInput}>
                                                    <option disabled value="">Select your Gender</option>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                    <option value="others">Others</option>
                                                </select>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="relRelation" className="edit-label edit-keys">Relation:</label>

                                                <input
                                                    className="edit-input"
                                                    type="text"
                                                    id="relName"
                                                    name="editURelation"
                                                    onChange={handleInput}
                                                    value={editedObject.userRelation}
                                                    readOnly
                                                />

                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="relCelebrate" className="edit-label edit-keys">What do you celebrate</label>
                                                <select value={editUserState.editUnakshatraTithi} className="edit-input" onChange={handleNakTithi} name="editUnakshatraTithi" >
                                                    <option disabled value="">--Nakshatra/Tithi--</option>
                                                    <option value="Nakshatra">Nakshatra</option>
                                                    <option value="Tithi">Tithi</option>
                                                </select>

                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="relPanchang" className="edit-label edit-keys">Panchang</label>
                                                <select defaultValue={""} className="edit-input" onChange={handleCalender} name="editUCalender" >
                                                    <option disabled value={""}>--Panchang--</option>
                                                    {
                                                        Calender2.map((clr, index) => (
                                                            <option key={index} value={clr.name} data-calendercode={clr.calenderCode}>{clr.name}</option>
                                                        ))
                                                    }
                                                </select>



                                            </div>




                                            <div className="form-group">
                                                <label htmlFor="relRelation" className="edit-label edit-keys">Birth Country:</label>
                                                <div className="edit-input" style={{ border: "transparent", padding: "0px " }}>
                                                    <CountrySelect
                                                        onChange={(e) => {
                                                            setCountryid(e.id);
                                                            // console.log(e);
                                                            SetRelFunction(prevState => ({
                                                                ...prevState,
                                                                userCountry: e.name
                                                            }));
                                                        }}
                                                        onTextChange={(e) => {
                                                            SetRelFunction(prevState => ({
                                                                ...prevState,
                                                                userCountry: e.target.value
                                                            }));
                                                        }}
                                                        name="userCountry"
                                                        placeHolder="Select Country"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="relRelation" className="edit-label edit-keys">Birth State:</label>
                                                <div className="edit-input" style={{ border: "transparent", padding: "0px " }}>
                                                    <StateSelect
                                                        countryid={countryid}
                                                        onChange={(e) => {
                                                            setstateid(e.id);
                                                            // console.log(e);
                                                            SetRelFunction(prevState => ({
                                                                ...prevState,
                                                                userState: e.name
                                                            }));
                                                        }}
                                                        onTextChange={(e) => {
                                                            SetRelFunction(prevState => ({
                                                                ...prevState,
                                                                userState: e.target.value
                                                            }));
                                                            // console.log(e.target.value);
                                                        }}
                                                        name="userState"
                                                        placeHolder="Select State"
                                                    />
                                                </div>

                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="relRelation" className="edit-label edit-keys">Birth City:</label>
                                                <div className="edit-input" style={{ border: "transparent", padding: "0px " }}>
                                                    <CitySelect
                                                        countryid={countryid}
                                                        stateid={stateid}
                                                        onChange={(e) => {
                                                            setCityid(e.id);
                                                            // console.log(e);
                                                            SetRelFunction(prevCity => ({
                                                                ...prevCity,
                                                                userCity: e.name
                                                            }));
                                                        }}
                                                        onTextChange={(e) => {
                                                            SetRelFunction(prevCity => ({
                                                                ...prevCity,
                                                                userCity: e.target.value
                                                            }));
                                                            // console.log(e.target.value);
                                                        }}
                                                        name="userCity"
                                                        placeHolder="Select City"
                                                    />
                                                </div>


                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="relRelation" className="edit-label edit-keys">Birth Date & Time:</label>
                                                <input className="edit-input" type='datetime-local' onChange={(e) => handleDateTime(e)} placeholder="--Select Date & Time" name="userDateTime" ></input>
                                            </div>




                                        </div>



                                        <span style={{ fontSize: '14px', color: '#666', fontStyle: 'italic' }}>
                                            (Note: Please fill out all fields to get edited.) *
                                        </span>


                                        <div className="edit-buttons">

                                            <button className="edit-changes" onClick={handleSave}
                                                disabled={
                                                    editUserState.editUCalender === "" ||
                                                    editUserState.userCountry === "" ||
                                                    editUserState.userCity === "" ||
                                                    editUserState.userState === "" ||
                                                    editUserState.userDateTime === ""
                                                }
                                            >Edit Changes</button>
                                            <button className="edit-close" onClick={onClose}>Close</button>
                                        </div>

                                    </>

                                    :




                                    <>
                                        {alertBuyPremium()}

                                        <form ref={formRef2} >


                                            <div className="title-name">
                                                <div className="key-title">Name:</div>
                                                <div className="value-input">
                                                    <input className="singup-input-box" type="text" name="userName" onChange={handleInput} />
                                                </div>
                                            </div>
                                            <div className="title-email hidden">
                                                <div className="key-title" >Email:</div>
                                                <div className="value-input">
                                                    <input className="singup-input-box hidden" type="email" placeholder="please enter user email" name="userEmail" readOnly />
                                                </div>
                                            </div>
                                            <div className="title-gender">
                                                <div className="key-title">Gender:</div>
                                                <div className="value-input">
                                                    <select defaultValue={""} className="singup-input-box" name="userGender" onChange={handleInput}>
                                                        <option disabled value="">Select your Gender</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                        <option value="others">Others</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="title-relation">
                                                <div className="key-title">Relation:</div>
                                                <div className="value-input">
                                                    <select defaultValue={""} className="singup-input-box" name="userRelation" onChange={handleInput}>
                                                        <option disabled value="">Select Relation</option>
                                                        <option value="Grand Parent">Grand Parent</option>
                                                        <option value="Grand Children">Grand Children</option>
                                                        <option value="Parent">Parent</option>
                                                        <option value="Friend">Friend</option>
                                                        <option value="Relative">Relative</option>
                                                        <option value="Spouse">Spouse</option>
                                                        <option value="Sibling">Sibling</option>
                                                        <option value="Children">Children</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="title-nakshatra-tithi">
                                                <div className="key-title letters-color">What do they celebrate:</div>
                                                <div className="value-input">
                                                    <select defaultValue={""} className="singup-input-box" onChange={handleNakTithi} name="nakshatraTithi" >
                                                        <option disabled value="">--Nakshatra/Tithi--</option>
                                                        <option value="Nakshatra">Nakshatra</option>
                                                        <option value="Tithi">Tithi</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="title-calender">
                                                <div className="key-title">Panchang:</div>
                                                <div className="value-input">
                                                    <select defaultValue={""} className="singup-input-box" onChange={handleCalender} name="userCalender" >
                                                        <option disabled value={""}>--Panchang--</option>
                                                        {
                                                            Calender2.map((clr, index) => (
                                                                <option key={index} value={clr.name} data-calendercode={clr.calenderCode}>{clr.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="title-gender">
                                                <div className="key-title">Birth Country:</div>
                                                <div className="value-input">
                                                    <CountrySelect
                                                        onChange={(e) => {
                                                            setCountryid(e.id);
                                                            // console.log(e);
                                                            SetRelFunction(prevState => ({
                                                                ...prevState,
                                                                userCountry: e.name
                                                            }));
                                                        }}
                                                        onTextChange={(e) => {
                                                            SetRelFunction(prevState => ({
                                                                ...prevState,
                                                                userCountry: e.target.value
                                                            }));
                                                        }}
                                                        name="userCountry"
                                                        placeHolder="Select Country"
                                                    />
                                                </div>
                                            </div>

                                            <div className="title-gender">
                                                <div className="key-title">Birth State:</div>
                                                <div className="value-input">
                                                    <StateSelect
                                                        countryid={countryid}
                                                        onChange={(e) => {
                                                            setstateid(e.id);
                                                            // console.log(e);
                                                            SetRelFunction(prevState => ({
                                                                ...prevState,
                                                                userState: e.name
                                                            }));
                                                        }}
                                                        onTextChange={(e) => {
                                                            SetRelFunction(prevState => ({
                                                                ...prevState,
                                                                userState: e.target.value
                                                            }));
                                                            // console.log(e.target.value);
                                                        }}
                                                        name="userState"
                                                        placeHolder="Select State"
                                                    />

                                                </div>
                                            </div>

                                            <div className="title-gender">
                                                <div className="key-title">Birth City:</div>
                                                <div className="value-input">
                                                    <CitySelect
                                                        countryid={countryid}
                                                        stateid={stateid}
                                                        onChange={(e) => {
                                                            setCityid(e.id);
                                                            // console.log(e);
                                                            SetRelFunction(prevCity => ({
                                                                ...prevCity,
                                                                userCity: e.name
                                                            }));
                                                        }}
                                                        onTextChange={(e) => {
                                                            SetRelFunction(prevCity => ({
                                                                ...prevCity,
                                                                userCity: e.target.value
                                                            }));
                                                            // console.log(e.target.value);
                                                        }}
                                                        name="userCity"
                                                        placeHolder="Select City"
                                                    />

                                                </div>
                                            </div>

                                            <div className="title-gender">
                                                <div className="key-title">Birth Date & Time:</div>
                                                <div className="value-input">
                                                    <input className="singup-input-box" type='datetime-local' onChange={(e) => handleDateTime(e)} placeholder="--Select Date & Time" name="userDateTime" ></input>
                                                </div>
                                            </div>







                                        </form>

                                        <br />

                                        <span style={{ fontSize: '14px', color: '#666', fontStyle: 'italic' }}>
                                            (Note: Please fill out all fields before submit.) *
                                        </span>

                                        <div className="title-signup-btn">
                                            <button onClick={() => navtoPricing()} className="signup-btn-style" data-bs-dismiss="modal"

                                            >Buy Premium</button>
                                            <button className="edit-close" onClick={onClose}>Close</button>
                                        </div>
                                    </>

                            }
                        </>
                }
            </div>
        </div>
    );
};

export default UserEditComponent;
