import { Box, Button } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

const InboxPerCustomer = () => {
    const location = useLocation();
    const Navigate = useNavigate();


    const params = new URLSearchParams(location.search);
    const perperson = JSON.parse(params.get('perPersonContact'));

    const [dynamicState, setDynamicFunction] = useState({
        companyReply: "",
        id: perperson.id,
        customerEmail: perperson.customerEmail,
        customerName: perperson.customerName,
        customerMessage: perperson.customerMessage,
        uniqueId: perperson.uniqueId || "user",
        customerPhoneNumber: perperson.customerPhoneNumber,
        timestamp: perperson.timestamp
    })

    const handleReply = (e) => {
        setDynamicFunction({ ...dynamicState, [e.target.name]: e.target.value })
    }

    const handleRead = () => {

        axios.delete(`https://backend.shubadinam.com/delete/inbox`, {
            params: {
                id: perperson.id,
                customerEmail: perperson.customerEmail,
                customerName: perperson.customerName,
                customerMessage: perperson.customerMessage,
                uniqueId: perperson.uniqueId || "user",
                customerPhoneNumber: perperson.customerPhoneNumber,
                timestamp: perperson.timestamp
            }
        })

            .then((response) => {
                // console.log(response);
                window.location.href = "/contact/inbox"
                // setContactData(contactData.filter(cdata => cdata.id !== row.id));
            })
            .catch((error) => {
                // console.log(error);
            });
    }

    const handleSubmit = () => {

        if (!dynamicState.companyReply) {
            alert("Please fill the reply before submit");
            return;
        }

        // console.log(dynamicState);



        const url = `https://backend.shubadinam.com/post/reply`
        axios.post(url, dynamicState)
            .then((response) => {
                // console.log(response);
                window.location.href = "/contact/inbox"
                // setContactData(contactData.filter(cdata => cdata.id !== row.id));
            })
            .catch((error) => {
                // console.log(error);
                alert("Something unexpected happen !Message not send to the user")
            });
    }

    const handleBack = () => {
        window.location.href = "/contact/inbox";
    };



    return (
        <div >
            <br />
            <h3 style={{ textAlign: "center", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> Message  </h3>
            <br />
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <tbody>
                    <tr >
                        <td style={{ fontWeight: 'bold', border: '1px solid #ddd', padding: '8px', textAlign: "center" }}>Name:</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{perperson.customerName}</td>
                    </tr>
                    <tr>
                        <td style={{ fontWeight: 'bold', border: '1px solid #ddd', padding: '8px', textAlign: "center" }}>Unique ID:</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{perperson.uniqueId || "User"}</td>
                    </tr>
                    <tr>
                        <td style={{ fontWeight: 'bold', border: '1px solid #ddd', padding: '8px', textAlign: "center" }}>Email:</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{perperson.customerEmail}</td>
                    </tr>
                    <tr>
                        <td style={{ fontWeight: 'bold', border: '1px solid #ddd', padding: '8px', textAlign: "center" }}>Phone:</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{perperson.customerPhoneNumber}</td>
                    </tr>
                    <tr>
                        <td style={{ fontWeight: 'bold', border: '1px solid #ddd', padding: '8px', textAlign: "center" }}>Message:</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px', whiteSpace: 'pre-wrap', wordWrap: 'break-word', height: "auto" }}>{perperson.customerMessage}</td>
                    </tr>
                    <tr>
                        <td style={{ fontWeight: 'bold', border: '1px solid #ddd', padding: '8px', textAlign: "center" }}>Reply:</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px', whiteSpace: 'pre-wrap', wordWrap: 'break-word', height: "auto" }}>
                            <textarea
                                // value={value}
                                onChange={(e) => handleReply(e)}
                                name='companyReply'
                                style={{
                                    width: '90%',
                                    minHeight: '100px',
                                    maxHeight: '200px',
                                    resize: 'both', // Allows resizing both horizontally and vertically
                                    padding: '10px',
                                    fontSize: '16px',
                                    boxSizing: 'border-box',
                                }}
                                placeholder="Type here..."
                            /></td>
                    </tr>
                    <tr>
                        <td style={{ fontWeight: 'bold', border: '1px solid #ddd', padding: '8px', textAlign: "center" }}></td>
                        <td style={{ border: '1px solid #ddd', padding: '8px', width: "50%", whiteSpace: 'pre-wrap', wordWrap: 'break-word', display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                            <Button onClick={() => handleSubmit()}>Submit</Button>
                            <Button onClick={() => handleBack()}>Back</Button>
                            <Button onClick={() => handleRead()}>Read</Button>
                        </td>
                    </tr>


                </tbody>
            </table>
            <div style={{ height: "100px" }}>

            </div>
        </div>
    );
};

export default InboxPerCustomer;