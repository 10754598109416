import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useRef } from "react";
import DataSharingContext from "../context/data-sharing-context";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import SplitButton from 'react-bootstrap/SplitButton';
import { ButtonGroup } from 'react-bootstrap'; // Add this import

import Chart from 'chart.js/auto';

import OtpInput from 'react-otp-input';



const Rough = () => {


  const context = useContext(DataSharingContext);

  const [dynamicIn, setDynamicIn] = useState({
    name: "",
    email: "",
  });

  const handleInput = (e) => {
    setDynamicIn({ ...dynamicIn, [e.target.name]: e.target.value });
  };

  const submit = () => {
    const url = "https://backend.shubadinam.com/test2";

    axios
      .post(url, dynamicIn)
      .then((res) => {
        const data = res.data;
        context.globalFunction(data);
        console.log(context.globalVariable);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const OtpComponent = () => {
  // const [otp, setOtp] = useState('');
  // const inputRef = useRef();

  // const handleOtpChange = (value) => {
  //   if (!/^\d*$/.test(value)) return; // Allow only numeric input
  //   setOtp(value);

  //   // Move focus to the next input box after each digit
  //   if (value.length < 4) {
  //     inputRef.current.focus();
  //   }
  // };


  const [otp, setOtp] = useState('');

  const [customers, setCustomers] = useState([]);
  const [checkouts, setCheckouts] = useState([]);

  useEffect(() => {
    // Fetch Shopify customers
    axios.get('https://backend.shubadinam.com/api/customers')
      .then(response => setCustomers(response.data.customers))
      .catch(error => console.error(error));

    // Fetch Shopify checkouts
    axios.get('https://backend.shubadinam.com/api/checkouts')
      .then(response => setCheckouts(response.data.checkouts))
      .catch(error => console.error(error));
  }, []);

  const speakWord = () => {
    const wordToSpeak = 'Hello'; // Change this to the word you want to speak

    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(wordToSpeak);
      window.speechSynthesis.speak(utterance);
    } else {
      alert('Speech synthesis is not supported in your browser.');
    }
  };

  // useEffect(() => {
  //   const getLocation = () => {
  //     // Check if geolocation is supported by the browser
  //     if ('geolocation' in navigator) {
  //       // Request the user's current position
  //       navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //           // Extract latitude and longitude from the position object
  //           const { latitude, longitude } = position.coords;

  //           // Log the coordinates to the console (you can perform other actions here)
  //           console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
  //         },
  //         (error) => {
  //           // Handle errors
  //           console.error('Geolocation error:', error.message);
  //         }
  //       );
  //     } else {
  //       // Geolocation is not supported by the browser
  //       console.error('Geolocation is not supported in this browser.');
  //     }
  //   };

  //   // Call the function to get location when the component mounts
  //   getLocation();
  // }, []);

  const localVideoRef = useRef(null);

  // useEffect(() => {
  //   const setupWebcam = async () => {
  //     try {
  //       const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

  //       // Use the ref to access the video element
  //       localVideoRef.current.srcObject = mediaStream;
  //     } catch (error) {
  //       console.error('Error accessing webcam:', error.message);
  //     }
  //   };
  //   setupWebcam();
  // }, [])





  return (
    <div>
      <h1>Rough</h1>
      <div>
        <input type="text" name="name" onChange={handleInput} />
        <br />
        <input type="text" name="email" onChange={handleInput} />
        <br />
        <button onClick={() => submit()}>Submit</button>
        <br />
      </div>
      <>
        <button>Submit</button>
      </>
      <button id="myButton" onClick={() => alert("Button clicked!")}>
        Click me
      </button>

      <>
        <div className="mb-2">
          {['up', 'up-centered', 'down', 'down-centered', 'start', 'end'].map(
            (direction) => (
              <DropdownButton
                as={ButtonGroup}
                key={direction}
                id={`dropdown-button-drop-${direction}`}
                drop={direction}
                variant="secondary"
                title={` Drop ${direction} `}
              >
                <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
              </DropdownButton>
            ),
          )}
        </div>

        <div>
          {['up', 'up-centered', 'down', 'down-centered', 'start', 'end'].map(
            (direction) => (
              <SplitButton
                key={direction}
                id={`dropdown-button-drop-${direction}`}
                drop={direction}
                variant="secondary"
                title={`Drop ${direction}`}
              >
                <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
              </SplitButton>
            ),
          )}
        </div>
      </>


      {/* <>
        <IonButton id="present-alert">Click Me</IonButton>
        <IonAlert
          isOpen={
            Array.isArray(context.globalVariable) &&
            context.globalVariable.length > 0
          }
          trigger="present-alert"
          header="Alert"
          subHeader="Values in Context"
          message={`Values in context.globalVariable: ${JSON.stringify(
            context.globalVariable
          )}`}
          buttons={["OK"]}
        ></IonAlert>
      </> */}

      <div>
        <button onClick={() => speakWord()}>Touch here</button>
      </div>

      <div>
        <video ref={localVideoRef} autoPlay playsInline muted />
      </div>







      <div class="parent-container">
        <div class="sub-container">
          Sub-container content
        </div>
      </div>


      <div>
        {Array.isArray(context.globalVariable) &&
          context.globalVariable.map((value, index) => (
            <div key={index}>
              <p>Name: {value.name}</p>
              <p>Email: {value.email}</p>
            </div>
          ))}
      </div>
      {/* 
      <div className="otp-container">
      <h5>Enter your OTP to verify:</h5>
      <div className="otp-inputs">
        {[1, 2, 3, 4].map((index) => (
          <input
            key={index}
            ref={index === 1 ? inputRef : null}
            type="text"
            maxLength="1"
            value={otp[index - 1] || ''}
            onChange={(e) => handleOtpChange(e.target.value)}
          />
        ))}
      </div>
      <button onClick={() => console.log('Submitted OTP:', otp)}>Submit OTP</button>
    </div> */}

      <OtpInput
        value={otp}
        onChange={setOtp}
        numInputs={4}
        renderSeparator={<span>-</span>}
        renderInput={(props) => <input {...props} />}
      />


      {/* <div>
        <h1>Shopify Data</h1>

        <h2>Customers</h2>
        <ul>
          {customers.map(customer => (
            <li key={customer.id}>{customer.email}</li>
          ))}
        </ul>

        <h2>Checkouts</h2>
        <ul>
          {checkouts.map(checkout => (
            <li key={checkout.id}>{checkout.token}</li>
          ))}
        </ul>
      </div> */}


    </div>
  );
};

export default Rough;
