import React from 'react';

const BottomSpace = () => {
    return (
        <div className='bottom-space'>
            
        </div>
    );
};

export default BottomSpace;