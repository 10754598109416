import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


const PerCustomerDetails = () => {

    const location = useLocation();

    const params = new URLSearchParams(location.search);
    console.log(params)
    const userEmailResult = JSON.parse(params.get('userEmailResult'));
    const userProfileResult = JSON.parse(params.get('userProfileResult'));
    const userRelResult = params.get('userRelResult');
    // const premiumResultTab = JSON.parse(params.get('premiumResultTab'));
    // const premiumResultUser = JSON.parse(params.get('premiumResultUser'));s


    return (
        <>
            <br />
            <h1 style={{ textAlign: "center", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> Customer Detailed Report  </h1>
            <br />
            <div className='admin-custmomer-credentials'>


                {/* <h2>User Profile Result:</h2> */}
                <table className='admin-customer-tableper'>
                    <thead>
                        <tr>
                            <th>Property</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr >
                            <td>People Added</td>
                            <td>{userRelResult}</td>
                        </tr>
                        {Object.entries(userProfileResult).map(([key, value]) => (

                            <tr key={key}>
                                <td>{key}</td>
                                <td>{value || "-"} </td>
                            </tr>
                        ))}
                    </tbody>
                </table>


            </div>
        </>
    );
};

export default PerCustomerDetails;