import React, { useState, useEffect, useRef } from 'react';
import { NavLink, Navigate, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

import Top20px from "../screens/Top20px"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut, Pie, Bar, Bubble, Scatter, HorizontalBar, StackedBar, Mixed, Radar, Line } from 'react-chartjs-2';
import ScrollToTop from 'react-scroll-to-top';

import CountUp from 'react-countup';
import AdminDashboard from '../admin/adminDashboard';
import SDadminHeader from '../admin/SDadminHeader';

ChartJS.register(ArcElement, Tooltip, Legend);



const AdminDatabase = () => {


    const [isDatabase, setDatabase] = useState(false);

    const databaseRef = useRef(null);

    const notify = () => toast("Wow so easy!");

    const navigate = useNavigate()

    useEffect(() => {
        if (!sessionStorage.getItem("adminId")) {
            navigate("/admin");
        }

    }, [navigate])


    const [chngPwd, SetPwd] = useState(false);
    const [confirmPassword, SetConfirmPassword] = useState({
        // oldPwd: "",
        confirmPwd: "",
        reconfirmPwd: "",
    });

    const [isSendMailClicked, setSendMailClicked] = useState(false);

    const handleSendMailClick = () => {
        setSendMailClicked(!isSendMailClicked);
    };

    const [formData, setFormData] = useState({
        from: "",
        to: "",
        subject: "",
        message: "",
        attachments: [],
    });

    const [emailType, setEmailType] = useState('custom'); // 'custom' or 'allUsers'

    const handleEmailTypeChange = (e) => {
        setEmailType(e.target.value);
        // If 'allUsers' is selected, clear the 'to' field
        if (e.target.value === 'allUsers') {
            setFormData({ ...formData, to: '' });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const base64String = event.target.result;
                setFormData({ ...formData, attachments: base64String });
            };

            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add your logic to send the email here
        // console.log('Form data submitted:', formData);
        // Add Axios request or any other logic to send the email
    };

    const changePwd = () => {
        SetPwd(true);
        // setSelectedNavItem(false);
        if (isSidebarOpen) {
            setSidebarOpen(false); // Close sidebar on item selection for mobile
        }
    };

    const pwdhandler = (evt) => {
        SetConfirmPassword({ ...confirmPassword, [evt.target.name]: evt.target.value });
    };

    const clickSubmit = () => {
        if (confirmPassword.confirmPwd !== "") {
            if (confirmPassword.confirmPwd === confirmPassword.reconfirmPwd) {

                const url = `https://backend.shubadinam.com/adminaccess/upt/password`;

                const adminId = sessionStorage.getItem("adminId")

                axios
                    .post(url, { ...confirmPassword, adminId: adminId })
                    .then((response) => {
                        // console.log(response);
                        alert(response.data.message)
                        // alert("Password updted successfully.");
                    })
                    .catch((error) => {
                        alert(error.response.data.message)
                        // console.log(error);
                    });

                SetPwd(false);
            } else {
                alert("not equal");
                SetPwd(false);
            }
        } else {
            alert("password field is empty");
            SetPwd(false);
        }

    };

    const clickClose = () => {
        SetPwd(false)
    };

    const handlePopupClick = (e) => {
        // Prevent the click event from reaching the pwd-container
        e.stopPropagation();
    };



    // const [homeCondition, SetHomeCondition] = useState(false)
    const [databaseCondition, SetDatabaseCondition] = useState(false)

    const clickHome = () => {
        // SetHomeCondition(true)
        SetDatabaseCondition(false)
        navigate(`/adminpage`)
        setExpanded(false); // Close the Navbar after click

    }

    const clickDatabase = (e) => {
        e.preventDefault()
        setExpanded(false); // Close the Navbar after click

        SetDatabaseCondition(true);
        // SetHomeCondition(false);
    };




    const direction = 'end';

    const adminId = sessionStorage.getItem("adminId")


    const [userData, setUserData] = useState([]);
    const [newUser, setNewuser] = useState([]);


    useEffect(() => {

        const url = `https://backend.shubadinam.com/adminpage/analyseusers`

        axios.get(url)
            .then(response => setUserData(response.data))
        // .catch(error => console.error('Error fetching user data:', error));


        const getTodayUsers = `https://backend.shubadinam.com/adminpage/newusers`;

        axios.get(getTodayUsers)
            .then(response => setNewuser(response.data.setNewuser))
        // .catch(error => console.error('Error fetching user data:', error));



    }, []);


    const totalUsers = userData.length;




    // Chart data
    const chartData = {
        labels: ['Total Users'],
        datasets: [
            {
                data: [totalUsers, 100 - totalUsers], // Assuming a total of 100 users
                backgroundColor: ['#36A2EB', '#FFCE56'], // You can customize colors
            },
        ],
    };

    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };


    const styles = {
        bodyChartContainer: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '1fr 1fr',
            gap: '5px',
            // border: '1px solid rgba(75, 74, 74, 0.596)',
            height: '100%',
            width: '100%',
            // justifyContent: 'space-between',
            // alignItems: 'center',
            // padding: '20px',
        },
        bodyChart: {
            border: '1px solid black',
            width: '100%',
            height: '100%',
            display: "flex",
            flexDirection: 'row'
            // backgroundColor:"grey"
        },
    };


    const [selectedSection, setSelectedSection] = useState('dashboard');

    const handleSectionChange = (section) => {
        setSelectedSection(section);
        // navigate(`/adminpage/${section}`);
    };

    const clickSignOut = () => {
        sessionStorage.removeItem("adminId")
        navigate("/admin")
    }
    const logoClick = () => {
        navigate("/")
    }

    const [expanded, setExpanded] = useState(false);

    const [users, setUsers] = useState([]);


    useEffect(() => {
        const url = `https://backend.shubadinam.com/get/nonpremiumusers`

        axios.get(url)
            .then((response) => {
                setUsers(response.data)
            })
            .catch((error) => {
                // console.log(error);
            })
    }, [])


    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [selectedNavItem, setSelectedNavItem] = useState(true);

    const toggleSidebar = (e) => {
        setSidebarOpen(!isSidebarOpen);
    };

    const handleNavItemSelect = (item) => {
        setSelectedNavItem(item);
        if (isSidebarOpen) {
            setSidebarOpen(false); // Close sidebar on item selection for mobile
        }
    };

    const handleOutsideClick = (event) => {
        // const sidebar = document.getElementById('layoutSidenav_nav');
        if (isSidebarOpen) {
            setSidebarOpen(false);
        }
    };

    const togglePassword = () => {
        SetPwd(true)
    }




    return (

        <div className="sd-adminpage">
            <header className="sd-header">
                <SDadminHeader clickPass={togglePassword} />
            </header>
            <Top20px />
            <div className="sd-body-database">
                <Outlet></Outlet>
            </div>
            {
                chngPwd ?
                    <div className="pwd-container" onClick={clickClose}>
                        <div className="pwd-popup" onClick={handlePopupClick}>

                            <label>Enter your New Password :</label>
                            <input type="text" name="confirmPwd" onChange={pwdhandler} />
                            <br />
                            <label>Reconfirm your Password :</label>
                            <input type="text" name="reconfirmPwd" onChange={pwdhandler} />
                            <br />
                            <button onClick={() => clickSubmit()}>Submit</button>
                            <button style={{ marginLeft: "10px", backgroundColor: "#ffd7d7", color: "black" }} onClick={() => clickClose()}>Close</button>

                        </div>
                    </div>
                    :
                    <span></span>
            }
        </div>
    );
};

export default AdminDatabase;