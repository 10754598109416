// import axios from 'axios';
// import React, { useEffect, useState } from 'react';

// const PremiumUsers = () => {
//     const [users, setUsers] = useState([]);
//     const [users2, setUsers2] = useState([]);

//     useEffect(() => {
//         const url = `https://backend.shubadinam.com/get/premiumusers/list`;

//         axios.get(url)
//             .then((response) => {
//                 setUsers(response.data.result);
//                 setUsers2(response.data.result2);
//                 console.log(response.data);
//             })
//             .catch((error) => {
//                 console.log(error);
//             })
//     }, []);

//     const currentPath = window.location.pathname;
//     // const pathAfterAdminPage = currentPath.split('/adminpage/')[1];

//     // console.log('Current Path:', pathAfterAdminPage.charAt(0).toUpperCase());
//     const mergeUserData = () => {
//         // Assuming that users and users2 share a common key like userId
//         return users.map(user => {
//             const additionalData = users2.find(u2 => u2.premiumId === user.premiumId) || {};
//             return { ...user, ...additionalData };
//         });
//     };
//     // const mergedUsers = mergeUserData();
//     const sortedMergedUsers = mergeUserData().sort((a, b) => b.id - a.id);




//     return (
//         <div style={containerStyle}>
//             <h3 style={{ textAlign: "center", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> Premium users  </h3>
//             <br />
//             <table style={{ borderCollapse: 'collapse', width: '100%' }}>
//                 <thead>
//                     <tr>
//                         <th style={thStyle}>S.No</th>
//                         <th style={thStyle}>Name</th>
//                         <th style={thStyle}>ID</th>
//                         <th style={thStyle}>Email</th>
//                         <th style={thStyle}>Amount (₹)</th>
//                         <th style={thStyle}>Purchased (₹)</th>
//                         <th style={thStyle}>Location</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {sortedMergedUsers.map((user, index) => (
//                         <tr key={user.userId}>
//                             <td style={tdStyle}>{index + 1}</td>
//                             <td style={tdStyle}>{user.userName}</td>
//                             <td style={tdStyle}>{user.premiumId}</td>
//                             <td style={tdStyle}>{user.userEmail}</td>
//                             <td style={tdStyle}>₹{user.amount / 100} /-</td>
//                             <td style={tdStyle}>{(new Date(user.entry_time)).toLocaleString()}</td>
//                             <td style={tdStyle}>{user.userState || 'N/A'}</td>

//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//         </div>
//     );
// };

// const containerStyle = {
//     padding: '20px',
//     backgroundColor: '#f7f7f7',
//     borderRadius: '8px',
//     // boxShadow: '0 4px 8px #1a181854',
// };

// const thStyle = {
//     backgroundColor: '#06022e',
//     border: '1px solid #dddddd',
//     padding: '10px',
//     textAlign: 'left',
//     color: "white"

// };

// const tdStyle = {
//     border: '1px solid #dddddd',
//     padding: '8px',
//     textAlign: 'left',
//     overflow: "auto"
// };


// export default PremiumUsers;




import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PropTypes from 'prop-types';
import AdminSearch from '../adminUtils/AdminSearch';

// Pagination Actions Component
function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

// Main Component
export default function PremiumUsers() {

    const [users, setUsers] = useState([]);
    const [users2, setUsers2] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);


    useEffect(() => {
        const url = `https://backend.shubadinam.com/get/premiumusers/list`;

        axios.get(url)
            .then((response) => {
                setUsers(response.data.result);
                setUsers2(response.data.result2);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }, []);

    const currentPath = window.location.pathname;
    // const pathAfterAdminPage = currentPath.split('/adminpage/')[1];

    // console.log('Current Path:', pathAfterAdminPage.charAt(0).toUpperCase());
    const mergeUserData = () => {
        // Assuming that users and users2 share a common key like userId
        return users.map(user => {
            const additionalData = users2.find(u2 => u2.premiumId === user.premiumId) || {};
            return { ...user, ...additionalData };
        });
    };
    // const mergedUsers = mergeUserData();
    const sortedMergedUsers = mergeUserData().sort((a, b) => b.id - a.id);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (query) => {
        setSearchQuery(query);
        setPage(0); // Reset page to 0 when search query changes
    };

    const filteredUsers = sortedMergedUsers.filter(user =>
        user.userName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.premiumId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.userEmail.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.amount.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.entry_time.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.entry_time.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.userState.toLowerCase().includes(searchQuery.toLowerCase())
    );


    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredUsers.length) : 0;

    return (
        <div>
            <h3 style={{ textAlign: "center", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> Premium users  </h3>
            <br />
            <AdminSearch onSearch={handleSearch} />
            <br />
            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={7}
                        count={filteredUsers.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        slotProps={{
                            select: {
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            },
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableHead >
                        <TableRow>
                            <TableCell style={{ width: 50, backgroundColor: "#0c0036", color: "white", fontWeight: "bolder" }}>S.No</TableCell>
                            <TableCell style={{ backgroundColor: "#0c0036", color: "white" }} align="right">Name</TableCell>
                            <TableCell style={{ backgroundColor: "#0c0036", color: "white" }} align="right">ID</TableCell>
                            <TableCell style={{ backgroundColor: "#0c0036", color: "white" }} align="right">Email</TableCell>
                            <TableCell style={{ backgroundColor: "#0c0036", color: "white" }} align="right">Amount (₹)</TableCell>
                            <TableCell style={{ backgroundColor: "#0c0036", color: "white" }} align="right">Purchased (₹)</TableCell>
                            <TableCell style={{ backgroundColor: "#0c0036", color: "white" }} align="right">Location</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUsers.length > 0
                            ? filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {index + 1 + page * rowsPerPage}
                                    </TableCell>
                                    <TableCell style={{ width: 160, overflow: "auto" }} align="right">
                                        {user.userName}
                                    </TableCell>
                                    <TableCell style={{ width: 160, overflow: "auto" }} align="right">
                                        {user.premiumId}
                                    </TableCell>
                                    <TableCell style={{ width: 160, overflow: "auto" }} align="right">
                                        {user.userEmail}
                                    </TableCell>
                                    <TableCell style={{ width: 160, overflow: "auto" }} align="right">
                                        {user.amount / 100}/-
                                    </TableCell>
                                    <TableCell style={{ width: 160, overflow: "auto" }} align="right">
                                        {new Date(user.entry_time).toLocaleString('en-GB', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true,
                                            timeZone: 'UTC'  // This ensures the time is shown in UTC
                                        })}

                                    </TableCell>
                                    <TableCell style={{ width: 160, overflow: "auto" }} align="right">
                                        {user.userState || 'N/A'}
                                    </TableCell>
                                </TableRow>
                            ))
                            : null
                        }
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={7} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={7}
                                count={filteredUsers.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                slotProps={{
                                    select: {
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
}
