import React from 'react';

const Top20px = () => {
    return (
        <div className='top20'>
            
        </div>
    );
};

export default Top20px;