// import axios from 'axios';
// import React from 'react';
// import { useEffect } from 'react';
// import { useState } from 'react';

// import Box from '@mui/material/Box';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
// import NativeSelect from '@mui/material/NativeSelect';

// const PremiumVisitors = () => {

//     const [users, setUsers] = useState([]);


//     useEffect(() => {

//         allUsers();

//     }, [])

//     const currentPath = window.location.pathname;
//     const pathAfterAdminPage = currentPath.split('/adminpage/')[1];

//     // console.log('Current Path:', pathAfterAdminPage.charAt(0).toUpperCase());

//     // function formatDateTime(dateTimeString) {
//     //     const date = new Date(dateTimeString);

//     //     const day = date.getDate().toString().padStart(2, '0');
//     //     const month = (date.getMonth() + 1).toString().padStart(2, '0');
//     //     const year = date.getFullYear();
//     //     const hours = date.getHours().toString().padStart(2, '0');
//     //     const minutes = date.getMinutes().toString().padStart(2, '0');
//     //     const seconds = date.getSeconds().toString().padStart(2, '0');

//     //     // console.log(`${day}-${month}-${year} / ${hours - 5}:${minutes - 30}`);

//     //     return `${day}-${month}-${year} / ${hours}:${minutes}`;
//     // }

//     function formatDateTime(dateTimeString) {
//         const date = new Date(dateTimeString);

//         // Subtract 5 hours and 30 minutes from the time
//         date.setHours(date.getHours() - 5);
//         date.setMinutes(date.getMinutes() - 30);

//         // Handle negative values and adjust accordingly
//         if (date.getHours() < 0) {
//             date.setDate(date.getDate() - 1); // Move to the previous day
//             date.setHours(24 + date.getHours()); // Adjust hours to the previous day
//         }
//         if (date.getMinutes() < 0) {
//             date.setHours(date.getHours() - 1); // Move to the previous hour
//             date.setMinutes(60 + date.getMinutes()); // Adjust minutes to the previous hour
//         }

//         const day = date.getDate().toString().padStart(2, '0');
//         const month = (date.getMonth() + 1).toString().padStart(2, '0');
//         const year = date.getFullYear();
//         const hours = date.getHours().toString().padStart(2, '0');
//         const minutes = date.getMinutes().toString().padStart(2, '0');
//         const seconds = date.getSeconds().toString().padStart(2, '0');

//         // console.log(`${day}-${month}-${year} / ${hours}:${minutes}:${seconds}`);

//         return `${day}-${month}-${year} / ${hours}:${minutes}`;
//     }
//     // console.log(formatDateTime);

//     const allUsers = () => {
//         const url = 'https://backend.shubadinam.com/get/premiumvisitors';
//         axios.get(url)
//             .then(response => {
//                 setUsers(response.data);
//             })
//             .catch(error => {
//                 console.error('Error fetching data:', error);
//             });
//     };

//     const handleFilterChange = (event) => {
//         console.log("Selected Value:", event.target.value);
//         const filterValue = event.target.value;

//         if (filterValue === "10") {
//             const uniqueEmailsSet = new Set();
//             // Filter out duplicates and store unique users
//             const uniqueUsersArray = users.filter(user => {
//                 if (!uniqueEmailsSet.has(user.userEmail)) {
//                     uniqueEmailsSet.add(user.userEmail);
//                     return true;
//                 }
//                 return false;
//             });
//             // Update state with unique users
//             setUsers(uniqueUsersArray);
//         }
//         if (filterValue === "0") {
//             allUsers();
//         }

//     }

//     const sortedData = [...users].sort((a, b) => {
//         return b.id - a.id;
//     });





//     return (
//         <div style={containerStyle}>
//             {/* <h2>{pathAfterAdminPage}</h2> */}
//             <h3>Premium visitors</h3>
//             <br />
//             <Box sx={{ minWidth: 50 }}>
//                 <FormControl >
//                     <InputLabel variant="standard" htmlFor="uncontrolled-native">
//                         Filter
//                     </InputLabel>
//                     <NativeSelect
//                         defaultValue={0}
//                         inputProps={{
//                             name: 'age',
//                             id: 'uncontrolled-native',
//                         }}
//                         onChange={handleFilterChange}
//                     >
//                         <option value={0}>All</option>
//                         <option value={10}>Unique Users</option>
//                         {/* <option value={20}>Twenty</option>
//                         <option value={30}>Thirty</option> */}
//                     </NativeSelect>
//                 </FormControl>
//             </Box>
//             <br />
//             <table style={{ borderCollapse: 'collapse', width: '100%' }}>
//                 <thead>
//                     <tr>
//                         <th style={thStyle}>S.No</th>
//                         <th style={thStyle}>Name</th>
//                         <th style={thStyle}>User Email</th>
//                         <th style={thStyle}>User Phone</th>
//                         <th style={thStyle}>Visited On</th>
//                         <th style={thStyle}>Country</th>
//                         {/* <th style={thStyle}>User ID</th> */}
//                         {/* <th style={thStyle}>User Profile Pic</th> */}
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {sortedData.map(user => (
//                         <tr key={user.userid}>
//                             <td style={tdStyle}>{user.id}</td>
//                             <td style={tdStyle}>{user.userName}</td>
//                             <td style={tdStyle}>{user.userEmail}</td>
//                             <td style={tdStyle}>{user.userPhone}</td>
//                             <td style={tdStyle}>
//                                 {new Date(user.entry_time).toLocaleString('en-GB', {
//                                     day: '2-digit',
//                                     month: '2-digit',
//                                     year: '2-digit',
//                                     hour: '2-digit',
//                                     minute: '2-digit',
//                                     // second: '2-digit',
//                                     hour12: true
//                                 })}
//                             </td>


//                             <td style={tdStyle}>{user.userCountry} / {user.userState}</td>
//                             {/* <td style={tdStyle}>{user.id}</td> */}
//                             {/* <td style={tdStyle}>
//                                 <img src={user.userprofilePic} alt="Profile Pic" style={{ maxWidth: '50%', maxHeight: '50%' }} onContextMenu={(e) => e.preventDefault()} />
//                             </td> */}
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>



//         </div>
//     );

// };

// const containerStyle = {
//     padding: '20px',
//     backgroundColor: '#f7f7f7',
//     borderRadius: '8px',
//     // boxShadow: '0 4px 8px #1a181854',
// };

// const thStyle = {
//     backgroundColor: '#06022e',
//     border: '1px solid #dddddd',
//     padding: '10px',
//     textAlign: 'left',
//     color: "white",


// };

// const tdStyle = {
//     border: '1px solid #dddddd',
//     padding: '8px',
//     textAlign: 'left',
//     overflow: "auto"
// };


// export default PremiumVisitors;






import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import AdminSearch from '../adminUtils/AdminSearch';


// Pagination Actions Component
function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

// Main Component
export default function PremiumVisitors() {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [users, setUsers] = useState([]);


    useEffect(() => {

        allUsers();

    }, [])


    const currentPath = window.location.pathname;
    const pathAfterAdminPage = currentPath.split('/adminpage/')[1];

    function formatDateTime(dateTimeString) {
        const date = new Date(dateTimeString);

        // Subtract 5 hours and 30 minutes from the time
        date.setHours(date.getHours() - 5);
        date.setMinutes(date.getMinutes() - 30);

        // Handle negative values and adjust accordingly
        if (date.getHours() < 0) {
            date.setDate(date.getDate() - 1); // Move to the previous day
            date.setHours(24 + date.getHours()); // Adjust hours to the previous day
        }
        if (date.getMinutes() < 0) {
            date.setHours(date.getHours() - 1); // Move to the previous hour
            date.setMinutes(60 + date.getMinutes()); // Adjust minutes to the previous hour
        }

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        // console.log(`${day}-${month}-${year} / ${hours}:${minutes}:${seconds}`);

        return `${day}-${month}-${year} / ${hours}:${minutes}`;
    }
    // console.log(formatDateTime);

    const allUsers = () => {
        const url = 'https://backend.shubadinam.com/get/premiumvisitors';
        axios.get(url)
            .then(response => {
                setUsers(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const handleFilterChange = (event) => {
        console.log("Selected Value:", event.target.value);
        const filterValue = event.target.value;

        if (filterValue === "10") {
            const uniqueEmailsSet = new Set();
            // Filter out duplicates and store unique users
            const uniqueUsersArray = users.filter(user => {
                if (!uniqueEmailsSet.has(user.userEmail)) {
                    uniqueEmailsSet.add(user.userEmail);
                    return true;
                }
                return false;
            });
            // Update state with unique users
            setUsers(uniqueUsersArray);
        }
        if (filterValue === "0") {
            allUsers();
        }

    }

    const sortedData = [...users].sort((a, b) => {
        return b.id - a.id;
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (query) => {
        setSearchQuery(query);
        setPage(0); // Reset page to 0 when search query changes
    };

    const filteredUsers = sortedData.filter(user =>
        user.userEmail.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.userPhone.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.entry_time.toLowerCase().includes(searchQuery.toLowerCase())
    );


    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredUsers.length) : 0;

    return (

        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <h3 style={{ textAlign: "center", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", paddingLeft: "20px" }}> Premium visitors  </h3>
            <br />
            <Box sx={{ minWidth: 50, paddingLeft: "20px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <FormControl >
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Filter
                    </InputLabel>
                    <NativeSelect
                        defaultValue={0}
                        inputProps={{
                            name: 'age',
                            id: 'uncontrolled-native',
                        }}
                        onChange={handleFilterChange}
                    >
                        <option value={0}>All</option>
                        <option value={10}>Unique Users</option>
                        {/* <option value={20}>Twenty</option>
                        <option value={30}>Thirty</option> */}
                    </NativeSelect>
                </FormControl>
                <AdminSearch onSearch={handleSearch} />
            </Box>
            <br />

            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={7}
                        count={filteredUsers.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        slotProps={{
                            select: {
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            },
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </TableRow>
            </TableFooter>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3} style={{ width: 50 }}>S.No</TableCell>
                            <TableCell style={{ width: 100 }} align="right">ID</TableCell>
                            <TableCell align="right">Email</TableCell>
                            <TableCell align="right">Phone number</TableCell>
                            <TableCell align="right">Signed On</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUsers.length > 0
                            ? filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => (
                                <TableRow key={index}>
                                    <TableCell colSpan={3} component="th" scope="row">
                                        {index + 1 + page * rowsPerPage}
                                    </TableCell>
                                    <TableCell style={{ width: 160, overflow: "auto" }} align="right">
                                        {user.id}
                                    </TableCell>
                                    <TableCell style={{ width: 160, overflow: "auto" }} align="right">
                                        {user.userEmail}
                                    </TableCell>
                                    <TableCell style={{ width: 160, overflow: "auto" }} align="right">
                                        {user.userPhone}
                                    </TableCell>
                                    <TableCell style={{ width: 160, overflow: "auto" }} align="right">
                                        {new Date(user.entry_time).toLocaleString('en-GB', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true,
                                            timeZone: 'UTC'
                                        })}
                                    </TableCell>
                                </TableRow>
                            ))
                            : null
                        }
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={7} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={7}
                                count={filteredUsers.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                slotProps={{
                                    select: {
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>

        </Box>
    );
}