import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";


const HeaderComponent = () => {

    const [isLesser768, setisLesser768] = useState(false);


    const Navigate = useNavigate()

    const signOut = () => {
        localStorage.removeItem("isAuthenticated")
        localStorage.removeItem("fkey")
        localStorage.removeItem("e-user")
        localStorage.removeItem("homeauth")
        // localStorage.clear()
        // Navigate("/")
    }

    const logoBtn = () => {
        Navigate("/")
    }


    // MENU BAR

    const [isOpenMenu, setOpenMenu] = useState(false);
    // 

    const [dashsignOut, setSignOut] = useState(false)


    useEffect(() => {
        const auth = localStorage.getItem("isAuthenticated");
        if (auth) {
            setSignOut(true)
        }


        const handleResize = () => {
            if (window.innerWidth < 768) {
                setisLesser768(true);
            } else {
                setisLesser768(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const signuptoSignout = () => {
        setSignOut(false)
        localStorage.removeItem("isAuthenticated")
        localStorage.removeItem("fkey")
        localStorage.removeItem("homeauth")
        // Navigate("/")
    }



    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

    const handleMobileNavToggle = () => {
        setIsMobileNavOpen(!isMobileNavOpen);
    };

    const handleDropdownClick = (e) => {
        e.preventDefault();
        if (isMobileNavOpen && e.target.nextElementSibling) {
            e.target.nextElementSibling.classList.toggle('dropdown-active');
        }
    };



    return (

        <>
            {/* ======= Header ======= */}
            <header id="header" className={`fixed-top d-flex align-items-center header-mobile-open`}>
                <div className="container d-flex justify-content-between align-items-center">
                    <div className="logo">
                        {/* <h1 className="text-light"><a href="index.html"><span>Moderna</span></a></h1> */}
                        <NavLink to="/">
                            <img src={require("../images/New Shubadinam Logo/Shubadinam White.png")} onContextMenu={(e) => e.preventDefault()} />
                        </NavLink>
                    </div>
                    <nav id="navbar" className={`navbar ${isMobileNavOpen ? 'navbar-mobile' : ''}`}>
                        <ul>
                            <li><NavLink to="/" className={({ isActive }) => isActive ? "active" : ""}>Home</NavLink></li>
                            <li><NavLink to="/about-us" className={({ isActive }) => isActive ? "active" : ""}>About Us</NavLink></li>

                            <li><NavLink to="/pricing" className={({ isActive }) => isActive ? "active" : ""}>Premium</NavLink></li>

                            {/* <li><NavLink to="/shop" className={({ isActive }) => isActive ? "active" : ""}>Shop</NavLink></li> */}



                            {
                                isLesser768 ?
                                    <>

                                        <li><NavLink to="/faq">FAQs</NavLink></li>
                                        <li><NavLink to="/Blogs">Blogs</NavLink></li>
                                        <li><NavLink to="/why-us">Why Shubadinam</NavLink></li>

                                    </>
                                    :
                                    <li className="dropdown">
                                        <a href="#" onClick={handleDropdownClick}><span>Info</span> <i className="bi bi-chevron-down" /></a>
                                        <ul>
                                            <li className="dropdown">
                                                {/* <a href="#" onClick={handleDropdownClick}><span>Deep Drop Down</span> <i className="bi bi-chevron-right" /></a> */}
                                                <ul>
                                                    <li><a href="#">Deep Drop Down 1</a></li>
                                                    <li><a href="#">Deep Drop Down 2</a></li>
                                                    <li><a href="#">Deep Drop Down 3</a></li>
                                                    <li><a href="#">Deep Drop Down 4</a></li>
                                                    <li><a href="#">Deep Drop Down 5</a></li>
                                                </ul>
                                            </li>
                                            <li><NavLink to="/faq">FAQs</NavLink></li>
                                            <li><NavLink to="/Blogs">Blogs</NavLink></li>
                                            <li><NavLink to="/why-us">Why Us</NavLink></li>
                                        </ul>

                                    </li>
                            }


                            <li><NavLink to="/contact-us">Contact Us</NavLink></li>



                            <li><NavLink to="/pricing">Sign In</NavLink></li>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle" onClick={handleMobileNavToggle} />
                    </nav>
                </div>
            </header>
            {/* End Header */}
        </>





        // <div className="container-header">

        //     {/* <div className="container-1"> */}



        //     <div className={`menu-bar-container ${isOpenMenu ? 'open' : ''}`}>
        //         <Hamburger toggled={isOpenMenu} toggle={setOpenMenu}>

        //         </Hamburger>

        //         {isOpenMenu && (

        //             <div className="menu-bar-cnt">

        //                 {
        //                     dashsignOut ?
        //                         <>

        //                             <div className="menu-bar-items" data-aos="fade-up">
        //                                 <NavLink to="/home" className={({ isActive }) => isActive ? "menu-link hightlight-link" : "menu-link"}>Dashboard</NavLink>
        //                             </div>

        //                             <div className="menu-bar-items" data-aos="fade-up">
        //                                 <NavLink to="/services" className={({ isActive }) => isActive ? "menu-link hightlight-link" : "menu-link"}>SD PREMIUM 👑</NavLink>
        //                             </div>

        //                             <div className="menu-bar-items" data-aos="fade-up">
        //                                 <NavLink to="/" className={({ isActive }) => isActive ? "menu-link hightlight-link" : "menu-link"}>Shop</NavLink>
        //                             </div>
        //                         </>
        //                         :
        //                         <div className="menu-bar-items" data-aos="fade-up">
        //                             <NavLink to="/home" className={({ isActive }) => isActive ? "menu-link hightlight-link" : "menu-link"}>Home</NavLink>
        //                         </div>
        //                 }
        //                 <div className="menu-bar-items" data-aos="fade-up">
        //                     <NavLink to="/about-us" className={({ isActive }) => isActive ? "menu-link hightlight-link" : "menu-link"}>About Us</NavLink>
        //                 </div>


        //                 <div className="dropdown menu-bar-items">
        //                     <NavLink to="/blogs"> <button className="dropbtn">Blogs</button></NavLink>
        //                     <div className="dropdown-content">
        //                         <NavLink to="/nakshatra-foods">1. Nakshatra Food</NavLink>
        //                         <NavLink to="/nakshatra-characteristics">2. Nakshatra Characteristics</NavLink>
        //                         <NavLink to="/nakshatra-deities">3. Nakshatra Deity</NavLink>
        //                         <NavLink to="/nakshatra-likes-dislikes">4. Nakshatra Likes and Dislikes</NavLink>
        //                         <NavLink to="/tithi-foods">5. Tithi Food</NavLink>
        //                         <NavLink to="/tithi-characteristics">6. Tithi Characteristics</NavLink>
        //                         <NavLink to="/tithi-deities">7. Tithi Deity</NavLink>
        //                         <NavLink to="/tithi-likes-dislikes">8. Tithi Likes and Dislikes</NavLink>
        //                         <NavLink to="/meanings-of-janma-nakshatra">9. Meanings of Janma Nakshatra</NavLink>
        //                     </div>
        //                 </div>

        //                 <div className="menu-bar-items" data-aos="fade-up">
        //                     <NavLink to="/faq" className={({ isActive }) => isActive ? "menu-link hightlight-link" : "menu-link"}>FAQ</NavLink>
        //                 </div>




        //                 {/* <div className="menu-bar-items" data-aos="fade-up">
        //                     <NavLink to="/" className={({ isActive }) => isActive ? "menu-link hightlight-link" : "menu-link"}>Sign Up</NavLink>
        //                 </div> */}
        //                 {
        //                     dashsignOut ?
        //                         <div className="menu-bar-items">
        //                             <label className="menu-link" onClick={() => signuptoSignout()}>Sign Out</label>
        //                         </div>
        //                         :
        //                         <>   <div className="menu-bar-items" data-aos="fade-up">
        //                             <NavLink to="/register" className={({ isActive }) => isActive ? "menu-link hightlight-link" : "menu-link"}>Sign Up</NavLink>
        //                         </div>
        //                             <div className="menu-bar-items">
        //                                 <NavLink to="/signin" className={({ isActive }) => isActive ? "menu-link hightlight-link" : "menu-link"}>Sign In</NavLink>
        //                             </div>
        //                         </>

        //                 }
        //                 {/* <div className="menu-bar-items">
        //                     <NavLink to="/" className={({ isActive }) => isActive ? "menu-link hightlight-link" : "menu-link"}>Sign In</NavLink>
        //                 </div> */}


        //             </div>
        //         )}
        //     </div>



        //     <div className="wordpress" onContextMenu={(e) => e.preventDefault()}>
        //         <img className="logo-fit-header" src={require("../images/New Shubadinam Logo/Shubadinam White.png")} width="100%" height="100%" onClick={() => logoBtn()} />
        //     </div>

        //     <div className="headers">
        //         {
        //             dashsignOut ?
        //                 <NavLink to="/home" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>Dashboard</NavLink>
        //                 :
        //                 <NavLink to="/home" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>Home</NavLink>
        //         }
        //         {/* <NavLink to="/home" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>Home</NavLink> */}
        //         <NavLink to="/about-us" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>About Us</NavLink>


        //         <div className="dropdown">
        //             <NavLink to="/blogs"> <button className="dropbtn">Blogs</button></NavLink>
        //             <div className="dropdown-content">
        //                 <NavLink to="/nakshatra-foods">1. Nakshatra Food</NavLink>
        //                 <NavLink to="/nakshatra-characteristics">2. Nakshatra Characteristics</NavLink>
        //                 <NavLink to="/nakshatra-deities">3. Nakshatra Deity</NavLink>
        //                 <NavLink to="/nakshatra-likes-dislikes">4. Nakshatra Likes and Dislikes</NavLink>
        //                 <NavLink to="/tithi-foods">5. Tithi Food</NavLink>
        //                 <NavLink to="/tithi-characteristics">6. Tithi Characteristics</NavLink>
        //                 <NavLink to="/tithi-deities">7. Tithi Deity</NavLink>
        //                 <NavLink to="/tithi-likes-dislikes">8. Tithi Likes and Dislikes</NavLink>
        //                 <NavLink to="/meanings-of-janma-nakshatra">9. Meanings of Janma Nakshatra</NavLink>
        //             </div>
        //         </div>


        //         {/* <NavLink to="/faq" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>FAQ</NavLink> */}
        //         {/* <NavLink to="/contact-us" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>Contact Us</NavLink> */}
        //         {/* <NavLink to="/" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>Shop</NavLink>
        //         <NavLink to="/services" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>SD PREMIUM 👑</NavLink> */}


        //         {
        //             dashsignOut ?
        //                 <>

        //                     <NavLink to="/" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>Shop</NavLink>
        //                     <NavLink to="/services" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>SD PREMIUM 👑</NavLink>
        //                 </>
        //                 :
        //            <></>
        //         }
        //     </div>

        //     <div className="login-out">


        //         {
        //             dashsignOut ?
        //                 <>
        //                     <label className="reg-btn open-btn" onClick={() => signuptoSignout()}>Sign Out</label>
        //                 </>
        //                 :
        //                 <NavLink to="/register" className={({ isActive }) => isActive ? "link hightlight-link" : "link-login"}>Sign Up</NavLink>
        //         }

        //         {/* instead of signout there is a change - login */}
        //         {/* <NavLink to="/" className={({ isActive }) => isActive ? "link hightlight-link" : "link-login"}>Sign Up</NavLink> */}
        //     </div>






        // </div >
    )
}
export default HeaderComponent;