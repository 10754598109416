import React, { useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2"


const AdminmailSend = () => {





    const [formData, setFormData] = useState({
        from: "Shubadinam Team <shubadinam.com@gmail.com>",
        to: "",
        subject: "",
        message: "",
        attachments: "",
    });

    const [emailType, setEmailType] = useState('custom'); // 'custom' or 'allUsers'


    const handleEmailTypeChange = (e) => {
        setEmailType(e.target.value);

        // If 'allUsers' is selected, set 'to' to 'All Users', otherwise, clear it
        if (e.target.value === 'allUsers') {
            setFormData({ ...formData, to: 'All Users' });
        } else {
            setFormData({ ...formData, to: '' });
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const base64String = event.target.result;
                setFormData({ ...formData, attachments: base64String });
            };

            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = (e) => {

        e.preventDefault();

        // validate email
        if (emailType === 'custom') {
            // Perform email validation
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const isValidEmail = emailRegex.test(formData.to);

            if (!isValidEmail) {
                // Display an error message or handle invalid email as needed
                alert('Please enter a valid email address.');
                return;
            }
        }
        // check empty fields
        if (formData.from.trim() !== "" && formData.to.trim() !== "" && formData.subject.trim() !== "" &&
            ((formData.message.trim() === "" && formData.attachments.length !== 0) ||
                (formData.message.trim() !== "" && formData.attachments.length !== 0) ||
                (formData.message.trim() !== "" && formData.attachments.length === 0))
        ) {

            Swal.fire({
                title: "Send Email",
                text: "Are you sure , you want to send this mail !",
                icon: "question",
                confirmButtonText: "Okay",
                showCancelButton: true
            }).then((result) => {
                if (result.isConfirmed) {


                    console.log('Form data submitted:', formData);



                    const url = `https://backend.shubadinam.com/adminsendmail`

                    axios.post(url, formData)
                        .then((response) => {
                            console.log(response);
                        })
                        .catch((error) => {
                            console.log(error);
                        })

                }
            });




        } else {
            alert("Both attachment and message column is empty")
        }



    };


    const currentPath = window.location.pathname;
    const pathAfterAdminPage = currentPath.split('/adminpage/')[1];

    console.log('Current Path:', pathAfterAdminPage.charAt(0).toUpperCase());



    return (
        <div>

            <div style={adminemailform}>
                <h2>{pathAfterAdminPage}</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="from"
                        value={formData.from}
                        onChange={handleChange}
                        placeholder="From"
                        className="admin-input-field"
                        required
                        disabled
                    />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label>
                            <input
                                type="radio"
                                name="emailType"
                                value="custom"
                                checked={emailType === 'custom'}
                                onChange={handleEmailTypeChange}
                            />
                            Custom Email
                        </label>
                        <div style={{ marginLeft: '10px', marginRight: '10px' }}></div>
                        <label>
                            <input
                                type="radio"
                                name="emailType"
                                value="allUsers"
                                checked={emailType === 'allUsers'}
                                onChange={handleEmailTypeChange}
                            />
                            All Users
                        </label>
                    </div>


                    {emailType === 'custom' && (
                        <input
                            type="email"
                            name="to"
                            value={formData.to}
                            onChange={handleChange}
                            placeholder="To"
                            className="admin-input-field"
                            // pattern="^[a-zA-Z0-9]+@gmail\.com$"
                            required
                        />
                    )}

                    {emailType === 'allUsers' && (
                        <input
                            type="text"
                            name="to"
                            value="All Users"
                            placeholder="To"
                            className="admin-input-field"
                            required
                            disabled // optionally, you can disable the input to prevent user modification
                        />
                    )}

                    <input
                        type="text"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        placeholder="Subject"
                        className="admin-input-field"
                        required
                    />
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder="Message"
                        className="admin-input-field"
                        required
                    />
                    <input
                        type="file"
                        name="attachments"
                        onChange={handleFileChange}
                        className="admin-input-field"
                        multiple
                    />
                    <div className="admin-submit-container">
                        <div className="admin-custom-button" onClick={handleSubmit}>
                            Send Email
                        </div>
                    </div>
                </form>
            </div>

        </div>
    );
};

const adminemailform = {
    padding: window.innerWidth > 576 ? "50px 130px" : "0px 0px", // Set padding if window width is greater than 576px
    backgroundColor: "#031ffa0d"
}

export default AdminmailSend;