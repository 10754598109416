import React from 'react';
import { Container, Typography, Grid, Paper, Card, CardActions, CardContent, Button, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Header from '../components/LandingHeader';
import FooterComponent from '../components/footer';
import Top20px from './Top20px';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { NavLink } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ScrollToTop from 'react-scroll-to-top';


const VirtualsevaInstructions = () => {
    const rituals = [
        {
            title: 'Avahanti Homam',
            schedule: 'Every Shukla Paksha Panchami (Fifth Tithi after Amavasya)',
            benefits: 'Retention power, intelligence, health, persuasive speech, effective listening, wealth, business growth, spiritual progress.',
            bookingLink: '/avahanti-homam-booking',
            learningLink: '/avahanti-homam-instructions'

        },
        {
            title: 'Maha Ganapathi Homam',
            schedule: 'Every Krishna Paksha Chaturthi (Fourth Tithi after Poornima)',
            benefits: 'Removing obstacles, wealth, prosperity, and enlightenment.',
            bookingLink: '/ganapathi-homam-booking',
            learningLink: '/ganapathi-homam-instructions'
        },
        {
            title: 'Surya Namaskara Prashna',
            schedule: 'Every Sunday at 6 am',
            benefits: 'Good health, prosperity, relief from sins and fears.',
            participation: 'Perform Namaskarams at home or visit the Patashala by 5:45 am.',
            bookingLink: '/surya-namaskaram-booking',
            learningLink: '/surya-namaskaram-instructions'
        },
        // {
        //     title: 'Navagraha Sukta Parayanam',
        //     schedule: 'Every Saturday at 6 pm',
        //     benefits: 'Protection from Navagraha Doshas, longevity, good health, protection from evil, achieving life goals.',
        // bookingLink: '/avahanti-homam-booking'
        // }
    ];



    return (
        <div className='homam-container'>
            <ScrollToTop smooth />
            <HelmetProvider>
                <Helmet>
                    <title>Virtual Pooja Seva </title>
                    <meta name="description" content="Virtual Pooja Seva  - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <Header />
            <Top20px />
            <div className='homam-body'>
                <Container maxWidth="lg" sx={{ my: 4 }}>
                    <Paper elevation={3} sx={{ p: 3 }}>
                        <Typography variant="h4" align="center" gutterBottom>
                            <strong>Virtual Pooja Seva</strong>
                        </Typography>

                        <Typography variant="body1" align="center" sx={{ mb: 4 }}>
                            Welcome to our Virtual Seva! Participate in sacred rituals and spiritual practices conducted by Vedic scholars and students at our Veda Patashala. These rituals are streamed live on our <NavLink to="/shubadinam-tv">shubadinam.tv</NavLink>.
                        </Typography>
                    </Paper>

                    <Grid container spacing={4} sx={{ mt: 2 }}>
                        <Grid item xs={12} md={6}>
                            <Paper elevation={2} sx={{ p: 3 }}>
                                <Typography variant="h5" gutterBottom>
                                    How to Participate
                                </Typography>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleIcon color="primary" />
                                        </ListItemIcon>
                                        <ListItemText primary="Book instantly for upcoming events or up to 2 months in advance. New slots open at the start of each month." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleIcon color="primary" />
                                        </ListItemIcon>
                                        <ListItemText primary="Submit the name and Nakshatra details of the person to be included in the Sankalpa (prayer)." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleIcon color="primary" />
                                        </ListItemIcon>
                                        <ListItemText primary="Watch live as names and Nakshatras of all participants (Yajamans) are read during the Sankalpa." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleIcon color="primary" />
                                        </ListItemIcon>
                                        <ListItemText primary="Prashad will be sent by post for applicable rituals." />
                                    </ListItem>
                                </List>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Paper elevation={2} sx={{ p: 3 }}>
                                <Typography variant="h5" gutterBottom>
                                    Available Rituals
                                </Typography>
                                {rituals.map((ritual, index) => (
                                    <Card key={index} variant="outlined" sx={{ mb: 2 }}>
                                        <CardContent>
                                            <Typography variant="h6" color="primary">
                                                {ritual.title}
                                            </Typography>
                                            <Divider sx={{ my: 1 }} />
                                            <Typography variant="body2" gutterBottom>
                                                <strong>Schedule:</strong> {ritual.schedule}
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>Benefits:</strong> {ritual.benefits}
                                            </Typography>
                                            {ritual.participation && (
                                                <Typography variant="body2">
                                                    <strong>Participation:</strong> {ritual.participation}
                                                </Typography>
                                            )}
                                        </CardContent>
                                        <CardActions>

                                            <Button
                                                variant="contained"
                                                size="small"
                                                startIcon={<ReadMoreIcon />}
                                                href={ritual.learningLink}
                                                sx={{
                                                    backgroundColor: "#787878",
                                                    '&:hover': {
                                                        transform: 'scale(0.9)',
                                                        backgroundColor: "#787878",
                                                        color: "white"
                                                    },
                                                    transition: 'all 0.3s ease-in-out',
                                                }}
                                            >
                                                Learn More
                                            </Button>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                startIcon={<EventAvailableIcon />}
                                                href={ritual.bookingLink}
                                                sx={{
                                                    '&:hover': {
                                                        transform: 'scale(0.9)',
                                                        // backgroundColor: "white",
                                                        color: "white"
                                                    },
                                                    transition: 'all 0.3s ease-in-out',
                                                }}
                                            >
                                                Book Now
                                            </Button>
                                        </CardActions>

                                    </Card>
                                ))}
                            </Paper>
                        </Grid>
                    </Grid>


                </Container>
            </div>
            <FooterComponent />
        </div>
    );
};

export default VirtualsevaInstructions;
