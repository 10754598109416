import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import HeaderComponent from "../../components/header";
import FooterComponent from "../../components/footer";

import "bootstrap/dist/css/bootstrap.min.css";

import ScrollToTop from "react-scroll-to-top";
import Top10px from "../Top10px";
import LandingHeader from "../../components/LandingHeader";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import {
    CitySelect,
    CountrySelect,
    StateSelect,
    LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import StepLabel from '@mui/material/StepLabel';

import { MuiOtpInput } from 'mui-one-time-password-input'

import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import { createTheme, useTheme, ThemeProvider, Theme } from '@mui/material/styles';
import axios from "axios";
import Swal from "sweetalert2";

import shubadinamLogo from '../../images/New Shubadinam Logo/Shubadinam Star logo.png';
import { create } from "@mui/material/styles/createTransitions";
import { Helmet, HelmetProvider } from 'react-helmet-async';



const PremiumCheckout = () => {

    let defaultCode = "91";

    const defaultCountry = {
        id: 101,
        name: "India",
        capital: "New Delhi",
        currency: "INR",
        currency_name: "Indian rupee",
        currency_symbol: "₹",
        emoji: "🇮🇳",
        iso2: "IN",
        iso3: "IND",
        latitude: "20.00000000",
        longitude: "77.00000000",
        native: "भारत",
        numeric_code: "356",
        phone_code: 91,
        region: "Asia",
        subregion: "Southern Asia",
        tld: ".in"
    };
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const [countryid, setCountryid] = useState(defaultCountry.id);
    const [stateid, setstateid] = useState(0);

    const [existingName, setexistingName] = useState("")
    const [existingEmail, setExistingEmail] = useState()
    const [existingId, setexistingId] = useState("")
    const [existingPh, setexistingPh] = useState("")
    const [existingPhCode, setexistingPhCode] = useState("")


    const Navigate = useNavigate();



    const [dynamicState, setDynamicState] = useState({
        userName: "",
        userEmail: "",
        userPhone: "",
        countryCode: defaultCode,
        userAddressLine1: "",
        userAddressLine2: "",
        userCountry: defaultCountry.name,
        userState: "",
        userCity: "",
        userPincode: "",
        sdinamId: "",
    })

    let isInnerWidthLessThan992 = false; // Default to false


    useEffect(() => {
        if (window.innerWidth > 992) {
            isInnerWidthLessThan992 = true;
        }
    }, [])

    useEffect(() => {
        const fkey = localStorage.getItem("fkey");
        const sdId = localStorage.getItem("SD_Id");
        const encodedData = localStorage.getItem("mainMPP");
        const encodedData3 = localStorage.getItem("isAuthenticated");
        const sdName = localStorage.getItem("myDataKey2")

        if (fkey) {
            try {
                const defaultEmail = atob(fkey).replace(/"/g, "").trim();
                setExistingEmail(defaultEmail);
            } catch (error) {
                // console.error("Error decoding or processing fkey:", error);
            }
        }
        if (sdId) {
            setexistingId(sdId);
        }
        if (encodedData) {
            try {
                const decodedData = atob(encodedData);
                const dataObject = JSON.parse(decodedData);

                const phValue = dataObject.userPhone;
                const phCode = dataObject.countryCode;
                setexistingPh(phValue);
                setexistingPhCode(phCode);

            } catch (error) {
                // console.error("Error decoding or parsing data:", error);
            }
        }
        else if (encodedData3) {
            try {
                const decodedData = atob(encodedData3);
                const dataObject = JSON.parse(decodedData);

                const phValue = dataObject.userPhone;
                const phCode = dataObject.countryCode;
                setexistingPh(phValue);
                setexistingPhCode(phCode);

            } catch (error) {
                console.error("Error decoding or parsing data:", error);
            }
        }
        if (sdName) {
            try {
                const nameValue1 = JSON.parse(sdName)
                const sdinamName = nameValue1[0].userName
                setexistingName(sdinamName)
            } catch (error) {
                // console.error("Error decoding or parsing data:", error);
            }
        }
    }, []);

    useEffect(() => {
        if (dynamicState.countryCode === "") {
            setDynamicState(prev => ({
                ...prev, // Don't forget the opening parenthesis here
                countryCode: defaultCode
            }));
        }
    },);


    useEffect(() => {
        setDynamicState(prevState => ({
            ...prevState,
            userName: existingName,
            userEmail: existingEmail,
            userPhone: existingPh,
            countryCode: existingPhCode,
            sdinamId: existingId,
        }));

    }, [existingEmail, existingId, existingPh, existingPhCode]);



    const fkey2 = localStorage.getItem("fkey");
    const encodedData2 = localStorage.getItem("mainMPP");
    const encodedData3 = localStorage.getItem("isAuthenticated");
    const sdName2 = localStorage.getItem("myDataKey2")


    let defaultPhone = "";

    let defaultEmail2 = ""; // Declare defaultEmail2 outside of the if block
    let defaultName = "";
    if (encodedData2) {
        try {
            const decodedData = atob(encodedData2);
            const dataObject = JSON.parse(decodedData);

            defaultPhone = dataObject.userPhone;
            defaultCode = dataObject.countryCode;

        } catch (error) {
            // console.error("Error decoding or parsing data:", error);
        }
    }
    else if (encodedData3) {
        try {
            const decodedData = atob(encodedData3);
            const dataObject = JSON.parse(decodedData);

            defaultPhone = dataObject.userPhone;
            defaultCode = dataObject.countryCode;

        } catch (error) {
            // console.error("Error decoding or parsing data:", error);
        }
    }
    if (sdName2) {
        try {
            const nameValue = JSON.parse(sdName2)
            defaultName = nameValue[0].userName;

        } catch (error) {
            // console.error("Error decoding or parsing data:", error);
        }
    }

    if (fkey2) {
        defaultEmail2 = atob(fkey2).replace(/"/g, "").trim(); // Assign value inside the if block
    }

    const gotoCheckout = () => {
        Navigate("/premium-checkout")
    }


    const steps = [
        'Registration',
        'Verification',
        'Payment',
    ];

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };


    const [isPhoneVerified, setPhoneVerification] = useState(false)
    const [isNotNumber, setIsNotNumber] = useState(false)

    const [otp, setOtp] = useState('')

    const handleChange = (newValue) => {
        if (!/^\d*$/.test(newValue)) {
            newValue = ""; // Set value to an empty string if not a number
            setIsNotNumber(true)
            return;
        }
        setIsNotNumber(false)
        setOtp(newValue)
    }

    const [changesMade, setChangesMade] = useState(false);


    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (changesMade) {
                event.preventDefault();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [changesMade]);

    const handlePremiumUsers = (e) => {
        // console.log(e.target.value);
        setDynamicState({ ...dynamicState, [e.target.name]: e.target.value })
        setChangesMade(true);
    }

    const handlePremiumUsername = (e) => {
        const value = e.target.value;
        // Regular expression to check for "@" or ".com"
        const regex = /[\/\.,;'():"+_\-=&*^$%#@!~`1234567890]|\.com/;

        // Check if the input value contains "@" or ".com"
        if (regex.test(value)) {
            // If it contains "@" or ".com", handle accordingly
            alert("Name only contains alphabets");
            e.target.value = dynamicState[e.target.name] || '';
        } else {
            setDynamicState({ ...dynamicState, [e.target.name]: value });
            setChangesMade(true);
        }
    };

    const countries = [
        { code: 'AD', label: 'Andorra', phone: '376' },
        {
            code: 'AE',
            label: 'United Arab Emirates',
            phone: '971',
        },
        { code: 'AF', label: 'Afghanistan', phone: '93' },
        {
            code: 'AG',
            label: 'Antigua and Barbuda',
            phone: '1-268',
        },
        { code: 'AI', label: 'Anguilla', phone: '1-264' },
        { code: 'AL', label: 'Albania', phone: '355' },
        { code: 'AM', label: 'Armenia', phone: '374' },
        { code: 'AO', label: 'Angola', phone: '244' },
        { code: 'AQ', label: 'Antarctica', phone: '672' },
        { code: 'AR', label: 'Argentina', phone: '54' },
        { code: 'AS', label: 'American Samoa', phone: '1-684' },
        { code: 'AT', label: 'Austria', phone: '43' },
        {
            code: 'AU',
            label: 'Australia',
            phone: '61',
            suggested: true,
        },
        { code: 'AW', label: 'Aruba', phone: '297' },
        { code: 'AX', label: 'Alland Islands', phone: '358' },
        { code: 'AZ', label: 'Azerbaijan', phone: '994' },
        {
            code: 'BA',
            label: 'Bosnia and Herzegovina',
            phone: '387',
        },
        { code: 'BB', label: 'Barbados', phone: '1-246' },
        { code: 'BD', label: 'Bangladesh', phone: '880' },
        { code: 'BE', label: 'Belgium', phone: '32' },
        { code: 'BF', label: 'Burkina Faso', phone: '226' },
        { code: 'BG', label: 'Bulgaria', phone: '359' },
        { code: 'BH', label: 'Bahrain', phone: '973' },
        { code: 'BI', label: 'Burundi', phone: '257' },
        { code: 'BJ', label: 'Benin', phone: '229' },
        { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
        { code: 'BM', label: 'Bermuda', phone: '1-441' },
        { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
        { code: 'BO', label: 'Bolivia', phone: '591' },
        { code: 'BR', label: 'Brazil', phone: '55' },
        { code: 'BS', label: 'Bahamas', phone: '1-242' },
        { code: 'BT', label: 'Bhutan', phone: '975' },
        { code: 'BV', label: 'Bouvet Island', phone: '47' },
        { code: 'BW', label: 'Botswana', phone: '267' },
        { code: 'BY', label: 'Belarus', phone: '375' },
        { code: 'BZ', label: 'Belize', phone: '501' },
        {
            code: 'CA',
            label: 'Canada',
            phone: '1',
            suggested: true,
        },
        {
            code: 'CC',
            label: 'Cocos (Keeling) Islands',
            phone: '61',
        },
        {
            code: 'CD',
            label: 'Congo, Democratic Republic of the',
            phone: '243',
        },
        {
            code: 'CF',
            label: 'Central African Republic',
            phone: '236',
        },
        {
            code: 'CG',
            label: 'Congo, Republic of the',
            phone: '242',
        },
        { code: 'CH', label: 'Switzerland', phone: '41' },
        { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
        { code: 'CK', label: 'Cook Islands', phone: '682' },
        { code: 'CL', label: 'Chile', phone: '56' },
        { code: 'CM', label: 'Cameroon', phone: '237' },
        { code: 'CN', label: 'China', phone: '86' },
        { code: 'CO', label: 'Colombia', phone: '57' },
        { code: 'CR', label: 'Costa Rica', phone: '506' },
        { code: 'CU', label: 'Cuba', phone: '53' },
        { code: 'CV', label: 'Cape Verde', phone: '238' },
        { code: 'CW', label: 'Curacao', phone: '599' },
        { code: 'CX', label: 'Christmas Island', phone: '61' },
        { code: 'CY', label: 'Cyprus', phone: '357' },
        { code: 'CZ', label: 'Czech Republic', phone: '420' },
        {
            code: 'DE',
            label: 'Germany',
            phone: '49',
            suggested: true,
        },
        { code: 'DJ', label: 'Djibouti', phone: '253' },
        { code: 'DK', label: 'Denmark', phone: '45' },
        { code: 'DM', label: 'Dominica', phone: '1-767' },
        {
            code: 'DO',
            label: 'Dominican Republic',
            phone: '1-809',
        },
        { code: 'DZ', label: 'Algeria', phone: '213' },
        { code: 'EC', label: 'Ecuador', phone: '593' },
        { code: 'EE', label: 'Estonia', phone: '372' },
        { code: 'EG', label: 'Egypt', phone: '20' },
        { code: 'EH', label: 'Western Sahara', phone: '212' },
        { code: 'ER', label: 'Eritrea', phone: '291' },
        { code: 'ES', label: 'Spain', phone: '34' },
        { code: 'ET', label: 'Ethiopia', phone: '251' },
        { code: 'FI', label: 'Finland', phone: '358' },
        { code: 'FJ', label: 'Fiji', phone: '679' },
        {
            code: 'FK',
            label: 'Falkland Islands (Malvinas)',
            phone: '500',
        },
        {
            code: 'FM',
            label: 'Micronesia, Federated States of',
            phone: '691',
        },
        { code: 'FO', label: 'Faroe Islands', phone: '298' },
        {
            code: 'FR',
            label: 'France',
            phone: '33',
            suggested: true,
        },
        { code: 'GA', label: 'Gabon', phone: '241' },
        { code: 'GB', label: 'United Kingdom', phone: '44' },
        { code: 'GD', label: 'Grenada', phone: '1-473' },
        { code: 'GE', label: 'Georgia', phone: '995' },
        { code: 'GF', label: 'French Guiana', phone: '594' },
        { code: 'GG', label: 'Guernsey', phone: '44' },
        { code: 'GH', label: 'Ghana', phone: '233' },
        { code: 'GI', label: 'Gibraltar', phone: '350' },
        { code: 'GL', label: 'Greenland', phone: '299' },
        { code: 'GM', label: 'Gambia', phone: '220' },
        { code: 'GN', label: 'Guinea', phone: '224' },
        { code: 'GP', label: 'Guadeloupe', phone: '590' },
        { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
        { code: 'GR', label: 'Greece', phone: '30' },
        {
            code: 'GS',
            label: 'South Georgia and the South Sandwich Islands',
            phone: '500',
        },
        { code: 'GT', label: 'Guatemala', phone: '502' },
        { code: 'GU', label: 'Guam', phone: '1-671' },
        { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
        { code: 'GY', label: 'Guyana', phone: '592' },
        { code: 'HK', label: 'Hong Kong', phone: '852' },
        {
            code: 'HM',
            label: 'Heard Island and McDonald Islands',
            phone: '672',
        },
        { code: 'HN', label: 'Honduras', phone: '504' },
        { code: 'HR', label: 'Croatia', phone: '385' },
        { code: 'HT', label: 'Haiti', phone: '509' },
        { code: 'HU', label: 'Hungary', phone: '36' },
        { code: 'ID', label: 'Indonesia', phone: '62' },
        { code: 'IE', label: 'Ireland', phone: '353' },
        { code: 'IL', label: 'Israel', phone: '972' },
        { code: 'IM', label: 'Isle of Man', phone: '44' },
        { code: 'IN', label: 'India', phone: '91' },
        {
            code: 'IO',
            label: 'British Indian Ocean Territory',
            phone: '246',
        },
        { code: 'IQ', label: 'Iraq', phone: '964' },
        {
            code: 'IR',
            label: 'Iran, Islamic Republic of',
            phone: '98',
        },
        { code: 'IS', label: 'Iceland', phone: '354' },
        { code: 'IT', label: 'Italy', phone: '39' },
        { code: 'JE', label: 'Jersey', phone: '44' },
        { code: 'JM', label: 'Jamaica', phone: '1-876' },
        { code: 'JO', label: 'Jordan', phone: '962' },
        {
            code: 'JP',
            label: 'Japan',
            phone: '81',
            suggested: true,
        },
        { code: 'KE', label: 'Kenya', phone: '254' },
        { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
        { code: 'KH', label: 'Cambodia', phone: '855' },
        { code: 'KI', label: 'Kiribati', phone: '686' },
        { code: 'KM', label: 'Comoros', phone: '269' },
        {
            code: 'KN',
            label: 'Saint Kitts and Nevis',
            phone: '1-869',
        },
        {
            code: 'KP',
            label: "Korea, Democratic People's Republic of",
            phone: '850',
        },
        { code: 'KR', label: 'Korea, Republic of', phone: '82' },
        { code: 'KW', label: 'Kuwait', phone: '965' },
        { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
        { code: 'KZ', label: 'Kazakhstan', phone: '7' },
        {
            code: 'LA',
            label: "Lao People's Democratic Republic",
            phone: '856',
        },
        { code: 'LB', label: 'Lebanon', phone: '961' },
        { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
        { code: 'LI', label: 'Liechtenstein', phone: '423' },
        { code: 'LK', label: 'Sri Lanka', phone: '94' },
        { code: 'LR', label: 'Liberia', phone: '231' },
        { code: 'LS', label: 'Lesotho', phone: '266' },
        { code: 'LT', label: 'Lithuania', phone: '370' },
        { code: 'LU', label: 'Luxembourg', phone: '352' },
        { code: 'LV', label: 'Latvia', phone: '371' },
        { code: 'LY', label: 'Libya', phone: '218' },
        { code: 'MA', label: 'Morocco', phone: '212' },
        { code: 'MC', label: 'Monaco', phone: '377' },
        {
            code: 'MD',
            label: 'Moldova, Republic of',
            phone: '373',
        },
        { code: 'ME', label: 'Montenegro', phone: '382' },
        {
            code: 'MF',
            label: 'Saint Martin (French part)',
            phone: '590',
        },
        { code: 'MG', label: 'Madagascar', phone: '261' },
        { code: 'MH', label: 'Marshall Islands', phone: '692' },
        {
            code: 'MK',
            label: 'Macedonia, the Former Yugoslav Republic of',
            phone: '389',
        },
        { code: 'ML', label: 'Mali', phone: '223' },
        { code: 'MM', label: 'Myanmar', phone: '95' },
        { code: 'MN', label: 'Mongolia', phone: '976' },
        { code: 'MO', label: 'Macao', phone: '853' },
        {
            code: 'MP',
            label: 'Northern Mariana Islands',
            phone: '1-670',
        },
        { code: 'MQ', label: 'Martinique', phone: '596' },
        { code: 'MR', label: 'Mauritania', phone: '222' },
        { code: 'MS', label: 'Montserrat', phone: '1-664' },
        { code: 'MT', label: 'Malta', phone: '356' },
        { code: 'MU', label: 'Mauritius', phone: '230' },
        { code: 'MV', label: 'Maldives', phone: '960' },
        { code: 'MW', label: 'Malawi', phone: '265' },
        { code: 'MX', label: 'Mexico', phone: '52' },
        { code: 'MY', label: 'Malaysia', phone: '60' },
        { code: 'MZ', label: 'Mozambique', phone: '258' },
        { code: 'NA', label: 'Namibia', phone: '264' },
        { code: 'NC', label: 'New Caledonia', phone: '687' },
        { code: 'NE', label: 'Niger', phone: '227' },
        { code: 'NF', label: 'Norfolk Island', phone: '672' },
        { code: 'NG', label: 'Nigeria', phone: '234' },
        { code: 'NI', label: 'Nicaragua', phone: '505' },
        { code: 'NL', label: 'Netherlands', phone: '31' },
        { code: 'NO', label: 'Norway', phone: '47' },
        { code: 'NP', label: 'Nepal', phone: '977' },
        { code: 'NR', label: 'Nauru', phone: '674' },
        { code: 'NU', label: 'Niue', phone: '683' },
        { code: 'NZ', label: 'New Zealand', phone: '64' },
        { code: 'OM', label: 'Oman', phone: '968' },
        { code: 'PA', label: 'Panama', phone: '507' },
        { code: 'PE', label: 'Peru', phone: '51' },
        { code: 'PF', label: 'French Polynesia', phone: '689' },
        { code: 'PG', label: 'Papua New Guinea', phone: '675' },
        { code: 'PH', label: 'Philippines', phone: '63' },
        { code: 'PK', label: 'Pakistan', phone: '92' },
        { code: 'PL', label: 'Poland', phone: '48' },
        {
            code: 'PM',
            label: 'Saint Pierre and Miquelon',
            phone: '508',
        },
        { code: 'PN', label: 'Pitcairn', phone: '870' },
        { code: 'PR', label: 'Puerto Rico', phone: '1' },
        {
            code: 'PS',
            label: 'Palestine, State of',
            phone: '970',
        },
        { code: 'PT', label: 'Portugal', phone: '351' },
        { code: 'PW', label: 'Palau', phone: '680' },
        { code: 'PY', label: 'Paraguay', phone: '595' },
        { code: 'QA', label: 'Qatar', phone: '974' },
        { code: 'RE', label: 'Reunion', phone: '262' },
        { code: 'RO', label: 'Romania', phone: '40' },
        { code: 'RS', label: 'Serbia', phone: '381' },
        { code: 'RU', label: 'Russian Federation', phone: '7' },
        { code: 'RW', label: 'Rwanda', phone: '250' },
        { code: 'SA', label: 'Saudi Arabia', phone: '966' },
        { code: 'SB', label: 'Solomon Islands', phone: '677' },
        { code: 'SC', label: 'Seychelles', phone: '248' },
        { code: 'SD', label: 'Sudan', phone: '249' },
        { code: 'SE', label: 'Sweden', phone: '46' },
        { code: 'SG', label: 'Singapore', phone: '65' },
        { code: 'SH', label: 'Saint Helena', phone: '290' },
        { code: 'SI', label: 'Slovenia', phone: '386' },
        {
            code: 'SJ',
            label: 'Svalbard and Jan Mayen',
            phone: '47',
        },
        { code: 'SK', label: 'Slovakia', phone: '421' },
        { code: 'SL', label: 'Sierra Leone', phone: '232' },
        { code: 'SM', label: 'San Marino', phone: '378' },
        { code: 'SN', label: 'Senegal', phone: '221' },
        { code: 'SO', label: 'Somalia', phone: '252' },
        { code: 'SR', label: 'Suriname', phone: '597' },
        { code: 'SS', label: 'South Sudan', phone: '211' },
        {
            code: 'ST',
            label: 'Sao Tome and Principe',
            phone: '239',
        },
        { code: 'SV', label: 'El Salvador', phone: '503' },
        {
            code: 'SX',
            label: 'Sint Maarten (Dutch part)',
            phone: '1-721',
        },
        {
            code: 'SY',
            label: 'Syrian Arab Republic',
            phone: '963',
        },
        { code: 'SZ', label: 'Swaziland', phone: '268' },
        {
            code: 'TC',
            label: 'Turks and Caicos Islands',
            phone: '1-649',
        },
        { code: 'TD', label: 'Chad', phone: '235' },
        {
            code: 'TF',
            label: 'French Southern Territories',
            phone: '262',
        },
        { code: 'TG', label: 'Togo', phone: '228' },
        { code: 'TH', label: 'Thailand', phone: '66' },
        { code: 'TJ', label: 'Tajikistan', phone: '992' },
        { code: 'TK', label: 'Tokelau', phone: '690' },
        { code: 'TL', label: 'Timor-Leste', phone: '670' },
        { code: 'TM', label: 'Turkmenistan', phone: '993' },
        { code: 'TN', label: 'Tunisia', phone: '216' },
        { code: 'TO', label: 'Tonga', phone: '676' },
        { code: 'TR', label: 'Turkey', phone: '90' },
        {
            code: 'TT',
            label: 'Trinidad and Tobago',
            phone: '1-868',
        },
        { code: 'TV', label: 'Tuvalu', phone: '688' },
        {
            code: 'TW',
            label: 'Taiwan',
            phone: '886',
        },
        {
            code: 'TZ',
            label: 'United Republic of Tanzania',
            phone: '255',
        },
        { code: 'UA', label: 'Ukraine', phone: '380' },
        { code: 'UG', label: 'Uganda', phone: '256' },
        {
            code: 'US',
            label: 'United States',
            phone: '1',
            suggested: true,
        },
        { code: 'UY', label: 'Uruguay', phone: '598' },
        { code: 'UZ', label: 'Uzbekistan', phone: '998' },
        {
            code: 'VA',
            label: 'Holy See (Vatican City State)',
            phone: '379',
        },
        {
            code: 'VC',
            label: 'Saint Vincent and the Grenadines',
            phone: '1-784',
        },
        { code: 'VE', label: 'Venezuela', phone: '58' },
        {
            code: 'VG',
            label: 'British Virgin Islands',
            phone: '1-284',
        },
        {
            code: 'VI',
            label: 'US Virgin Islands',
            phone: '1-340',
        },
        { code: 'VN', label: 'Vietnam', phone: '84' },
        { code: 'VU', label: 'Vanuatu', phone: '678' },
        { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
        { code: 'WS', label: 'Samoa', phone: '685' },
        { code: 'XK', label: 'Kosovo', phone: '383' },
        { code: 'YE', label: 'Yemen', phone: '967' },
        { code: 'YT', label: 'Mayotte', phone: '262' },
        { code: 'ZA', label: 'South Africa', phone: '27' },
        { code: 'ZM', label: 'Zambia', phone: '260' },
        { code: 'ZW', label: 'Zimbabwe', phone: '263' },
    ];

    function isNullOrEmpty(value) {
        return value == null || value.trim() === "";
    }

    const handlePhoneVerification = (e) => {

        e.preventDefault();
        // setPhoneVerification(true)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        const url = `https://backend.shubadinam.com/get/premiumotp?userEmail=${dynamicState.userEmail}&userPhone=${dynamicState.userPhone}&countryCode=${dynamicState.countryCode}&userName=${dynamicState.userName}&userCountry=${dynamicState.userCountry}&userState=${dynamicState.userState}`;

        axios.get(url)
            .then((response) => {

                // console.log(response);

                if (response.status === 200) {
                    Swal.fire({
                        title: 'Verification Code',
                        // text: "OTP has been sent to your Phone number and Email.",
                        html: "OTP has been sent to your Phone number and Email." + '<br><br><span style="color: red; font-size: 12px;">(* In case you did not receive the SMS OTP, you may use the Email OTP)</span>', // Add your manual text here
                        icon: 'success',
                        confirmButtonText: 'Got it!', // Change the confirm button text
                        confirmButtonColor: '#3085d6', // Change the confirm button color
                        background: '#f5f5f5',
                    })
                    setPhoneVerification(true)
                }

                // console.log("OTP Sent Successfully")
            }).catch((error) => {
                if (error.response.status === 403) {
                    Swal.fire({
                        title: 'Verification',
                        text: error.response.data.message,
                        icon: 'error',
                        confirmButtonText: 'Got it!', // Change the confirm button text
                        confirmButtonColor: '#3085d6', // Change the confirm button color
                        background: '#f5f5f5',
                    })
                }
                else {
                    Swal.fire({
                        title: 'Server Busy',
                        text: error.message,
                        icon: 'error',
                        confirmButtonText: 'Got it!', // Change the confirm button text
                        confirmButtonColor: '#3085d6', // Change the confirm button color
                        background: '#f5f5f5',
                    })
                }
                // console.log(error);
            });
    }


    const handlePaymentVerification = (e) => {

        setIsButtonClicked(true);
        e.preventDefault();
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);

        const url = "https://backend.shubadinam.com/post/premiumotp";

        axios.post(url, { userOtp: otp, ...dynamicState }) // Include the OTP in the request body
            .then((response) => {
                // console.log("OTP Valid");
                // createOrder();
                if (response.status === 200) {
                    createOrder()
                }
                // Handle response from the server
            })
            .catch((error) => {
                // console.log(`Error! ${error}`);
                const invaildOtp = error.response.data.message
                setIsButtonClicked(false);

                Swal.fire({
                    title: 'Authentication Revoked',
                    text: invaildOtp,
                    icon: 'info',
                    confirmButtonText: 'Got it!', // Change the confirm button text
                    confirmButtonColor: '#3085d6', // Change the confirm button color
                    background: '#f5f5f5', // Change the background color
                    customClass: {
                        container: 'my-custom-container-class', // Add a custom container class
                        title: 'my-custom-title-class', // Add a custom title class
                        content: 'my-custom-content-class', // Add a custom content class
                        confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                    },
                })
                setOtp('');
            });
    };

    const [orderId, setOrderId] = useState(null);
    const [transactionId, setTransactionId] = useState(null);
    const [qrCodeUrl, setQrCodeUrl] = useState('');


    const createOrder = async () => {
        try {
            const response = await fetch('https://backend.shubadinam.com/create-order', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dynamicState),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }



            const data = await response.json();

            // console.log(data);
            if (data.order.id) {
                // setQrCodeUrl(response.data.qr_code_url);
                // console.log(data);
                setOrderId(data.order.id);
                setTransactionId(data.order.receipt);
                handlePayment(data.order.id, data.order.notes.premiumPayment, data.impId);
                //  transactionId = data.receipt;

                // if (data.redirect) {
                //   window.location.href = data.redirect;
                // }
            } else {
                alert('Failed to create order');
            }
        } catch (error) {
            console.error('Error:', error.message);
            alert('Failed to fetch data. Check the console for details.');
        }
    };

    // useEffect(() => {
    //   createOrder();
    // }, []); 


    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            document.getElementById('rzp-button1').addEventListener('click', createOrder);
        };
    }, [orderId]);

    const handlePayment = (orderId, premiumPayment, impId) => {

        const premiumUserName = dynamicState.userName;
        const premiumUserPhone = dynamicState.userPhone;
        const PremiumUserEmail = dynamicState.userEmail;
        // console.log(orderId, impId);
        if (orderId) {
            const options = {
                key: 'rzp_live_gp41aILMbhUm19', // Replace with your Razorpay key
                amount: 299 * 100,
                currency: 'INR',
                order_id: orderId,
                name: 'Shubadinam',
                description: 'Shubadinam Transaction',
                image: shubadinamLogo,
                prefill: {
                    name: premiumUserName,
                    email: PremiumUserEmail,
                    contact: premiumUserPhone,
                },
                // redirect: true,
                // callback_url: "http://localhost:3000/PremiumProfile",

                handler: function (response) {
                    // console.log(response);

                    const url = `https://backend.shubadinam.com/razorpayresponse`


                    axios.post(url, {
                        paymentId: response.razorpay_payment_id, orderId: response.razorpay_order_id,
                        signatureId: response.razorpay_signature, dynamicState: dynamicState, impId: impId
                    })
                        .then((response) => {

                            // const paymentId= response.razorpay_payment_id
                            // console.log(response);

                            const paymentId = response.data.paymentId
                            if (response.status === 200) {
                                localStorage.setItem("nav_", response.data.message)

                                Navigate(`/paymentsuccess?reference=${paymentId}`, { replace: true });
                            }

                        })
                        .catch((error) => {
                            // console.log("Error redirecting : ", error);
                        })
                },
                notes: {
                    premiumPayment: premiumPayment,
                    address: 'Razorpay Corporate Office',
                },
                theme: {
                    color: '#3399cc',
                },
                // method: {
                //     qr: {
                //         url: qrCodeUrl,
                //         format: 'png',
                //         label: 'Scan to Pay',
                //     }
                // }
                config: {
                    display: {
                        hide: [
                            { method: 'paylater' },
                            { method: 'emi' },
                            { method: 'wallet' },
                            { method: 'bank_transfer' },
                        ],
                        preferences: { show_default_blocks: true }
                    }
                },
                method: {
                    //     netbanking: true,
                    //     card: true,
                    //     upi: true,
                    //     wallet: false,
                    //     emi: false,
                    //     bank_transfer: false,
                    // qr: false, // Disable QR Code payment option
                    //     paylater: false // Disable Pay Later option
                }
            };

            const rzp1 = new window.Razorpay(options);

            rzp1.on('payment.success', (response) => {
                // console.log(response);
                // alert(`Payment Successful. Payment ID: ${response.razorpay_payment_id}`);
            });

            rzp1.on('payment.failed', function (response) {
                // alert(response.error.code);
                alert(response.error.description);
                // alert(response.error.source);
                // alert(response.error.step);
                // alert(response.error.reason);
                // alert(response.error.metadata.order_id);
                // alert(response.error.metadata.payment_id);
            })

            rzp1.on('payment.error', (response) => {
                // console.log(response);
                alert(`Payment Failed. Error: ${response.error.description}`);
            });

            rzp1.on('payment.closed', () => {
                console.warn('Payment modal closed by user');
                alert('Payment process was not completed. Please try again.');
            });


            rzp1.open();
        } else {
            alert('Order ID is missing. Please try again.');
        }
    };

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', handleEnterKeyPress);
        return () => {
            document.removeEventListener('keypress', handleEnterKeyPress);
        };
    }, []);

    const resendOTP = (e) => {
        setIsButtonClicked(false);
        setOtp('');
        // e.preventDefault();
        handlePhoneVerification(e);
    }







    return (
        <div className="overall-container-home">
            <HelmetProvider>
                <Helmet>
                    <title>Premium Checkout</title>
                    <meta name="description" content="Premium Checkout  - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <div className="overall-percent-home">
                <ScrollToTop smooth />

                <div className="container-header-profile">
                    <LandingHeader />
                </div>

                <Top10px />

                {/* <div className="services-container"> */}



                <section className="premium-checkout-overall">

                    <div className="premium-checkout-left">
                        <div className="pc-left-1">
                            <button>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 24">
                                    <path d="m18 0 8 12 10-8-4 20H4L0 4l10 8 8-12z"></path>
                                </svg>
                                Unlock Premium
                            </button>
                        </div>
                        <br />
                        <div className="pc-left-1">
                            <i>Total</i>
                            <b>&#8377; 299 <i style={{ fontWeight: "100" }}>(including GST)</i></b>
                            <i style={{ fontWeight: "100" }}>Yearly</i>


                        </div>
                        <br />
                        <div className="pc-left-2">
                            <p>Email Notification (Globally)</p>


                            <p>SMS (India only)</p>

                            <p>Whatsapp (India and Global)</p>

                            {/* <p>Live Sathsangh Webinar</p> */}


                            {/* <p>Recording Access</p> */}

                            <p>Free Delivery For Merchandise
                                (India only)
                            </p>
                            <p>One Free Virtual Pooja Seva Slot
                            </p>


                            <p>Nakshatra / Tithi Finder Assistance
                                <span>{"(As per English Date of Birth)"}</span>
                            </p>
                        </div>

                    </div>

                    <div className="premium-checkout-right">
                        <ul style={{ listStyle: "none", margin: "10px" }}>
                            <li><span style={{ fontWeight: "900", marginRight: "10px", color: "#37aa00" }}>✓</span> Complete the below 3 simple steps to create your account.</li>
                            <li><span style={{ fontWeight: "900", marginRight: "10px", color: "#37aa00" }}>✓</span> And enter your profile details to get Janma Dinam reminders.</li>
                        </ul>
                        <br />
                        <form onSubmit={(e) => handlePhoneVerification(e)} className="pc-right-container ">
                            <Box sx={{ width: '80%' }}>
                                <Stepper activeStep={activeStep}>
                                    {steps.map((label, index) => {
                                        const stepProps = {};
                                        const labelProps = {};
                                        if (isStepOptional(index)) {
                                            labelProps.optional = (
                                                <Typography variant="caption"></Typography>
                                            );
                                        }
                                        if (isStepSkipped(index)) {
                                            stepProps.completed = false;
                                        }
                                        return (
                                            <Step key={label} {...stepProps}>
                                                <StepLabel {...labelProps}>{label}</StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                                {activeStep === steps.length ? (
                                    <React.Fragment>

                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>

                                            <Box sx={{ flex: '1 1 auto' }} />


                                            {/* <Button onClick={handleNext}>
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button> */}
                                        </Box>
                                    </React.Fragment>
                                )}
                            </Box>
                            <div className="pc-right-1 ">
                                <div className="pc-right-input">
                                    <i style={{ marginBottom: "10px" }}>Name</i>
                                    <TextField
                                        name="userName"
                                        required
                                        id="outlined-password-input"
                                        label="Name"
                                        type="Name"
                                        defaultValue={defaultName}
                                        onChange={(e) => handlePremiumUsername(e)}
                                        onTextChange={(e) => {
                                            setDynamicState(prevState => ({
                                                ...prevState,
                                                userName: e.target.value
                                            }));
                                            // console.log(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="pc-right-input">
                                    <i style={{ marginBottom: "10px" }}>E-mail</i>
                                    <TextField
                                        required
                                        name="userEmail"
                                        id="outlined-password-input"
                                        label="E-mail"
                                        type="email"
                                        defaultValue={defaultEmail2}
                                        onChange={(e) => handlePremiumUsers(e)}
                                        onTextChange={(e) => {
                                            setDynamicState(prevState => ({
                                                ...prevState,
                                                userEmail: e.target.value
                                            }));
                                            // console.log(e.target.value);
                                        }}
                                    />
                                    {/* <TextField
                    name="userEmail"
                    value={defaultEmail}
                    required
                    id="outlined-password-input"
                    label="Email"
                    type="email"
                    onChange={(e) => handlePremiumUsers(e)}
                /> */}
                                </div>
                            </div>
                            <div className="pc-right-1">
                                <div className="pc-right-input">
                                    <i style={{ marginBottom: "10px" }}>Country Code</i>
                                    <Autocomplete
                                        onChange={(e, value) => {
                                            setDynamicState((prevDynamicState) => ({
                                                ...prevDynamicState,
                                                countryCode: value ? value.phone : ""
                                            }));
                                            setChangesMade(true);
                                        }}
                                        onTextChange={(e, value) => {
                                            setDynamicState((prevDynamicState) => ({
                                                ...prevDynamicState,
                                                countryCode: value ? value.phone : ""
                                            }));
                                            setChangesMade(true);
                                        }}
                                        id="country-customized-option-demo"
                                        options={countries}
                                        defaultValue={countries.find(country => country.phone === defaultCode)}
                                        // value={countries.find(country => country.phone === defaultCode)}
                                        // disableCloseOnSelect
                                        getOptionLabel={(option) => `(${option.code}) +${option.phone}`}
                                        name="countryCode"
                                        required
                                        isOptionEqualToValue={(option, value) =>
                                            option.code === value.code &&
                                            option.label === value.label &&
                                            option.phone === value.phone
                                        }
                                        renderInput={(params) => <TextField {...params} label="Country Code" />}
                                    />


                                </div>
                                <div className="pc-right-input">
                                    <i style={{ marginBottom: "10px" }}>Phone Number</i>
                                    <TextField
                                        name="userPhone"
                                        required
                                        id="outlined-password-input"
                                        label="10-digits only"
                                        type="text"
                                        defaultValue={defaultPhone}
                                        onChange={(e) => handlePremiumUsers(e)}
                                        inputProps={{ maxLength: 10 }}

                                    />
                                </div>

                            </div>
                            <div className="pc-right-2 " style={{ display: "none" }}>
                                <i style={{ marginBottom: "10px" }}>Address</i>

                                <Box
                                    sx={{
                                        width: 600,
                                        maxWidth: '100%',

                                    }}
                                >
                                    <TextField name="userAddressLine1" fullWidth label="Address line 1" id="fullWidth" onChange={(e) => handlePremiumUsers(e)} onTextChange={(e) => {
                                        setDynamicState(prevAdd => ({
                                            ...prevAdd,
                                            userAddressLine1: e.target.value
                                        }));
                                    }} />
                                </Box>
                                <Box
                                    sx={{
                                        width: 600,
                                        maxWidth: '100%',
                                    }}
                                >
                                    <TextField name="userAddressLine2" fullWidth label="Address line 2" id="fullWidth" onChange={(e) => handlePremiumUsers(e)} onTextChange={(e) => {
                                        setDynamicState(prevAdd => ({
                                            ...prevAdd,
                                            userAddressLine2: e.target.value
                                        }));
                                    }} />
                                </Box>
                            </div>
                            <div className="pc-right-3 ">
                                <div className="pc-right-input">
                                    <i style={{ marginBottom: "10px" }}>Country</i>
                                    <Box
                                        sx={{
                                            width: 400,
                                            maxWidth: '100%',
                                        }}
                                    >
                                        <CountrySelect

                                            defaultValue={dynamicState.userCountry === "India" ? defaultCountry : ""}
                                            required
                                            onChange={(e) => {
                                                setCountryid(e.id);
                                                console.log(e);
                                                setDynamicState(prevState => ({
                                                    ...prevState,
                                                    userCountry: e.name
                                                }));
                                            }}
                                            onTextChange={(e) => {
                                                setDynamicState(prevState => ({
                                                    ...prevState,
                                                    userCountry: e.target.value
                                                }));
                                            }}
                                            name="userCountry"
                                            placeHolder="Select Country"
                                        />
                                    </Box>
                                </div>
                                <div className="pc-right-input">
                                    <i style={{ marginBottom: "10px" }}>State</i>
                                    <Box
                                        sx={{
                                            width: 400,
                                            maxWidth: '100%',
                                        }}
                                    >
                                        <StateSelect
                                            required
                                            countryid={countryid}
                                            onChange={(e) => {
                                                setstateid(e.id);
                                                // console.log(e);
                                                setDynamicState(prevState => ({
                                                    ...prevState,
                                                    userState: e.name
                                                }));
                                            }}
                                            onTextChange={(e) => {
                                                setDynamicState(prevState => ({
                                                    ...prevState,
                                                    userState: e.target.value
                                                }));
                                                // console.log(e.target.value);
                                            }}
                                            name="userState"
                                            placeHolder="Select State"
                                        />

                                    </Box>
                                </div>
                            </div>
                            <div className="pc-right-1 " style={{ display: "none" }}>
                                <div className="pc-right-input">
                                    <i style={{ marginBottom: "10px" }}>City</i>
                                    <Box
                                        sx={{
                                            width: 300,
                                            maxWidth: '100%',
                                        }}
                                    >
                                        <CitySelect

                                            countryid={countryid}
                                            stateid={stateid}
                                            onChange={(e) => {
                                                setstateid(e.id);
                                                // console.log(e);
                                                setDynamicState(prevCity => ({
                                                    ...prevCity,
                                                    userCity: e.name
                                                }));
                                            }}
                                            onTextChange={(e) => {
                                                setDynamicState(prevCity => ({
                                                    ...prevCity,
                                                    userCity: e.target.value
                                                }));
                                                // console.log(e.target.value);
                                            }}
                                            name="userCity"
                                            placeHolder="Select City"
                                        />
                                    </Box>
                                </div>
                                <div className="pc-right-input">
                                    <i style={{ marginBottom: "10px" }}>Pincode</i>
                                    <Box
                                        sx={{
                                            width: 300,
                                            maxWidth: '100%',
                                        }}
                                    >
                                        <TextField

                                            id="outlined-password-input"
                                            label="Postal / Zip"
                                            type="Number"
                                            name="userPincode"
                                            onChange={(e) => {
                                                // console.log(e);
                                                setDynamicState(prevZip => ({
                                                    ...prevZip,
                                                    userPincode: e.target.value
                                                }));
                                            }}
                                            onTextChange={(e) => {
                                                setDynamicState(prevZip => ({
                                                    ...prevZip,
                                                    userPincode: e.target.value
                                                }));
                                                // console.log(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </div>
                            </div>

                            <div className="pc-right-4 ">
                                {
                                    isPhoneVerified ?
                                        <div className="payment-verify">
                                            <b>Verification Code</b>
                                            <p>We have sent a verification code to your mobile number.
                                            </p>
                                            <MuiOtpInput value={otp} onChange={handleChange} length={4} autoFocus inputMode="numeric" TextFieldsProps={(index) => ({ type: 'tel' })} />
                                            <br />
                                            {
                                                isNotNumber && <p>(Note : Please enter numeric values.)</p>
                                            }
                                            <div className="resend-cancel">

                                                <Button size="medium" onClick={(e) => resendOTP(e)}>
                                                    Resend
                                                </Button>
                                                <Button

                                                    size="medium"
                                                    onClick={handlePaymentVerification}
                                                    disabled={isButtonClicked || otp.length < 4} // Disable if OTP is less than 3 digits

                                                >
                                                    {isButtonClicked ? 'Processing...' : 'Confirm'}
                                                </Button>
                                            </div>
                                        </div>
                                        :
                                        <Button type="submit" size="large"
                                            disabled={
                                                !dynamicState.userCountry?.trim() ||
                                                !dynamicState.countryCode?.trim() ||
                                                !dynamicState.userState?.trim() ||
                                                !dynamicState.userName?.trim() ||
                                                !dynamicState.userEmail?.trim() ||
                                                !dynamicState.userPhone?.trim()

                                            }

                                        //  disabled={
                                        // isNullOrEmpty(dynamicState.userName) ||
                                        // isNullOrEmpty(dynamicState.userEmail) ||
                                        // isNullOrEmpty(dynamicState.userPhone) ||
                                        // isNullOrEmpty(dynamicState.userAddressLine1) ||
                                        // isNullOrEmpty(dynamicState.userCountry) ||
                                        // isNullOrEmpty(dynamicState.userState) ||
                                        // isNullOrEmpty(dynamicState.userPincode)
                                        // }
                                        >
                                            Verify And Pay
                                        </Button>
                                }
                                <button style={{ display: "none" }} id="rzp-button1">Pay Now</button>



                            </div>

                        </form>

                    </div>






                </section>



                {/* </div> */}


                <FooterComponent></FooterComponent>
            </div >
        </div >
    );
};

export default PremiumCheckout;
