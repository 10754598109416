import React, { useState, useEffect } from 'react';
import LandingHeader from "../components/LandingHeader";
import Top20px from "../screens/Top20px";
import FooterComponent from "../components/footer";
import { Box, Grid, Stack } from '@mui/material';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';


const BookingsPage = () => {

    const today = new Date();
    const theme = useTheme();


    const [bookings, setBookings] = useState([])

    useEffect(() => {

        const localId = localStorage.getItem("SD_Id") || localStorage.getItem("premiumVerified")
        const url = `https://backend.shubadinam.com/getbookings?localId=${localId}`;

        axios.get(url)
            .then((response) => {
                // console.log(response);
                setBookings(response.data)

            })
            .catch((error) => {
                // console.log(error);
            })

    }, [])

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const getStatus = (dateString) => {
        // Example input: 'Friday, 09/08/24'
        // console.log(dateString);

        // Convert the input date to a Date object
        // Extract date parts from the string
        const [weekday, datePart] = dateString.split(', ');
        const [day, month, year] = datePart.split('/').map(Number);

        const inputDate = new Date(`20${year}`, month - 1, day); // '20${year}' to handle 2-digit year

        const today = new Date();
        today.setHours(6, 0, 0, 0);

        inputDate.setHours(6, 0, 0, 0);
        // console.log(inputDate);

        const now = new Date();
        const localHours = now.getHours();
        const localMinutes = now.getMinutes();




        if (inputDate < today) {
            return 'Completed';
        }
        else if (inputDate > today) {
            return 'Upcoming';
        }
        // Check if the current time is after 6:00 AM local time
        else {
            if (localHours > 7 || (localHours === 7 && localMinutes > 0)) {
                return "Completed";
            }
            else {
                return "Today";
            }
        }
    };


    const statusStyles = {
        Upcoming: { color: 'green', fontWeight: 'bold' },
        Completed: { color: '#c40000', fontWeight: 'bold' },
        Today: { color: 'blue' }
    };


    return (
        <div className='homam-container'>
            <LandingHeader />
            <Top20px />
            <div className='homam-body'>
                <div className='homam-title'>
                    <h5 style={{ fontSize: "larger" }}>My Bookings</h5>
                    {/* <NavLink to='/booking-history'>Booking History</NavLink> */}
                </div>
                <br />



                {bookings.length === 0 ? (
                    <Typography variant="h6" align="center" sx={{ mt: 2 }}>
                        You haven't booked any homams yet.
                    </Typography>
                ) : isMobile ? (
                    // Mobile view using cards
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, }}>
                        {bookings.slice().reverse().map((booked, index) => (
                            <Paper key={index} sx={{ padding: 2 }}>
                                <Stack direction="row" spacing={10}>
                                    <Typography variant="h6" sx={{ color: "#006ba8" }}>{booked.homamName}</Typography>
                                    <span style={statusStyles[getStatus(booked.homam_date)]}>{getStatus(booked.homam_date)}</span>
                                </Stack>

                                <br />
                                <Grid container spacing={2} sx={{ marginTop: 1 }}>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">
                                            <strong>Homam on:</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">
                                            {booked.homam_date}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">
                                            <strong>Name:</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">
                                            {booked.customersName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">
                                            <strong>Nakshatra:</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">
                                            {booked.customersNakshatra}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">
                                            <strong>Purchased on:</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">
                                            {new Date(booked.entry_time).toLocaleDateString('en-GB')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        ))}
                    </Box>
                ) : (
                    // Desktop view using table
                    <TableContainer component={Paper}>
                        <Table aria-label="bookings table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>S.no</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Nakshatra</TableCell>
                                    <TableCell>Purchased on</TableCell>
                                    <TableCell>Event</TableCell>
                                    <TableCell>Homam on</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bookings.slice().reverse().map((booked, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>

                                        <TableCell>{booked.customersName}</TableCell>
                                        <TableCell>{booked.customersNakshatra}</TableCell>
                                        <TableCell>
                                            {new Date(booked.entry_time).toLocaleDateString('en-GB')}
                                        </TableCell>
                                        <TableCell>{booked.homamName}</TableCell>
                                        <TableCell>
                                            {booked.homam_date}
                                        </TableCell>
                                        <TableCell>
                                            <span style={statusStyles[getStatus(booked.homam_date)]}>
                                                {getStatus(booked.homam_date)}
                                            </span>
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                <br />
            </div>
            <FooterComponent />
        </div >
    );
};

export default BookingsPage;