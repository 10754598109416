import React, { useEffect } from "react";
import LandingHeader from '../components/LandingHeader';
import Top20px from '../screens/Top20px';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FooterComponent from '../components/footer';
import axios from "axios";
import Swal from "sweetalert2";


const InstructionsNavagraha = () => {

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);

    }, [])

    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleRouting = () => {

        const localId = localStorage.getItem("SD_Id") || localStorage.getItem("premiumVerified");
        if (localId) {
            axios.get(`https://backend.shubadinam.com/checkuser?userId=${localId}`)
                .then((response) => {
                    // console.log(response.data);
                    navigate("/Navagraha-Sukta-Parayanam-booking")
                })
                .catch((error) => {
                    Swal.fire({
                        title: 'Log In !',
                        text: 'Please login to continue further.',
                        icon: 'Info',
                        confirmButtonText: 'Okay',
                    })
                    navigate("/signin")
                })
        }
        else {
            Swal.fire({
                title: 'Log In !',
                text: 'Please login to continue further.',
                icon: 'info',
                confirmButtonText: 'Okay',
            })
            navigate("/signin")
        }






    }

    return (
        <div className='homam-container'>
            <LandingHeader />
            <Top20px />
            <div className='homam-body-instructions'>
                <div className='homam-title'>
                    <b style={{ fontSize: "22px" }}>Navagraha Sukta Parayanam  (Virtual Participation) Instructions</b>
                </div>

                <div className='homam-title2'>
                    <b>Rs. 150/- per person</b>
                    <p> Navagraha Sukta parayanam (recital) is done for protection from Doshas caused by the Navagrahas namely Surya, Chandra, Bhudha, Guru, Shukra, Shani, Rahu and Kethu.</p>
                </div>

                <div className='homam-instuctions-list'>
                    <h5 style={{ paddingLeft: "20px" }}>Benefits of Avahanti Homam</h5>
                    <ul>
                        <li>The chanting of mantras along with the sacred offerings can help ease out the suffering caused due to Navagraha Doshas and confer longevity, good health, protection from evil forces, and help you achieve goals in life. </li>

                        <li>Those suffering from chronic and acute ailments are strongly advised to perform this ritual periodically to improve the quality of their life.</li>

                    </ul>

                </div>

                <br />
                <div className='homam-instuctions-list'>

                    <h5 style={{ paddingLeft: "20px" }}> When and Where?</h5>
                    <p style={{ paddingLeft: "20px" }}>Navagraha Sukta parayana is conducted every Saturday at 6 Pm by vedic Students of the Veda Patashala associated with Shubadinam.com. It will be streamed live at the youtube channel  @shubadinam. Click here to see the timetable.</p>

                    <h5 style={{ paddingLeft: "20px" }}>How to Participate?</h5>
                    <ol>
                        <li style={{ padding: "2px" }}>You can book your slots 2 months in advance by paying a nominal fee. Booking Slots will be opened on 20th of every month. (For Example Booking Slot for September 2024 will be opened on 20th July 2024.)</li>
                        <li style={{ padding: "2px" }}>Provide the Name and Nakshatra details of the person whose name should be included in Sankalpa.</li>
                        <li style={{ padding: "2px" }}>Name and Nakshatra of all yajamans will be read out during the sankalpam that can be watched Live.</li>
                        <li style={{ padding: "2px" }}>Homam Prashad will be sent by Post.</li>
                    </ol>


                </div>
                <br />
                <div className='homam-instuctions-list'>

                    <h5 style={{ paddingLeft: "20px" }}> Optional Contribution.</h5>
                    <p style={{ paddingLeft: "20px" }}>You can contribute to Brahmana bhojana on the day of homam. Click Here for More details.</p>

                    <span style={{ paddingLeft: "20px" }}>Disclaimer: Please contact a qualified pundit if you want to conduct this Homam exclusively at your residence for more benefits</span>

                    <br />
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            required
                            label="I have read and agree to the instructions"
                        />
                    </div>

                    <br />
                    <div className='homam-continue'>
                        {
                            checked ?
                                <Button className='homam-continue-btns homam-ctn-btn' onClick={() => handleRouting()}>Book Slot</Button>
                                :
                                <Button className='homam-continue-btns' disabled  >Book Slot</Button>
                        }

                        {/* <Button className='homam-continue-btns homam-ctn-btn' onClick={() => handleRouting()}>Continue</Button> */}


                    </div>


                </div>

            </div>
            <FooterComponent />
        </div>
    );
};

export default InstructionsNavagraha;