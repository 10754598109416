import React, { useRef, useState } from 'react';

import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import CenteredSlider from "./CenteredSlider";
import Top10px from "./Top10px";
import Top20px from "./Top20px";
import BottomSpace from "./BottomSpace"
import BlogsBannerAd from "../AdsFolder/BlogsBannerAd";



import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import LandingHeader from "../components/LandingHeader";

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ScrollToTop from 'react-scroll-to-top';





const BlogScreen = () => {




    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    const [searchQuery, setSearchQuery] = useState('');

    const cardData = [
        {
            title: 'Nakshatra Food',
            imageSrc: require("../images/boxesImages/food1.jpg"),
            content: "A person's birth star, also known as Nakshatra, plays a significant role in their life and is believed to influence various aspects, including food preferences.",
            link: '/nakshatra-foods'
        },
        {
            title: 'Nakshatra Characteristics',
            imageSrc: require("../images/boxesImages/char1.jpg"),
            content: "This is a general overview of the characteristics associated with each Janma Nakshatra or Birth Star in Vedic astrology. ",
            link: '/nakshatra-characteristics'
        },
        {
            title: 'Nakshatra deities',
            imageSrc: require("../images/boxesImages/deity1.png"),
            content: "In Hindu way of life, Nakshatras are lunar mansions or constellations that play a significant role in determining one's personality, behaviour, and destiny.",
            link: '/nakshatra-deities'
        },
        {
            title: 'Nakshatra Likes and Dislikes',
            imageSrc: require("../images/boxesImages/likes11.jpg"),
            content: "This is a general overview of some tendencies and preferences associated with each Nakshatra. ",
            link: '/nakshatra-likes-dislikes'
        },
        {
            title: 'Tithi Food',
            imageSrc: require("../images/boxesImages/food2.jpg"),
            content: "Food preferences can vary greatly among individuals, and they are influenced by factors such as culture, personal tastes, and dietary choices. ",
            link: '/tithi-foods'
        },
        {
            title: 'Tithi characteristics',
            imageSrc: require("../images/boxesImages/char2.jpg"),
            content: "This is a general overview of the characteristics associated with each Janma Tithi in Vedic astrology. ",
            link: '/tithi-characteristics'
        },
        {
            title: 'Tithi deities',
            imageSrc: require("../images/boxesImages/deity2.png"),
            content: "In Vedic astrology and Hindu tradition, each Janma Tithi is associated with a presiding deity.",
            link: '/tithi-deities'
        },

        {
            title: 'Tithi Likes and Dislikes',
            imageSrc: require("../images/boxesImages/likes22.jpg"),
            content: "This a general overview of the likes and dislikes that individuals born on each Janma Tithi might have, according to Vedic astrology.",
            link: '/tithi-likes-dislikes'
        },
        // Add more card data as needed
    ];

    const filteredCards = cardData.filter(card =>
        card.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = 'https://checkout.razorpay.com/v1/payment-button.js';
    //     script.async = true;
    //     script.setAttribute('data-payment_button_id', 'pl_NRxcEtbKhNmvnE');
    //     document.getElementById('razorpay-form').appendChild(script);

    //     return () => {
    //         document.getElementById('razorpay-form').removeChild(script);
    //     };
    // }, []);


    return (
        <div className="overall-container">
            <ScrollToTop smooth />
            <HelmetProvider>
                <Helmet>
                    <title>Blogs </title>
                    <meta name="description" content="Blogs - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <div className="overall-percent">
                <LandingHeader />
                {/* <HeaderComponent></HeaderComponent> */}
                <Top10px />
                <br />
                <br />

                <div className="blog-title">
                    <img src={require("../images/blogs-2-gradint.gif")} alt="shubadinam image" onContextMenu={(e) => e.preventDefault()} />
                    {/* <h3>BLOGS</h3> */}
                </div>
                {/* <div className="top20"> */}
                {/* <div className="blogs-banner-ad">
                        <BlogsBannerAd data-ad-slot="6616774036" />
                    </div> */}
                {/* </div> */}



                {/* <Top20px /> */}
                <div className="seasrch-bar">
                    <input
                        className="search-bar"
                        style={{ width: "300px", borderRadius: "6px", border: "1px solid black", paddingLeft: "10px", }}
                        type="search"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>

                {/* <form id="razorpay-form">
                    <div id="razorpay-container"></div>
                </form> */}

                <div className="center-container-blogs">

                    <div className="blogs-area">

                        <div className="blog-trip-boxes">

                            {filteredCards.map((card, index) => (
                                <NavLink to={card.link} className="text-noblue" key={index}>
                                    <Card sx={{ maxWidth: 345, height: 345, flex: 1, margin: '10px' }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="160"
                                                src={card.imageSrc} alt="shubadinam image"
                                            />
                                            <CardContent sx={{ backgroundColor: "white" }}>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    {card.title}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {card.content}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Read more
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </NavLink>
                            ))}


                            <br />


                        </div>

                        {/* <div className="blogs-ph-slider">
                            <div className="blogs-ph-slider-cnt">
                                <CenteredSlider />
                            </div>

                        </div> */}
                    </div>







                </div>

                <BottomSpace />
                <FooterComponent></FooterComponent>
            </div>
        </div>
    );
};

export default BlogScreen;


{/* 
                            <div className="blog-container1" onContextMenu={(e) => e.preventDefault()}>

                                <NavLink to="/nakshatra-foods" className="text-noblue">
                                    <Card sx={{ maxWidth: 345, height: "300" }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="160"
                                                img src={require("../images/boxesImages/food1.jpg")} alt="shubadinam image"
                                            />
                                            <CardContent >
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Nakshatra Food
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Lizards are a widespread group of squamate reptiles, with over 6,000
                                                    species, ranging across all continents except Antarctica
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Read more
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </NavLink>

                                <NavLink to="/nakshatra-characteristics" className="text-noblue">
                                    <Card sx={{ maxWidth: 345, height: "300" }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="160"
                                                img src={require("../images/boxesImages/char1.jpg")} alt="shubadinam image" loading="lazy" />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Nakshatra Characteristics
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Lizards are a widespread group of squamate reptiles, with over 6,000
                                                    species, ranging across all continents except Antarctica
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Read more
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </NavLink>

                            </div>
                            <br />

                            <div className="blog-container2" onContextMenu={(e) => e.preventDefault()}>

                                <NavLink to="/nakshatra-deities" className="text-noblue">

                                    <Card sx={{ maxWidth: 345, height: "300" }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="160"
                                                img src={require("../images/boxesImages/deity1.png")} alt="shubadinam image"
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Nakshatra Deity

                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Lizards are a widespread group of squamate reptiles, with over 6,000
                                                    species, ranging across all continents except Antarctica
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Read more
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </NavLink>

                                <NavLink to="/nakshatra-likes-dislikes" className="text-noblue">
                                    <Card sx={{ maxWidth: 345, height: "300" }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="160"
                                                img src={require("../images/boxesImages/likes11.jpg")} alt="shubadinam image" loading="lazy" />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Nakshatra Likes and Dislikes

                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Lizards are a widespread group of squamate reptiles, with over 6,000
                                                    species, ranging across all continents except Antarctica
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Read more
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </NavLink>

                            </div>
                            <br />

                            <div className="blog-container3" onContextMenu={(e) => e.preventDefault()}>

                                <NavLink to="/tithi-foods" className="text-noblue">
                                    <Card sx={{ maxWidth: 345, height: "300" }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="160"
                                                img src={require("../images/boxesImages/food2.jpg")} alt="shubadinam image"
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Tithi Food
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Lizards are a widespread group of squamate reptiles, with over 6,000
                                                    species, ranging across all continents except Antarctica
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Read more
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </NavLink>

                                <NavLink to="/tithi-characteristics" className="text-noblue">
                                    <Card sx={{ maxWidth: 345, height: "300" }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="160"
                                                img src={require("../images/boxesImages/char2.jpg")} alt="shubadinam image" loading="lazy" />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Tithi Characteristics
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Lizards are a widespread group of squamate reptiles, with over 6,000
                                                    species, ranging across all continents except Antarctica
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Read more
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </NavLink>

                            </div>
                            <br /> */}

{/* <div className="blog-container3" onContextMenu={(e) => e.preventDefault()}>


                                <NavLink to="/tithi-deities" className="text-noblue">

                                    <Card sx={{ maxWidth: 345, height: "300" }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="160"
                                                img src={require("../images/boxesImages/deity2.png")} alt="shubadinam image"
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Tithi Deity

                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Lizards are a widespread group of squamate reptiles, with over 6,000
                                                    species, ranging across all continents except Antarctica
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Read more
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </NavLink>

                                <NavLink to="/tithi-likes-dislikes" className="text-noblue">
                                    <Card sx={{ maxWidth: 345, height: "300" }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="160"
                                                img src={require("../images/boxesImages/likes22.jpg")} alt="shubadinam image" loading="lazy" />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Tithi Likes and Dislikes

                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Lizards are a widespread group of squamate reptiles, with over 6,000
                                                    species, ranging across all continents except Antarctica
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <Button size="small" color="primary">
                                                Read more
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </NavLink>


                            </div> */}