import React, { useState, useEffect, useRef } from "react";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import axios, { Axios } from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, fa1, fa2, fa3, faUser, faL } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from "@fortawesome/fontawesome-free"
import top20 from "../screens/Top20px"

import ScrollToTop from "react-scroll-to-top";

import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

import FooterComponent from "../components/footer";
import HomeSignInComponent from "../components/home-signin";
import Swal from 'sweetalert2';
// import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import LanguageSwitcher from "./LanguageSwitcher";
import MenuBar from "./menubar";
import Blogs from "./blogs";
import Coverflow from "./Coverflow";
import CenteredSlider from "./CenteredSlider";
import TestimonialScreen from "./testimonials";
import Top20px from "../screens/Top20px";
import Top10px from "./Top10px";
import LandingHeader from "../components/LandingHeader";


import { Helmet, HelmetProvider } from 'react-helmet-async';



AOS.init({
    duration: 1000, // Notice the single quotes around '2s'
});


const SigninPage = () => {


    const [dashsignOut, setSignOut] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.refresh();
    }, []);

    useEffect(() => {
        const auth = localStorage.getItem("homeauth");
        if (auth) {
            setSignOut(true)
            setisHomeauth(true)
        }
        setisHomeauth(false)
    }, []);

    const [isHomeauth, setisHomeauth] = useState(false)



    //  verify user

    // const clickHome = () => {
    //     const auth = localStorage.getItem("isAuthenticated");
    //     if (!auth) {
    //         Swal.fire({
    //             title: '',
    //             text: 'New to Shubadinam.com! Please Sign up or Log in.',
    //             icon: 'warning',
    //             confirmButtonText: 'Ok',
    //         }).then((result) => {
    //             if (result.isConfirmed) {
    //                 navigate("/");
    //             }
    //         });
    //     } else {
    //         navigate("/home");
    //     }
    // }



    const [signupcontent, SetSignupContent] = useState(false)



    const clientId = "516265231821-9n4dbqkgm349bl3a76j75lgeu19vv71h.apps.googleusercontent.com"

    // const clientSecret = "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";

    const navigate = useNavigate();

    const sectionRef = useRef(null);

    const howitworks = useRef(null)


    const [offset, setOffset] = useState(0);
    const [inView, setInView] = useState(false);

    const handleScroll = () => {
        setOffset(window.scrollY);

        // Check if the content is in view
        const element = document.querySelector('.parallax-content');
        if (element) {
            const rect = element.getBoundingClientRect();
            setInView(rect.top < window.innerHeight);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const [backendError, SetBackendError] = useState([])


    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [flag, SetFlag] = useState(false)
    const [name, SetName] = useState("")

    const [dynamicInput, dynamicInputFunction] = useState({
        userPhone: "",
        countryCode: "91"
    });

    const [errorForm, ErrorFormFunction] = useState({
        userEmail: false,
        userPassword: false,
        userPhone: false
    })

    const [eyeIcon, UpdateEyeIcon] = useState(true)

    const eyeOpenClose = (icon) => {
        UpdateEyeIcon(icon)
    }

    const inputHandle = (event) => {
        const { name, value } = event.target;

        if (name === "userPhone") {
            dynamicInputFunction({ ...dynamicInput, userPhone: value });
        }
    };

    const handleCountryClick = (number) => {
        const numericCountryCode = number.replace(/\D/g, '');

        dynamicInputFunction({ ...dynamicInput, countryCode: numericCountryCode });
    };

    const onSuccess = (response) => {
        // SetName(response.profileObj);
        // console.log("success", response);
        SetFlag(true)
    }

    const onFailure = (err) => {
        // console.log(err);
    }







    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    const [isOptSent, SetIsOtpSent] = useState(false);

    const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);

    const clickSubmitcheck = () => {
        // clickSubmitbtn()
        // SetIsOtpSent(true)
        console.log(dynamicInput);
        const userPhone = dynamicInput.userPhone
        const countryCode = dynamicInput.countryCode

        const url = `https://backend.shubadinam.com/createSigninOtp?userPhone=${userPhone}&&countryCode=${countryCode}`

        axios.get(url)
            .then((response) => {
                console.log(response);
                const otpSent = response.data.message;
                const settingPremium = response.data.userStatus;
                // alert(settingPremium);
                const preId = response.data.userId;
                const stringifyKey = response.data.data.userEmail;
                const stringifyForeignKey = JSON.stringify(stringifyKey);

                if (settingPremium === "Incomplete") {
                    localStorage.setItem("premiumVerified", preId)
                    localStorage.setItem("nav_", "profile")
                    localStorage.setItem("fkey", btoa(stringifyForeignKey));
                    sessionStorage.setItem("profileauth", true)
                }
                else if (settingPremium === "Already User") {
                    // localStorage.setItem("premiumVerified", preId)
                    localStorage.setItem("nav_", "dashboard")
                    localStorage.setItem("fkey", btoa(stringifyForeignKey));
                    // sessionStorage.setItem("profileauth", true)
                }

                else if (settingPremium === "premium dashboard") {
                    localStorage.setItem("premiumVerified", preId)
                    localStorage.setItem("nav_", "dashboard")
                    localStorage.setItem("fkey", btoa(stringifyForeignKey));
                    // sessionStorage.setItem("profileauth", true)
                }

                Swal.fire({
                    title: 'OTP Sent',
                    text: otpSent,
                    icon: 'success',
                    html: otpSent + '<br><br><span style="color: red; font-size: 12px;">(* In case you did not receive the SMS OTP , you may use the Email OTP)</span>', // Add your manual text here
                    confirmButtonText: 'Got it!', // Change the confirm button text
                    confirmButtonColor: '#3085d6', // Change the confirm button color
                    background: '#f5f5f5', // Change the background color
                    customClass: {
                        container: 'my-custom-container-class', // Add a custom container class
                        title: 'my-custom-title-class', // Add a custom title class
                        content: 'my-custom-content-class', // Add a custom content class
                        confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                    },
                });

                SetIsOtpSent(true)

            })
            .catch((error) => {
                console.log(error)
                if (error.response.status === 501) {
                    Swal.fire({
                        title: 'Authorization Revoked',
                        text: error.response.data.message,
                        icon: 'warning',
                        confirmButtonText: 'Got it!', // Change the confirm button text
                        confirmButtonColor: '#3085d6', // Change the confirm button color
                        background: '#f5f5f5', // Change the background color
                        customClass: {
                            container: 'my-custom-container-class', // Add a custom container class
                            title: 'my-custom-title-class', // Add a custom title class
                            content: 'my-custom-content-class', // Add a custom content class
                            confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                        },
                    });
                }
            })
    }

    const clickSubmit = () => {

        if (!isSubmitClicked) {
            setIsSubmitClicked(true);

            const link = "https://backend.shubadinam.com/upload/mailpass"
            axios.post(link, dynamicInput)
                .then((response) => {


                    const loggingUser = response.data.data2;
                    const loggingRelation = response.data.data1;

                    if (response.data.errors) {
                        SetBackendError(response.data.errors)

                        const invalidmailPass = response.data.errors[0].msg
                        // console.log(invalidmailPass)

                        Swal.fire({
                            title: 'Invalid',
                            text: invalidmailPass,
                            icon: 'error',
                            confirmButtonText: 'Got it!', // Change the confirm button text
                            confirmButtonColor: '#3085d6', // Change the confirm button color
                            background: '#f5f5f5', // Change the background color
                            customClass: {
                                container: 'my-custom-container-class', // Add a custom container class
                                title: 'my-custom-title-class', // Add a custom title class
                                content: 'my-custom-content-class', // Add a custom content class
                                confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                            },
                        });




                    } else {
                        SetBackendError([])
                        const verifySuccess = response.data.success
                        // console.log(verifySuccess)
                        Swal.fire({
                            title: 'Email Verification',
                            text: verifySuccess,
                            icon: 'success',
                            confirmButtonText: 'Got it!', // Change the confirm button text
                            confirmButtonColor: '#3085d6', // Change the confirm button color
                            background: '#f5f5f5', // Change the background color
                            customClass: {
                                container: 'my-custom-container-class', // Add a custom container class
                                title: 'my-custom-title-class', // Add a custom title class
                                content: 'my-custom-content-class', // Add a custom content class
                                confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                            },
                        });
                        const stringifyUserInfo = JSON.stringify(dynamicInput);
                        // localStorage.setItem("isAuthenticated", btoa(stringifyUserInfo));
                        const stringifyForeignKey = JSON.stringify(dynamicInput.userEmail);
                        // localStorage.setItem("fkey", btoa(stringifyForeignKey));
                        // console.log(dynamicInput)

                        // console.log(loggingUser);
                        // console.log(loggingRelation);
                        localStorage.setItem('myDataKey', JSON.stringify(loggingRelation));
                        localStorage.setItem('myDataKey2', JSON.stringify(loggingUser));

                        // window.location.reload();

                    }

                    setIsSubmitClicked(false);

                })
                .catch((error) => {
                    // console.log(error);


                    if (error.message === "Network Error") {
                        // const msg1 = error.response.data.message
                        Swal.fire({
                            title: 'Under Maintanance',
                            text: 'Please login after sometime!',
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                        })
                    } else {
                        const userExist = error.response.data

                        Swal.fire({
                            title: 'Invalid User',
                            text: userExist,
                            icon: 'warning',
                            confirmButtonText: 'Ok', // Change the confirm button text
                            confirmButtonColor: '#3085d6', // Change the confirm button color
                            background: '#f5f5f5', // Change the background color
                            customClass: {
                                container: 'my-custom-container-class', // Add a custom container class
                                title: 'my-custom-title-class', // Add a custom title class
                                content: 'my-custom-content-class', // Add a custom content class
                                confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                            },
                        });
                    }

                    setIsSubmitClicked(false);

                    // alert(error.response.data)
                });

        }

        // const stringifyUserInfo = JSON.stringify(dynamicInput);
        // localStorage.setItem("isAuthenticated", btoa(stringifyUserInfo));
        // localStorage.setItem("email", dynamicInput.userEmail);
        // console.log(dynamicInput)



        ErrorFormFunction({
            ...errorForm,
            userEmail: dynamicInput.userEmail === "" ? true : false,
            userPassword: dynamicInput.userPassword === "" ? true : false,
            userPhone: dynamicInput.userPhone === "" ? true : false
        })
    }

    const clickSubmitbtn = () => {
        console.log(dynamicInput);

        const link = "https://backend.shubadinam.com/logged/user"
        axios.post(link, dynamicInput)
            .then((response) => {

                console.log(response.data);

                const loggingUser = response.data.data2;
                const loggingRelation = response.data.data1;
                const setFkey = response.data.fkey;
                const SD_Id = response.data.data2[0].uniqueId;

                if (response.data.success === "Success") {


                    localStorage.setItem("SD_Id", SD_Id);

                    const stringifyUserInfo = JSON.stringify(dynamicInput);
                    localStorage.setItem("isAuthenticated", btoa(stringifyUserInfo));
                    // localStorage.setItem("email", dynamicInput.userEmail);

                    const stringifyForeignKey = JSON.stringify(setFkey);
                    localStorage.setItem("fkey", btoa(stringifyForeignKey));

                    // console.log(loggingUser);
                    // console.log(loggingRelation);
                    localStorage.setItem('myDataKey', JSON.stringify(loggingRelation));
                    localStorage.setItem('myDataKey2', JSON.stringify(loggingUser));


                    localStorage.setItem('homeauth', 'true')

                    // window.location.reload();
                    window.location.href = "/dashboard";

                    // navigate("/home");
                    // alert("yes")






                } else {
                    // console.log(response.data);
                    alert("No records existed")
                }

            })
            .catch((error) => {

                const noRecords = error.response.data.message;
                const wrongpass = error.response.data.message;

                console.error('Error:', error)

                if (error.response.data.norecords === "Failure") {
                    Swal.fire({
                        title: 'Invalid',
                        // text: "We are currently unavailable.Please try again after sometime.",
                        // text: "No records existed.Please Sign up/Log in after sometime."    backendstop: "backend stop",
                        text: noRecords,
                        icon: 'warning',
                        confirmButtonText: 'Got it!', // Change the confirm button text
                        confirmButtonColor: '#3085d6', // Change the confirm button color
                        background: '#f5f5f5', // Change the background color
                        customClass: {
                            container: 'my-custom-container-class', // Add a custom container class
                            title: 'my-custom-title-class', // Add a custom title class
                            content: 'my-custom-content-class', // Add a custom content class
                            confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                        },
                    });
                }
                else if (error.response.data.passfailure === "Failure") {

                    Swal.fire({
                        title: 'Invalid',
                        // text: "We are currently unavailable.Please try again after sometime.",
                        // text: "No records existed.Please Sign up/Log in after sometime."    backendstop: "backend stop",
                        text: wrongpass,
                        icon: 'warning',
                        confirmButtonText: 'Got it!', // Change the confirm button text
                        confirmButtonColor: '#3085d6', // Change the confirm button color
                        background: '#f5f5f5', // Change the background color
                        customClass: {
                            container: 'my-custom-container-class', // Add a custom container class
                            title: 'my-custom-title-class', // Add a custom title class
                            content: 'my-custom-content-class', // Add a custom content class
                            confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                        },
                    });
                }
                else {
                    Swal.fire({
                        title: 'Invalid',
                        text: "We are currently unavailable.Please Sign up/Log in after sometime.",
                        icon: 'warning',
                        confirmButtonText: 'Got it!', // Change the confirm button text
                        confirmButtonColor: '#3085d6', // Change the confirm button color
                        background: '#f5f5f5', // Change the background color
                        customClass: {
                            container: 'my-custom-container-class', // Add a custom container class
                            title: 'my-custom-title-class', // Add a custom title class
                            content: 'my-custom-content-class', // Add a custom content class
                            confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                        },
                    });
                }
            });
    }

    const verifySigninOtp = () => {

        const userPhone = dynamicInput.userPhone
        const countryCode = dynamicInput.countryCode

        const signOtp = sessionStorage.getItem("otp");
        // console.log(signOtp)

        const url = `https://backend.shubadinam.com/verifySigninOtp?userPhone=${userPhone}&&userOtp=${signOtp}`

        axios.get(url)
            .then((response) => {


                // console.log(response.data);
                const validOtp = response.data.message
                const navTo = localStorage.getItem("nav_")
                if (navTo === "profile") {
                    window.location.href = "/profile";
                    return;
                } else {
                    clickSubmitbtn()
                    console.log(response);
                }

                // Swal.fire({
                //     title: 'Otp Verified',
                //     text: validOtp, // Assuming validOtp contains the OTP message
                //     icon: 'success',
                //     confirmButtonText: 'Got it!', // Change the confirm button text
                //     confirmButtonColor: '#3085d6', // Change the confirm button color
                //     background: '#f5f5f5', // Change the background color
                //     customClass: {
                //         container: 'my-custom-container-class', // Add a custom container class
                //         title: 'my-custom-title-class', // Add a custom title class
                //         content: 'my-custom-content-class', // Add a custom content class
                //         confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                //     }
                // }).then((result) => {
                //     if (result.isConfirmed) {


                // window.location.href = '/home'; // Redirect to a success page
                //     }
                // });
            })

            .catch((error) => {
                // console.log(error);
                const invaildOtp = error.response.data.message

                Swal.fire({
                    title: 'Authentication Revoked',
                    text: invaildOtp,
                    icon: 'info',
                    confirmButtonText: 'Got it!', // Change the confirm button text
                    confirmButtonColor: '#3085d6', // Change the confirm button color
                    background: '#f5f5f5', // Change the background color
                    customClass: {
                        container: 'my-custom-container-class', // Add a custom container class
                        title: 'my-custom-title-class', // Add a custom title class
                        content: 'my-custom-content-class', // Add a custom content class
                        confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                    },
                });
            })
    }

    const resendOtp = () => {
        clickSubmitcheck()
    }

    const inputRefs = useRef([]);
    const [isNotNumber, setIsNotNumber] = useState(false)

    const handleInputChange = (index, value) => {

        if (!/^\d*$/.test(value)) {
            value = ""; // Set value to an empty string if not a number
            setIsNotNumber(true)
            return
        }
        setIsNotNumber(false)
        const updatedInputs = [...otpInputs];
        updatedInputs[index] = value;
        setOtpInputs(updatedInputs);

        // Combine all input values and store in sessionStorage
        const otpValue = updatedInputs.join("").trim();
        sessionStorage.setItem("otp", otpValue);

        setOtpInputs(updatedInputs);

        if (value && index < otpInputs.length - 1) {
            inputRefs.current[index + 1].focus();
        }

    };

    const handleKeyDown = (index, event) => {
        // if (!/^\d$/.test(event.key)) {
        //     event.preventDefault();
        // }
        if (event.keyCode === 8 && !otpInputs[index] && index > 0) {
            // Handle Backspace key when input is empty
            inputRefs.current[index - 1].focus();
        }
    };



    const handleMultipleClicks = () => {
        scrollToSection()
        togglePopup()

    }

    const scrollToSection = () => {
        navigate("/register")
    };
    const siginbackReg = () => {

        navigate("/register")
    };

    const clickHow = () => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            SetSignupContent(false)
            setIsOpen(false)
        }
    };

    const clickSignparallax = () => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            SetSignupContent(true)
        }

    };



    const [isOpen, setIsOpen] = useState(false);
    const [scrollThresholdPercentage, setScrollThresholdPercentage] = useState(10); // 10% initial threshold


    const togglePopup = () => {
        navigate("/signin")
    };


    useEffect(() => {
        const handleScrollfade = () => {
            // Calculate the scroll position threshold based on screen width
            const screenWidth = window.innerWidth;
            let scrollThreshold;

            if (screenWidth > 1300) {
                // For screens wider than 1300px, set a different threshold
                scrollThreshold = 3000;
            } else {
                // For screens 1300px or narrower, use a different threshold
                scrollThreshold = 2000; // You can adjust this value
            }

            if (window.scrollY > scrollThreshold) {
                setIsOpen(false);
            }
        };

        window.addEventListener('scroll', handleScrollfade);

        return () => {
            window.removeEventListener('scroll', handleScrollfade);
        };
    }, []);







    // MENU BAR

    const [isOpenMenu, setOpenMenu] = useState(false);


    // ENTER BUTTON

    const formRef = useRef(null);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            // Trigger the submit button click
            var submitEnter = document.getElementsByClassName("login-button-submit")[0];
            if (submitEnter) {
                submitEnter.click();
            }
        }
    };

    // dashboard signout
    const signuptoSignout = () => {
        setSignOut(false)
        localStorage.removeItem("isAuthenticated")
        localStorage.removeItem("fkey")
        localStorage.removeItem("homeauth")
        // Navigate("/")
    }

    // const clickDashboard=()=>{
    //     Navigate("/home")
    // }

    const backtoHome = () => {
        navigate("/")
    }
    const clickLogo = () => {
        navigate("/")
    }

    const handleEyeIcon = () => {
        if (window.innerHeight > 768) {
            return { marginRight: "10px" };
        } else {
            return { marginRight: "20px" };
        }


    }




    return (


        <div className="overall-container">
            <HelmetProvider>
                <Helmet>
                    <title>Sign In </title>
                    <meta name="description" content="Sign In  - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <ScrollToTop smooth />



            <div className="overall-percent">
                <Top10px />

                <LandingHeader />
                <br />

                <div className="double-container-signin" ref={sectionRef}>
                    {/* <br/> */}

                    <div className="signin-image-container" ref={formRef}>

                        <div className="signin-image">
                            {/* <video autoPlay loop muted className="signin-video">
                                <source src={require("../videos/777_1.mp4")} />
                            </video> */}
                            <img src={require("../images/undraw_Gift_box_re_vau4.png")} className="signin-video" onContextMenu={(e) => e.preventDefault()} />
                        </div>
                        <div className="signin-image-cnt2">
                            <p> <span style={{ fontWeight: "900" }}>Welcome Back:{" "}</span> Rediscover Your Janma Nakshatra/Tithi Journey. Reconnect with your cosmic path as you sign in.
                            </p>

                        </div>


                    </div>

                    <div className="login-form" >
                        <div className="login-flex-column">
                            <label>Registered Phone Number (Log In) </label></div>
                        <div className="login-inputForm">

                            {/* <input type="text" className="login-input" placeholder="Enter your Email" name="userEmail" onChange={inputHandle} onKeyPress={handleKeyPress} /> */}

                            <div className="ui-wrapper">
                                <input defaultChecked id="India" name="flag" type="radio" />
                                <input id="Austria" name="flag" type="radio" />
                                <input id="Belgium" name="flag" type="radio" />
                                <input id="India" name="flag" type="radio" />
                                <input id="Bulgaria" name="flag" type="radio" />
                                <input id="Croatia" name="flag" type="radio" />
                                <input id="Cyprus" name="flag" type="radio" />
                                <input id="Czech" name="flag" type="radio" />
                                <input id="Denmark" name="flag" type="radio" />
                                <input id="Estonia" name="flag" type="radio" />
                                <input id="Finland" name="flag" type="radio" />
                                <input id="France" name="flag" type="radio" />
                                <input id="Germany" name="flag" type="radio" />
                                <input id="Greece" name="flag" type="radio" />
                                <input id="Hungary" name="flag" type="radio" />
                                <input id="Iceland" name="flag" type="radio" />
                                <input id="Ireland" name="flag" type="radio" />
                                <input id="Italy" name="flag" type="radio" />
                                <input id="Latvia" name="flag" type="radio" />
                                <input id="Liechtenstein" name="flag" type="radio" />
                                <input id="Lithuania" name="flag" type="radio" />
                                <input id="Luxembourg" name="flag" type="radio" />
                                <input id="Malta" name="flag" type="radio" />
                                <input id="Netherlands" name="flag" type="radio" />
                                <input id="Norway" name="flag" type="radio" />
                                <input id="Poland" name="flag" type="radio" />
                                <input id="Portugal" name="flag" type="radio" />
                                <input id="Romania" name="flag" type="radio" />
                                <input id="Slovakia" name="flag" type="radio" />
                                <input id="Slovenia" name="flag" type="radio" />
                                <input id="Spain" name="flag" type="radio" />
                                <input id="Sweden" name="flag" type="radio" />
                                <input className="dropdown-checkbox" name="dropdown" id="dropdown" type="checkbox" />
                                <label className="dropdown-container" htmlFor="dropdown" />
                                <div className="input-wrapper">
                                    {/* <legend>
                                        <label htmlFor="phonenumber">
                                            Phonenumber*
                                        </label>
                                    </legend> */}
                                    <div className="textfield">
                                        <input pattern="\d+" maxLength={10} inputMode="numeric" value={dynamicInput.userPhone} id="phonenumber" type="text" name="userPhone" onChange={inputHandle} />
                                        <span className="invalid-msg">This is not a valid phone number</span>
                                    </div>
                                </div>
                                <div className="select-wrapper">
                                    <ul>
                                        <li className="India" onClick={() => handleCountryClick('+91')}><label htmlFor="India"><span>🇮🇳</span>India (+91)</label></li>
                                        <li className="Austria" onClick={() => handleCountryClick('+43')}><label htmlFor="Austria"><span>🇦🇹</span>Austria (+43)</label></li>
                                        <li className="Belgium" onClick={() => handleCountryClick('+32')}><label htmlFor="Belgium"><span>🇧🇪</span>Belgium (+32)</label></li>
                                        <li className="Bulgaria" onClick={() => handleCountryClick('+359')}><label htmlFor="Bulgaria"><span>🇧🇬</span>Bulgaria (+359)</label></li>
                                        <li className="Croatia" onClick={() => handleCountryClick('+385')}><label htmlFor="Croatia"><span>🇭🇷</span>Croatia (+385)</label></li>
                                        <li className="Cyprus" onClick={() => handleCountryClick('+357')}><label htmlFor="Cyprus"><span>🇨🇾</span>Cyprus (+357)</label></li>
                                        <li className="Czech" onClick={() => handleCountryClick('+420')}><label htmlFor="Czech"><span>🇨🇿</span>Czech Republic (+420)</label></li>
                                        <li className="Denmark" onClick={() => handleCountryClick('+45')}><label htmlFor="Denmark"><span>🇩🇰</span>Denmark (+45)</label></li>
                                        <li className="Estonia" onClick={() => handleCountryClick('+372')}><label htmlFor="Estonia"><span>🇪🇪</span>Estonia (+372)</label></li>
                                        <li className="Finland" onClick={() => handleCountryClick('+358')}><label htmlFor="Finland"><span>🇫🇮</span>Finland (+358)</label></li>
                                        <li className="France" onClick={() => handleCountryClick('+33')}><label htmlFor="France"><span>🇫🇷</span>France (+33)</label></li>
                                        <li className="Germany" onClick={() => handleCountryClick('+49')}><label htmlFor="Germany"><span>🇩🇪</span>Germany (+49)</label></li>
                                        <li className="Greece" onClick={() => handleCountryClick('+30')}><label htmlFor="Greece"><span>🇬🇷</span>Greece (+30)</label></li>
                                        <li className="Hungary" onClick={() => handleCountryClick('+36')}><label htmlFor="Hungary"><span>🇭🇺</span>Hungary (+36)</label></li>
                                        <li className="Iceland" onClick={() => handleCountryClick('+354')}><label htmlFor="Iceland"><span>🇮🇸</span>Iceland (+354)</label></li>
                                        <li className="Ireland" onClick={() => handleCountryClick('+353')}><label htmlFor="Ireland"><span>🇮🇪</span>Republic of Ireland (+353)</label></li>
                                        <li className="Italy" onClick={() => handleCountryClick('+39')}><label htmlFor="Italy"><span>🇮🇹</span>Italy (+39)</label></li>
                                        <li className="Latvia" onClick={() => handleCountryClick('+371')}><label htmlFor="Latvia"><span>🇱🇻</span>Latvia (+371)</label></li>
                                        <li className="Liechtenstein" onClick={() => handleCountryClick('+423')}><label htmlFor="Liechtenstein"><span>🇱🇮</span>Liechtenstein (+423)</label></li>
                                        <li className="Lithuania" onClick={() => handleCountryClick('+370')}><label htmlFor="Lithuania"><span>🇱🇹</span>Lithuania (+370)</label></li>
                                        <li className="Luxembourg" onClick={() => handleCountryClick('+352')}><label htmlFor="Luxembourg"><span>🇱🇺</span>Luxembourg (+352)</label></li>
                                        <li className="Malta" onClick={() => handleCountryClick('+356')}><label htmlFor="Malta"><span>🇲🇹</span>Malta (+356)</label></li>
                                        <li className="Netherlands" onClick={() => handleCountryClick('+31')}><label htmlFor="Netherlands"><span>🇳🇱</span>Netherlands (+31)</label></li>
                                        <li className="Norway" onClick={() => handleCountryClick('+47')}><label htmlFor="Norway"><span>🇳🇴</span>Norway (+47)</label></li>
                                        <li className="Poland" onClick={() => handleCountryClick('+48')}><label htmlFor="Poland"><span>🇵🇱</span>Poland (+48)</label></li>
                                        <li className="Portugal" onClick={() => handleCountryClick('+351')}><label htmlFor="Portugal"><span>🇵🇹</span>Portugal (+351)</label></li>
                                        <li className="Romania" onClick={() => handleCountryClick('+40')}><label htmlFor="Romania"><span>🇷🇴</span>Romania (+40)</label></li>
                                        <li className="Slovakia" onClick={() => handleCountryClick('+421')}><label htmlFor="Slovakia"><span>🇸🇰</span>Slovakia (+421)</label></li>
                                        <li className="Slovenia" onClick={() => handleCountryClick('+386')}><label htmlFor="Slovenia"><span>🇸🇮</span>Slovenia (+386)</label></li>
                                        <li className="Spain" onClick={() => handleCountryClick('+34')}><label htmlFor="Spain"><span>🇪🇸</span>Spain (+34)</label></li>
                                        <li className="Sweden" onClick={() => handleCountryClick('+46')}><label htmlFor="Sweden"><span>🇸🇪</span>Sweden (+46)</label></li>
                                    </ul>

                                </div>
                            </div>



                        </div>
                        <br />

                        {
                            isOptSent &&

                            <form id="reg-form" className="reg-form" style={{ alignSelf: "center" }}>
                                <div id="reg-title1" className="reg-title">OTP</div>
                                <p id="reg-message" className="reg-message">We have sent a verification code to your mobile number</p>
                                {
                                    isNotNumber && <p>(Note : Please enter numeric values.)</p>
                                }
                                <div id="reg-inputs" className="reg-inputs">
                                    {otpInputs.map((value, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            maxLength={1}
                                            value={value}
                                            ref={ref => inputRefs.current[index] = ref}
                                            onChange={e => handleInputChange(index, e.target.value)}
                                            onKeyDown={e => handleKeyDown(index, e)}
                                            inputMode="numeric"
                                        />
                                    ))}
                                </div>
                            </form>
                        }


                        {/* <div className="login-flex-column">
                            <label>Password </label>
                        </div> */}
                        {/* <div className="login-inputForm">
                            <svg height={20} viewBox="-64 0 512 512" width={20} xmlns="http://www.w3.org/2000/svg"><path d="m336 512h-288c-26.453125 0-48-21.523438-48-48v-224c0-26.476562 21.546875-48 48-48h288c26.453125 0 48 21.523438 48 48v224c0 26.476562-21.546875 48-48 48zm-288-288c-8.8125 0-16 7.167969-16 16v224c0 8.832031 7.1875 16 16 16h288c8.8125 0 16-7.167969 16-16v-224c0-8.832031-7.1875-16-16-16zm0 0" /><path d="m304 224c-8.832031 0-16-7.167969-16-16v-80c0-52.929688-43.070312-96-96-96s-96 43.070312-96 96v80c0 8.832031-7.167969 16-16 16s-16-7.167969-16-16v-80c0-70.59375 57.40625-128 128-128s128 57.40625 128 128v80c0 8.832031-7.167969 16-16 16zm0 0" /></svg>
                            <input  type={eyeIcon ? 'password' : 'text'} className="login-input" placeholder="Enter your Password" name="userPassword" onChange={inputHandle} onKeyPress={handleKeyPress} />


                            {
                                eyeIcon ?
                                <svg xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer",...handleEyeIcon()  }}  height="1.2em" viewBox="0 0 64 64" id="hide"  onClick={() => UpdateEyeIcon(false)}><path fill="#222" d="M44 45a2 2 0 0 0-.24-.26l-.7-.82-2.63-3-13.08-15a1.48 1.48 0 0 1-.24-.26l-2.78-3.18-2.63-3-1.35-1.58-.07-.09-.16-.18-8.2-9.41a2 2 0 1 0-3 2.62l6.68 7.66c-5.69 3.38-9.85 7.9-12.71 11-.34.37-.65.72-1 1l-.09.09-.09.11a1.09 1.09 0 0 0-.13.17 1.82 1.82 0 0 0-.16.31 2.78 2.78 0 0 0-.12.38 2 2 0 0 0 0 .8 2.78 2.78 0 0 0 .12.38 1.82 1.82 0 0 0 .16.31 1.09 1.09 0 0 0 .13.17l.09.11.09.09c.3.32.62.66 1 1C7.91 40 17.29 50.16 32 50.16a30.94 30.94 0 0 0 9.79-1.59l6.29 7.21a2 2 0 1 0 3-2.62Zm-12 3.27a16.25 16.25 0 0 1-13.07-25.93l2.71 3.11A12.25 12.25 0 0 0 32 44.27a12 12 0 0 0 5.07-1.12l1.83 2.1.89 1A16.27 16.27 0 0 1 32 48.27Zm13.58-7.43-.12.16a.6.6 0 0 0 .12-.16ZM40.65 29.1a2 2 0 0 0 .2-2.31 10.15 10.15 0 0 0-6.34-4.72 10.42 10.42 0 0 0-5.26 0l-1.43-1.64a11.94 11.94 0 0 1 4.18-.7 4 4 0 0 1 .49 0A12.26 12.26 0 0 1 44.27 32a11.51 11.51 0 0 1-.06 1.18 11.69 11.69 0 0 1-1.36 4.53L32.43 25.76a6.33 6.33 0 0 1 5 3.07 2 2 0 0 0 2.74.7 1.75 1.75 0 0 0 .48-.43ZM62.71 32a1.87 1.87 0 0 1 0 .4 2.78 2.78 0 0 1-.12.38 1.82 1.82 0 0 1-.16.31 1.09 1.09 0 0 1-.13.17l-.09.11-.09.09-.91 1a69.77 69.77 0 0 1-8.65 8.25A37.11 37.11 0 0 1 49.22 45l-2.67-3-.93-1.06A16 16 0 0 0 47.5 37a16.28 16.28 0 0 0-18.3-21 15.26 15.26 0 0 0-4.15 1.34l-1.84-2.12a25.3 25.3 0 0 1 4.41-1 29.9 29.9 0 0 1 4.38-.38c14.71 0 24.09 10.16 29.13 15.66.33.38.65.72.95 1a1.12 1.12 0 0 1 .18.2 1.09 1.09 0 0 1 .13.17v.05a2.1 2.1 0 0 1 .13.26 2.4 2.4 0 0 1 .12.38 1.81 1.81 0 0 1 .07.44Z" data-name="Hide"></path></svg>
                                    :
                                   <svg viewBox="0 0 576 512" style={{ cursor: "pointer",...handleEyeIcon() }} height="1em" xmlns="http://www.w3.org/2000/svg"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" onClick={() => UpdateEyeIcon(true)} /></svg>

                            }



                        </div> */}
                        {/* <div className="login-flex-row">
                            <NavLink className="login-span" to="/forgotpassword"><span >Forgot password?</span></NavLink>
                        </div> */}


                        {
                            isOptSent ?
                                <>

                                    <div style={{ alignSelf: "center" }}>
                                        <button id="resend-button" className="reg-action" onClick={() => resendOtp()}>Resend OTP</button>
                                        <button id="verify-button" className="reg-action" onClick={() => verifySigninOtp()}>Verify me</button>
                                    </div>

                                </>
                                :
                                <button className="form--submit" onClick={() => clickSubmitcheck()} disabled={
                                    dynamicInput.userPhone.length < 10
                                } style={{ alignSelf: "center" }}> {isSubmitClicked ? 'Submitting...' : 'Submit'}
                                </button>
                        }


                        {/* <button className="login-button-submit" type="submit" ref={formRef} onClick={() => clickSubmitcheck()} disabled={
                            dynamicInput.userPhone.length < 10
                        } >Sign In</button> */}
                        <p className="login-p">Don't have an account?
                            <NavLink className="login-span" to="/pricing"><span >Sign Up</span></NavLink>
                        </p>



                    </div>


                    <span style={{ height: "10%" }}></span>




                </div>



                <FooterComponent></FooterComponent>

            </div>





        </div >

    );
};

export default SigninPage;