import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useRef } from "react";
import Swal from "sweetalert2";
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import DataSharingContext from "../context/data-sharing-context";
import SecondDataSharing from "../context/second-data-sharing";
import SignInComponent from "../components/signin-pop";
import EditComponent from "../components/EditComponent";
import UserEditComponent from "../components/UserEditComponent";

import ReactPaginate from "react-paginate";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { } from "@fortawesome/free-regular-svg-icons";
import { } from "@fortawesome/fontawesome-free";
import {
  faArrowRight, faArrowLeft,
  faSearch,
  faTrashCan,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons"; // Replace with the correct FontAwesome icon import

import "bootstrap/dist/css/bootstrap.min.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { isDate } from "moment";

import ScrollToTop from "react-scroll-to-top";
import HomeSideRail from "../AdsFolder/HomeSideRail";
import HomeBannerAd from "../AdsFolder/HomeBannerAd";


import DashboardHeader from "../components/DashboardHeader";
import Top20px from "../screens/Top20px";

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import InviteFriends from "../components/InviteFriends";
import { Badge } from '@mui/base/Badge';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import Button from '@mui/material/Button';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


import Grid from '@mui/material/Grid';
import TooltipMui from '@mui/material/Tooltip';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import PremiumPurchaseAlert from "./utils/PremiumPurchaseAlert";
import HomamEventPhone from "../components/HomamEventPhone";
import DashboardEvents from "../components/DashboardEvents";



const HomeScreen = () => {

  const Navigate = useNavigate();






  //   setTimeout(() => {
  //     setShowModal(true);
  // sessionStorage.setItem('hasSeenModal', 'true');
  //   }, 5000);

  const auth = localStorage.getItem("isAuthenticated");

  useEffect(() => {

    window.scrollTo(0, 0); // Scroll to the top when component mounts


    const auth = localStorage.getItem("homeauth");
    if (auth) {
      setSignOut(true);
    }
    else {
      Swal.fire({
        title: 'Authentication Revoked.',
        text: 'Please login again.',
        icon: 'warning',
        confirmButtonText: 'Got it!'
      });
      // Navigate("/")
      signOut();
    }
  }, []);

  // useEffect(() => {
  //     const auth = localStorage.getItem("isAuthenticated");
  //     if (!auth) {
  //         // Swal.fire({
  //         //     title: '',
  //         //     text: 'Please login again.',
  //         //     icon: 'warning',
  //         //     confirmButtonText: 'Got it!'
  //         // });
  //         Navigate("/");
  //     }
  // }, []);



  useEffect(() => {
    const sentUserEmail = localStorage.getItem("fkey");
    const localEmail = atob(sentUserEmail).replace(/"/g, "").trim();

    const localId = localStorage.getItem("SD_Id");

    console.log(localId);

    if (localId !== undefined || localId !== null) {
      const url = `https://backend.shubadinam.com/loginLocalId?localId=${localId}`;
      axios
        .get(url)
        .then((response) => {



          const loggingUser = response.data.data2;
          const loggingRelation = response.data.data1;

          if (response.data.success === "Success") {
            // console.log(loggingUser);
            // console.log(loggingRelation);
            localStorage.setItem("myDataKey", JSON.stringify(loggingRelation));
            localStorage.setItem("myDataKey2", JSON.stringify(loggingUser));

            // window.location.reload();
            const storedData = localStorage.getItem("myDataKey");
            const storedDataTwo = localStorage.getItem("myDataKey2");

            setData(JSON.parse(storedData));
            setDataTwo(JSON.parse(storedDataTwo));

            // console.log(response.data);
          } else {
            // console.log(response.data);
            // alert("please login again,,,,,,,,,,,,,!")
            localStorage.clear();
            Navigate("/")
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          // console.log(error.response.data);
          if (error.response.status === 401) {
            Swal.fire({
              title: 'Warning',
              text: 'Something went wrong ! Please login again.',
              icon: 'Warning',
              confirmButtonText: 'OK'
            })
            localStorage.clear();
            sessionStorage.clear();
            Navigate("/")
          }

          else if (error.message === "Network Error") {
            // const msg1 = error.response.data.message
            Swal.fire({
              title: "Under Maintanance",
              text: "Please login after sometime!",
              icon: "warning",
              confirmButtonText: "Ok",
            });
          }
        });
    }
    else if (!sentUserEmail || !auth || !localId) {
      localStorage.clear();

      Swal.fire({
        title: "",
        text: "New to Shubadinam.com! Please Sign up or Log in.",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      Navigate("/");
    }
    else {
      localStorage.clear();

      Swal.fire({
        title: "",
        text: "New to Shubadinam.com! Please Sign up or Log in.",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      Navigate("/");
    }
  }, []);

  const [search, setSearch] = useState("");

  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [sortOption, setSortOption] = useState(null);

  const [dataTwo, setDataTwo] = useState(null);

  const tableData = useContext(DataSharingContext);

  const tableTwoData = useContext(SecondDataSharing);
  // console.log(tableTwoData);

  useEffect(() => {
    const storedData = localStorage.getItem("myDataKey");
    if (storedData) {
      setData(JSON.parse(storedData));
    }
  }, []);

  useEffect(() => {
    const storedDataTwo = localStorage.getItem("myDataKey2");
    if (storedDataTwo) {
      setDataTwo(JSON.parse(storedDataTwo));
    }
  }, []);

  const deleteUserDetails = (object) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to Delete this profile?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete it!",
      confirmButtonColor: "#060856",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed the deletion
        const url =
          "https://backend.shubadinam.com/delete/userdetails/" + object.profileid;

        axios
          .delete(url)
          .then((response) => {
            const result = response.data;
            // Swal.fire('Deleted!', result.message, 'success').then(()=>{
            window.location.reload();
            // })
          })
          .catch((error) => {
            // console.log(error);
            // Swal.fire('Error', 'An error occurred while deleting.', 'error');
          });
      }
    });
  };


  const signOut = () => {
    setSignOut(false);

    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("homeauth")
    localStorage.removeItem("fkey");
    // Navigate("/");
  };

  const logoBtn = () => {
    // Navigate("/");
  };

  // MENU BAR

  const [isOpenMenu, setOpenMenu] = useState(false);

  // edit seciton
  const [isEdit, SetOpenEdit] = useState(false);
  const [isUserEdit, SetOpenUserEdit] = useState(false);

  const [selectedObject, setSelectedObject] = useState(null);
  const [selectedseUserObject, setSelectedUserObject] = useState(null);

  const clickEdit = (object) => {
    // if (object.userRelation === "Myself") {
    //     alert("lfhdhfdlfhlh")
    //     SetOpenUserEdit(true)
    //     SetOpenEdit(false);
    //     setSelectedUserObject(object);
    // } else {
    SetOpenEdit(true);
    SetOpenUserEdit(false);
    setSelectedObject(object);
    // }
  };

  const clickEditUser = (object) => {
    // console.log(object);

    if (object.userRelation == "Myself") {
      SetOpenUserEdit(true);
      SetOpenEdit(false);
      setSelectedUserObject(object);
    }
  };

  const onSave = (editedData) => {
    // console.log(editedData);

    const url = "https://backend.shubadinam.com/edit/reldetails/" + editedData.profileid;

    axios
      .put(url, editedData)
      .then((response) => {
        const result = response.data.message;
        // window.location.reload()

        Swal.fire({
          title: "Edit Profile",
          text: result,
          icon: "success",
          confirmButtonText: "Ok",
        })
          .then(() => {
            window.location.reload();
            SetOpenEdit(false);
          })
          .catch(() => {
            window.location.reload();
          });
        // alert(result)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSaveUser = (editedData) => {
    // console.log(editedData);

    const url =
      "https://backend.shubadinam.com/edit/userdetails/" + editedData.firstuserid;

    axios
      .put(url, editedData)
      .then((response) => {
        const result = response.data.message;
        // window.location.reload()

        Swal.fire({
          title: "Edit Profile",
          text: result,
          icon: "success",
          confirmButtonText: "Ok",
        })
          .then(() => {
            window.location.reload();
            SetOpenUserEdit(false);
          })
          .catch(() => {
            window.location.reload();
          });
        // alert(result)
      })
      .catch((error) => {
        console.log(error);
      });

    SetOpenUserEdit(false);
  };

  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 8; // Number of rows per page
  const tbodyRef = useRef(); // Create a ref for the tbody element

  // const handlePageChange = (selectedPage) => {
  //   setCurrentPage(selectedPage.selected);

  //   if (tbodyRef.current) {
  //     const tableBodyTop = tbodyRef.current.offsetTop;

  //     // Scroll to the top of the tbody
  //     window.scrollTo({
  //       top: tableBodyTop,
  //       behavior: "smooth", // You can use 'auto' for instant scrolling
  //     });
  //   }
  // };

  // const paginatedData = data
  //   ? data.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage)
  //   : [];




  // useEffect(() => {
  //   // Check if data is not null before filtering
  //   if (data) {
  //     // Filter data based on search term
  //     const filtered = data.filter(item => {
  //       const searchableFields = ['relName', 'relGender', 'relRelation', 'relCalender', 'relDate', 'relMonth', 'relNakshathra', 'relPaksham', 'relTithi']; // Fields to search
  //       return searchableFields.some(field =>
  //         item[field]?.toString().toLowerCase().includes(search.toLowerCase())
  //       );

  //     });
  //     setFilteredData(filtered);
  //   }


  // }, [search, data]);


  useEffect(() => {
    // Check if data is not null before filtering
    if (data) {
      // Filter data based on search term
      const filtered = data.filter(item => {
        const searchableFields = ['relName', 'relGender', 'relRelation', 'relCalender', 'relDate', 'relMonth', 'relNakshathra', 'relPaksham', 'relTithi']; // Fields to search
        return searchableFields.some(field =>
          item[field]?.toString().toLowerCase().includes(search.toLowerCase())
        );
      });

      if (sortOption === 'A-Z') {
        filtered.sort((a, b) => a.relName.localeCompare(b.relName));
      }
      else if (sortOption === 'Date') {
        filtered.sort((a, b) => {
          // Convert strings to Date objects for comparison
          const dateA = a.relDate;
          const dateB = b.relDate;

          // Compare dates
          if (dateA < dateB) {
            return -1; // dateA comes before dateB
          } else if (dateA > dateB) {
            return 1; // dateA comes after dateB
          } else {
            return 0; // dates are equal
          }
        })
      }

      setFilteredData(filtered);
    }
  }, [search, data, sortOption]);


  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);

    if (tbodyRef.current) {
      const tableBodyTop = tbodyRef.current.offsetTop;

      // Scroll to the top of the tbody
      window.scrollTo({
        top: tableBodyTop,
        behavior: "smooth", // You can use 'auto' for instant scrolling
      });
    }
  };

  const handleSortOptionChange = (option) => {
    setSortOption(option);
  };

  const offset = currentPage * rowsPerPage;
  const currentData = filteredData.slice(offset, offset + rowsPerPage);

  const [dashsignOut, setSignOut] = useState(false);


  const [expandedRows, setExpandedRows] = useState([]);

  // Toggle the row's visibility
  const toggleRow = (id) => {
    // console.log(id);
    if (id && id.firstuserid) {
      setExpandedRows((prevExpandedRows) =>
        prevExpandedRows.includes(id.firstuserid)
          ? prevExpandedRows.filter((rowId) => rowId !== id.firstuserid)
          : [...prevExpandedRows, id.firstuserid]
      );
    }
  };

  const [expandedRows2, setExpandedRows2] = useState([]);

  const toggleRow2 = (id) => {
    // console.log(id);
    if (id && id.profileid) {
      setExpandedRows2((prevExpandedRows) =>
        prevExpandedRows.includes(id.profileid)
          ? prevExpandedRows.filter((rowId) => rowId !== id.profileid)
          : [...prevExpandedRows, id.profileid]
      );
    }
  };



  const [greater1200, setGreater1200] = useState(false);
  const [greater768, setGreater768] = useState(false);
  const [lesser768, setlesser768] = useState(false);
  const [isNonPremium, setisNonPremium] = useState(false);


  useEffect(() => {

    const checkPremium = () => {
      const isNotPremium = sessionStorage.getItem("nonpre");
      if (isNotPremium) {
        setisNonPremium(true)
      }
    };

    const handleResize = () => {
      setGreater1200(window.innerWidth > 1300);
      setGreater768(window.innerWidth > 768);
      setlesser768(window.innerWidth < 768);
    };

    // Initial setup
    handleResize();
    checkPremium();

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const [loading, setLoading] = useState(true);


  useEffect(() => {
    // Simulate an asynchronous operation
    const timer1 = setTimeout(() => {
      // Update loading state when the first operation is complete
      setLoading(prevLoading => !prevLoading);
    }, 2000);

    // Cleanup function to clear the timer
    return () => clearTimeout(timer1);
  }, []);




  const [age, setAge] = useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };


  const gotoPricing = () => {
    Navigate("/pricing");
  };

  const [activeButton, setActiveButton] = useState(1); // State to track active button

  const handleButtonClick = (page) => {
    Navigate(`/${page}`);
  };

  return (



    <div className="overall-container-home">

      <PremiumPurchaseAlert />

      <HelmetProvider>
        <Helmet>
          <title>Dashboard</title>
          <meta name="description" content="Dashboard - Janma Nakshatra/Tithi Birthday Reminder Service." />
        </Helmet>
      </HelmetProvider>
      {/* {loading && <div className="loader"></div>} */}

      <div className="overall-percent-home">
        <ScrollToTop smooth />
        {/* <HeaderComponent></HeaderComponent> */}
        {/* <Top20px /> */}

        {/* <DashboardHeader /> */}

        {/* <div className="container-header-home"> */}
        <DashboardHeader />
        <Top20px />
        {/* </div> */}
        {/* <span style={{ height: "200px" }}></span> */}

        <Box sx={{ width: "100%", textAlign: "end", p: { xs: 2, lg: 5 } }}>
          <NavLink to="/homam-bookings">My Bookings</NavLink>
        </Box>


        <div className="double-container-banner-home">
          <DashboardEvents />


          {/* <video
            className="videoPlayer"
            autoPlay
            loop
            muted
            onContextMenu={(e) => e.preventDefault()}
          >
            <source
              src={require("../videos/intro-shub.mp4")}
              type="video/mp4"
            />
          </video> */}
        </div>
        <div className="home-container">

          <div className="table-float">
            <div className="home-cnt-body">
              <div className="seasrch-bar">
                <input
                  className="search-bar"
                  type="search"
                  placeholder="🔍 Search anything from the table..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />


                <FormControl sx={{ m: 1, minWidth: 80 }} title="Filter">
                  <InputLabel id="demo-simple-select-autowidth-label">
                    <FilterAltIcon />
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={age}
                    onChange={handleChange}
                    autoWidth
                    label="Age"
                  >
                    <MenuItem onClick={() => handleSortOptionChange('A-Z')} value={10}>Sort by A-Z</MenuItem>
                    <MenuItem onClick={() => handleSortOptionChange('Date')} value={21}>Sort by Date</MenuItem>
                  </Select>
                </FormControl>



                {greater768 && isNonPremium && (
                  <Button
                    className={`date-btn ${activeButton === 2 ? 'date-btn-active' : 'date-btn-inactive'}`}
                    onClick={() => handleButtonClick("premium-checkout")}
                  >
                    👑
                    Get Premium
                  </Button>
                )}


                {/* Invite Friends */}
                {/* <InviteFriends /> */}





              </div>

              {
                lesser768 && isNonPremium && (

                  <div style={{
                    order: "2", marginBottom: "10px",
                    display: "flex",
                    justifyContent: "center"
                  }}>
                    <Button
                      className={`date-btn ${activeButton === 2 ? 'date-btn-active' : 'date-btn-inactive'}`}
                      onClick={() => handleButtonClick("premium-checkout")}
                    >
                      👑
                      Get Premium
                    </Button>
                  </div>
                )
              }

              <div className="table-add">
                <label
                  style={{ marginRight: "30px", whiteSpace: "nowrap" }}
                  className="letters-color table-add-text"
                >
                  Click to add your beloved ones
                </label>
                <SignInComponent></SignInComponent>
              </div>
              <div className="table-container">

                {greater1200 ?

                  <table id="customers" className="table-header">
                    <thead className="table-header">
                      <tr>
                        <th className="centered-cell" >NAME</th>
                        <th className="centered-cell">RELATION</th>
                        <th className="centered-cell">PANCHANG</th>
                        <th className="centered-cell">MONTH</th>
                        <th className="centered-cell">
                          JANMA NAKSHATRA / JANMA TITHI
                        </th>
                        <th className="centered-cell">JANMA DINAM</th>
                        <th className="centered-cell">ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody className="table-body" ref={tbodyRef}>
                      {
                        dataTwo &&
                        dataTwo
                          .filter(
                            (object) =>
                              (object.userName &&
                                object.userName
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.userCalender &&
                                object.userCalender
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.userRelation &&
                                object.userRelation
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.userMonth &&
                                object.userMonth
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.nakshatratithi &&
                                object.nakshatratithi
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.userPaksham &&
                                object.userPaksham
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.userTithi &&
                                object.userTithi
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.userNakshatra &&
                                object.userNakshatra
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.userDate &&
                                object.userDate
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.relName &&
                                object.relName
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.relRelation &&
                                object.relRelation
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.relCalender &&
                                object.relCalender
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.relMonth &&
                                object.relMonth
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.relPaksham &&
                                object.relPaksham
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.relTithi &&
                                object.relTithi
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.relNakshathra &&
                                object.relNakshathra
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.relDate &&
                                object.relDate
                                  .toLowerCase()
                                  .includes(search.toLowerCase()))
                          )

                          .map((object, index) => (
                            <React.Fragment key={index}>
                              <tr className="all-row-size">
                                <td className="centered-cell" >
                                  <TooltipMui title={object.userName} placement="top">
                                    {object.userName}
                                  </TooltipMui>

                                </td>
                                <td className="centered-cell">
                                  {object.userRelation}
                                </td>
                                <td className="centered-cell">
                                  {object.userCalender}
                                </td>
                                <td className="month-table">
                                  {object.userMonth}
                                </td>
                                <td className="centered-cell">
                                  <span className="paksham-table">
                                    {object.userPaksham}
                                  </span>
                                  <br />
                                  <span className="tithi-table">
                                    {object.userTithi}
                                  </span>
                                  <span className="nakshatra-table">
                                    {object.userNakshathra}
                                  </span>
                                </td>
                                <td
                                  className="centered-cell dateSize"
                                  id="unique-cell"
                                >
                                  <span style={{}}>{object.userDate}</span>
                                </td>
                                <td className="centered-cell-actions">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip
                                        id="tooltip-id"
                                        className="custom-tooltip"
                                      >
                                        Edit
                                      </Tooltip>
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className="edit-icon"
                                      icon={faPenToSquare}
                                      onClick={() => clickEditUser(object)}
                                    />
                                  </OverlayTrigger>


                                </td>
                              </tr>
                            </React.Fragment>
                          ))
                      }

                      {

                        currentData.map((object, index) => (
                          //   .filter(
                          //     (object) =>
                          //       (object.userName &&
                          //         object.userName
                          //           .toLowerCase()
                          //           .includes(search.toLowerCase())) ||
                          //       (object.userCalender &&
                          //         object.userCalender
                          //           .toLowerCase()
                          //           .includes(search.toLowerCase())) ||
                          //       (object.userRelation &&
                          //         object.userRelation
                          //           .toLowerCase()
                          //           .includes(search.toLowerCase())) ||
                          //       (object.userMasam &&
                          //         object.userMasam
                          //           .toLowerCase()
                          //           .includes(search.toLowerCase())) ||
                          //       (object.userDate &&
                          //         object.userDate
                          //           .toLowerCase()
                          //           .includes(search.toLowerCase())) ||
                          //       (object.userNakshatra &&
                          //         object.userNakshatra
                          //           .toLowerCase()
                          //           .includes(search.toLowerCase())) ||
                          //       (object.relName &&
                          //         object.relName
                          //           .toLowerCase()
                          //           .includes(search.toLowerCase())) ||
                          //       (object.relRelation &&
                          //         object.relRelation
                          //           .toLowerCase()
                          //           .includes(search.toLowerCase())) ||
                          //       (object.relCalender &&
                          //         object.relCalender
                          //           .toLowerCase()
                          //           .includes(search.toLowerCase())) ||
                          //       (object.relMonth &&
                          //         object.relMonth
                          //           .toLowerCase()
                          //           .includes(search.toLowerCase())) ||
                          //       (object.relDate &&
                          //         object.relDate
                          //           .toLowerCase()
                          //           .includes(search.toLowerCase())) ||
                          //       (object.relNakshathra &&
                          //         object.relNakshathra
                          //           .toLowerCase()
                          //           .includes(search.toLowerCase()))
                          //   )

                          //   .map((object, index) => (
                          <React.Fragment key={index}>
                            <tr className="all-row-size">
                              <td className="centered-cell" >
                                <TooltipMui title={object.relName} placement="top">
                                  {object.relName}
                                </TooltipMui>
                              </td>
                              <td className="centered-cell">
                                {object.relRelation}
                              </td>
                              <td className="centered-cell">
                                {object.relCalender}
                              </td>
                              <td className="month-table">{object.relMonth}</td>
                              <td className="centered-cell">
                                <span className="paksham-table">
                                  {object.relPaksham}
                                </span>
                                <br />
                                <span className="tithi-table">
                                  {object.relTithi}
                                </span>
                                <span className="nakshatra-table">
                                  {object.relNakshathra}
                                </span>
                              </td>
                              <td
                                className="centered-cell dateSize"
                                id="unique-cell"
                              >
                                <span style={{}}>{object.relDate}</span>
                              </td>
                              <td className="centered-cell-actions">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip
                                      id="tooltip-id"
                                      className="custom-tooltip"
                                    >
                                      Edit
                                    </Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    className="edit-icon"
                                    icon={faPenToSquare}
                                    onClick={() => clickEdit(object)}
                                  />
                                </OverlayTrigger>


                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip
                                      id="tooltip-id"
                                      className="custom-tooltip"
                                    >
                                      Delete
                                    </Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    className="edit-icon"
                                    icon={faTrashCan}
                                    onClick={() => deleteUserDetails(object)}
                                  />
                                </OverlayTrigger>

                              </td>
                            </tr>
                          </React.Fragment>
                        ))
                      }
                    </tbody>
                  </table>

                  :

                  <table id="customers" className="table-header">
                    <thead className="table-header">
                      <tr colSpan={2}>

                        <th className="centered-cell" colSpan={2}>NAME</th>
                        <th className="centered-cell" colSpan={3}>
                          JANMA NAKSHATRA / JANMA TITHI
                        </th>
                        <th className="centered-cell" colSpan={3}>JANMA DINAM</th>
                        <th colSpan={1}></th>
                      </tr>
                    </thead>
                    <tbody className="table-body" ref={tbodyRef}>
                      {
                        dataTwo &&
                        dataTwo
                          .filter(
                            (object) =>
                              (object.userName &&
                                object.userName
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.userCalender &&
                                object.userCalender
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.userRelation &&
                                object.userRelation
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.userMonth &&
                                object.userMonth
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.nakshatratithi &&
                                object.nakshatratithi
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.userPaksham &&
                                object.userPaksham
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.userTithi &&
                                object.userTithi
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.userNakshatra &&
                                object.userNakshatra
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.userDate &&
                                object.userDate
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.relName &&
                                object.relName
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.relRelation &&
                                object.relRelation
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.relCalender &&
                                object.relCalender
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.relMonth &&
                                object.relMonth
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.relPaksham &&
                                object.relPaksham
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.relTithi &&
                                object.relTithi
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.relNakshathra &&
                                object.relNakshathra
                                  .toLowerCase()
                                  .includes(search.toLowerCase())) ||
                              (object.relDate &&
                                object.relDate
                                  .toLowerCase()
                                  .includes(search.toLowerCase()))
                          )

                          .map((object, index) => (
                            <React.Fragment key={index}>
                              <tr className="all-row-size" onClick={() => toggleRow(object)} >


                                <td className="centered-cell" colSpan={2}>
                                  <TooltipMui title={object.userName} placement="top">
                                    {object.userName}
                                  </TooltipMui>

                                </td>
                                <td className="centered-cell" colSpan={3}>
                                  <span className="paksham-table">
                                    {object.userPaksham}
                                  </span>
                                  <br />
                                  <span className="tithi-table">
                                    {object.userTithi}
                                  </span>
                                  <span className="nakshatra-table">
                                    {object.userNakshathra}
                                  </span>
                                </td>
                                <td
                                  className="centered-cell dateSize"
                                  id="unique-cell"
                                  colSpan={3}
                                >

                                  <span style={{}}>{object.userDate}</span>
                                </td>

                                <td style={{ backgroundColor: "white", textAlign: "center" }}>
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    style={{ border: "none" }}
                                  >
                                    {expandedRows.includes(object.firstuserid) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                  </IconButton>
                                </td>
                              </tr>


                              {expandedRows.includes(object.firstuserid) && (

                                <tr className="open-transition">

                                  <td id="home-additional" colSpan={7} style={{ borderRight: "none", background: "linear-graderien" }}>
                                    <p style={{ marginTop: "10px", textIndent: "20px", }}>Additional Details :</p>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                      <div style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                        <p >Panchang:</p>
                                        <p>Relation:</p>
                                        <p>Month:</p>
                                        <p>Actions:</p>
                                      </div>
                                      <div style={{ width: "50%" }}>
                                        <p>{object.userCalender}</p>
                                        <p>{object.userRelation}</p>
                                        <p>{object.userMonth}</p>
                                        <p>
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip
                                                id="tooltip-id"
                                                className="custom-tooltip"
                                              >
                                                Edit
                                              </Tooltip>
                                            }
                                          >
                                            <FontAwesomeIcon
                                              className="edit-icon"
                                              icon={faPenToSquare}
                                              onClick={() => clickEditUser(object)}
                                            />
                                          </OverlayTrigger>
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td style={{ backgroundColor: "white", textAlign: "center", borderLeft: "none" }}>

                                  </td>
                                </tr>
                              )}

                            </React.Fragment>
                          ))
                      }

                      {/* {
                            paginatedData
                              .filter(
                                (object) =>
                                  (object.userName &&
                                    object.userName
                                      .toLowerCase()
                                      .includes(search.toLowerCase())) ||
                                  (object.userCalender &&
                                    object.userCalender
                                      .toLowerCase()
                                      .includes(search.toLowerCase())) ||
                                  (object.userRelation &&
                                    object.userRelation
                                      .toLowerCase()
                                      .includes(search.toLowerCase())) ||
                                  (object.userMasam &&
                                    object.userMasam
                                      .toLowerCase()
                                      .includes(search.toLowerCase())) ||
                                  (object.userDate &&
                                    object.userDate
                                      .toLowerCase()
                                      .includes(search.toLowerCase())) ||
                                  (object.userNakshatra &&
                                    object.userNakshatra
                                      .toLowerCase()
                                      .includes(search.toLowerCase())) ||
                                  (object.relName &&
                                    object.relName
                                      .toLowerCase()
                                      .includes(search.toLowerCase())) ||
                                  (object.relRelation &&
                                    object.relRelation
                                      .toLowerCase()
                                      .includes(search.toLowerCase())) ||
                                  (object.relCalender &&
                                    object.relCalender
                                      .toLowerCase()
                                      .includes(search.toLowerCase())) ||
                                  (object.relMonth &&
                                    object.relMonth
                                      .toLowerCase()
                                      .includes(search.toLowerCase())) ||
                                  (object.relDate &&
                                    object.relDate
                                      .toLowerCase()
                                      .includes(search.toLowerCase())) ||
                                  (object.relNakshathra &&
                                    object.relNakshathra
                                      .toLowerCase()
                                      .includes(search.toLowerCase()))
                              )

                              .map((object, index) => (
                                <React.Fragment key={index}>
                                  <tr className="all-row-size" onClick={() => { toggleRow2(object); console.log(object); }}>
                                    <td className="centered-cell">
                                      {object.relName}
                                    </td>
                                    <td className="centered-cell" colSpan={3}>
                                      <span className="paksham-table">
                                        {object.relPaksham}
                                      </span>
                                      <br />
                                      <span className="tithi-table">
                                        {object.relTithi}
                                      </span>
                                      <span className="nakshatra-table">
                                        {object.relNakshathra}
                                      </span>
                                    </td>
                                    <td
                                      className="centered-cell dateSize"
                                      id="unique-cell"
                                      colSpan={3}
                                    >
                                      <span style={{}}>{object.relDate}</span>
                                    </td>

                                    <td style={{ backgroundColor: "white", textAlign: "center" }}>
                                      <IconButton
                                        aria-label="expand row"
                                        size="small"
                                      >
                                        {expandedRows2.includes(object.profileid) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                      </IconButton>
                                    </td>
                                  </tr>
                                  {expandedRows2.includes(object.profileid) && (

                                    <tr>

                                      <td id="home-additional" colSpan={7} style={{ borderRight: "none" }}>
                                      <p style={{marginTop:"10px"}}>Additional Details :</p>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                          <div style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <p>Panchang:</p>
                                            <p>Relation:</p>
                                            <p>Month:</p>
                                            <p>Actions:</p>
                                          </div>
                                          <div style={{ width: "50%" }}>
                                            <p>{object.relCalender}</p>
                                            <p>{object.relRelation}</p>
                                            <p>{object.relMonth}</p>
                                            <p>
                                              <OverlayTrigger

                                                placement="top"
                                                overlay={
                                                  <Tooltip
                                                    id="tooltip-id"
                                                    className="custom-tooltip"
                                                  >
                                                    Edit
                                                  </Tooltip>
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  className="edit-icon"
                                                  icon={faPenToSquare}
                                                  onClick={() => { clickEdit(object); console.log(object); }}
                                                />
                                              </OverlayTrigger>

                                              <label style={{ width: '10px' }} />

                                              <OverlayTrigger

                                                placement="top"
                                                overlay={
                                                  <Tooltip
                                                    id="tooltip-id"
                                                    className="custom-tooltip"
                                                  >
                                                    Delete
                                                  </Tooltip>
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  className="edit-icon"
                                                  icon={faTrashCan}
                                                  onClick={() => deleteUserDetails(object)}
                                                />
                                              </OverlayTrigger>
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                      <td style={{ backgroundColor: "white", textAlign: "center",borderLeft:"none" }}>

                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              ))
                          } */}



                      {currentData.map((object, index) => (
                        <React.Fragment key={index}>
                          <tr className="all-row-size" onClick={() => {
                            toggleRow2(object);
                            // console.log(object);
                          }}>
                            <td className="centered-cell" colSpan={2}>
                              <TooltipMui title={object.relName} placement="top">
                                {object.relName}
                              </TooltipMui>

                            </td>
                            <td className="centered-cell" colSpan={3}>
                              <span className="paksham-table">
                                {object.relPaksham}
                              </span>
                              <br />
                              <span className="tithi-table">
                                {object.relTithi}
                              </span>
                              <span className="nakshatra-table">
                                {object.relNakshathra}
                              </span>
                            </td>
                            <td
                              className="centered-cell dateSize"
                              id="unique-cell"
                              colSpan={3}
                            >
                              <span style={{}}>{object.relDate}</span>
                            </td>

                            <td style={{ backgroundColor: "white", textAlign: "center" }}>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                style={{ border: "none" }}
                              >
                                {expandedRows2.includes(object.profileid) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                              </IconButton>
                            </td>
                          </tr>
                          {expandedRows2.includes(object.profileid) && (

                            <tr className="additional-row">

                              <td id="home-additional" colSpan={7} style={{ borderRight: "none" }}>
                                <p style={{ marginTop: "10px", textIndent: "20px", }}>Additional Details :</p>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                  <div style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <p>Panchang:</p>
                                    <p>Relation:</p>
                                    <p>Month:</p>
                                    <p>Actions:</p>
                                  </div>
                                  <div style={{ width: "50%" }}>
                                    <p>{object.relCalender}</p>
                                    <p>{object.relRelation}</p>
                                    <p>{object.relMonth}</p>
                                    <p>
                                      <OverlayTrigger

                                        placement="top"
                                        overlay={
                                          <Tooltip
                                            id="tooltip-id"
                                            className="custom-tooltip"
                                          >
                                            Edit
                                          </Tooltip>
                                        }
                                      >
                                        <FontAwesomeIcon
                                          className="edit-icon"
                                          icon={faPenToSquare}
                                          onClick={() => {
                                            clickEdit(object);
                                            //  console.log(object);
                                          }}
                                        />
                                      </OverlayTrigger>

                                      <label style={{ width: '10px' }} />

                                      <OverlayTrigger

                                        placement="top"
                                        overlay={
                                          <Tooltip
                                            id="tooltip-id"
                                            className="custom-tooltip"
                                          >
                                            Delete
                                          </Tooltip>
                                        }
                                      >
                                        <FontAwesomeIcon
                                          className="edit-icon"
                                          icon={faTrashCan}
                                          onClick={() => deleteUserDetails(object)}
                                        />
                                      </OverlayTrigger>
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td style={{ backgroundColor: "white", textAlign: "center", borderLeft: "none" }}>

                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}



                    </tbody>
                  </table>

                }

              </div>

              {isEdit && (
                <div className="popup-edit">
                  <div className="popup-content-edit">
                    <EditComponent
                      object={selectedObject}
                      onSave={onSave}
                      onClose={() => SetOpenEdit(false)}
                    />
                  </div>
                </div>
              )}

              {isUserEdit && (
                <div className="popup-edit">
                  <div className="popup-content-edit">
                    <UserEditComponent
                      object={selectedseUserObject}
                      onSaveUser={onSaveUser}
                      onClose={() => SetOpenUserEdit(false)}
                    />
                  </div>
                </div>
              )}

              <div className="pagination-container">
                {/* <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(filteredData.length / rowsPerPage)} // total number of pages
                    page={currentPage} // current page
                    onChange={handlePageChange} // function to handle page change
                  />
                </Stack> */}
                <ReactPaginate
                  previousLabel={<ArrowBackIosNewSharpIcon />}
                  nextLabel={<ArrowForwardIosSharpIcon />}
                  breakLabel={"..."}
                  pageCount={Math.ceil(filteredData.length / rowsPerPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination"}
                  // subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>

              <div className="table-add">
                <label
                  style={{ marginRight: "30px", whiteSpace: "nowrap" }}
                  className="letters-color table-add-text"
                >
                  Click to add your beloved ones
                </label>
                <SignInComponent></SignInComponent>
              </div>
            </div>
          </div>
          {/* <br /> */}
          {/* <div className="ads">
            <HomeSideRail data-ad-slot="2931871398" />
          </div> */}
        </div>
        <br />
        {/* <div className="home-banner-ads">
          <HomeBannerAd data-ad-slot="7861049851" />
        </div> */}
        <br />

        {/* <form><script src="https://checkout.razorpay.com/v1/payment-button.js" data-payment_button_id="pl_NRxcEtbKhNmvnE" async> </script> </form> */}
        {/* <div className="empty-container-footer-home"> */}
        <FooterComponent></FooterComponent>
        {/* </div> */}
      </div>

    </div>


  );
};

export default HomeScreen;
