import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';


const DashboardEvents = () => {

    const navigate = useNavigate();

    const [swiperRef, setSwiperRef] = useState(null);

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 576);
    const [paginationType, setPaginationType] = useState(window.innerWidth <= 576 ? 'bullets' : 'bullets');


    useEffect(() => {
        const handleResize = () => {
            const smallScreen = window.innerWidth <= 576;
            setIsSmallScreen(smallScreen);
            setPaginationType(smallScreen ? 'bullets' : 'fraction');
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleRouting = (page) => {
        navigate(`/${page}`)
    }



    return (
        <>
            <Swiper
                onSwiper={setSwiperRef}
                slidesPerView={4}
                // centeredSlides={true}
                spaceBetween={40}
                navigation={true}
                // loop={true}
                pagination={{
                    type: paginationType,

                }}
                breakpoints={{
                    0: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                        centeredSlides: false

                    },
                    576: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },

                }}

                modules={[Pagination, Navigation]}
                className="dashboard-events" // Changed className to dashboard-events
            >
                <SwiperSlide>
                    <div className='dash-homam-events'>
                        <div className='dash-homam-events-img-container' onClick={() => handleRouting("Avahanti-Homam-instructions")}>
                            <img src={require("../images/homamImages/avahanti.png")} />
                        </div>
                        <h6>Avahanti Homam</h6>
                        <button className='dash-e-btn1' onClick={() => handleRouting("Avahanti-Homam-instructions")}>Learn more</button>
                        <button className='dash-e-btn2 date-btn' onClick={() => handleRouting("Avahanti-Homam-booking")}>Book slot</button>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='dash-homam-events'>
                        <div className='dash-homam-events-img-container' onClick={() => handleRouting("Ganapathi-Homam-instructions")}>
                            <img src={require("../images/homamImages/ganapathi.png")} />
                        </div>
                        <h6>Ganapathi Homam</h6>
                        <button className='dash-e-btn1' onClick={() => handleRouting("Ganapathi-Homam-instructions")}>Learn more</button>
                        <button className='dash-e-btn2 date-btn' onClick={() => handleRouting("Ganapathi-Homam-booking")}>Book slot</button>
                    </div>

                </SwiperSlide>

                <SwiperSlide>
                    <div className='dash-homam-events'>
                        <div className='dash-homam-events-img-container' onClick={() => handleRouting("Surya-Namaskaram-instructions")}>
                            <img src={require("../images/homamImages/suryanamaksar.png")} />
                        </div>
                        <h6>Surya Namaskaram</h6>
                        <button className='dash-e-btn1' onClick={() => handleRouting("Surya-Namaskaram-instructions")}>Learn more</button>
                        <button className='dash-e-btn2 date-btn' onClick={() => handleRouting("Surya-Namaskaram-booking")}>Book slot</button>
                    </div>
                </SwiperSlide>
                {/* <SwiperSlide>
                    <div className='dash-homam-events'>
                        <div className='dash-homam-events-img-container'>
                            <img src={require("../images/homamImages/homam-logo-2.png")} />
                        </div>
                        <h6>Navagraha Sukta Parayanam</h6>
                        <button className='dash-e-btn1' onClick={() => handleRouting("Navagraha-Sukta-Parayanam-instructions")}> Learn more</button>
                        <button className='dash-e-btn2 date-btn' onClick={() => handleRouting("Navagraha-Sukta-Parayanam-booking")}>Book slot</button>
                    </div>
                </SwiperSlide> */}
                <SwiperSlide>
                    <div className='dash-homam-events' onClick={() => handleRouting("pricing")}>
                        <div className='dash-homam-events-img-container'>
                            <img src={require("../images/homamImages/premium.png")} />
                        </div>
                        <h6> Premium</h6>
                        {/* <button className='dash-e-btn1'  onClick={() => handleRouting("pricing")}>Learn more</button> */}
                        <button className='dash-e-btn2 date-btn' onClick={() => handleRouting("pricing")}>Buy Premium</button>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='dash-homam-events' onClick={() => window.open("https://shop.shubadinam.com", "_blank")}
                    >
                        <div className='dash-homam-events-img-container'>
                            <img src={require("../images/homamImages/shop.png")} />
                        </div>
                        <h6> Gift Shoppe</h6>
                        {/* <button className='dash-e-btn1'  onClick={() => handleRouting("pricing")}>Learn more</button> */}
                        <button className='dash-e-btn2 date-btn' onClick={() => window.open("https://shop.shubadinam.com", "_blank")}
                        >Purchase</button>
                    </div>
                </SwiperSlide>



            </Swiper>


        </>
    );
};

export default DashboardEvents;