import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import SDadminHeader from './SDadminHeader';
import Top20px from '../screens/Top20px';
import { Box, Typography, TextField, Button, Grid, Stack } from '@mui/material';
import axios from 'axios';

const AdminPremiumupdate = () => {
    const [premiumValue, setPremiumValue] = useState('');
    const [formData, setFormData] = useState({
        id: '',
        entry_time: '',
        userName: '',
        userEmail: '',
        userContact: '',
        paymentId: '',
        orderId: '',
        receipt: '',
        entity: '',
        currency: '',
        amount: '',
        pay_status: '',
        pay_method: '',
        captured: '',
        attempts: '',
        card_id: '',
        card_last4: '',
        card_network: '',
        card_type: '',
        refunded: '',
        refund_status: '',
        premiumId: '',
        upi_transaction_id: '',
        upi_vpa: '',
        rrn: '',
        bank_transaction_id: '',
        bank_name: ''
    });

    // Handle input change for the premiumValue field
    const handleInputChange = (event) => {
        setPremiumValue(event.target.value);
    };

    const handleChange = (event) => {
        console.log(event.target.value);
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // Fetch data from the server based on the premiumValue
    const handleSubmit = () => {
        const url = `https://backend.shubadinam.com/getadmin/orderId?orderToken=${premiumValue}`;
        axios.get(url)
            .then((response) => {
                const data = response.data[0]; // Assuming the response data is an array with at least one object
                setFormData({
                    id: data.id || '',
                    entry_time: data.entry_time || '',
                    userName: data.userName || '',
                    userEmail: data.userEmail || '',
                    userContact: data.userContact || '',
                    paymentId: data.paymentId || '',
                    orderId: data.orderId || '',
                    receipt: data.receipt || '',
                    entity: data.entity || '',
                    currency: data.currency || '',
                    amount: data.amount || '',
                    pay_status: data.pay_status || '',
                    pay_method: data.pay_method || '',
                    captured: data.captured || '',
                    attempts: data.attempts || '',
                    card_id: data.card_id || '',
                    card_last4: data.card_last4 || '',
                    card_network: data.card_network || '',
                    card_type: data.card_type || '',
                    refunded: data.refunded || '',
                    refund_status: data.refund_status || '',
                    premiumId: data.premiumId || '',
                    upi_transaction_id: data.upi_transaction_id || '',
                    upi_vpa: data.upi_vpa || '',
                    rrn: data.rrn || '',
                    bank_transaction_id: data.bank_transaction_id || '',
                    bank_name: data.bank_name || ''
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const updatePremium = () => {
        console.log(formData);
        // const url = `https://backend.shubadinam.com/update/premiumtable`
        // axios.put(url)
        //     .then((response) => {
        //         const data = response.data[0]; // Assuming the response data is an array with at least one object
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
    };


    return (
        <Container>
            <SDadminHeader />
            <Top20px />
            <Box>
                <Typography variant="h6">Premium Update</Typography>

                {/* Input for Premium Value */}
                {
                    !formData.id &&
                    <Grid item xs={12} md={2} sx={{ mt: 20 }} >
                        <Stack spacing={3} sx={{ width: 300 }} >

                            <TextField
                                label="Enter Order Id"
                                variant="outlined"
                                value={premiumValue}
                                onChange={handleInputChange}

                            />
                            <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Stack>
                    </Grid>
                }

                {/* Displaying Form Fields */}
                {
                    formData.id &&



                    <Grid item xs={12} md={2} sx={{ mt: 20 }}>

                        <Button variant="contained" color="primary" sx={{ m: 5, textAlign: "center", width: 300, }} onClick={() => updatePremium()}>
                            Update
                        </Button>
                        <br />
                        {Object.keys(formData).map((key) => (
                            <TextField
                                key={key}
                                label={key}
                                name={key}
                                value={formData[key]}
                                onChange={handleChange} // Add onChange handler
                                variant="outlined"
                                margin="normal"
                                InputProps={{
                                    readOnly: false, // Make fields readonly if required
                                }}
                                sx={{
                                    backgroundColor: key.includes('entity') || key.includes('paymentId') || key.includes('amount') || key.includes('userContact') || key.includes('pay_status') || key.includes('captured') || key.includes('pay_method') || key.includes('upi_vpa') || key.includes('rrn') || key.includes('refunded') ? '#eee' : 'none',
                                    mr: 2
                                }}
                            />
                        ))}
                    </Grid>

                }
            </Box>
        </Container>
    );
};

export default AdminPremiumupdate;
