import React, { useState, useEffect, useRef } from 'react';
import { NavLink, Navigate, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

import Top20px from "../screens/Top20px"


import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import SplitButton from 'react-bootstrap/SplitButton';
import { ButtonGroup } from 'react-bootstrap'; // Add this import

import CloseButton from 'react-bootstrap/CloseButton';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBell } from '@fortawesome/free-regular-svg-icons';
import { faBars, faL } from '@fortawesome/free-solid-svg-icons'; // Keep faBars here

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut, Pie, Bar, Bubble, Scatter, HorizontalBar, StackedBar, Mixed, Radar, Line } from 'react-chartjs-2';
import ScrollToTop from 'react-scroll-to-top';

import CountUp from 'react-countup';
import AdminDashboard from '../admin/adminDashboard';
import SDadminHeader from '../admin/SDadminHeader';


ChartJS.register(ArcElement, Tooltip, Legend);



const AdminPage = () => {


  const [isDatabase, setDatabase] = useState(false);


  const databaseRef = useRef(null);

  const notify = () => toast("Wow so easy!");

  const navigate = useNavigate()

  useEffect(() => {
    if (!sessionStorage.getItem("adminId")) {
      navigate("/admin");
    }

  }, [navigate])


  const [chngPwd, SetPwd] = useState(false);
  const [confirmPassword, SetConfirmPassword] = useState({
    // oldPwd: "",
    confirmPwd: "",
    reconfirmPwd: "",
  });

  const [isSendMailClicked, setSendMailClicked] = useState(false);

  const handleSendMailClick = () => {
    setSendMailClicked(!isSendMailClicked);
  };

  const [formData, setFormData] = useState({
    from: "",
    to: "",
    subject: "",
    message: "",
    attachments: [],
  });

  const [emailType, setEmailType] = useState('custom'); // 'custom' or 'allUsers'

  const handleEmailTypeChange = (e) => {
    setEmailType(e.target.value);
    // If 'allUsers' is selected, clear the 'to' field
    if (e.target.value === 'allUsers') {
      setFormData({ ...formData, to: '' });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64String = event.target.result;
        setFormData({ ...formData, attachments: base64String });
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your logic to send the email here
    // console.log('Form data submitted:', formData);
    // Add Axios request or any other logic to send the email
  };

  const changePwd = () => {
    SetPwd(true);
    // setSelectedNavItem(false);
    if (isSidebarOpen) {
      setSidebarOpen(false); // Close sidebar on item selection for mobile
    }
  };

  const pwdhandler = (evt) => {
    SetConfirmPassword({ ...confirmPassword, [evt.target.name]: evt.target.value });
  };

  const clickSubmit = () => {
    if (confirmPassword.confirmPwd !== "") {
      if (confirmPassword.confirmPwd === confirmPassword.reconfirmPwd) {

        const url = `https://backend.shubadinam.com/adminaccess/upt/password`;

        const adminId = sessionStorage.getItem("adminId")

        axios
          .post(url, { ...confirmPassword, adminId: adminId })
          .then((response) => {
            // console.log(response);
            alert(response.data.message)
            // alert("Password updted successfully.");
          })
          .catch((error) => {
            alert(error.response.data.message)
            // console.log(error);
          });

        SetPwd(false);
      } else {
        alert("not equal");
        SetPwd(false);
      }
    } else {
      alert("password field is empty");
      SetPwd(false);
    }

  };

  const clickClose = () => {
    SetPwd(false)
  };

  const handlePopupClick = (e) => {
    // Prevent the click event from reaching the pwd-container
    e.stopPropagation();
  };



  // const [homeCondition, SetHomeCondition] = useState(false)
  const [databaseCondition, SetDatabaseCondition] = useState(false)

  const clickHome = () => {
    // SetHomeCondition(true)
    SetDatabaseCondition(false)
    navigate(`/adminpage`)
    setExpanded(false); // Close the Navbar after click

  }

  const clickDatabase = (e) => {
    e.preventDefault()
    setExpanded(false); // Close the Navbar after click

    SetDatabaseCondition(true);
    // SetHomeCondition(false);
  };




  const direction = 'end';

  const adminId = sessionStorage.getItem("adminId")


  const [userData, setUserData] = useState([]);
  const [newUser, setNewuser] = useState([]);


  useEffect(() => {

    const url = `https://backend.shubadinam.com/adminpage/analyseusers`

    axios.get(url)
      .then(response => setUserData(response.data))
    // .catch(error => console.error('Error fetching user data:', error));


    const getTodayUsers = `https://backend.shubadinam.com/adminpage/newusers`;

    axios.get(getTodayUsers)
      .then(response => setNewuser(response.data.setNewuser))
    // .catch(error => console.error('Error fetching user data:', error));



  }, []);


  const totalUsers = userData.length;
  // console.log(totalUsers);




  // Chart data
  const chartData = {
    labels: ['Total Users'],
    datasets: [
      {
        data: [totalUsers, 100 - totalUsers], // Assuming a total of 100 users
        backgroundColor: ['#36A2EB', '#FFCE56'], // You can customize colors
      },
    ],
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };


  const styles = {
    bodyChartContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr',
      gap: '5px',
      // border: '1px solid rgba(75, 74, 74, 0.596)',
      height: '100%',
      width: '100%',
      // justifyContent: 'space-between',
      // alignItems: 'center',
      // padding: '20px',
    },
    bodyChart: {
      border: '1px solid black',
      width: '100%',
      height: '100%',
      display: "flex",
      flexDirection: 'row'
      // backgroundColor:"grey"
    },
  };


  const [selectedSection, setSelectedSection] = useState('dashboard');

  const handleSectionChange = (section) => {
    setSelectedSection(section);
    // navigate(`/adminpage/${section}`);
  };

  const clickSignOut = () => {
    sessionStorage.removeItem("adminId")
    navigate("/admin")
  }
  const logoClick = () => {
    navigate("/")
  }

  const [expanded, setExpanded] = useState(false);

  const [users, setUsers] = useState([]);


  useEffect(() => {
    const url = `https://backend.shubadinam.com/get/nonpremiumusers`

    axios.get(url)
      .then((response) => {
        // console.log(response.data);
        setUsers(response.data)
      })
      .catch((error) => {
        // console.log(error);
      })
  }, [])


  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [selectedNavItem, setSelectedNavItem] = useState(true);

  const toggleSidebar = (e) => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleNavItemSelect = (item) => {
    setSelectedNavItem(item);
    if (isSidebarOpen) {
      setSidebarOpen(false); // Close sidebar on item selection for mobile
    }
  };

  const handleOutsideClick = (event) => {
    // const sidebar = document.getElementById('layoutSidenav_nav');
    if (isSidebarOpen) {
      setSidebarOpen(false);
    }
  };

  const togglePassword = () => {
    SetPwd(true)
  }




  return (


    <div className="sd-adminpage">
      <ScrollToTop smooth />
      <header className="sd-header">
        <SDadminHeader clickPass={togglePassword} />
      </header>
      <Top20px />
      <div className="sd-body">
        <AdminDashboard />
        {
          chngPwd ?
            <div className="pwd-container" onClick={clickClose}>
              <div className="pwd-popup" onClick={handlePopupClick}>

                <label>Enter your New Password :</label>
                <input type="text" name="confirmPwd" onChange={pwdhandler} />
                <br />
                <label>Reconfirm your Password :</label>
                <input type="text" name="reconfirmPwd" onChange={pwdhandler} />
                <br />
                <button onClick={() => clickSubmit()}>Submit</button>
                <button style={{ marginLeft: "10px", backgroundColor: "#ffd7d7", color: "black" }} onClick={() => clickClose()}>Close</button>

              </div>
            </div>
            :
            <span></span>
        }
      </div>




      {/* <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">



        <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
          <li className="nav-item dropdown">

            <Form className="d-flex admin-mobile-header-notify">

              <Dropdown>
                <Dropdown.Toggle style={{ marginRight: "30px" }}>
                  <FontAwesomeIcon icon={faUser} style={{ marginRight: "10px", height: "20px" }} />
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ right: 0, left: "auto", width: "20px" }}>
                  <Dropdown.Item style={{ color: "black" }}>Action</Dropdown.Item>
                  <Dropdown.Item style={{ color: "black" }} onClick={() => changePwd()}>
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item style={{ color: "black" }} onClick={() => clickSignOut()}>Log Out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

            </Form>

          </li>
        </ul>
      </nav>
      {
        chngPwd ?
          <div className="pwd-container" onClick={clickClose}>
            <div className="pwd-popup" onClick={handlePopupClick}>

              <label>Enter your New Password :</label>
              <input type="text" name="confirmPwd" onChange={pwdhandler} />
              <br />
              <label>Reconfirm your Password :</label>
              <input type="text" name="reconfirmPwd" onChange={pwdhandler} />
              <br />
              <button onClick={() => clickSubmit()}>Submit</button>
              <button style={{ marginLeft: "10px", backgroundColor: "#ffd7d7", color: "black" }} onClick={() => clickClose()}>Close</button>

            </div>
          </div>
          :
          <span></span>
      }

      <div id="layoutSidenav" >
        <div id="layoutSidenav_nav" className={isSidebarOpen ? 'open' : ''} >
          <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
            <div className="sb-sidenav-menu">
              <div className="nav">
                <div className="sb-sidenav-menu-heading">Core</div>
                <a className="nav-link" >
                  <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt" /></div>
                  <NavLink to="/adminpage/admin-dashboard" style={{ textDecoration: "none", color: "white" }} onClick={() => handleNavItemSelect(true)}>Dashboard</NavLink>

                </a>

                <div className="sb-sidenav-menu-heading">Interface</div>

                <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
                  <div className="sb-nav-link-icon"><i className="fas fa-columns" /></div>
                  Database
                  <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down" /></div>
                </a>
                <div className="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                  <nav className="sb-sidenav-menu-nested nav">
                    <DropdownButton
                      as={ButtonGroup}
                      key={direction}
                      id={`dropdown-button-drop-${direction}`}
                      drop={direction}
                      variant="secondary"
                      title={`Panchang`}
                      style={{ width: '170px', height: "50px" }}
                    >
                      <Dropdown.Item as="div"><NavLink to="/adminpage/tamilpanchangam" className="dropdown-item" onClick={() => handleNavItemSelect(false)}>Tamil Panchangam</NavLink></Dropdown.Item>
                      <Dropdown.Item as="div" ><NavLink to="/adminpage/telugupanchangam" className="dropdown-item" onClick={() => handleNavItemSelect(false)}>Telugu Panchangam</NavLink></Dropdown.Item>
                      <Dropdown.Item as="div"><NavLink to="/adminpage/malayalampanchangam" className="dropdown-item" onClick={() => handleNavItemSelect(false)}>Malayalam Panchangam</NavLink></Dropdown.Item>
                      <Dropdown.Item as="div"><NavLink to="/adminpage/kannadampanchangam" className="dropdown-item" onClick={() => handleNavItemSelect(false)}>Kannadam Panchangam</NavLink></Dropdown.Item>
                      <Dropdown.Item as="div"><NavLink to="/adminpage/gujaratipanchangam" className="dropdown-item" onClick={() => handleNavItemSelect(false)}>Gujarati Panchangam</NavLink></Dropdown.Item>
                      <Dropdown.Item as="div"><NavLink to="/adminpage/hindipanchangam" className="dropdown-item" onClick={() => handleNavItemSelect(false)}>Hindi Panchangam</NavLink></Dropdown.Item>
                      <Dropdown.Item as="div"><NavLink to="/adminpage/bengalipanchangam" className="dropdown-item" onClick={() => handleNavItemSelect(false)}>Bengali Panchangam</NavLink></Dropdown.Item>

                    </DropdownButton>
                    <br />
                    <DropdownButton
                      as={ButtonGroup}
                      key={direction}
                      id={`dropdown-button-drop-${direction}`}
                      drop={direction}
                      variant="secondary"
                      title={` Tithi `}
                      style={{ width: '170px', height: "50px", }}

                    >
                      <Dropdown.Item as="div"><NavLink to="/adminpage/tamiltithi" className="dropdown-item" onClick={() => handleNavItemSelect(false)}>Tamil Tithi</NavLink></Dropdown.Item>
                      <Dropdown.Item as="div"><NavLink to="/adminpage/telugutithi" className="dropdown-item" onClick={() => handleNavItemSelect(false)}>Telugu Tithi</NavLink></Dropdown.Item>
                      <Dropdown.Item as="div"><NavLink to="/adminpage/malayalamtithi" className="dropdown-item">Malayalam Tithi</NavLink></Dropdown.Item>
                      <Dropdown.Item as="div"><NavLink to="/adminpage/kannadamtithi" className="dropdown-item" onClick={() => handleNavItemSelect(false)}>Kannadam Tithi</NavLink></Dropdown.Item>
                      <Dropdown.Item as="div"><NavLink to="/adminpage/gujaratitithi" className="dropdown-item" onClick={() => handleNavItemSelect(false)}>Gujarati Tithi</NavLink></Dropdown.Item>
                      <Dropdown.Item as="div"><NavLink to="/adminpage/hinditithi" className="dropdown-item" onClick={() => handleNavItemSelect(false)}>Hindi Tithi</NavLink></Dropdown.Item>
                      <Dropdown.Item as="div"><NavLink to="/adminpage/bengalitithi" className="dropdown-item" onClick={() => handleNavItemSelect(false)}>Bengali Tithi</NavLink></Dropdown.Item>

                    </DropdownButton>
                  </nav>
                </div>
                <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
                  <div className="sb-nav-link-icon"><i className="fas fa-book-open" /></div>
                  Reports
                  <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down" /></div>
                </a>
                <div className="collapse" id="collapsePages" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
                  <nav className="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionPages">
                    <DropdownButton
                      as={ButtonGroup}
                      key={direction}
                      id={`dropdown-button-drop-${direction}`}
                      drop={direction}
                      variant="secondary"
                      title={` Users `}
                      style={{ width: '170px', height: "50px", }}

                    >
                      <Dropdown.Item as="div"><NavLink to="/adminpage/premiumusers" className="dropdown-item" onClick={() => handleNavItemSelect(false)}>Premium Users</NavLink></Dropdown.Item>
                      <Dropdown.Item as="div"><NavLink to="/adminpage/non-premiumusers" className="dropdown-item" onClick={() => handleNavItemSelect(false)}>Non-Premium Users</NavLink></Dropdown.Item>


                    </DropdownButton>
                    <br />
                    <DropdownButton
                      as={ButtonGroup}
                      key={direction}
                      id={`dropdown-button-drop-${direction}`}
                      drop={direction}
                      variant="secondary"
                      title={` Visitors `}
                      style={{ width: '170px', height: "50px", }}

                    >
                      <Dropdown.Item as="div"><NavLink to="/adminpage/signup-visitors" className="dropdown-item" onClick={() => handleNavItemSelect(false)}>Signup Visitors</NavLink></Dropdown.Item>
                      <Dropdown.Item as="div"><NavLink to="/adminpage/login-visitors" className="dropdown-item" onClick={() => handleNavItemSelect(false)}>Login Visitors</NavLink></Dropdown.Item>


                    </DropdownButton>
                    <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseAuth" aria-expanded="false" aria-controls="pagesCollapseAuth">

                      <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down" /></div>
                    </a>
                    <div className="collapse" id="pagesCollapseAuth" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                      <nav className="sb-sidenav-menu-nested nav">

                      </nav>
                    </div>
                    <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseError" aria-expanded="false" aria-controls="pagesCollapseError">
                      <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down" /></div>
                    </a>
                    <div className="collapse" id="pagesCollapseError" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                      <nav className="sb-sidenav-menu-nested nav">

                      </nav>
                    </div>
                  </nav>
                </div>

                <div className="sb-sidenav-menu-heading">Addons</div>
                <a className="nav-link" onClick={() => changePwd()}>
                  <div className="sb-nav-link-icon"></div>
                  Change Password
                </a>
                <a className="nav-link" onClick={() => clickSignOut()} >
                  <div className="sb-nav-link-icon"><i className="fas fa-table" /></div>
                  Log Out
                </a>
              </div>
            </div>
            <div className="sb-sidenav-footer">
              <div className="small">Logged in as:</div>
              Admin
            </div>
          </nav>
        </div>
        <div id="layoutSidenav_content">

          {selectedNavItem ? <AdminDashboard setSelectedNavItem={setSelectedNavItem} /> : <Outlet />}

        </div>

      </div> */}

    </div>







    // <div className="overall-container">

    // </div>

  )
}



export default AdminPage;