import React, { useEffect } from "react";
import LandingHeader from '../components/LandingHeader';
import Top20px from '../screens/Top20px';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FooterComponent from '../components/footer';
import axios from "axios";
import Swal from "sweetalert2";
import { Container, Grid, Card, CardContent, Typography, Box, } from '@mui/material';


const InstructionsSuryaNamaskar = () => {


    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);

    }, [])

    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleRouting = () => {

        const localId = localStorage.getItem("SD_Id") || localStorage.getItem("premiumVerified");
        if (localId) {
            axios.get(`https://backend.shubadinam.com/checkuser?userId=${localId}`)
                .then((response) => {
                    // console.log(response.data);
                    navigate("/Surya-Namaskaram-booking")
                })
                .catch((error) => {
                    Swal.fire({
                        title: 'Log In !',
                        text: 'Please login to continue further.',
                        icon: 'info',
                        confirmButtonText: 'Okay',
                    })
                    navigate("/signin")
                })
        }
        else {
            Swal.fire({
                title: 'Log In !',
                text: 'Please login to continue further.',
                icon: 'info',
                confirmButtonText: 'Okay',
            })
            navigate("/signin")
        }

    }

    return (
        <div className='homam-container'>
            <LandingHeader />
            <Top20px />
            <div className='homam-body-instructions'>
                {/* <div className='homam-title'>
                    <b style={{ fontSize: "22px" }}>Surya Namaskaram (Virtual Participation) Instructions</b>
                </div>

                <div className='homam-title2'>
                    <b>Rs. 150/- per person</b>
                    <p> Surya Namaskara Prashna or Aruna Prashna parayana consists of 32 Anuvaakams (chapters).</p>
                    <p> When the Japam is performed, it is in our sampradaya, to offer one sashtanga namaskaram (prostration), facing east (Surya Bhagwan), at the end of every Anuvaakam (chapter).</p>
                    <p> Totally about 32+ Namaskarams will be performed.</p>
                    <p> Generally this japam will be followed by and concluded with Navagraha Mantras.</p>

                </div>

                <div className='homam-instuctions-list'>
                    <h5 style={{ paddingLeft: "20px" }}>Benefits of Avahanti Homam</h5>
                    <ul>
                        <li>Retention power for Students.</li>
                        <li>Awakening of Intelligence.</li>
                        <li>Health that is required for enjoying the richness.</li>
                        <li>Power of speech to convince others about your ideas.</li>
                        <li>Effective listening.</li>
                        <li>Wealth and prosperity.</li>
                        <li>Non-stop expansion of your company.</li>
                        <li>Spiritual progress to make you an Enlightened professional.</li>
                    </ul>

                </div>

                <br />
                <div className='homam-instuctions-list'>

                    <h5 style={{ paddingLeft: "20px" }}> When and Where?</h5>
                    <p style={{ paddingLeft: "20px" }}>Avahanti homam will be conducted on every Shukla Paksha Panchami (Fifth Tithi after Amavasya) by vedic Scholars in the Veda Patashala associated with Shubadinam.com. It will be streamed live at the youtube channel  @shubadinam. Click here to see the timetable.</p>

                    <h5 style={{ paddingLeft: "20px" }}>How to Participate?</h5>
                    <ol>
                        <li style={{ padding: "2px" }}>You can book your slots 2 months in advance by paying a nominal fee. Booking Slots will be opened on 20th of every month. (For Example Booking Slot for September 2024 will be opened on 20th July 2024.)</li>
                        <li style={{ padding: "2px" }}>Provide the Name and Nakshatra details of the person whose name should be included in Sankalpa.</li>
                        <li style={{ padding: "2px" }}>Name and Nakshatra of all yajamans will be read out during the sankalpam that can be watched Live.</li>
                        <li style={{ padding: "2px" }}>Homam Prashad will be sent by Post.</li>
                    </ol>


                </div>
                <br />
                <div className='homam-instuctions-list'>

                    <h5 style={{ paddingLeft: "20px" }}> Optional Contribution.</h5>
                    <p style={{ paddingLeft: "20px" }}>You can contribute to Brahmana bhojana on the day of homam. Click Here for More details.</p>

                    <span style={{ paddingLeft: "20px" }}>Disclaimer: Please contact a qualified pundit if you want to conduct this Homam exclusively at your residence for more benefits</span>

                    <br />
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            required
                            label="I have read and agree to the instructions"
                        />
                    </div>

                    <br />
                    <div className='homam-continue'>
                        {
                            checked ?
                                <Button className='homam-continue-btns homam-ctn-btn' onClick={() => handleRouting()}>Book Slot</Button>
                                :
                                <Button className='homam-continue-btns' disabled  >Book Slot</Button>
                        }


                    </div>

                </div> */}


                <Container maxWidth="lg" sx={{ marginTop: 4 }}>
                    {/* Overview Section */}
                    <Card sx={{ marginBottom: 4, backgroundColor: '#f4f6f8' }}>
                        <CardContent>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2, color: "black !important", }}>
                                Surya Namaskara Prashna (Aruna Prashna Parayana) -
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: "#d32f2f",  // Red color for highlighting
                                        padding: '8px',
                                        borderRadius: '4px',
                                        display: 'inline-block'
                                    }}
                                >
                                    ₹125
                                    <Typography variant="p" sx={{ fontWeight: "100", color: "black" }}>/person</Typography> <span style={{ fontWeight: "100", fontSize: "medium", color: "black" }}>(including gst).</span>
                                </Typography>
                            </Typography>
                            {/* <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2, color: "black !important", }}>
                                Virtual Seva Instructions
                            </Typography> */}
                            <Typography variant="body1">
                                Popularly Surya Namaskar is done by yoga practioners as a combination of 12 Asanas. Alternatively there is a vedic way of doing Surya Namaskar in which everyone can participate and benefit.
                                Here the Acharya will first start with a puja to invoke Lord Surya in a Kalash. Then the Vedic pundits will recite the Surya Namaskara Prashna or Aruna Prashna, that consists of 32 Anuvaakams (chapters). It is then followed by Navagraha Mantras and conclusion Puja.
                                Surya Namaskara Prashna or Aruna Prashna parayana consists of 32 Anuvaakams (chapters).
                                When the Japam is performed, it is in our sampradaya, to offer one sashtanga namaskaram (prostration), facing east (Surya Bhagwan), at the end of every Anuvaakam (chapter).
                                Totally about 32+ Namaskarams will be performed. It is advised that those with any medical history may consult their physician before performing namaskarams at a stretch.
                            </Typography>
                        </CardContent>
                    </Card>

                    {/* Benefits Section */}
                    <Card sx={{ marginBottom: 4, backgroundColor: '#fff3e0' }}>
                        <CardContent>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                                Benefits of Surya Namaskaram
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">Promotes good health and prosperity.</Typography>
                                <Typography component="li" variant="body1">Provides relief from sins and fears.</Typography>
                                <Typography component="li" variant="body1">Enhances spiritual growth and well-being.</Typography>
                            </ul>
                        </CardContent>
                    </Card>

                    {/* Timing and Location Section */}
                    <Card sx={{ marginBottom: 4, backgroundColor: '#e3f2fd' }}>
                        <CardContent>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                                When and Where
                            </Typography>
                            <Typography variant="body1">
                                <strong>Timing:</strong> Conducted every Sunday at 6 am.
                            </Typography>
                            <Typography variant="body1">
                                <strong>Location:</strong> Performed by Vedic scholars at the Veda Patashala associated with Shubadinam.com.
                            </Typography>
                            <Typography variant="body1">
                                <strong>Streaming:</strong> Watch the Parayana live on Shubadinam TV, available through your dashboard.
                            </Typography>
                        </CardContent>
                    </Card>

                    {/* Participation Instructions */}
                    <Card sx={{ marginBottom: 4, backgroundColor: '#f0f4c3' }}>
                        <CardContent>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                                How to Participate
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1">
                                        <strong>Booking Slots:</strong> Instantly book for the upcoming event or pre-book up to 2 months in advance. [Premium customers receive one slot for free.]
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1">
                                        <strong>Provide Details:</strong> Submit the name and Nakshatra of the person to be included in the Sankalpa.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1">
                                        <strong>Live Sankalpa:</strong> The names and Nakshatras of all yajamans will be announced during the Sankalpa and can be viewed live.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1">
                                        <strong>Dress Code:</strong> Yajamans are encouraged to wear traditional attire while watching the live stream.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1">
                                        <strong>Perform Namaskaram:</strong> Devotees can perform Namaskaram (prostration) at home facing east while watching the live parayana.
                                    </Typography>
                                </Grid>
                                {/* <Grid item xs={12} md={6}>
                                    <Typography variant="body1">
                                        <strong>Visit the Patashala:</strong> Those who can attend in person should arrive at the Patashala by 5:45 am to perform Namaskaram along with the students. [Click here for Patashala details.]
                                    </Typography>
                                </Grid> */}
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1">
                                        <strong>Prashad Delivery:</strong> Parayana Prashad will be sent by post.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    {/* Disclaimer Section */}
                    <Card sx={{ backgroundColor: '#ffebee', marginBottom: 4 }}>
                        <CardContent>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                Disclaimer: Please contact a qualified pundit if you want to conduct this Parayana exclusively at your residence for more benefits.
                            </Typography>
                        </CardContent>
                    </Card>

                    {/* Checkbox and Buttons */}
                    <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            required
                            label="I have read and agree to the instructions"
                        />

                        <Box className='homam-continue' sx={{ marginTop: 2 }}>
                            {
                                checked ?
                                    <Button
                                        className='homam-continue-btns homam-ctn-btn'
                                        onClick={handleRouting}
                                        sx={{ backgroundColor: '#28ab76', color: '#fff' }}
                                    >
                                        Book Slot
                                    </Button>
                                    :
                                    <Button
                                        className='homam-continue-btns'
                                        disabled
                                        sx={{ backgroundColor: '#ccc', color: '#fff' }}
                                    >
                                        Book Slot
                                    </Button>
                            }
                        </Box>
                    </Box>
                </Container>

            </div>
            <FooterComponent />
        </div>
    );
};

export default InstructionsSuryaNamaskar;