import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { useNavigate } from 'react-router-dom';
import SDadminHeader from './SDadminHeader';
import Top20px from '../screens/Top20px';

const CustomerDetails = () => {

    const navigate = useNavigate();


    const [userEmailResult, setUserEmailResult] = useState([]);
    const [userProfileResult, setUserProfileResult] = useState([]);
    const [userRelResult, setUserRelResult] = useState([]);
    const [premiumResultTab, setPremiumResultTab] = useState([]);
    const [premiumResultUser, setPremiumResultUser] = useState([]);

    useEffect(() => {
        // Retrieve stored results from session storage
        const result1 = sessionStorage.getItem('userCredent');
        const result2 = sessionStorage.getItem('userProfile');
        const result3 = sessionStorage.getItem('userRel');
        const result4 = sessionStorage.getItem('userPremiumtable');
        const result5 = sessionStorage.getItem('userPremiumuser');

        if (result1) {
            try {
                setUserEmailResult(JSON.parse(result1));
            } catch (error) {
                console.error("Error parsing JSON for result1:", error);
                setUserEmailResult([]); // Set to an empty array if parsing fails
            }
        } else {
            setUserEmailResult([]); // Set to an empty array if result1 is undefined, null, or empty
        }

        // Parse and set user profile result
        if (result2) {
            try {
                setUserProfileResult(JSON.parse(result2));
            } catch (error) {
                console.error("Error parsing JSON for result2:", error);
                setUserProfileResult([]); // Set to an empty array if parsing fails
            }
        } else {
            setUserProfileResult([]); // Set to an empty array if result2 is undefined, null, or empty
        }

        // Similar checks for result3, result4, and result5...
        // Parse and set user relationship result
        if (result3) {
            try {
                setUserRelResult(JSON.parse(result3));
            } catch (error) {
                console.error("Error parsing JSON for result3:", error);
                setUserRelResult([]); // Set to an empty array if parsing fails
            }
        } else {
            setUserRelResult([]); // Set to an empty array if result3 is undefined, null, or empty
        }

        // Parse and set premium result table
        if (result4) {
            try {
                setPremiumResultTab(JSON.parse(result4));
            } catch (error) {
                console.error("Error parsing JSON for result4:", error);
                setPremiumResultTab([]); // Set to an empty array if parsing fails
            }
        } else {
            setPremiumResultTab([]); // Set to an empty array if result4 is undefined, null, or empty
        }

        // Parse and set premium result user
        if (result5) {
            try {
                setPremiumResultUser(JSON.parse(result5));
            } catch (error) {
                console.error("Error parsing JSON for result5:", error);
                setPremiumResultUser([]); // Set to an empty array if parsing fails
            }
        } else {
            setPremiumResultUser([]); // Set to an empty array if result5 is undefined, null, or empty
        }
    }, []);

    // console.log("User Email Result:", userEmailResult);
    // console.log("User Profile Result:", userProfileResult);
    // console.log("User Relationship Result:", userRelResult);
    // console.log("Premium Result (Tab):", premiumResultTab);
    // console.log("Premium Result (User):", premiumResultUser);



    const formatData = (dateString) => {
        if (!dateString || typeof dateString !== 'string') {
            return ''; // Return empty string if dateString is undefined or not a string
        }

        const [datePart, timePart] = dateString.split('T');
        if (!datePart || !timePart) {
            return ''; // Return empty string if dateString does not contain a 'T' separator
        }

        const [year, month, day] = datePart.split('-');
        if (!year || !month || !day) {
            return ''; // Return empty string if any part of the date is missing
        }

        const [time, _] = timePart.split('.');
        if (!time) {
            return ''; // Return empty string if time part is missing
        }

        const [hours, minutes, _2] = time.split(':');
        if (!hours || !minutes) {
            return ''; // Return empty string if any part of the time is missing
        }

        return `${day}-${month}-${year} ${hours}:${minutes}`;
    };


    const handleOpenNewTab = () => {
        const queryParams = new URLSearchParams({
            userEmailResult: JSON.stringify(userEmailResult),
            userProfileResult: JSON.stringify(userProfileResult),
            userRelResult: userRelResult.length,
            // premiumResultTab: JSON.stringify(premiumResultTab),
            // premiumResultUser: JSON.stringify(premiumResultUser)
        });

        const url = `/per-customer-detail?${queryParams.toString()}`;
        window.open(url, '_blank');
    };




    return (
        <div className='admin-customer-details'>
            <header className="sd-header">
                <SDadminHeader />
            </header>
            <Top20px />
            <br />
            <h1 style={{ textAlign: "center", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> Customer Details  </h1>
            <br />

            <div className='admin-custmomer-credentials'>


                {/* <h3 style={{ textAlign: "center", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> Customer Bio  </h3> */}

                <table className='admin-customer-table'>
                    <tbody>
                        <tr>
                            <td>Name:</td>
                            <td>{userProfileResult.userName}</td>
                        </tr>
                        <tr>
                            <td>Email:</td>
                            <td>{userEmailResult.userEmail}</td>
                        </tr>
                        <tr>
                            <td>Id:</td>
                            <td>{userEmailResult.uniqueId}</td>
                        </tr>
                    </tbody>
                </table>
                <table className='admin-customer-table'>
                    <tbody>
                        <tr>
                            <td>Phone:</td>
                            <td>+{userEmailResult.countryCode} {userEmailResult.userPhone}</td>
                        </tr>
                        <tr>
                            <td>Gender:</td>
                            <td>{userProfileResult.userGender}</td>
                        </tr>
                        <tr>
                            <td>Location:</td>
                            <td>{userEmailResult.userState} / {userEmailResult.userCountry}</td>
                        </tr>
                    </tbody>
                </table>


                {/* <table className='admin-customer-table'>
                    <thead>
                        <tr>
                            <th>Property</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(userEmailResult).map((key) => (
                            <tr key={key}>
                                <td>{key}</td>
                                <td>{userEmailResult[key] || '-'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table> */}

            </div>
            <br />
            <br />
            <div className='admin-custmomer-credentials'>
                {/* <h3 style={{ textAlign: "center", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> Customer Profile  </h3> */}


                <table className='admin-customer-table'>
                    <thead>
                        <tr>
                            <th>Entry Date</th>
                            <th>No. of People Added</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {/* <td>{formatData(userEmailResult.entry_time)}</td> */}
                            <td>{(new Date(userEmailResult.entry_time)).toLocaleString()}</td>

                            <td>{userRelResult.length}</td>
                            <td><NavLink onClick={() => handleOpenNewTab()}>View Detailed Report</NavLink>
                            </td>
                        </tr>

                    </tbody>
                </table>

                {/* <table className='admin-customer-table'>
                    <thead>
                        <tr>
                            <th>Property</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(userProfileResult).map((key) => (
                            <tr key={key}>
                                <td>{key}</td>
                                <td>{userProfileResult[key] || '-'}</td>
                            </tr>

                        ))}
                        <tr>
                            <td>People Added</td>
                            <td>{userRelResult.length}</td>
                        </tr>
                    </tbody>
                </table> */}
            </div>
            <br />
            <br />
            <div className='admin-custmomer-credentials'>
                {/* <h3 style={{ textAlign: "center", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> Premium </h3> */}


                <table className='admin-customer-tableprem'>
                    <thead>
                        <tr colSpan={3}>
                            <th>Premium Purchased</th>
                            {/* <th>No. of People Added</th> */}
                            {/* <th>Action</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        <tr colSpan={2}>
                            <td style={{ backgroundColor: 'rgb(242, 242, 242)', color: "black" }} >
                                {premiumResultUser && premiumResultUser.length !== 0 ? (
                                    <div>
                                        <CurrencyRupeeIcon />{(premiumResultUser.amount) / 100} <br />
                                        {(new Date(premiumResultUser.entry_time)).toLocaleString()}
                                    </div>
                                ) : 0}
                            </td>
                            <td style={{}} >
                                {/* Additional cells */}
                            </td>
                            <td style={{}} >
                                {/* Additional cells */}
                            </td>
                            <td style={{}} >
                                {/* Additional cells */}
                            </td>
                            <td style={{}} >
                                {/* Additional cells */}
                            </td>
                        </tr>
                    </tbody>

                </table>


            </div>
            {/* <div className='admin-custmomer-credentials'> */}
            {/* <h2>Premium Results:</h2> */}
            {/* <table >
                    <thead>
                        <tr>
                            {premiumResultTab.length > 0 && Object.keys(premiumResultTab[0]).map((key) => (
                                <th key={key}>{key}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {premiumResultTab.map((item, index) => (
                            <tr key={index}>
                                {Object.values(item).map((value, valueIndex) => (
                                    <td key={valueIndex}>{value}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table> */}
            {/* </div> */}


        </div>
    );
};

export default CustomerDetails;