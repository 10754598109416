import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const EditEmailVerify = () => {

  const [apiResult, setApiResult] = useState(null);



  const params = useLocation();
  //   const navigate = useNavigate();

  useEffect(() => {
    const token = params.pathname.replace("/editemail/", "");
    const url = "https://backend.shubadinam.com/editemail/" + token;

    axios.get(url)
      .then((response) => {
        // console.log(response.data);
        setApiResult("success");
        // navigate("/my-profile");

      })
      .catch((error) => {
        // console.log(error);
        setApiResult("error occurred ! Please try again");
        // navigate("");
      })
  }, [])

  return (
    <div>
      <h1>
        {apiResult}
      </h1>
    </div>
  )
};

export default EditEmailVerify;