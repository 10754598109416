import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti'
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

const PaymentSuccess = () => {

    const navigate = useNavigate();


    const searchQuery = useSearchParams()[0];
    const refereceNum = searchQuery.get("reference");


    const [count, setCount] = useState(0)
    const [showConfetti, setShowConfetti] = useState(true);

    useEffect(() => {

        const loadScript = async (snippet) => {
            try {

                const script = document.createElement('script');
                script.innerHTML = snippet;
                script.async = true; // Load script asynchronously
                document.body.appendChild(script);
                // console.log(document.scripts);

            } catch (error) {
                console.error('Error occurred while adding script:', error);
            }
        };

        const loadScripts = async () => {
            loadScript(`gtag('event', 'conversion', {
                'send_to': 'AW-11290519266/HT8eCO74qrgZEOLN3ocq',
                'value': 30.0,
                'currency': 'INR',
                'transaction_id': ''
              });`);
        };

        loadScripts();
    }, []);


    useEffect(() => {

        const url = `https://backend.shubadinam.com/premium/checker?reference=${refereceNum}`;

        axios.get(url)
            .then((response) => {
                // console.log(response);
                const pID = response.data[0].premiumId;
                localStorage.setItem("premiumVerified", pID)
                // console.log(pID);
                const interval = setInterval(() => {

                    if (count == 6) {
                        setShowConfetti(false) // Update count using the state updater function
                    }
                    if (count < 8) {
                        setCount(prevCount => prevCount + 1); // Update count using the state updater function
                    } else {
                        clearInterval(interval); // Stop the interval when count reaches 3
                        // sessionStorage.removeItem("profileauth")
                        const navTo = localStorage.getItem("nav_")
                        if (navTo && navTo === "dashboard") {
                            localStorage.setItem("SD_Id", pID);
                            localStorage.setItem('homeauth', 'true')
                            navigate("/dashboard")
                            return;
                        }
                        if (navTo && navTo === "profile") {
                            sessionStorage.setItem("profileauth", true)
                            navigate("/profile")
                            return;
                        }

                    }
                }, 1000);
                return () => clearInterval(interval); // Clean up the interval on component unmount
            })
            .catch((error) => {
                // console.log(error);
                navigate("/pricing");
            })

    }, [count,]);






    return (
        <div className='redirect-signup'>
            {showConfetti && <Confetti />}
            <h1>Thank You For Signing Up...</h1>
            <div className="slider">
                <div className="line"></div>
                <div className="break dot1"></div>
                <div className="break dot2"></div>
                <div className="break dot3"></div>
            </div>
            <strong><b>Payment Successful</b></strong>
            <label>Refernce Number : {refereceNum}</label>
            <p>Please wait. This page will redirect you to your Profile in {count} seconds ... </p>
        </div>
    );
};

export default PaymentSuccess;