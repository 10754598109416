import React, { useEffect, useRef, useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {

    const navigate = useNavigate();

    useEffect(() => {
        navigate("/")
    }, [])

    return (
        <div className="error-align">
            <HelmetProvider>
                <Helmet>
                    <title>Error 404 </title>
                    <meta name="description" content="Error 404  - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            {/* <h1 className="error">404 - PageNotFound!</h1> */}
            <img src={require("../images/404 ERROR.jpg")} width="100%" height="100%" onContextMenu={(e) => e.preventDefault()} />
        </div>
    )
}
export default PageNotFound;