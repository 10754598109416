import React, { useState } from "react";

import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import LandingHeader from "../components/LandingHeader";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const ShopPage = () => {
  // const [dateTimeValue, setDateTimeValue] = useState('');

  // const handleDateTimeChange = (event) => {
  //   const newValue = event.target.value;
  //   console.log(newValue); // Log the value
  //   setDateTimeValue(newValue); // Update state with the new value
  // };

  const styles = {

  };


  return (

    <div style={{ height: "100%", width: "100%", textAlign: 'center' }}>
      <HelmetProvider>
        <Helmet>
          <title>Shop </title>
          <meta name="description" content="Shop  - Janma Nakshatra/Tithi Birthday Reminder Service." />
        </Helmet>
      </HelmetProvider>
      <LandingHeader />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h2 style={{ textAlign: "center", fontWeight: "600", color: "black", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", marginTop: '50px', }}> Coming soon ...
      </h2>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <FooterComponent />

    </div>


    // <div>
    //   <HeaderComponent />
    //   <div>
    //     <Helmet>
    //       <script type="text/javascript">
    //         {`
    //           (function () {
    //             var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
    //             if (window.ShopifyBuy) {
    //               if (window.ShopifyBuy.UI) {
    //                 ShopifyBuyInit();
    //               } else {
    //                 loadScript();
    //               }
    //             } else {
    //               loadScript();
    //             }
    //             function loadScript() {
    //               var script = document.createElement('script');
    //               script.async = true;
    //               script.src = scriptURL;
    //               (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    //               script.onload = ShopifyBuyInit;
    //             }
    //             function ShopifyBuyInit() {
    //               var client = ShopifyBuy.buildClient({
    //                 domain: '70c3ed-3.myshopify.com',
    //                 storefrontAccessToken: 'cdd20b34f1d21ae19697e7d8c1bbb1e2',
    //               });
    //               ShopifyBuy.UI.onReady(client).then(function (ui) {
    //                 ui.createComponent('collection', {
    //                   id: '299322310794',
    //                   node: document.getElementById('collection-component-1700733672291'),
    //                   moneyFormat: 'Rs.%20%7B%7Bamount%7D%7D',
    //                   options: {
    //                     // ... Your options here ...
    //                   },
    //                 });
    //               });
    //             }
    //           })();
    //         `}
    //       </script>
    //     </Helmet>

    //     <div id="collection-component-1700733672291"></div>
    //   </div>
    //   <FooterComponent />
    // </div>
  );
};

export default ShopPage;
