// import React from 'react';
// import Paper from '@mui/material/Paper';
// import TableContainer from '@mui/material/TableContainer';
// import Table from '@mui/material/Table';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import TableCell from '@mui/material/TableCell';
// import TableBody from '@mui/material/TableBody';
// import TablePagination from '@mui/material/TablePagination';
// import HeaderComponent from '../../components/header';
// import Top10px from '../Top10px';
// import Top20px from '../Top20px';
// import { useEffect } from 'react';


// const columns = [
//   { id: 'name', label: 'Name', minWidth: 170 },
//   { id: 'code', label: 'ISOu00a0Code', minWidth: 100 },
//   {
//     id: 'population',
//     label: 'Population',
//     minWidth: 170,
//     align: 'right',
//     format: (value) => value.toLocaleString('en-US'),
//   },
//   {
//     id: 'size',
//     label: 'Sizeu00a0(kmu00b2)',
//     minWidth: 170,
//     align: 'right',
//     format: (value) => value.toLocaleString('en-US'),
//   },
//   {
//     id: 'density',
//     label: 'Density',
//     minWidth: 170,
//     align: 'right',
//     format: (value) => value.toFixed(2),
//   },
// ];

// function createData(name, code, population, size) {
//   const density = population / size;
//   return { name, code, population, size, density };
// }

// const rows = [
//   createData('India', 'IN', 1324171354, 3287263),
//   createData('China', 'CN', 1403500365, 9596961),
//   createData('Italy', 'IT', 60483973, 301340),
//   createData('United States', 'US', 327167434, 9833520),
//   createData('Canada', 'CA', 37602103, 9984670),
//   createData('Australia', 'AU', 25475400, 7692024),
//   createData('Germany', 'DE', 83019200, 357578),
//   createData('Ireland', 'IE', 4857000, 70273),
//   createData('Mexico', 'MX', 126577691, 1972550),
//   createData('Japan', 'JP', 126317000, 377973),
//   createData('France', 'FR', 67022000, 640679),
//   createData('United Kingdom', 'GB', 67545757, 242495),
//   createData('Russia', 'RU', 146793744, 17098246),
//   createData('Nigeria', 'NG', 200962417, 923768),
//   createData('Brazil', 'BR', 210147125, 8515767),
//   // Add more rows as needed
// ];

// const RoughTwo = () => {
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(10);

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };



//   useEffect(() => {
//     console.log('useEffect is running');
//     // ... rest of the code
//   }, []);



//   // useEffect(() => {
//   //   const script = document.createElement('script');
//   //   script.src = 'https://checkout.razorpay.com/v1/payment-button.js';
//   //   script.async = true;
//   //   script.setAttribute('data-payment_button_id', 'pl_NRxcEtbKhNmvnE');
//   //   document.getElementById('razorpay-form').appendChild(script);

//   //   return () => {
//   //     // document.getElementById('razorpay-form').removeChild(script);
//   //   };
//   // }, []);







//   return (

//     <>
//       {/* <HeaderComponent/> */}
//       <Top10px />
//       <Top20px />

//       {/* <form id="razorpay-form">
//       <div id="razorpay-container"></div>
//     </form> */}

//       {/* <Paper sx={{ width: '70%', overflow: 'hidden' }}>
//         <TableContainer sx={{ maxHeight: 440 }}>
//           <Table stickyHeader aria-label="sticky table">
//             <TableHead>
//               <TableRow>
//                 {columns.map((column) => (
//                   <TableCell
//                     key={column.id}
//                     align={column.align}
//                     style={{ minWidth: column.minWidth }}
//                   >
//                     {column.label}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {rows
//                 .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                 .map((row) => {
//                   return (
//                     <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
//                       {columns.map((column) => {
//                         const value = row[column.id];
//                         return (
//                           <TableCell key={column.id} align={column.align}>
//                             {column.format && typeof value === 'number'
//                               ? column.format(value)
//                               : value}
//                           </TableCell>
//                         );
//                       })}
//                     </TableRow>
//                   );
//                 })}
//             </TableBody>
//           </Table>
//         </TableContainer>
//         <TablePagination
//           rowsPerPageOptions={[10, 25, 100]}
//           component="div"
//           count={rows.length}
//           rowsPerPage={rowsPerPage}
//           page={page}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />
//       </Paper> */}


//     </>
//   );
// }

// export default RoughTwo;

import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import * as XLSX from 'xlsx';

const RoughTwo = () => {

  console.log("relation database");
  const data = [
    { "relName": "Surya", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": "", "relTithi": "", "relMonth": "PANGUNI/பங்குனி", "relNakshathra": "VISAKAM/விசாகம்", "profileid": "16", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Arjun", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AADI/ஆடி", "relNakshathra": "POORATATHI/பூரட்டாதி", "profileid": "17", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Gowri ", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "MAASI/மாசி", "relNakshathra": "BARANI/பரணி", "profileid": "18", "relDate": "-- Not Found --", "relRelation": "Spouse" },
    { "relName": "Radha", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "PANGUNI/பங்குனி", "relNakshathra": "SWATHI/சுவாதி", "profileid": "19", "relDate": "-- Not Found --", "relRelation": "Parent" },
    { "relName": "Bhuvana", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "THAI/தை", "relNakshathra": "POORAM/பூரம்", "profileid": "20", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Hariharan", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AAVANI/ஆவணி", "relNakshathra": "UTHIRAM/உத்திரம்", "profileid": "21", "relDate": "-- Not Found --", "relRelation": "Friend" },
    { "relName": "Meena Hari", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "THAI/தை", "relNakshathra": "MIRUGASIRDAM/மிருகசீரிடம்", "profileid": "22", "relDate": "-- Not Found --", "relRelation": "Friend" },
    { "relName": "Sowmya Hari", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "SWATHI/சுவாதி", "profileid": "23", "relDate": "-- Not Found --", "relRelation": "Friend" },
    { "relName": "Pavithra Balaji", "userEmail": "vidyabalajigs@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "MARGAZHI/மார்கழி", "relNakshathra": "BARANI/பரணி", "profileid": "24", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Preshant Sankar Balaji", "userEmail": "vidyabalajigs@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AAVANI/ஆவணி", "relNakshathra": "UTHIRAM/உத்திரம்", "profileid": "25", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Pooja Vignesh", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AANI/ஆனி", "relNakshathra": "POOSAM/பூசம்", "profileid": "26", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Virupakshan", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "UTHIRAM/உத்திரம்", "profileid": "27", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Sri Krishna Chaitanya", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "PURATTASI/புரட்டாசி", "relNakshathra": "THIRUVATHIRA/திருவாதிரை", "profileid": "28", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Raji", "userEmail": "ashok.75@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "MAASI/மாசி", "relNakshathra": "ROHINI/ரோகிணி", "profileid": "29", "relDate": "18-02-2024", "relRelation": "Spouse" },
    { "relName": "Anirudh", "userEmail": "ashok.75@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "MARGAZHI/மார்கழி", "relNakshathra": "BARANI/பரணி", "profileid": "30", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Appa", "userEmail": "ashok.75@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Tithi", "relPaksham": "KRISHNA Paksha/கிருஷ்ண பக்ஷம்", "relTithi": "TRAYODASI/த்ரயோதசி", "relMonth": "MARGAZHI/மார்கழி", "relNakshathra": null, "profileid": "31", "relDate": "-- Not Found --", "relRelation": "Parent" },
    { "relName": "Meena", "userEmail": "ashok.75@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AAVANI/ஆவணி", "relNakshathra": "UTHIRAM/உத்திரம்", "profileid": "32", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Radha", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "PANGUNI/பங்குனி", "relNakshathra": "SWATHI/சுவாதி", "profileid": "33", "relDate": "-- Not Found --", "relRelation": "Parent" },
    { "relName": "Netresha Ghosh", "userEmail": "ankurr369@gmail.com", "relGender": "female", "relCalender": "Hindi", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "ASHVIN/अश्विन", "relNakshathra": "UTTARA ASHADA/उत्तराषाढा", "profileid": "34", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "DURBA BHATTACHARJEE", "userEmail": "ankurr369@gmail.com", "relGender": "female", "relCalender": "Hindi", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "ASHVIN/अश्विन", "relNakshathra": "UTTARA ASHADA/उत्तराषाढा", "profileid": "35", "relDate": "-- Not Found --", "relRelation": "Spouse" },
    { "relName": "ANJALI GHOSH", "userEmail": "ankurr369@gmail.com", "relGender": "female", "relCalender": "Hindi", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "JYESTHA/ज्येष्ठा", "relNakshathra": "SHRAVANA/श्रवण", "profileid": "36", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Vishnu", "userEmail": "vidhur12@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "POORAM/பூரம்", "profileid": "37", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Vidya ", "userEmail": "vidhur12@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "PANGUNI/பங்குனி", "relNakshathra": "SADHAYAM/சதயம்", "profileid": "38", "relDate": "-- Not Found --", "relRelation": "Parent" },
    { "relName": "Raja.G.S", "userEmail": "vidhur12@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AADI/ஆடி", "relNakshathra": "MAGAM/மகம்", "profileid": "39", "relDate": "-- Not Found --", "relRelation": "Spouse" },
    { "relName": "Ravi", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "MAASI/மாசி", "relNakshathra": "AYILYAM/ஆயில்யம்", "profileid": "40", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Rishikesh ", "userEmail": "rishirathna11@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AANI/ஆனி", "relNakshathra": "MIRUGASIRDAM/மிருகசீரிடம்", "profileid": "41", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "aravind ", "userEmail": "rishirathna11@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AANI/ஆனி", "relNakshathra": "UTHIRATTATHI/உத்திரட்டாதி", "profileid": "42", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Madhuryaa", "userEmail": "varshni.murali09@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "THAI/தை", "relNakshathra": "ASWINI/அஸ்வினி", "profileid": "43", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Madhuryaa", "userEmail": "varshni.murali09@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "MAASI/மாசி", "relNakshathra": "ASWINI/அஸ்வினி", "profileid": "44", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Vimarsini", "userEmail": "varshni.murali09@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "THAI/தை", "relNakshathra": "KRUTHIKAI/கார்த்திகை", "profileid": "45", "relDate": "-- Not Found --", "relRelation": "Parent" },
    { "relName": "Murali", "userEmail": "varshni.murali09@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "THAI/தை", "relNakshathra": "ASTHAM/அஸ்தம்", "profileid": "46", "relDate": "-- Not Found --", "relRelation": "Parent" },
    { "relName": "Kishor", "userEmail": "varshni.murali09@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "ASWINI/அஸ்வினி", "profileid": "47", "relDate": "-- Not Found --", "relRelation": "Friend" },
    { "relName": "Vinoth", "userEmail": "valarmathi840567@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "KARTHHIGAI/கார்த்திகை", "relNakshathra": "KETTAI/கேட்டை", "profileid": "48", "relDate": "-- Not Found --", "relRelation": "Spouse" },
    { "relName": "Valarmathi", "userEmail": "vinothsuresh1724@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "ROHINI/ரோகிணி", "profileid": "49", "relDate": "21-10-2024", "relRelation": "Spouse" },
    { "relName": "Sujatha K", "userEmail": "narayanansridhar10@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "VAIGAASI/வைகாசி", "relNakshathra": "KETTAI/கேட்டை", "profileid": "50", "relDate": "-- Not Found --", "relRelation": "Spouse" },
    { "relName": "Vignesh", "userEmail": "evilalwaysleads001@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "ROHINI/ரோகிணி", "profileid": "66", "relDate": "21-10-2024", "relRelation": "Friend" },
    { "relName": "Ramasubramanian", "userEmail": "jayanagshree@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "PANGUNI/பங்குனி", "relNakshathra": "POORADAM/பூராடம்", "profileid": "67", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Ramalingam", "userEmail": "jayanagshree@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "THAI/தை", "relNakshathra": "POORATATHI/பூரட்டாதி", "profileid": "68", "relDate": "-- Not Found --", "relRelation": "Spouse" },
    { "relName": "Nagasubramanian", "userEmail": "jayanagshree@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "KARTHHIGAI/கார்த்திகை", "relNakshathra": "UTHIRAM/உத்திரம்", "profileid": "69", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Ramasubramanian", "userEmail": "rajeshwariram77@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AANI/ஆனி", "relNakshathra": "SWATHI/சுவாதி", "profileid": "70", "relDate": "-- Not Found --", "relRelation": "Spouse" },
    { "relName": "Sumedh", "userEmail": "rajeshwariram77@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "VAIGAASI/வைகாசி", "relNakshathra": "MOOLAM/மூலம்", "profileid": "71", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Sivakumar ", "userEmail": "llbjayashree81@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "MAASI/மாசி", "relNakshathra": "MAGAM/மகம்", "profileid": "72", "relDate": "-- Not Found --", "relRelation": "Parent" },
    { "relName": "Vinay varshigan", "userEmail": "llbjayashree81@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "CHITHIRAI/சித்திரை", "relNakshathra": "UTHIRADAM/உத்திராடம்", "profileid": "73", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Maheswari", "userEmail": "maheswari.mca@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AAVANI/ஆவணி", "relNakshathra": "ASWINI/அஸ்வினி", "profileid": "74", "relDate": "-- Not Found --", "relRelation": "Parent" },
    { "relName": "Vignesh", "userEmail": "maheswari.mca@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "ROHINI/ரோகிணி", "profileid": "75", "relDate": "21-10-2024", "relRelation": "Children" },
    { "relName": "Surya", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "PANGUNI/பங்குனி", "relNakshathra": "VISAKAM/விசாகம்", "profileid": "76", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Muthu Mama", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "VAIGAASI/வைகாசி", "relNakshathra": "UTHIRAM/உத்திரம்", "profileid": "77", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Nandakumar", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "THAI/தை", "relNakshathra": "POORAM/பூரம்", "profileid": "78", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Arjun", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AADI/ஆடி", "relNakshathra": "POORATATHI/பூரட்டாதி", "profileid": "79", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Geetha", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AADI/ஆடி", "relNakshathra": "THIRUVATHIRA/திருவாதிரை", "profileid": "80", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Kumar", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "MAASI/மாசி", "relNakshathra": "MOOLAM/மூலம்", "profileid": "81", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "R Vidya", "userEmail": "raja.gs@samantacomm.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "PANGUNI/பங்குனி", "relNakshathra": "SADHAYAM/சதயம்", "profileid": "98", "relDate": "-- Not Found --", "relRelation": "Spouse" },
    { "relName": "Rama K", "userEmail": "raja.gs@samantacomm.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "MIRUGASIRDAM/மிருகசீரிடம்", "profileid": "99", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Kaushik", "userEmail": "raja.gs@samantacomm.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "UTHIRADAM/உத்திராடம்", "profileid": "100", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Pavithra", "userEmail": "raja.gs@samantacomm.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": "", "relTithi": "", "relMonth": "MARGAZHI/மார்கழி", "relNakshathra": "BARANI/பரணி", "profileid": "101", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Kutta", "userEmail": "sathyacm23@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "KARTHHIGAI/கார்த்திகை", "relNakshathra": "THIRUVATHIRA/திருவாதிரை", "profileid": "104", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Lakshmi", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "PURATTASI/புரட்டாசி", "relNakshathra": "ASTHAM/அஸ்தம்", "profileid": "105", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Balaji", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "PURATTASI/புரட்டாசி", "relNakshathra": "UTHIRAM/உத்திரம்", "profileid": "106", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Raja", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AANI/ஆனி", "relNakshathra": "MAGAM/மகம்", "profileid": "107", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Kannan", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AAVANI/ஆவணி", "relNakshathra": "ROHINI/ரோகிணி", "profileid": "108", "relDate": "27-08-2024", "relRelation": "Sibling" },
    { "relName": "Rama Kannan", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "MIRUGASIRDAM/மிருகசீரிடம்", "profileid": "109", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Vidya Balaji", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "PURATTASI/புரட்டாசி", "relNakshathra": "REVATHI/ரேவதி", "profileid": "110", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Sundari Mami", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "CHITHIRAI/சித்திரை", "relNakshathra": "REVATHI/ரேவதி", "profileid": "111", "relDate": "-- Not Found --", "relRelation": "Parent" },
    { "relName": "Raji Raja", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "PANGUNI/பங்குனி", "relNakshathra": "SADHAYAM/சதயம்", "profileid": "112", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Karan", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "THAI/தை", "relNakshathra": "KETTAI/கேட்டை", "profileid": "113", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Kowshik", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "UTHIRADAM/உத்திராடம்", "profileid": "114", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Pavithra", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "MARGAZHI/மார்கழி", "relNakshathra": "BARANI/பரணி", "profileid": "115", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Bharath", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AAVANI/ஆவணி", "relNakshathra": "UTHIRAM/உத்திரம்", "profileid": "116", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Anand", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "CHITHIRAI/சித்திரை", "relNakshathra": "AYILYAM/ஆயில்யம்", "profileid": "117", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Vishnu", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "POORAM/பூரம்", "profileid": "118", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Vaishnavi ", "userEmail": "suryasundhar.sk@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AAVANI/ஆவணி", "relNakshathra": "PUNARPOOSAM/புனர்பூசம்", "profileid": "120", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Maha Swetha", "userEmail": "suryasundhar.sk@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "CHITHIRAI/சித்திரை", "relNakshathra": "UTHIRAM/உத்திரம்", "profileid": "121", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Chakri", "userEmail": "thinnaloorikiran7337@gmail.com", "relGender": "male", "relCalender": "Telugu", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "Ashwayujamu/ఆశ్వయుజము", "relNakshathra": "Revati/రేవతి", "profileid": "124", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Madhavi ", "userEmail": "vrishit2008@gmail.com", "relGender": "female", "relCalender": "Telugu", "nakshatratithi": "Tithi", "relPaksham": "SHUKLA PAKSHA/శుక్లపక్షం", "relTithi": "ASHTAMI/అష్టమి", "relMonth": "Chaitramu/చైత్రము", "relNakshathra": null, "profileid": "129", "relDate": "-- Not Found --", "relRelation": "Parent" },
    { "relName": "Murthy ", "userEmail": "vrishit2008@gmail.com", "relGender": "male", "relCalender": "Telugu", "nakshatratithi": "Tithi", "relPaksham": "SHUKLA PAKSHA/శుక్లపక్షం", "relTithi": "SAPTAMI/సప్తమి", "relMonth": "Pushyamu/పుష్యము", "relNakshathra": null, "profileid": "130", "relDate": "-- Not Found --", "relRelation": "Parent" },
    { "relName": "Varshit", "userEmail": "vrishit2008@gmail.com", "relGender": "male", "relCalender": "Telugu", "nakshatratithi": "Tithi", "relPaksham": "SHUKLA PAKSHA/శుక్లపక్షం", "relTithi": "NAVAMI/నవమి", "relMonth": "Pushyamu/పుష్యము", "relNakshathra": null, "profileid": "131", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Raghavendra", "userEmail": "akhi.lekha@gmail.com", "relGender": "male", "relCalender": "Telugu", "nakshatratithi": "Tithi", "relPaksham": "KRISHNA PAKSHA/కృష్ణపక్షం", "relTithi": "ASHTAMI/అష్టమి", "relMonth": "Sravanamu/శ్రావణము", "relNakshathra": null, "profileid": "136", "relDate": "-- Not Found --", "relRelation": "Spouse" },
    { "relName": "Srividya ", "userEmail": "vittuvidhya@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "ANUSHAM/அனுஷம்", "profileid": "137", "relDate": "-- Not Found --", "relRelation": "Parent" },
    { "relName": "Shankar", "userEmail": "vittuvidhya@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "KARTHHIGAI/கார்த்திகை", "relNakshathra": "PUNARPOOSAM/புனர்பூசம்", "profileid": "138", "relDate": "-- Not Found --", "relRelation": "Parent" },
    { "relName": "Bhargava", "userEmail": "vittuvidhya@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "KARTHHIGAI/கார்த்திகை", "relNakshathra": "UTHIRATTATHI/உத்திரட்டாதி", "profileid": "139", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Venkateswaran", "userEmail": "suryacbr@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": "", "relTithi": "", "relMonth": "MAASI/மாசி", "relNakshathra": "ASHLESHA/ஆயில்யம்", "profileid": "140", "relDate": "23-02-2024", "relRelation": "Parent" },
    { "relName": "Gowri", "userEmail": "suryacbr@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": "", "relTithi": "", "relMonth": "MAASI/மாசி", "relNakshathra": "BHARANI/பரணி", "profileid": "141", "relDate": "16-02-2024", "relRelation": "Parent" },
    { "relName": "Arjun", "userEmail": "suryacbr@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": "", "relTithi": "", "relMonth": "AADI/ஆடி", "relNakshathra": "PURVABHADRA/பூரட்டாதி", "profileid": "142", "relDate": "25-07-2024", "relRelation": "Sibling" },
    { "relName": "priya", "userEmail": "rishirvs1121@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AADI/ஆடி", "relNakshathra": "UTHIRAM/உத்திரம்", "profileid": "143", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Varsha", "userEmail": "rishirvs1121@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "MARGAZHI/மார்கழி", "relNakshathra": "ROHINI/ரோகிணி", "profileid": "144", "relDate": "11-01-2025", "relRelation": "Sibling" },
    { "relName": "vinoth", "userEmail": "rishirvs1121@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "KARTHHIGAI/கார்த்திகை", "relNakshathra": "KETTAI/கேட்டை", "profileid": "145", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "vivek", "userEmail": "rishirvs1121@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "CHITHIRAI/சித்திரை", "relNakshathra": "KETTAI/கேட்டை", "profileid": "146", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Subramani", "userEmail": "vittuvidhya@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "KARTHHIGAI/கார்த்திகை", "relNakshathra": "SADHAYAM/சதயம்", "profileid": "148", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Ashwini", "userEmail": "achusabarinathan@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "PURATTASI/புரட்டாசி", "relNakshathra": "ASWINI/அஸ்வினி", "profileid": "150", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Sainethra", "userEmail": "sainethra29082017@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AAVANI/ஆவணி", "relNakshathra": "ANUSHAM/அனுஷம்", "profileid": "151", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Subarmanni", "userEmail": "sainethra29082017@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "POOSAM/பூசம்", "profileid": "152", "relDate": "-- Not Found --", "relRelation": "Parent" },
    { "relName": "Akshaya", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "CHITHIRAI/சித்திரை", "relNakshathra": "POORAM/பூரம்", "profileid": "153", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Ashwini", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "CHITHIRAI/சித்திரை", "relNakshathra": "MIRUGASIRDAM/மிருகசீரிடம்", "profileid": "154", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Sowmya", "userEmail": "bhuvanakannan1305@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "AYILYAM/ஆயில்யம்", "profileid": "155", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Shruthilaya", "userEmail": "nsreeman4@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "VAIGAASI/வைகாசி", "relNakshathra": "SADHAYAM/சதயம்", "profileid": "157", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Anand ", "userEmail": "Ashok.75@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "CHITHIRAI/சித்திரை", "relNakshathra": "POOSAM/பூசம்", "profileid": "158", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "jgjdddd", "userEmail": "rishirvs1121@gmail.com", "relGender": "male", "relCalender": "Malayalam", "nakshatratithi": "Nakshatra", "relPaksham": "", "relTithi": "", "relMonth": "DHANU/ഭാനു", "relNakshathra": "ANURADHA/അനിഴം", "profileid": "159", "relDate": "-- Not Found --", "relRelation": "Grand Parent" },
    { "relName": "jgjdddd", "userEmail": "rishirvs1121@gmail.com", "relGender": "male", "relCalender": "Telugu", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "Margashiramu/మార్గశిరము", "relNakshathra": "Jyeshta/జ్యేష్ట", "profileid": "160", "relDate": "-- Not Found --", "relRelation": "Grand Parent" },
    { "relName": "Samcoms", "userEmail": "offlguy@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": "", "relTithi": "", "relMonth": "THAI/தை", "relNakshathra": "ASWINI/அஸ்வினி", "profileid": "179", "relDate": "-- Not Found --", "relRelation": "Parent" },
    { "relName": "Samcoms", "userEmail": "offlguy@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": "", "relTithi": "", "relMonth": "MARGAZHI/மார்கழி", "relNakshathra": "KETTAI/கேட்டை", "profileid": "180", "relDate": "-- Not Found --", "relRelation": "Grand Parent" },
    { "relName": "fdfdd", "userEmail": "offlguy@gmail.com", "relGender": "male", "relCalender": "Malayalam", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "MITHUNAM/മിഥുനം", "relNakshathra": "VISHAKA/വിശാഖം", "profileid": "181", "relDate": "19-06-2024", "relRelation": "Grand Parent" },
    { "relName": "Abishek", "userEmail": "offlguy@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": "", "relTithi": "", "relMonth": "PURATTASI/புரட்டாசி", "relNakshathra": "KRUTHIKAI/கார்த்திகை", "profileid": "183", "relDate": "-- Not Found --", "relRelation": "Friend" },
    { "relName": "Ramanathan Chitappa", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "KARTHHIGAI/கார்த்திகை", "relNakshathra": "AYILYAM/ஆயில்யம்", "profileid": "184", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Kannan USA", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "CHITHIRAI/சித்திரை", "relNakshathra": "VISAKAM/விசாகம்", "profileid": "185", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Raji USA", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "THAI/தை", "relNakshathra": "UTHIRATTATHI/உத்திரட்டாதி", "profileid": "186", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Roshan USA", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "UTHIRATTATHI/உத்திரட்டாதி", "profileid": "187", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Roshini USA", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AADI/ஆடி", "relNakshathra": "SADHAYAM/சதயம்", "profileid": "188", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Lakshmi", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "PURATTASI/புரட்டாசி", "relNakshathra": "ASTHAM/அஸ்தம்", "profileid": "189", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Bhavani Chromepet", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "AYILYAM/ஆயில்யம்", "profileid": "190", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Shivani", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "ROHINI/ரோகிணி", "profileid": "191", "relDate": "21-10-2024", "relRelation": "Relative" },
    { "relName": "Charu", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "MARGAZHI/மார்கழி", "relNakshathra": "MAGAM/மகம்", "profileid": "192", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Kumar", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "MAASI/மாசி", "relNakshathra": "MOOLAM/மூலம்", "profileid": "193", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Shanti Kumar", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "MARGAZHI/மார்கழி", "relNakshathra": "POORATATHI/பூரட்டாதி", "profileid": "194", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Akshaya Rahul", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "CHITHIRAI/சித்திரை", "relNakshathra": "POORAM/பூரம்", "profileid": "195", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Ashwini Kumar", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "CHITHIRAI/சித்திரை", "relNakshathra": "MIRUGASIRDAM/மிருகசீரிடம்", "profileid": "196", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Rahul", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "MARGAZHI/மார்கழி", "relNakshathra": "UTHIRADAM/உத்திராடம்", "profileid": "197", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Sivasailam", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AANI/ஆனி", "relNakshathra": "KETTAI/கேட்டை", "profileid": "198", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Geeta sailam", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AADI/ஆடி", "relNakshathra": "THIRUVATHIRAI/திருவாதிரை", "profileid": "199", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "Gayathri sailam", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "PUNARPOOSAM/புனர்பூசம்", "profileid": "200", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Puja Vignesh", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AANI/ஆனி", "relNakshathra": "POOSAM/பூசம்", "profileid": "201", "relDate": "-- Not Found --", "relRelation": "Children" },
    { "relName": "Shanti suryanarayanan", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "PANGUNI/பங்குனி", "relNakshathra": "ASWINI/அஸ்வினி", "profileid": "202", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Vignesh", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AAVANI/ஆவணி", "relNakshathra": "ASWINI/அஸ்வினி", "profileid": "203", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Vishwaksena", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "THAI/தை", "relNakshathra": "MAGAM/மகம்", "profileid": "204", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Viji Lakshman", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "KRUTHIKAI/கார்த்திகை", "profileid": "205", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Lakshmanan", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "CHITHIRAI/சித்திரை", "relNakshathra": "POORAM/பூரம்", "profileid": "206", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Balaji", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "PURATTASI/புரட்டாசி", "relNakshathra": "UTHIRAM/உத்திரம்", "profileid": "207", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Vidya Balaji", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "PURATTASI/புரட்டாசி", "relNakshathra": "REVATHI/ரேவதி", "profileid": "208", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "Pavithra Balaji", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "MARGAZHI/மார்கழி", "relNakshathra": "BARANI/பரணி", "profileid": "209", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "BHARATH PRASHANTH", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AAVANI/ஆவணி", "relNakshathra": "UTHIRAM/உத்திரம்", "profileid": "210", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "KANNAN DOHA", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AAVANI/ஆவணி", "relNakshathra": "ROHINI/ரோகிணி", "profileid": "211", "relDate": "27-08-2024", "relRelation": "Relative" },
    { "relName": "RAMA KANNAN", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "MIRUGASIRDAM/மிருகசீரிடம்", "profileid": "212", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "KARAN", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "THAI/தை", "relNakshathra": "KETTAI/கேட்டை", "profileid": "213", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "KAUSHIK KANNAN", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "UTHIRADAM/உத்திராடம்", "profileid": "214", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "RAJA MANDAVELI", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AANI/ஆனி", "relNakshathra": "MAGAM/மகம்", "profileid": "215", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "RAJI RAJA", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "PANGUNI/பங்குனி", "relNakshathra": "SADHAYAM/சதயம்", "profileid": "216", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "THIRUPURAM", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "CHITHIRAI/சித்திரை", "relNakshathra": "REVATHI/ரேவதி", "profileid": "217", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "ANAND RAJA", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "CHITHIRAI/சித்திரை", "relNakshathra": "AYILYAM/ஆயில்யம்", "profileid": "218", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "VISHNU RAJA", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "POORAM/பூரம்", "profileid": "219", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "MAMI KOLKATTA", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "MARGAZHI/மார்கழி", "relNakshathra": "ASWINI/அஸ்வினி", "profileid": "220", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "SUBHA KOLKATTA", "userEmail": "svenky2011@gmail.com", "relGender": "female", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "THAI/தை", "relNakshathra": "BARANI/பரணி", "profileid": "221", "relDate": "-- Not Found --", "relRelation": "Sibling" },
    { "relName": "SAURABH KOLKATA", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "CHITHIRAI/சித்திரை", "relNakshathra": "MAGAM/மகம்", "profileid": "222", "relDate": "-- Not Found --", "relRelation": "Relative" },
    { "relName": "GOVIND PATASHALA", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "PURATTASI/புரட்டாசி", "relNakshathra": "MIRUGASIRDAM/மிருகசீரிடம்", "profileid": "223", "relDate": "-- Not Found --", "relRelation": "Friend" },
    { "relName": "SABARINATHAN PATASHALA", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "AYPPASI/ஐப்பசி", "relNakshathra": "ASWINI/அஸ்வினி", "profileid": "224", "relDate": "-- Not Found --", "relRelation": "Friend" },
    { "relName": "ASHWATH", "userEmail": "svenky2011@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "KARTHHIGAI/கார்த்திகை", "relNakshathra": "POORATATHI/பூரட்டாதி", "profileid": "225", "relDate": "-- Not Found --", "relRelation": "Friend" },
    { "relName": "Rishi", "userEmail": "suryacbr@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": "", "relTithi": "", "relMonth": "AANI/ஆனி", "relNakshathra": "MRIGHASIRA/மிருகசிரம்", "profileid": "226", "relDate": "04-07-2024", "relRelation": "Friend" },
    { "relName": "Samcoms", "userEmail": "offlguy@gmail.com", "relGender": "male", "relCalender": "Malayalam", "nakshatratithi": "Tithi", "relPaksham": "SHUKLA PAKSHA/വെളുത്ത പക്ഷം", "relTithi": "TRAYODASI/ത്രിദശി", "relMonth": "KANNI/കാനി", "relNakshathra": "", "profileid": "227", "relDate": "15-10-2024", "relRelation": "Grand Parent" },
    { "relName": "fdfdd", "userEmail": "offlguy@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Tithi", "relPaksham": "SHUKLA PAKSHA/சுக்ல பக்ஷம்", "relTithi": "DVADASI/துவாதசி", "relMonth": "MAASI/மாசி", "relNakshathra": null, "profileid": "228", "relDate": "20-02-2024", "relRelation": "Grand Parent" },
    { "relName": "Surya", "userEmail": "arjunuvacha@gmail.com", "relGender": "male", "relCalender": "Tamil", "nakshatratithi": "Nakshatra", "relPaksham": null, "relTithi": null, "relMonth": "PANGUNI/பங்குனி", "relNakshathra": "VISHAKA/விசாகம்", "profileid": "229", "relDate": "29-03-2024", "relRelation": "Sibling" }
  ]

  console.log("user database");
  // [
  //   {"userName":"Venkateswaran ","userEmail":"svenky2011@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"MAASI/மாசி","userNakshathra":"AYILYAM/ஆயில்யம்","userDate":"-- Not Found --","firstuserid":"12"},
  //   {"userName":"Arjun","userEmail":"arjunuvacha@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":" ","userTithi":" ","userMonth":"AADI/ஆடி","userNakshathra":"PURVABHADRA/பூரட்டாதி","userDate":"25-07-2024","firstuserid":"14"},
  //   {"userName":"Vivek","userEmail":"viveksnk005@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"CHITHIRAI/சித்திரை","userNakshathra":"KETTAI/கேட்டை","userDate":"-- Not Found --","firstuserid":"17"},
  //   {"userName":"Vidya","userEmail":"vidyabalajigs@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"PURATTASI/புரட்டாசி","userNakshathra":"REVATHI/ரேவதி","userDate":"-- Not Found --","firstuserid":"18"},
  //   {"userName":"Kaushik Manikandan Kannan","userEmail":"kaushikshankar2@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AYPPASI/ஐப்பசி","userNakshathra":"UTHIRADAM/உத்திராடம்","userDate":"-- Not Found --","firstuserid":"19"},
  //   {"userName":"Bhuvana","userEmail":"bhuvanakannan1305@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"THAI/தை","userNakshathra":"POORAM/பூரம்","userDate":"-- Not Found --","firstuserid":"20"},
  //   {"userName":"Ashok","userEmail":"ashok.75@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"PURATTASI/புரட்டாசி","userNakshathra":"CHITHIRAI/சித்திரை","userDate":"-- Not Found --","firstuserid":"21"},
  //   {"userName":"Ankur Ghosh","userEmail":"ankurr369@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Hindi","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"BHADRAPADA/भाद्रपद","userNakshathra":"KRITTIKA/कृत्तिका","userDate":"26-08-2024","firstuserid":"24"},
  //   {"userName":"Vidya","userEmail":"vidhur12@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AADI/ஆடி","userNakshathra":"MAGAM/மகம்","userDate":"-- Not Found --","firstuserid":"25"},
  //   {"userName":"Shankar kannan ","userEmail":"karanshankar1@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"VAIGAASI/வைகாசி","userNakshathra":"KETTAI/கேட்டை","userDate":"-- Not Found --","firstuserid":"26"},
  //   {"userName":"Rathna ","userEmail":"rishirathna11@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"THAI/தை","userNakshathra":"POOSAM/பூசம்","userDate":"-- Not Found --","firstuserid":"27"},
  //   {"userName":"K.lakshmanan","userEmail":"lakshmanan237@yahoo.in","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"PURATTASI/புரட்டாசி","userNakshathra":"POORATATHI/பூரட்டாதி","userDate":"-- Not Found --","firstuserid":"28"},
  //   {"userName":"Vaishnav ","userEmail":"dytmotaop@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Malayalam","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"KARKADAKAM/കാർകിഡകാം","userNakshathra":"UTTRA PHALGUNI/ഉത്രം","userDate":"-- Not Found --","firstuserid":"29"},
  //   {"userName":"Sankaranarayanan kp","userEmail":"sankaranarayanankp91@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Malayalam","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"MITHUNAM/മിഥുനം","userNakshathra":"ARDRA/തിരുവാതിര","userDate":"-- Not Found --","firstuserid":"30"},
  //   {"userName":"BHARANIDHARAN ","userEmail":"iravuparavai96@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"PURATTASI/புரட்டாசி","userNakshathra":"BARANI/பரணி","userDate":"-- Not Found --","firstuserid":"31"},
  //   {"userName":"Aniruddh Sridharaan ","userEmail":"aniruddhsridharaan@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"MAASI/மாசி","userNakshathra":"AVITTAM/அவிட்டம்","userDate":"-- Not Found --","firstuserid":"32"},
  //   {"userName":"Varshni","userEmail":"varshni.murali09@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AAVANI/ஆவணி","userNakshathra":"POORAM/பூரம்","userDate":"-- Not Found --","firstuserid":"33"},
  //   {"userName":"Pooja ","userEmail":"poojapsad3@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AADI/ஆடி","userNakshathra":"POOSAM/பூசம்","userDate":"-- Not Found --","firstuserid":"36"},
  //   {"userName":"Pooja ","userEmail":"poojapsad3@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AANI/ஆனி","userNakshathra":"POOSAM/பூசம்","userDate":"-- Not Found --","firstuserid":"37"},
  //   {"userName":"Sivabarani ","userEmail":"vellurbarani@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AANI/ஆனி","userNakshathra":"BARANI/பரணி","userDate":"-- Not Found --","firstuserid":"38"},
  //   {"userName":"Sathya","userEmail":"sathyacm23@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AYPPASI/ஐப்பசி","userNakshathra":"UTHIRATTATHI/உத்திரட்டாதி","userDate":"-- Not Found --","firstuserid":"39"},
  //   {"userName":"Valarmathi ","userEmail":"valarmathi840567@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AYPPASI/ஐப்பசி","userNakshathra":"ROHINI/ரோகிணி","userDate":"21-10-2024","firstuserid":"40"},
  //   {"userName":"S.Vinoth","userEmail":"vinothsuresh1724@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"KARTHHIGAI/கார்த்திகை","userNakshathra":"KETTAI/கேட்டை","userDate":"-- Not Found --","firstuserid":"42"},
  //   {"userName":"Narayanan PS","userEmail":"narayanansridhar10@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"THAI/தை","userNakshathra":"KETTAI/கேட்டை","userDate":"-- Not Found --","firstuserid":"44"},
  //   {"userName":"Radhika Shetty","userEmail":"undoradhika@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Hindi","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"MAGH/माघ","userNakshathra":"ROHINI/रोहिणी","userDate":"18-02-2024","firstuserid":"48"},
  //   {"userName":"Radhika Shetty","userEmail":"undoradhika@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Hindi","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"MAGH/माघ","userNakshathra":"ROHINI/रोहिणी","userDate":"18-02-2024","firstuserid":"49"},
  //   {"userName":"Eliya patra","userEmail":"eliyapatra2003@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Hindi","nakshatratithi":"Tithi","userPaksham":"SHUKLA PAKSHA/शुक्ल पक्ष","userTithi":"PRATHAMA/प्रतिपदा","userMonth":"CHAITRA/चैत्र","userNakshathra":null,"userDate":"-- Not Found --","firstuserid":"50"},
  //   {"userName":"Gokul raj","userEmail":"evilalwaysleads001@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AYPPASI/ஐப்பசி","userNakshathra":"SADHAYAM/சதயம்","userDate":"-- Not Found --","firstuserid":"52"},
  //   {"userName":"Jayashree ","userEmail":"jayanagshree@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"MAASI/மாசி","userNakshathra":"SWATHI/சுவாதி","userDate":"-- Not Found --","firstuserid":"53"},
  //   {"userName":"Sumedh","userEmail":"rajeshwariram77@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"VAIGAASI/வைகாசி","userNakshathra":"MOOLAM/மூலம்","userDate":"-- Not Found --","firstuserid":"54"},
  //   {"userName":"Vimarsini Murali","userEmail":"vimarsinimurali@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"THAI/தை","userNakshathra":"KRUTHIKAI/கார்த்திகை","userDate":"-- Not Found --","firstuserid":"55"},
  //   {"userName":"Sai varshan","userEmail":"llbjayashree81@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"KARTHHIGAI/கார்த்திகை","userNakshathra":"ASWINI/அஸ்வினி","userDate":"-- Not Found --","firstuserid":"56"},
  //   {"userName":"Jayashree ","userEmail":"llbjayashree81@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AAVANI/ஆவணி","userNakshathra":"ASTHAM/அஸ்தம்","userDate":"-- Not Found --","firstuserid":"57"},
  //   {"userName":"Dhakshith","userEmail":"maheswari.mca@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"PANGUNI/பங்குனி","userNakshathra":"POORATATHI/பூரட்டாதி","userDate":"-- Not Found --","firstuserid":"58"},
  //   {"userName":"Lalitha","userEmail":"lbarguru@yahoo.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"PURATTASI/புரட்டாசி","userNakshathra":"ASTHAM/அஸ்தம்","userDate":"-- Not Found --","firstuserid":"59"},
  //   {"userName":"Hariharan","userEmail":"v_hh69@hotmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AANI/ஆனி","userNakshathra":"MIRUGASIRDAM/மிருகசீரிடம்","userDate":"-- Not Found --","firstuserid":"60"},
  //   {"userName":"G S Raja","userEmail":"raja.gs@samantacomm.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AANI/ஆனி","userNakshathra":"MAGAM/மகம்","userDate":"-- Not Found --","firstuserid":"64"},
  //   {"userName":"abi","userEmail":"offlguy@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":" ","userTithi":" ","userMonth":"MAASI/மாசி","userNakshathra":"THIRUVATHIRAI/திருவாதிரை","userDate":"-- Not Found --","firstuserid":"65"},
  //   {"userName":"rishikesh","userEmail":"rishirvs1121@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AANI/ஆனி","userNakshathra":"MIRUGASIRDAM/மிருகசீரிடம்","userDate":"-- Not Found --","firstuserid":"66"},
  //   {"userName":"Rama","userEmail":"ramakannn@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AYPPASI/ஐப்பசி","userNakshathra":"MIRUGASIRDAM/மிருகசீரிடம்","userDate":"-- Not Found --","firstuserid":"67"},
  //   {"userName":"Surya","userEmail":"suryacbr@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":" ","userTithi":" ","userMonth":"PANGUNI/பங்குனி","userNakshathra":"VISHAKA/விசாகம்","userDate":"29-03-2024","firstuserid":"68"},
  //   {"userName":"Surya ","userEmail":"suryasundhar.sk@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AANI/ஆனி","userNakshathra":"SADHAYAM/சதயம்","userDate":"-- Not Found --","firstuserid":"69"},
  //   {"userName":"SIVAGURU","userEmail":"10.10.10dulkarsachin@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"KARTHHIGAI/கார்த்திகை","userNakshathra":"AVITTAM/அவிட்டம்","userDate":"-- Not Found --","firstuserid":"70"},
  //   {"userName":"Balasubrahmanyam ","userEmail":"sivaramabalu@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Telugu","nakshatratithi":"Tithi","userPaksham":"SHUKLA PAKSHA/శుక్లపక్షం","userTithi":"ASHTAMI/అష్టమి","userMonth":"Pushyamu/పుష్యము","userNakshathra":null,"userDate":"-- Not Found --","firstuserid":"72"},
  //   {"userName":"Kiran","userEmail":"thinnaloorikiran7337@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Telugu","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"Ashadamu/ఆషాఢము","userNakshathra":"Mrigashirsha/మృగశిర","userDate":"-- Not Found --","firstuserid":"73"},
  //   {"userName":"Bhavani ","userEmail":"bhavanikrishnan69@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"CHITHIRAI/சித்திரை","userNakshathra":"POORADAM/பூராடம்","userDate":"-- Not Found --","firstuserid":"74"},
  //   {"userName":"Rishit ","userEmail":"vrishit2008@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Telugu","nakshatratithi":"Tithi","userPaksham":"SHUKLA PAKSHA/శుక్లపక్షం","userTithi":"POURNAMI/పౌర్ణమి/పౌర్ణమి","userMonth":"Chaitramu/చైత్రము","userNakshathra":null,"userDate":"-- Not Found --","firstuserid":"75"},
  //   {"userName":"Lakshmi","userEmail":"lakshmiramesh304@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AYPPASI/ஐப்பசி","userNakshathra":"SADHAYAM/சதயம்","userDate":"-- Not Found --","firstuserid":"76"},
  //   {"userName":"Akhila","userEmail":"akhi.lekha@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Telugu","nakshatratithi":"Tithi","userPaksham":"KRISHNA PAKSHA/కృష్ణపక్షం","userTithi":"PANCHAMI/పంచమి","userMonth":"Bhaadrapadamu/భాద్రపదము","userNakshathra":null,"userDate":"-- Not Found --","firstuserid":"77"},
  //   {"userName":"Sarweswaran ","userEmail":"sarwesh.shiva003@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AANI/ஆனி","userNakshathra":"UTHIRATTATHI/உத்திரட்டாதி","userDate":"-- Not Found --","firstuserid":"78"},
  //   {"userName":"Shivani","userEmail":"shivgovind0013@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AYPPASI/ஐப்பசி","userNakshathra":"ROHINI/ரோகிணி","userDate":"21-10-2024","firstuserid":"79"},
  //   {"userName":"Govind","userEmail":"vittuvidhya@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"PURATTASI/புரட்டாசி","userNakshathra":"MIRUGASIRDAM/மிருகசீரிடம்","userDate":"-- Not Found --","firstuserid":"80"},
  //   {"userName":"gayathri","userEmail":"saibadrinath02@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AADI/ஆடி","userNakshathra":"UTHIRADAM/உத்திராடம்","userDate":"-- Not Found --","firstuserid":"81"},
  //   {"userName":"Nagalakshmi Govindraman ","userEmail":"bhavanig94@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AYPPASI/ஐப்பசி","userNakshathra":"AYILYAM/ஆயில்யம்","userDate":"-- Not Found --","firstuserid":"82"},
  //   {"userName":"Badhriprasath","userEmail":"raj0sathiya@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":" ","userTithi":" ","userMonth":"AADI/ஆடி","userNakshathra":"POORADAM/பூராடம்","userDate":"-- Not Found --","firstuserid":"83"},
  //   {"userName":"S.Govind","userEmail":"ritvikgovind2009@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"PURATTASI/புரட்டாசி","userNakshathra":"MIRUGASIRDAM/மிருகசீரிடம்","userDate":"-- Not Found --","firstuserid":"84"},
  //   {"userName":"Sujitha.G","userEmail":"rowdysuji75@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"THAI/தை","userNakshathra":"ASWINI/அஸ்வினி","userDate":"-- Not Found --","firstuserid":"85"},
  //   {"userName":"Sabarinathan","userEmail":"achusabarinathan@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AYPPASI/ஐப்பசி","userNakshathra":"ASWINI/அஸ்வினி","userDate":"-- Not Found --","firstuserid":"86"},
  //   {"userName":"Sabarinathan","userEmail":"achusabarinathan@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AYPPASI/ஐப்பசி","userNakshathra":"ASWINI/அஸ்வினி","userDate":"-- Not Found --","firstuserid":"87"},
  //   {"userName":"Ramya","userEmail":"sramya16071988@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AADI/ஆடி","userNakshathra":"AYILYAM/ஆயில்யம்","userDate":"-- Not Found --","firstuserid":"88"},
  //   {"userName":"Jayalakshmi ","userEmail":"sainethra29082017@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AANI/ஆனி","userNakshathra":"ROHINI/ரோகிணி","userDate":"03-07-2024","firstuserid":"89"},
  //   {"userName":"Bhavani","userEmail":"1971bhavanikumar@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"MARGAZHI/மார்கழி","userNakshathra":"ASWINI/அஸ்வினி","userDate":"-- Not Found --","firstuserid":"90"},
  //   {"userName":"Sreeman Sanjeevan","userEmail":"nsreeman4@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AAVANI/ஆவணி","userNakshathra":"SWATHI/சுவாதி","userDate":"-- Not Found --","firstuserid":"91"},
  //   {"userName":"Sreeman","userEmail":"nsreeman4@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AAVANI/ஆவணி","userNakshathra":"SWATHI/சுவாதி","userDate":"-- Not Found --","firstuserid":"92"},
  //   {"userName":"Surya","userEmail":"surya49official@gmail.com","userGender":"male","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":" ","userTithi":" ","userMonth":"PANGUNI/பங்குனி","userNakshathra":"VISAKAM/விசாகம்","userDate":"-- Not Found --","firstuserid":"93"},
  //   {"userName":"Pooja Pophale","userEmail":"poojapophale@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Hindi","nakshatratithi":"Tithi","userPaksham":"KRISHNA PAKSHA/कृष्ण पक्ष","userTithi":"ASHTAMI/अष्टमी","userMonth":"CHAITRA/चैत्र","userNakshathra":null,"userDate":"02-04-2024","firstuserid":"94"},
  //   {"userName":"Pragati Srinivasan","userEmail":"pragatis866@gmail.com","userGender":"female","userRelation":"Myself","userCalender":"Tamil","nakshatratithi":"Nakshatra","userPaksham":null,"userTithi":null,"userMonth":"AADI/ஆடி","userNakshathra":"UTHIRADAM/உத்திராடம்","userDate":"-- Not Found --","firstuserid":"95"}
  //   ]

  const convertToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'userdatabase.xlsx');
  };

  return (
    <div>
      <button onClick={convertToExcel}>Download Excel</button>
    </div>
  );
}

export default RoughTwo;