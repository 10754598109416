import React, { useRef } from 'react';

const RotatingCube = () => {

  return (
    //  <mesh></mesh>
    <>
    </>
  );
};

export default RotatingCube;
