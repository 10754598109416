import React, { useState } from "react";
import { useEffect } from "react";
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import Top20px from "./Top20px";
import Top10px from "./Top10px";
import LandingHeader from "../components/LandingHeader"
import axios from "axios";
import Swal from "sweetalert2";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import ScrollToTop from "react-scroll-to-top";

const ContactScreen = () => {

    const [secureId, setSecureId] = useState("")

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts

        const uniqId = localStorage.getItem("SD_Id")
        setSecureId(uniqId)


    }, []);

    const [dynamicState, setDynamicState] = useState({
        userName: "",
        userEmail: "",
        userPhone: "",
        userMessage: ""
    })

    useEffect(() => {
        setDynamicState(prevState => ({
            ...prevState,
            securedId: secureId
        }));
    }, [secureId])

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(dynamicState);
        const url = `https://backend.shubadinam.com/contactmessage`

        axios.post(url, dynamicState)
            .then((response) => {
                // console.log(response.data);
                Swal.fire({
                    title: 'Thank you for your feedback',
                    text: response.data.message,
                    icon: 'success',
                    confirmButtonText: 'OK'
                });

                setTimeout(() => {
                    window.location.reload();
                }, 2000);


            })
            .catch((error) => {
                // console.log(error);
            })
    }

    const handleInput = (e) => {
        setDynamicState({ ...dynamicState, [e.target.name]: e.target.value })
    }

    return (
        <div className="overall-container">
            <ScrollToTop smooth />
            <HelmetProvider>
                <Helmet>
                    <title>Contact </title>
                    <meta name="description" content="Contact  - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <div className="overall-percent">
                <LandingHeader />
                <Top20px />
                <div className="center-container-whyus" style={{ height: "auto" }}>


                    {/* <section className="contact-section section-padding" id="section_6"> */}
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-12 mx-auto">
                                <h2 className="text-center mb-4" style={{ fontFamily: "serif", paddingTop: "60px" }}>Interested? Let's talk</h2>
                                <nav className="d-flex justify-content-center">
                                    <div className="nav nav-tabs align-items-baseline justify-content-center" id="nav-tab" role="tablist">
                                        <button className="nav-link active" id="nav-ContactForm-tab" data-bs-toggle="tab" data-bs-target="#nav-ContactForm" type="button" role="tab" aria-controls="nav-ContactForm" aria-selected="false">
                                            <h5 style={{ fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>Contact Form</h5>
                                        </button>
                                        <button className="nav-link" id="nav-ContactMap-tab" data-bs-toggle="tab" data-bs-target="#nav-ContactMap" type="button" role="tab" aria-controls="nav-ContactMap" aria-selected="false">
                                            <h5 style={{ fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>Google Maps</h5>
                                        </button>
                                    </div>
                                </nav>
                                <div className="tab-content shadow-lg mt-5" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="nav-ContactForm" role="tabpanel" aria-labelledby="nav-ContactForm-tab">
                                        <form className="custom-form contact-form mb-5 mb-lg-0"
                                            onSubmit={(e) => handleSubmit(e)}
                                        // action="#"
                                        //  method="post"
                                        //   role="form"
                                        >
                                            <div className="contact-form-body">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-12">
                                                        <input type="text" name="userName" id="contact-name" className="form-control" placeholder="Full name" required onChange={(e) => handleInput(e)} />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-12">
                                                        <input type="email" name="userEmail" id="contact-email" pattern="[^ @]*@[^ @]*" className="form-control" placeholder="Registered Email" required onChange={(e) => handleInput(e)} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-12">
                                                    <input type="text" name="userPhone" id="contact-company" className="form-control" placeholder="Phone Number" required onChange={(e) => handleInput(e)} />
                                                </div>

                                                <textarea name="userMessage" rows={3} className="form-control" id="contact-message" placeholder="Message" defaultValue={""} onChange={(e) => handleInput(e)} />

                                                <div className="col-lg-4 col-md-10 col-8 mx-auto">
                                                    <button type="submit" className="form-control" disabled={
                                                        dynamicState.userMessage === ''
                                                    }>Send message</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="tab-pane fade" id="nav-ContactMap" role="tabpanel" aria-labelledby="nav-ContactMap-tab"> <div className="map-container">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d124376.04248286066!2d80.19640319999999!3d13.0514944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1707122076138!5m2!1sen!2sin" title="Contact Map" style={{ border: "0" }} allowfullscreen="" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />

                    <br />
                    <br />
                    {/* <h2>Contact Us</h2> */}
                    <div className="contact-us-details">
                        <div className="contact-us-left">
                            <h1 className="letters-color-subhead1">Email: {"    "}<span style={{ fontWeight: 100, color: "#595959", paddingLeft: "10px" }}>{'<info@shubadinam.com>'}</span></h1>
                            {/* <br /> */}
                            <h1 className="letters-color-subhead1">Phone Number: {" "}<span style={{ fontWeight: 100, color: "#595959", paddingLeft: "10px", fontSize: "16px" }}>+91 8925251938</span></h1>

                        </div>
                        <div className="contact-us-right">

                            <p className="letters-color-subhead1">Address:</p>
                            <address style={{ fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}></address>
                            <div className="contact-address">
                                <div className="address-1">

                                    <p >UNIT NO. M-1, 1ST FLOOR,</p>
                                    <p>Landmark Cyber Park, Sector 67,</p>
                                    <p>Gurugram, Haryana</p>


                                </div>
                                <div className="address-2">
                                    <p >Plot no 36, 13th street,</p>
                                    <p>Balaji Nagar, Adambakkam,</p>
                                    <p>Chennai - 600 088</p>

                                </div>


                            </div>

                        </div>

                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    {/* </section> */}
                    <div className="empty-container-footer">
                        <FooterComponent></FooterComponent>
                    </div>

                </div>
            </div >
        </div >
        // <div className="overall-container">
        //     <div className="overall-percent">
        //         <HeaderComponent></HeaderComponent>
        //         <div className="center-container-whyus">




        //             <h2>Contact Us</h2>
        //             <ol>

        //             <h1 className="letters-color-subhead1">Contact us on {'<noreply@shubadinam.com>'}</h1>



        //             <p className="letters-color-subhead1">Address:</p>
        //                 <address>
        //                     <p>Plot no 36, 13th street,</p>
        //                     <p>Balaji Nagar, Adambakkam,</p>
        //                     <p>Chennai - 600088</p>
        //                 </address>



        //                 </ol>


        //         </div>
        //         <div className="empty-container-footer">
        //         <FooterComponent></FooterComponent>
        //         </div>
        //     </div>
        // </div> 

    )
}
export default ContactScreen;