import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import Top10px from "./Top10px";
import Top20px from "./Top20px";
import ScrollToTop from "react-scroll-to-top";
import LandingHeader from "../components/LandingHeader";

import { Helmet, HelmetProvider } from 'react-helmet-async';




const WhyUsPage = () => {

    const [isLesser768, setisLesser768] = useState(false)


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts

        const handleResize = () => {
            if (window.innerWidth < 768) {
                setisLesser768(true);
            } else {
                setisLesser768(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // const Navigate = useNavigate()

    // const signOut = () => {
    //     localStorage.removeItem("isAuthenticated")
    //     localStorage.removeItem("email")
    //     // Navigate("/")
    // }

    // const logoBtn = () => {
    //     Navigate("/")
    // }
    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Why us </title>
                    <meta name="description" content="Why us  - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <div className="overall-container-whyus">
                <div className="overall-percent">
                    <ScrollToTop smooth />
                    <LandingHeader />
                    {/* <HeaderComponent></HeaderComponent> */}
                    <Top10px />
                    {/* <Top20px /> */}

                    <section id="why-us-temp" className="why-us-temp">
                        <div className="container">
                            <div className="section-header">
                                <h2>Why {isLesser768 ? "Shubadinam" : "Us"}</h2>
                                <strong >Welcome to Shubadinam.com – where tradition meets technology, and your cosmic journey begins.
                                </strong>
                            </div>
                            <div className="why-divider">

                                <div className="why-divi-1">
                                    <img src={require("../images/why-us1.png")} alt onContextMenu={(e) => e.preventDefault()} />

                                    <div>

                                        <p>At Shubadinam, we're more than just a website. We're here to help you rediscover your roots and celebrate your {isLesser768 ? "Indian" : "Hindu"} identity. Imagine us as your guide on a journey to the ancient wisdom that's part of your heritage.</p>

                                        <h3>Empowering You to Celebrate Your Identity:</h3>

                                        <p>Shubadinam goes beyond dates, unveiling the magic of Birth Stars (Janma nakshatra)/Janma Tithi. It's like unlocking secrets of the stars on the day you were born.</p>

                                        <p>Our mission is to empower you, fostering a deep understanding and appreciation of your unique Janma Dinam, symbolized by your Janma nakshatra (Birth Star)/Janma Tithi. With our user-friendly Shubadinam finder, effortlessly receive notifications for your real Birthday, as well as those of your friends and family. These cosmic constellations are a part of you, and we're here to help you celebrate your special Janmadinam.

                                        </p>
                                        {isLesser768 && <button style={{ width: "150px", height: "50px", borderRadius: "10px", textDecorationLine: "none", backgroundColor: "#020332", color: "white" }}><NavLink to="/pricing" style={{ textDecorationLine: "none", color: "white" }}>Register Now</NavLink></button>}
                                    </div>
                                    <br />

                                </div>
                                <div className="why-divi-1">
                                    <div>
                                        <h3>Discover the Sacred Connection:</h3>
                                        <p>At Shubadinam, we blend tradition seamlessly into your daily life, fostering a community of individuals who cherish their Hindu heritage. Recognizing and celebrating your Janma dinam becomes a sacred connection with the divine, understanding the traits associated with your Nakshatra/Tithi deepens your appreciation for your individuality and divine purpose.</p>


                                        <p><NavLink to="/meanings-of-janma-nakshatra" style={{ textDecorationLine: "none" }}>(Click here to read more on 'Meanings of Janma Nakshatra)</NavLink>Celebrate your Janma Dinam for Good Health, Longer Life, Ample Wealth, Great fame, and spiritual Wisdom as you journey through the celestial influences shaping your life.</p>

                                        <p>Join us to celebrate your unique cosmic spark, learning about your roots.</p>

                                        <button style={{ width: "150px", height: "50px", borderRadius: "10px", textDecorationLine: "none", backgroundColor: "#020332", color: "white" }}><NavLink to="/pricing" style={{ textDecorationLine: "none", color: "white" }}>Get Started</NavLink></button>
                                    </div>
                                    <img src={require("../images/why-us2.png")} alt onContextMenu={(e) => e.preventDefault()} />

                                </div>


                                {/* <div className="col-lg-6">
                                    <img src={require("../images/undraw_Gift_box_re_vau4.png")} className="img-fluid rounded-4 mb-4" alt />
                                    <p>At Shubadinam, we're more than just a website. We're here to help you rediscover your roots and celebrate your Hindu identity. Imagine us as your guide on a journey to the ancient wisdom that's part of your heritage.</p>
                                    <p>Temporibus nihil enim deserunt sed ea. Provident sit expedita aut cupiditate nihil vitae quo officia vel. Blanditiis eligendi possimus et in cum. Quidem eos ut sint rem veniam qui. Ut ut repellendus nobis tempore doloribus debitis explicabo similique sit. Accusantium sed ut omnis beatae neque deleniti repellendus.</p>
                                </div>
                                <div className="col-lg-6">
                                    <div className="content ps-0 ps-lg-5">

                                        <p className="fst-italic">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                            magna aliqua.
                                        </p>
                                        <ul>
                                            <li><i className="bi bi-check-circle-fill" /> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                                            <li><i className="bi bi-check-circle-fill" /> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                                            <li><i className="bi bi-check-circle-fill" /> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
                                        </ul>
                                        <p>
                                            Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                                            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident
                                        </p>
                                        <div className="position-relative mt-4">
                                            <img src={require("../images/undraw_Gift_box_re_vau4.png")} className="img-fluid rounded-4" alt />
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </section>



                    {/* <div className="center-container-whyus">


                        <ol>

                            <h2 className="letters-color-subhead">Why Shubadinam.com?</h2>
                            <p className="letters-color-subhead1" >At Shubadinam, we're more than just a website. We're here to help you rediscover your roots and celebrate your Hindu identity. Imagine us as your guide on a journey to the ancient wisdom that's part of your heritage. </p>


                            <p className="letters-color-subhead1" >We're not only about dates. Shubadinam shows you the magic of Birth Star (Janma nakshatra)/Janma Tithi. It's like unlocking secrets of the stars on the day you were born. These cosmic constellations are a part of you, and we're here to help you celebrate your special day, your Janmadinam.</p>

                            <p className="letters-color-subhead1" >It's not just about the past, it's about making tradition a part of your daily life. We're a community that connects people who love their Hindu heritage. Join us at Shubadinam to celebrate who you are, learn about your roots and light up your unique cosmic spark.</p>


                        </ol>

                    </div> */}


                    <Top10px />
                    {/* <div className="empty-container-footer-whyus"> */}
                    <FooterComponent></FooterComponent>
                    {/* </div> */}
                </div>
            </div>
        </div>




    )
}

export default WhyUsPage;


// {/* <div className="container-header-whyus">
// <div className="wordpress">
//     <img className="logo-fit-header" src={require("../images/logo-1.png")} width="100%" height="100%" onClick={() => logoBtn()} />
// </div>
// <div className="headers">
//     <NavLink to="/home" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>Home</NavLink>
//     <NavLink to="/about-us" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>About</NavLink>
//     <NavLink to="/faq" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>FAQ</NavLink>
//     <NavLink to="/contact-us" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>Contact Us</NavLink>
// </div>
// <div className="login-out">
//     {/* <button className="signout-btn" onClick={() => signOut()}>Sign Out</button> */}
//     <NavLink to="/" onClick={() => signOut()}>Sign Out</NavLink>
// </div>
// </div>  */}