import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

const AdminSearch = ({ onSearch }) => {
    const [query, setQuery] = useState('');

    const handleSearch = (e) => {
        const inputValue = e.target.value;
        setQuery(inputValue); // Update state with the input value

        if (e.key === 'Enter') {
            // Handle search on Enter key press
            onSearch(inputValue.trim());
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Optional: Handle form submission logic if needed
        onSearch(query.trim()); // Ensure query is trimmed before searching
    };

    return (
        <form onSubmit={handleSubmit}>
            <TextField
                size='small'
                type="text"
                value={query}
                onChange={handleSearch}
                placeholder="Search..."
                onKeyDown={handleSearch} // Handle Enter key press
            />
            <Button type="submit" disabled={query.trim() === ""}>Search <PersonSearchIcon /></Button>
        </form>
    );
};

export default AdminSearch;
