import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, fa1, fa2, fa3, faUser, faL } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from "@fortawesome/fontawesome-free"

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

AOS.init({
    duration: 600,
});

const TestimonialScreen = () => {




    const testimonials = [
        {
            id: 1,
            profilePic: require("../images/Profile Icon1.jpg"),
            text: 'Testimonial 1: This is the first testimonial.',
            author: 'Author 1',
            background: 'linear-gradient(to left,white, #daeff5)'
        },
        {
            id: 2,
            text: 'Testimonial 2: This is the second testimonial.',
            profilePic: require("../images/profile icon2.jpg"),
            author: 'Author 2',
            background: 'linear-gradient(to left,white, #f6dcdc)'
        },
        {
            id: 3,
            text: 'Testimonial 3: This is the third testimonial.',
            profilePic: require("../images/Profile Icon1.jpg"),
            author: 'Author 3',
            background: 'linear-gradient(to left,white, #daeff5)'
        },
    ];

    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };
    const settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        background: "red",

    };


    return (

        <div className="slider-container">


            <Slider
                ref={slider => {
                    sliderRef = slider;
                }}
                {...settings}
            >
                <div key={1} className='slider-box'>
                    <img
                        className="rounded-circle shadow-1-strong mb-4"
                        src={require("../images/profile icon2.jpg")}
                        alt="avatar"
                        style={{ width: "80px", height: "80px", textAlign: "center", marginLeft: "43%" }}
                    />
                    <h3 className='how-cnt-para1 silder-text' style={{ textIndent: "5%", padding: "0px 25px", textAlign: "center" }}>"Shubadinam has transformed the way I celebrate my birthday. Embracing Indian traditions is giving a special feel on my birthday, sorry janmadin!" </h3>
                    {/* <br /> */}
                    <p style={{ textAlign: "center" }}>
                        - Priyakumari
                    </p>

                </div>
                <div key={2}>
                    <img
                        className="rounded-circle shadow-1-strong mb-4"
                        src={require("../images/Profile Icon1.jpg")}
                        alt="avatar"
                        style={{ width: "80px", height: "80px", textAlign: "center", marginLeft: "43%" }}
                    />
                    <h3 className='how-cnt-para1 silder-text' style={{ textIndent: "5%", padding: "0px 25px", textAlign: "center" }}>"I registered on Shubadinam last month for testing purpose. It was a an eye-opening experience. The notification was very insightful. Highly recommend!" </h3>
                    <p style={{ textAlign: "center" }}>
                        - Rahul
                    </p>
                </div>
                <div key={3} >
                    <img
                        className="rounded-circle shadow-1-strong mb-4"
                        src={require("../images/Profile Icon1.jpg")}
                        alt="avatar"
                        style={{ width: "80px", height: "80px", textAlign: "center", marginLeft: "43%" }}
                    />
                    <h3 className='how-cnt-para1 silder-text' style={{ textIndent: "5%", padding: "0px 25px", textAlign: "center" }}>"It's a joy and a satisfying feeling to celebrate birthdays the Indian way. Thanks to Shubadinam for making it easy!" </h3>
                    <p style={{ textAlign: "center" }}>
                        - Prashanth
                    </p>
                </div>
                <div key={4} >
                    <img
                        className="rounded-circle shadow-1-strong mb-4"
                        src={require("../images/profile icon2.jpg")}
                        alt="avatar"
                        style={{ width: "80px", height: "80px", textAlign: "center", marginLeft: "43%" }}
                    />
                    <h3 className='how-cnt-para1 silder-text' style={{ textIndent: "5%", padding: "0px 25px", textAlign: "center" }}>"Good Initiative!"</h3>
                    <p style={{ textAlign: "center" }}>
                        - Ankita Sharma
                    </p>
                </div>
                <div key={5} >
                    <img
                        className="rounded-circle shadow-1-strong mb-4"
                        src={require("../images/profile icon2.jpg")}
                        alt="avatar"
                        style={{ width: "80px", height: "80px", textAlign: "center", marginLeft: "43%" }}
                    />
                    <h3 className='how-cnt-para1 silder-text' style={{ textIndent: "5%", padding: "0px 25px", textAlign: "center" }}>"As someone deeply connected to my Indian roots, I found Shubadinam very useful and definitely recommend it's a must-visit site for anyone seeking to preserve our Hindu identity!"</h3>
                    <p style={{ textAlign: "center" }}>
                        - Ananya
                    </p>
                </div>

            </Slider>
            <div className='slider-buttons'>
                {/* <Stack direction="row" spacing={2}> */}
                <Button variant="outlined" onClick={previous} className="slider-button">Previous</Button>
                <Button variant="outlined" onClick={next} className="slider-button">Next</Button>
                {/* </Stack> */}

            </div>
        </div>



        // <Carousel centerMode centerSlidePercentage={100}>
        //     {testimonials.map(testimonial => (
        //         <div className='testimonial-h' key={testimonial.id} style={{
        //             background: testimonial.background, // Use the background property
        //             backgroundSize: 'cover',
        //             backgroundPosition: 'center',
        //             // padding: '25px',
        //         }}>
        //             <img src={testimonial.profilePic} alt={testimonial.author} className="testimonial-profile-pic" onContextMenu={(e) => e.preventDefault()} />
        //             {/* <p className="testimonial-text">{testimonial.text}</p> */}
        //             {/* <p className="testimonial-author">- {testimonial.author}</p> */}
        //         </div>
        //     ))}
        // </Carousel>


    );
}

export default TestimonialScreen;