import React, { useState, useEffect, useRef } from "react";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, fa1, fa2, fa3, faUser, faL } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from "@fortawesome/fontawesome-free"


import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import Swal from 'sweetalert2';
import Top20px from "../screens/Top20px";

AOS.init({
    duration: 1000, // Notice the single quotes around '2s'
});



const ProfileHeader = () => {

    const [dashsignOut, setSignOut] = useState(false)

    useEffect(() => {
        AOS.refresh();
    }, []);

    useEffect(() => {
        const auth = localStorage.getItem("isAuthenticated");
        if (auth) {
            setSignOut(true)
        }
    }, []);




    const [signupcontent, SetSignupContent] = useState(false)



    const clientId = "516265231821-9n4dbqkgm349bl3a76j75lgeu19vv71h.apps.googleusercontent.com"

    // const clientSecret = "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";

    const navigate = useNavigate();

    const sectionRef = useRef(null);

    const howitworks = useRef(null)


    const [offset, setOffset] = useState(0);
    const [inView, setInView] = useState(false);

    const handleScroll = () => {
        setOffset(window.scrollY);

        // Check if the content is in view
        const element = document.querySelector('.parallax-content');
        if (element) {
            const rect = element.getBoundingClientRect();
            setInView(rect.top < window.innerHeight);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const [backendError, SetBackendError] = useState([])


    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [flag, SetFlag] = useState(false)
    const [name, SetName] = useState("")

    const [dynamicInput, DynamicInputFunction] = useState({
        userEmail: "",
        userPassword: "",
        userPhone: "",
    })

    const [errorForm, ErrorFormFunction] = useState({
        userEmail: false,
        userPassword: false,
        userPhone: false
    })

    const [eyeIcon, UpdateEyeIcon] = useState(true)

    const eyeOpenClose = (icon) => {
        UpdateEyeIcon(icon)
    }

    const inputHandle = (event) => {
        DynamicInputFunction({ ...dynamicInput, [event.target.name]: event.target.value })
        setEmail(dynamicInput.userEmail)
    }

    const onSuccess = (response) => {
        // SetName(response.profileObj);
        // console.log("success", response);
        SetFlag(true)
    }

    const onFailure = (err) => {
        // console.log(err);
    }







    const [isSubmitClicked, setIsSubmitClicked] = useState(false);




    const clickSubmit = () => {

        if (!isSubmitClicked) {
            setIsSubmitClicked(true);

            const link = "https://backend.shubadinam.com/upload/mailpass";
            axios.post(link, dynamicInput)
                .then((response) => {


                    const loggingUser = response.data.data2;
                    const loggingRelation = response.data.data1;

                    if (response.data.errors) {
                        SetBackendError(response.data.errors)

                        const invalidmailPass = response.data.errors[0].msg
                        // console.log(invalidmailPass)

                        Swal.fire({
                            title: 'Invalid',
                            text: invalidmailPass,
                            icon: 'error',
                            confirmButtonText: 'Got it!', // Change the confirm button text
                            confirmButtonColor: '#3085d6', // Change the confirm button color
                            background: '#f5f5f5', // Change the background color
                            customClass: {
                                container: 'my-custom-container-class', // Add a custom container class
                                title: 'my-custom-title-class', // Add a custom title class
                                content: 'my-custom-content-class', // Add a custom content class
                                confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                            },
                        });




                    } else {
                        SetBackendError([])
                        const verifySuccess = response.data.success
                        console.log(verifySuccess)
                        Swal.fire({
                            title: 'Email Verification',
                            text: verifySuccess,
                            icon: 'success',
                            confirmButtonText: 'Got it!', // Change the confirm button text
                            confirmButtonColor: '#3085d6', // Change the confirm button color
                            background: '#f5f5f5', // Change the background color
                            customClass: {
                                container: 'my-custom-container-class', // Add a custom container class
                                title: 'my-custom-title-class', // Add a custom title class
                                content: 'my-custom-content-class', // Add a custom content class
                                confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                            },
                        });
                        const stringifyUserInfo = JSON.stringify(dynamicInput);
                        // localStorage.setItem("isAuthenticated", btoa(stringifyUserInfo));
                        const stringifyForeignKey = JSON.stringify(dynamicInput.userEmail);
                        // localStorage.setItem("fkey", btoa(stringifyForeignKey));
                        // console.log(dynamicInput)

                        // console.log(loggingUser);
                        // console.log(loggingRelation);
                        localStorage.setItem('myDataKey', JSON.stringify(loggingRelation));
                        localStorage.setItem('myDataKey2', JSON.stringify(loggingUser));

                        // window.location.reload();

                    }

                    setIsSubmitClicked(false);

                })
                .catch((error) => {
                    // console.log(error);


                    if (error.message === "Network Error") {
                        // const msg1 = error.response.data.message
                        Swal.fire({
                            title: 'Under Maintanance',
                            text: 'Please login after sometime!',
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                        })
                    } else {
                        const userExist = error.response.data

                        Swal.fire({
                            title: 'Invalid User',
                            text: userExist,
                            icon: 'warning',
                            confirmButtonText: 'Ok', // Change the confirm button text
                            confirmButtonColor: '#3085d6', // Change the confirm button color
                            background: '#f5f5f5', // Change the background color
                            customClass: {
                                container: 'my-custom-container-class', // Add a custom container class
                                title: 'my-custom-title-class', // Add a custom title class
                                content: 'my-custom-content-class', // Add a custom content class
                                confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                            },
                        });
                    }

                    setIsSubmitClicked(false);

                    // alert(error.response.data)
                });

        }

        // const stringifyUserInfo = JSON.stringify(dynamicInput);
        // localStorage.setItem("isAuthenticated", btoa(stringifyUserInfo));
        // localStorage.setItem("email", dynamicInput.userEmail);
        // console.log(dynamicInput)



        ErrorFormFunction({
            ...errorForm,
            userEmail: dynamicInput.userEmail === "" ? true : false,
            userPassword: dynamicInput.userPassword === "" ? true : false,
            userPhone: dynamicInput.userPhone === "" ? true : false
        })
    }


    const clickSubmitbtn = () => {
        // console.log(dynamicInput);

        const link = "https://backend.shubadinam.com/logged/user"
        axios.post(link, dynamicInput)
            .then((response) => {
                const loggingUser = response.data.data2;
                const loggingRelation = response.data.data1;

                // console.log(response.data);
                if (response.data.errors) {
                    SetBackendError(response.data.errors)


                    const invalidmailPass = response.data.errors[0].msg
                    // console.log(invalidmailPass)

                    Swal.fire({
                        title: 'Invalid',
                        text: invalidmailPass,
                        icon: 'error',
                        confirmButtonText: 'Got it!', // Change the confirm button text
                        confirmButtonColor: '#3085d6', // Change the confirm button color
                        background: '#f5f5f5', // Change the background color
                        customClass: {
                            container: 'my-custom-container-class', // Add a custom container class
                            title: 'my-custom-title-class', // Add a custom title class
                            content: 'my-custom-content-class', // Add a custom content class
                            confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                        },
                    });

                    // alert(response.data.errors[0].msg)




                } else {
                    SetBackendError([])
                    if (response.data.success === "Success") {

                        navigate("/dashboard");


                        const stringifyUserInfo = JSON.stringify(dynamicInput);
                        localStorage.setItem("isAuthenticated", btoa(stringifyUserInfo));
                        // localStorage.setItem("email", dynamicInput.userEmail);
                        const stringifyForeignKey = JSON.stringify(dynamicInput.userEmail);
                        localStorage.setItem("fkey", btoa(stringifyForeignKey));

                        // console.log(loggingUser);
                        // console.log(loggingRelation);
                        localStorage.setItem('myDataKey', JSON.stringify(loggingRelation));
                        localStorage.setItem('myDataKey2', JSON.stringify(loggingUser));

                        window.location.reload();

                        // navigate("/home");
                        // alert("yes")

                        // console.log(response.data);




                    } else {
                        // console.log(response.data);
                        alert("No records existed")
                    }
                }
            })
            .catch((error) => {

                const noRecords = error.response.data.message;
                const wrongpass = error.response.data.message;

                // console.error('Error:', error)

                if (error.response.data.norecords === "Failure") {
                    Swal.fire({
                        title: 'Invalid',
                        // text: "We are currently unavailable.Please try again after sometime.",
                        // text: "No records existed.Please Sign up/Log in after sometime."    backendstop: "backend stop",
                        text: noRecords,
                        icon: 'warning',
                        confirmButtonText: 'Got it!', // Change the confirm button text
                        confirmButtonColor: '#3085d6', // Change the confirm button color
                        background: '#f5f5f5', // Change the background color
                        customClass: {
                            container: 'my-custom-container-class', // Add a custom container class
                            title: 'my-custom-title-class', // Add a custom title class
                            content: 'my-custom-content-class', // Add a custom content class
                            confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                        },
                    });
                }
                else if (error.response.data.passfailure === "Failure") {

                    Swal.fire({
                        title: 'Invalid',
                        // text: "We are currently unavailable.Please try again after sometime.",
                        // text: "No records existed.Please Sign up/Log in after sometime."    backendstop: "backend stop",
                        text: wrongpass,
                        icon: 'warning',
                        confirmButtonText: 'Got it!', // Change the confirm button text
                        confirmButtonColor: '#3085d6', // Change the confirm button color
                        background: '#f5f5f5', // Change the background color
                        customClass: {
                            container: 'my-custom-container-class', // Add a custom container class
                            title: 'my-custom-title-class', // Add a custom title class
                            content: 'my-custom-content-class', // Add a custom content class
                            confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                        },
                    });
                }
                else {
                    Swal.fire({
                        title: 'Invalid',
                        text: "We are currently unavailable.Please Sign up/Log in after sometime.",
                        icon: 'warning',
                        confirmButtonText: 'Got it!', // Change the confirm button text
                        confirmButtonColor: '#3085d6', // Change the confirm button color
                        background: '#f5f5f5', // Change the background color
                        customClass: {
                            container: 'my-custom-container-class', // Add a custom container class
                            title: 'my-custom-title-class', // Add a custom title class
                            content: 'my-custom-content-class', // Add a custom content class
                            confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                        },
                    });
                }
            });
    }


    // const boxLink = (pageNumber) => {

    //     if (pageNumber === 1) {
    //         navigate("/nakshatra-foods")
    //     }
    //     else if (pageNumber === 2) {
    //         navigate("/nakshatra-characteristics")
    //     }
    //     else if (pageNumber === 3) {
    //         navigate("/nakshatra-deities")
    //     }
    //     else if (pageNumber === 4) {
    //         navigate("/nakshatra-likes-dislikes")
    //     }
    //     else if (pageNumber === 5) {
    //         navigate("/tithi-foods")
    //     }
    //     else if (pageNumber === 6) {
    //         navigate("/tithi-characteristics")
    //     }
    //     else if (pageNumber === 7) {
    //         navigate("/tithi-deities")
    //     }
    //     else if (pageNumber === 8) {
    //         navigate("/tithi-likes-dislikes")
    //     }

    // }


    const handleMultipleClicks = () => {
        scrollToSection()
        togglePopup()

    }

    const scrollToSection = () => {

        navigate("/register")

        // if (sectionRef.current) {
        //     sectionRef.current.scrollIntoView({ behavior: 'smooth' });
        //     setIsOpen(false)
        //     SetSignupContent(true)
        //     setOpenMenu(false)
        // }
    };
    const siginbackReg = () => {
        navigate("/signin")
        // if (sectionRef.current) {
        //     sectionRef.current.scrollIntoView({ behavior: 'smooth' });
        //     setIsOpen(true)
        //     SetSignupContent(false)
        //     setOpenMenu(false)

        // }
    };

    const clickHow = () => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            SetSignupContent(false)
            setIsOpen(false)
        }
    };

    const clickSignparallax = () => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            SetSignupContent(true)
        }

    };



    const [isOpen, setIsOpen] = useState(false);
    const [scrollThresholdPercentage, setScrollThresholdPercentage] = useState(10); // 10% initial threshold


    const togglePopup = () => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            setIsOpen(!isOpen);
        }
        // setIsOpen(!isOpen);
    };

    // const handleMouseLeave = () => {
    //     setIsOpen(false); // Set dynamic variable to false on mouse leave
    // };

    useEffect(() => {
        const handleScrollfade = () => {
            // Calculate the scroll position threshold based on screen width
            const screenWidth = window.innerWidth;
            let scrollThreshold;

            if (screenWidth > 1300) {
                // For screens wider than 1300px, set a different threshold
                scrollThreshold = 3000;
            } else {
                // For screens 1300px or narrower, use a different threshold
                scrollThreshold = 2000; // You can adjust this value
            }

            if (window.scrollY > scrollThreshold) {
                setIsOpen(false);
            }
        };

        window.addEventListener('scroll', handleScrollfade);

        return () => {
            window.removeEventListener('scroll', handleScrollfade);
        };
    }, []);





    // /////////////COUNT DOWN and CELEBRATION EFFECT//////////////////

    const [inAugrate, SetInAugrate] = useState(true) // **********important dont forget to change this false to activate inaugrate

    const [countdown, setCountdown] = useState(5);

    const [celebrate, setCelebrate] = useState(false);



    const startCountdown = () => {
        if (countdown > 0) {
            startCountdownRecursive(countdown);
        }
    };

    const startCountdownRecursive = (currentCount) => {
        if (currentCount > 0) {
            const countdownTimeout = setTimeout(() => {
                setCountdown(currentCount - 1);
                startCountdownRecursive(currentCount - 1);
            }, 1000);
        } else {
            SetInAugrate(true)
            localStorage.setItem("inaugrated", "true")
            localStorage.setItem("celebration", "true")

            const celebrationKey = localStorage.getItem('celebration'); // Replace with your specific key

            if (celebrationKey) {
                // If the key is found in local storage, trigger the celebration
                setCelebrate(true);

                // Remove the key from local storage after 4 seconds
                setTimeout(() => {
                    localStorage.removeItem('celebration'); // Replace with your specific key
                    setCelebrate(false);
                }, 4000);
            }
        }
    }

    useEffect(() => {
        if (localStorage.getItem("inaugrated")) {
            SetInAugrate(true)
        }
        window.scrollTo(0, 0); // Scroll to the top when component mounts

    }, [])

    // ///////////////////COUNT DOWN END and CEKEBRATION EFFECT END//////////////


    // MENU BAR

    const [isOpenMenu, setOpenMenu] = useState(false);


    // ENTER BUTTON

    const formRef = useRef(null);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !isSubmitClicked) {
            e.preventDefault(); // Prevent form submission
            // Trigger the submit button click
            formRef.current.querySelector('.sumbit-font').click();
        }
    };

    // Profile signout
    const signuptoSignout = () => {
        setSignOut(false)
        sessionStorage.clear()
        localStorage.clear();
        localStorage.removeItem("isAuthenticated")
        localStorage.removeItem("fkey")
        localStorage.removeItem("homeauth")
        // Navigate("/")
    }

    // const clickDashboard=()=>{
    //     Navigate("/home")
    // }




    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

    const handleMobileNavToggle = () => {
        setIsMobileNavOpen(!isMobileNavOpen);
    };

    const handleDropdownClick = (e) => {
        e.preventDefault();
        if (isMobileNavOpen && e.target.nextElementSibling) {
            e.target.nextElementSibling.classList.toggle('dropdown-active');
        }
    };


    const [isLesser768, setisLesser768] = useState(false)


    useEffect(() => {

        const handleResize = () => {
            if (window.innerWidth < 768) {
                setisLesser768(true);
            } else {
                setisLesser768(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    return (
        <>
            {/* ======= Header ======= */}
            <header id="header" className={`fixed-top d-flex align-items-center header-mobile-open`}>
                <div className="container d-flex justify-content-between align-items-center">
                    <div className="logo">
                        <NavLink to="/">
                            <img src={require("../images/New Shubadinam Logo/Shubadinam White.png")} onContextMenu={(e) => e.preventDefault()} />
                        </NavLink>
                    </div>
                    <nav id="navbar" className={`navbar ${isMobileNavOpen ? 'navbar-mobile' : ''}`}>
                        <ul>

                            <li><NavLink to="/dashboard" className="reg-btn open-btn"><span style={{ borderBottom: "1px solid white" }}>Dashboard</span></NavLink></li>

                            <li><NavLink to="/my-profile" className={({ isActive }) => isActive ? "active" : ""}>My Profile</NavLink></li>

                            <li><NavLink to="/pricing">Premium</NavLink></li>
                            {/* <li><NavLink to="/shop">Shop</NavLink></li> */}

                            {/* <li><NavLink to="/services" className={({ isActive }) => isActive ? "active" : ""}>SD Premium 👑</NavLink></li> */}

                            {/* <li><a href="" >Shop</a></li> */}




                            {
                                isLesser768 ?
                                    <>

                                        <li><NavLink to="/faq">FAQs</NavLink></li>
                                        <li><NavLink to="/Blogs">Blogs</NavLink></li>
                                        <li><NavLink to="/why-us">Why Shubadinam</NavLink></li>

                                    </>
                                    :
                                    <li className="dropdown">
                                        <a href="#" onClick={handleDropdownClick}><span>Info</span> <i className="bi bi-chevron-down" /></a>
                                        <ul>
                                            <li className="dropdown">
                                                {/* <a href="#" onClick={handleDropdownClick}><span>Deep Drop Down</span> <i className="bi bi-chevron-right" /></a> */}
                                                <ul>
                                                    <li><a href="#">Deep Drop Down 1</a></li>
                                                    <li><a href="#">Deep Drop Down 2</a></li>
                                                    <li><a href="#">Deep Drop Down 3</a></li>
                                                    <li><a href="#">Deep Drop Down 4</a></li>
                                                    <li><a href="#">Deep Drop Down 5</a></li>
                                                </ul>
                                            </li>
                                            <li><NavLink to="/faq">FAQs</NavLink></li>
                                            <li><NavLink to="/Blogs">Blogs</NavLink></li>
                                            <li><NavLink to="/why-us">Why Us</NavLink></li>
                                        </ul>

                                    </li>
                            }
                            <li><NavLink to="/contact-us">Contact Us</NavLink></li>



                            <li>{
                                dashsignOut ?
                                    <li>
                                        <li><a href="/" onClick={() => signuptoSignout()}>Sign Out</a></li>

                                    </li>
                                    :
                                    <NavLink to="/register" className="link">Sign Up</NavLink>
                            }</li>



                        </ul>
                        <i className="bi bi-list mobile-nav-toggle" onClick={handleMobileNavToggle} />
                    </nav>
                </div>
            </header>
            {/* End Header */}
        </>
    );
};

export default ProfileHeader;