import React, { useState, useEffect, useRef } from "react";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEnvelope, fa1, fa2, fa3, faUser, faL } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from "@fortawesome/fontawesome-free"
import ScrollToTop from "react-scroll-to-top";

import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

import FooterComponent from "../components/footer";
import HomeSignInComponent from "../components/home-signin";
import Swal from 'sweetalert2';
// import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import LanguageSwitcher from "./LanguageSwitcher";
import MenuBar from "./menubar";
import Blogs from "./blogs";
import Coverflow from "./Coverflow";
import CenteredSlider from "./CenteredSlider";
import TestimonialScreen from "./testimonials";

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import HeaderComponent from "../components/header";
import LandingHeader from "../components/LandingHeader"
import Top10px from "./Top10px";

import animationData from '../locales/diwali-rangoli.json'; // Adjust the file path as needed
import animationData1 from '../locales/diwali-mithai.json'; // Adjust the file path as needed

import { Helmet, HelmetProvider } from 'react-helmet-async';




AOS.init({
    duration: 1000, // Notice the single quotes around '2s'
});



const RegisterPage = () => {


    const [isVerifyclicked, setVerifiedClicked] = useState(false)

    const [dashsignOut, setSignOut] = useState(false)

    useEffect(() => {
        AOS.refresh();
    }, []);

    useEffect(() => {
        const auth = localStorage.getItem("homeauth");
        if (auth) {
            setSignOut(true)
        }
        SetIsOtpSent(false)
    }, []);







    const [signupcontent, SetSignupContent] = useState(false)



    const clientId = "516265231821-9n4dbqkgm349bl3a76j75lgeu19vv71h.apps.googleusercontent.com"

    // const clientSecret = "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";

    const navigate = useNavigate();

    const sectionRef = useRef(null);

    const howitworks = useRef(null)


    const [offset, setOffset] = useState(0);
    const [inView, setInView] = useState(false);

    const handleScroll = () => {
        setOffset(window.scrollY);

        // Check if the content is in view
        const element = document.querySelector('.parallax-content');
        if (element) {
            const rect = element.getBoundingClientRect();
            setInView(rect.top < window.innerHeight);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const [backendError, SetBackendError] = useState([])


    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [flag, SetFlag] = useState(false)
    const [name, SetName] = useState("")

    const [dynamicInput, DynamicInputFunction] = useState({
        userEmail: "",
        // userPassword: "",
        countryCode: "91",
        userPhone: "",
    })

    const [errorForm, ErrorFormFunction] = useState({
        userEmail: false,
        userPassword: false,
        userPhone: false
    })

    const [eyeIcon, UpdateEyeIcon] = useState(true)

    const eyeOpenClose = (icon) => {
        UpdateEyeIcon(icon)
    }

    const inputHandle = (event) => {
        DynamicInputFunction({ ...dynamicInput, [event.target.name]: event.target.value })
        setEmail(dynamicInput.userEmail)
    }

    const handleCountryClick = (number) => {
        const numericCountryCode = number.replace(/\D/g, '');

        DynamicInputFunction({ ...dynamicInput, countryCode: numericCountryCode });
    };


    const onSuccess = (response) => {
        // SetName(response.profileObj);
        // console.log("success", response);
        SetFlag(true)
    }

    const onFailure = (err) => {
        // console.log(err);
    }







    const [isSubmitClicked, SetIsSubmitClicked] = useState(false);

    const [isOptSent, SetIsOtpSent] = useState(false);

    const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);


    const clickSubmitcheck = () => {

        // console.log(dynamicInput);

        Swal.fire({
            title: "Confirmation",
            html: `<p>Please confirm that you want to proceed with the following information:</p>
            <ul style="list-style: none; padding: 0; margin: 0;">
            <li style="margin-bottom: 10px;"> Email Address: ${dynamicInput.userEmail}</li>
            <li> Phone Number: ${dynamicInput.userPhone}</li>
            </ul>`,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes, Continue",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                clickSubmit()
            }
        });

    }

    const clickSubmit = () => {

        // console.log('====================================');
        // console.log(dynamicInput);
        // console.log('====================================');

        if (!/^\d+$/.test(dynamicInput.userPhone)) {
            alert('Please enter only numeric values in the phone number field.');
            return; // Do not proceed further if the input is invalid
        }


        if (!isSubmitClicked) {
            SetIsSubmitClicked(true);

            const link = "https://backend.shubadinam.com/upload/mailpass"
            axios.post(link, dynamicInput)
                .then((response) => {


                    const loggingUser = response.data.data2;
                    const loggingRelation = response.data.data1;

                    if (response.data.errors) {
                        SetBackendError(response.data.errors)

                        const invalidmailPass = response.data.errors[0].msg
                        // console.log(invalidmailPass)

                        Swal.fire({
                            title: 'Invalid',
                            text: invalidmailPass,
                            icon: 'error',
                            confirmButtonText: 'Got it!', // Change the confirm button text
                            confirmButtonColor: '#3085d6', // Change the confirm button color
                            background: '#f5f5f5', // Change the background color
                            customClass: {
                                container: 'my-custom-container-class', // Add a custom container class
                                title: 'my-custom-title-class', // Add a custom title class
                                content: 'my-custom-content-class', // Add a custom content class
                                confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                            },
                        });




                    }
                    else {

                        // shows otp 
                        SetIsOtpSent(true)

                        SetBackendError([])
                        const verifySuccess = response.data.success
                        // console.log(verifySuccess)
                        Swal.fire({
                            title: 'OTP Verification',
                            text: verifySuccess,
                            html: verifySuccess + '<br><br><span style="color: red; font-size: 12px;">(* In case you did not receive the SMS OTP, you may use the Email OTP.)</span>', // Add your manual text here
                            icon: 'success',
                            confirmButtonText: 'Got it!', // Change the confirm button text
                            confirmButtonColor: '#3085d6', // Change the confirm button color
                            background: '#f5f5f5', // Change the background color
                            customClass: {
                                container: 'my-custom-container-class', // Add a custom container class
                                title: 'my-custom-title-class', // Add a custom title class
                                content: 'my-custom-content-class', // Add a custom content class
                                confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                            },
                        });
                        const stringifyUserInfo = JSON.stringify(dynamicInput);
                        // localStorage.setItem("isAuthenticated", btoa(stringifyUserInfo));
                        const stringifyForeignKey = JSON.stringify(dynamicInput.userEmail);
                        // localStorage.setItem("fkey", btoa(stringifyForeignKey));
                        // console.log(dynamicInput)

                        // console.log(loggingUser);
                        // console.log(loggingRelation);
                        localStorage.setItem('myDataKey', JSON.stringify(loggingRelation));
                        localStorage.setItem('myDataKey2', JSON.stringify(loggingUser));

                        // window.location.reload();

                    }

                    SetIsSubmitClicked(false);

                })
                .catch((error) => {
                    // console.log(error);


                    if (error.message === "Network Error") {
                        // const msg1 = error.response.data.message
                        Swal.fire({
                            title: 'Under Maintanance',
                            text: 'Please login after sometime!',
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                        })
                    } else {
                        const userExist = error.response.data

                        Swal.fire({
                            title: 'Invalid User',
                            text: userExist,
                            icon: 'warning',
                            confirmButtonText: 'Ok', // Change the confirm button text
                            confirmButtonColor: '#3085d6', // Change the confirm button color
                            background: '#f5f5f5', // Change the background color
                            customClass: {
                                container: 'my-custom-container-class', // Add a custom container class
                                title: 'my-custom-title-class', // Add a custom title class
                                content: 'my-custom-content-class', // Add a custom content class
                                confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                            },
                        });
                    }

                    SetIsSubmitClicked(false);

                    // alert(error.response.data)
                });

        }

        const stringifyUserInfo = JSON.stringify(dynamicInput);
        // localStorage.setItem("isAuthenticated", btoa(stringifyUserInfo));
        localStorage.setItem("email", dynamicInput.userEmail);
        localStorage.setItem("details", btoa(stringifyUserInfo));
        // console.log(dynamicInput)

    }

    const verifyRegOtp = () => {


        setVerifiedClicked(true)

        const encodedDetails = localStorage.getItem("details");
        const decodedDetails = atob(encodedDetails);

        // console.log(decodedDetails);


        const regPhone = dynamicInput.userPhone

        // const regPhone = localStorage.getItem("email")
        const regOtp = sessionStorage.getItem("otp");
        // console.log(regOtp)
        // console.log(regPhone);

        const url = `https://backend.shubadinam.com/verifyRegOtp?userPhone=${regPhone}&&userOtp=${regOtp}`

        axios.get(url)
            .then((response) => {

                // const stringifyUserInfo = JSON.stringify(dynamicInput);
                // localStorage.setItem("isAuthenticated", btoa(stringifyUserInfo));

                const stringifyForeignKey = JSON.stringify(dynamicInput.userEmail);
                localStorage.setItem("fkey", btoa(stringifyForeignKey));

                const serializedMailPhPass = JSON.stringify(dynamicInput);
                localStorage.setItem("mainMPP", btoa(serializedMailPhPass))

                sessionStorage.setItem("profileauth", true)
                sessionStorage.setItem("isFree", true)

                const code = dynamicInput.countryCode

                localStorage.setItem("code", code)

                // console.log(response.data);
                const validOtp = response.data.message

                Swal.fire({
                    title: 'Otp Verified',
                    // text: validOtp, // Assuming validOtp contains the OTP message
                    icon: 'success',
                    confirmButtonText: 'Got it!', // Change the confirm button text
                    confirmButtonColor: '#3085d6', // Change the confirm button color
                    background: '#f5f5f5', // Change the background color
                    customClass: {
                        container: 'my-custom-container-class', // Add a custom container class
                        title: 'my-custom-title-class', // Add a custom title class
                        content: 'my-custom-content-class', // Add a custom content class
                        confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                    }
                })
                // .then((result) => {
                //     if (result.isConfirmed) {

                //         window.location.href = '/profile'; // Redirect to a success page
                //     }
                // });
                window.location.href = '/profile'; // Redirect to a success page

            })

            .catch((error) => {
                // console.log(error);

                setVerifiedClicked(false)

                setOtpInputs(["", "", "", ""]);

                const invaildOtp = error.response.data.message

                Swal.fire({
                    title: 'Authentication Revoked',
                    text: invaildOtp,
                    icon: 'info',
                    confirmButtonText: 'Got it!', // Change the confirm button text
                    confirmButtonColor: '#3085d6', // Change the confirm button color
                    background: '#f5f5f5', // Change the background color
                    customClass: {
                        container: 'my-custom-container-class', // Add a custom container class
                        title: 'my-custom-title-class', // Add a custom title class
                        content: 'my-custom-content-class', // Add a custom content class
                        confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                    },
                });
            })
    }

    const resendOtp = () => {
        clickSubmit()
    }

    const inputRefs = useRef([]);
    const [isNotNumber, setIsNotNumber] = useState(false)

    const handleInputChange = (index, value) => {

        if (!/^\d*$/.test(value)) {
            value = ""; // Set value to an empty string if not a number
            setIsNotNumber(true)
            return
        }
        setIsNotNumber(false)
        const updatedInputs = [...otpInputs];
        updatedInputs[index] = value;
        setOtpInputs(updatedInputs);

        // Combine all input values and store in sessionStorage
        const otpValue = updatedInputs.join("").trim();
        sessionStorage.setItem("otp", otpValue);

        setOtpInputs(updatedInputs);

        if (value && index < otpInputs.length - 1) {
            inputRefs.current[index + 1].focus();
        }

    };

    const handleKeyDown = (index, event) => {
        if (event.keyCode === 8 && !otpInputs[index] && index > 0) {
            // Handle Backspace key when input is empty
            inputRefs.current[index - 1].focus();
        }
    };




    const handleMultipleClicks = () => {
        scrollToSection()
        togglePopup()

    }

    const scrollToSection = () => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            setIsOpen(false)
            SetSignupContent(true)
            setOpenMenu(false)
        }
    };
    const siginbackReg = () => {

        navigate("/signin")
    };

    const clickHow = () => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            SetSignupContent(false)
            setIsOpen(false)
        }
    };

    const clickSignparallax = () => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            SetSignupContent(true)
        }

    };



    const [isOpen, setIsOpen] = useState(false);
    const [scrollThresholdPercentage, setScrollThresholdPercentage] = useState(10); // 10% initial threshold


    const togglePopup = () => {
        navigate("/signin")
    };


    useEffect(() => {
        const handleScrollfade = () => {
            // Calculate the scroll position threshold based on screen width
            const screenWidth = window.innerWidth;
            let scrollThreshold;

            if (screenWidth > 1300) {
                // For screens wider than 1300px, set a different threshold
                scrollThreshold = 3000;
            } else {
                // For screens 1300px or narrower, use a different threshold
                scrollThreshold = 2000; // You can adjust this value
            }

            if (window.scrollY > scrollThreshold) {
                setIsOpen(false);
            }
        };

        window.addEventListener('scroll', handleScrollfade);

        return () => {
            window.removeEventListener('scroll', handleScrollfade);
        };
    }, []);





    // /////////////COUNT DOWN and CELEBRATION EFFECT//////////////////

    const [inAugrate, SetInAugrate] = useState(true) // **********important dont forget to change this false to activate inaugrate

    const [countdown, setCountdown] = useState(5);

    const [celebrate, setCelebrate] = useState(false);



    const startCountdown = () => {
        if (countdown > 0) {
            startCountdownRecursive(countdown);
        }
    };

    const startCountdownRecursive = (currentCount) => {
        if (currentCount > 0) {
            const countdownTimeout = setTimeout(() => {
                setCountdown(currentCount - 1);
                startCountdownRecursive(currentCount - 1);
            }, 1000);
        } else {
            SetInAugrate(true)
            localStorage.setItem("inaugrated", "true")
            localStorage.setItem("celebration", "true")

            const celebrationKey = localStorage.getItem('celebration'); // Replace with your specific key

            if (celebrationKey) {
                // If the key is found in local storage, trigger the celebration
                setCelebrate(true);

                // Remove the key from local storage after 4 seconds
                setTimeout(() => {
                    localStorage.removeItem('celebration'); // Replace with your specific key
                    setCelebrate(false);
                }, 4000);
            }
        }
    }

    useEffect(() => {
        if (localStorage.getItem("inaugrated")) {
            SetInAugrate(true)
        }
        window.scrollTo(0, 0); // Scroll to the top when component mounts

    }, [])

    // ///////////////////COUNT DOWN END and CEKEBRATION EFFECT END//////////////


    // MENU BAR

    const [isOpenMenu, setOpenMenu] = useState(false);


    // ENTER BUTTON

    const formRef = useRef(null);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !isSubmitClicked) {
            e.preventDefault(); // Prevent form submission
            // Trigger the submit button click
            formRef.current.querySelector('.form--submit').click();
        }
    };

    // dashboard signout
    const signuptoSignout = () => {
        setSignOut(false)
        localStorage.removeItem("isAuthenticated")
        localStorage.removeItem("fkey")
        localStorage.removeItem("homeauth")
        // Navigate("/")
    }

    // const clickDashboard=()=>{
    //     Navigate("/home")
    // }

    const backtoHome = () => {
        navigate("/")
    }

    const clickLogo = () => {
        navigate("/")
    }

    const getTooltipPlacement = () => {
        if (window.innerWidth < 768) {
            return "top";
        } else {
            return "right";
        }
    };












    return (
        <div className="overall-container">
            <HelmetProvider>
                <Helmet>
                    <title>Register </title>
                    <meta name="description" content="Register  - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <div className="overall-percent">

                <Top10px />

                <LandingHeader />


                <br />

                <div className="double-container-signup" ref={sectionRef}>



                    <div className="signup-image-container" ref={formRef}>

                        <div className="signup-image">
                            {/* <video autoPlay loop muted className="signup-video">
                                <source src={require("../videos/555.mp4")} />
                            </video> */}
                            <img className="signup-video" src={require("../images/sig in 2-01.png")} />
                        </div>
                        <div className="signup-image-cnt2">
                            <p> <span style={{ fontWeight: "900" }}>Aligning Stars:{" "}</span>Embrace Your Janma Nakshatra/Tithi. Unlock insights into your destiny with just a click.</p>

                        </div>


                    </div>

                    <div className="form" ref={formRef}>

                        <span className="signup">Sign Up</span>
                        <OverlayTrigger
                            placement={getTooltipPlacement()}
                            overlay={<Tooltip id="tooltip-id" >Provide your active email address so that verification mail and Janma dinam reminders can be sent.</Tooltip>}>

                            <input type="email" placeholder="Email address" className="form--input" name="userEmail" onChange={inputHandle} onKeyPress={handleKeyPress} />
                        </OverlayTrigger>


                        <OverlayTrigger
                            placement={getTooltipPlacement()}
                            overlay={<Tooltip id="tooltip-id" >Provide your active phone number to receive OTP.</Tooltip>}>
                            <div className="ui-wrapper" style={{ width: "280px", height: "40px", borderRadius: "5px", border: "1px solid gray" }}>
                                <input defaultChecked id="India" name="flag" type="radio" />
                                <input id="Austria" name="flag" type="radio" />
                                <input id="Belgium" name="flag" type="radio" />
                                <input id="India" name="flag" type="radio" />
                                <input id="Bulgaria" name="flag" type="radio" />
                                <input id="Croatia" name="flag" type="radio" />
                                <input id="Cyprus" name="flag" type="radio" />
                                <input id="Czech" name="flag" type="radio" />
                                <input id="Denmark" name="flag" type="radio" />
                                <input id="Estonia" name="flag" type="radio" />
                                <input id="Finland" name="flag" type="radio" />
                                <input id="France" name="flag" type="radio" />
                                <input id="Germany" name="flag" type="radio" />
                                <input id="Greece" name="flag" type="radio" />
                                <input id="Hungary" name="flag" type="radio" />
                                <input id="Iceland" name="flag" type="radio" />
                                <input id="Ireland" name="flag" type="radio" />
                                <input id="Italy" name="flag" type="radio" />
                                <input id="Latvia" name="flag" type="radio" />
                                <input id="Liechtenstein" name="flag" type="radio" />
                                <input id="Lithuania" name="flag" type="radio" />
                                <input id="Luxembourg" name="flag" type="radio" />
                                <input id="Malta" name="flag" type="radio" />
                                <input id="Netherlands" name="flag" type="radio" />
                                <input id="Norway" name="flag" type="radio" />
                                <input id="Poland" name="flag" type="radio" />
                                <input id="Portugal" name="flag" type="radio" />
                                <input id="Romania" name="flag" type="radio" />
                                <input id="Slovakia" name="flag" type="radio" />
                                <input id="Slovenia" name="flag" type="radio" />
                                <input id="Spain" name="flag" type="radio" />
                                <input id="Sweden" name="flag" type="radio" />
                                <input className="dropdown-checkbox" name="dropdown" id="dropdown" type="checkbox" />
                                <label className="dropdown-container" htmlFor="dropdown" />
                                <div className="input-wrapper">
                                    {/* <legend>
                                        <label htmlFor="phonenumber">
                                            Phonenumber*
                                        </label>
                                    </legend> */}
                                    <div className="textfield">
                                        <input pattern="\d+" maxLength={10} value={dynamicInput.userPhone} id="phonenumber" type="text" name="userPhone" onChange={inputHandle} placeholder="Phone number" />
                                        <span className="invalid-msg">This is not a valid phone number</span>
                                    </div>
                                </div>
                                <div className="select-wrapper">
                                    <ul>
                                        <li className="India" onClick={() => handleCountryClick('+91')}><label htmlFor="India"><span>🇮🇳</span>India (+91)</label></li>
                                        <li className="Austria" onClick={() => handleCountryClick('+43')}><label htmlFor="Austria"><span>🇦🇹</span>Austria (+43)</label></li>
                                        <li className="Belgium" onClick={() => handleCountryClick('+32')}><label htmlFor="Belgium"><span>🇧🇪</span>Belgium (+32)</label></li>
                                        <li className="Bulgaria" onClick={() => handleCountryClick('+359')}><label htmlFor="Bulgaria"><span>🇧🇬</span>Bulgaria (+359)</label></li>
                                        <li className="Croatia" onClick={() => handleCountryClick('+385')}><label htmlFor="Croatia"><span>🇭🇷</span>Croatia (+385)</label></li>
                                        <li className="Cyprus" onClick={() => handleCountryClick('+357')}><label htmlFor="Cyprus"><span>🇨🇾</span>Cyprus (+357)</label></li>
                                        <li className="Czech" onClick={() => handleCountryClick('+420')}><label htmlFor="Czech"><span>🇨🇿</span>Czech Republic (+420)</label></li>
                                        <li className="Denmark" onClick={() => handleCountryClick('+45')}><label htmlFor="Denmark"><span>🇩🇰</span>Denmark (+45)</label></li>
                                        <li className="Estonia" onClick={() => handleCountryClick('+372')}><label htmlFor="Estonia"><span>🇪🇪</span>Estonia (+372)</label></li>
                                        <li className="Finland" onClick={() => handleCountryClick('+358')}><label htmlFor="Finland"><span>🇫🇮</span>Finland (+358)</label></li>
                                        <li className="France" onClick={() => handleCountryClick('+33')}><label htmlFor="France"><span>🇫🇷</span>France (+33)</label></li>
                                        <li className="Germany" onClick={() => handleCountryClick('+49')}><label htmlFor="Germany"><span>🇩🇪</span>Germany (+49)</label></li>
                                        <li className="Greece" onClick={() => handleCountryClick('+30')}><label htmlFor="Greece"><span>🇬🇷</span>Greece (+30)</label></li>
                                        <li className="Hungary" onClick={() => handleCountryClick('+36')}><label htmlFor="Hungary"><span>🇭🇺</span>Hungary (+36)</label></li>
                                        <li className="Iceland" onClick={() => handleCountryClick('+354')}><label htmlFor="Iceland"><span>🇮🇸</span>Iceland (+354)</label></li>
                                        <li className="Ireland" onClick={() => handleCountryClick('+353')}><label htmlFor="Ireland"><span>🇮🇪</span>Republic of Ireland (+353)</label></li>
                                        <li className="Italy" onClick={() => handleCountryClick('+39')}><label htmlFor="Italy"><span>🇮🇹</span>Italy (+39)</label></li>
                                        <li className="Latvia" onClick={() => handleCountryClick('+371')}><label htmlFor="Latvia"><span>🇱🇻</span>Latvia (+371)</label></li>
                                        <li className="Liechtenstein" onClick={() => handleCountryClick('+423')}><label htmlFor="Liechtenstein"><span>🇱🇮</span>Liechtenstein (+423)</label></li>
                                        <li className="Lithuania" onClick={() => handleCountryClick('+370')}><label htmlFor="Lithuania"><span>🇱🇹</span>Lithuania (+370)</label></li>
                                        <li className="Luxembourg" onClick={() => handleCountryClick('+352')}><label htmlFor="Luxembourg"><span>🇱🇺</span>Luxembourg (+352)</label></li>
                                        <li className="Malta" onClick={() => handleCountryClick('+356')}><label htmlFor="Malta"><span>🇲🇹</span>Malta (+356)</label></li>
                                        <li className="Netherlands" onClick={() => handleCountryClick('+31')}><label htmlFor="Netherlands"><span>🇳🇱</span>Netherlands (+31)</label></li>
                                        <li className="Norway" onClick={() => handleCountryClick('+47')}><label htmlFor="Norway"><span>🇳🇴</span>Norway (+47)</label></li>
                                        <li className="Poland" onClick={() => handleCountryClick('+48')}><label htmlFor="Poland"><span>🇵🇱</span>Poland (+48)</label></li>
                                        <li className="Portugal" onClick={() => handleCountryClick('+351')}><label htmlFor="Portugal"><span>🇵🇹</span>Portugal (+351)</label></li>
                                        <li className="Romania" onClick={() => handleCountryClick('+40')}><label htmlFor="Romania"><span>🇷🇴</span>Romania (+40)</label></li>
                                        <li className="Slovakia" onClick={() => handleCountryClick('+421')}><label htmlFor="Slovakia"><span>🇸🇰</span>Slovakia (+421)</label></li>
                                        <li className="Slovenia" onClick={() => handleCountryClick('+386')}><label htmlFor="Slovenia"><span>🇸🇮</span>Slovenia (+386)</label></li>
                                        <li className="Spain" onClick={() => handleCountryClick('+34')}><label htmlFor="Spain"><span>🇪🇸</span>Spain (+34)</label></li>
                                        <li className="Sweden" onClick={() => handleCountryClick('+46')}><label htmlFor="Sweden"><span>🇸🇪</span>Sweden (+46)</label></li>
                                    </ul>

                                </div>
                            </div>
                        </OverlayTrigger>
                        <br />

                        {/* <OverlayTrigger
                            placement={getTooltipPlacement()}
                            overlay={<Tooltip id="tooltip-id" >Provide your active phone number so that Janma dinam reminders can be sent.</Tooltip>}>
                            <input type="tel" placeholder="Phone Number" className="form--input" name="userPhone" onChange={inputHandle} onKeyPress={handleKeyPress} />
                        </OverlayTrigger> */}

                        {
                            isOptSent &&

                            <form id="reg-form" className="reg-form">
                                <div id="reg-title1" className="reg-title">OTP</div>
                                <p id="reg-message" className="reg-message">We have sent a verification code to your mobile number</p>
                                {
                                    isNotNumber && <p>(Note : Please enter numeric values.)</p>
                                }
                                <div id="reg-inputs" className="reg-inputs">
                                    {otpInputs.map((value, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            maxLength={1}
                                            value={value}
                                            ref={ref => inputRefs.current[index] = ref}
                                            onChange={e => handleInputChange(index, e.target.value)}
                                            onKeyDown={e => handleKeyDown(index, e)}
                                            inputMode="numeric"
                                        />
                                    ))}
                                </div>
                            </form>
                        }



                        {
                            isOptSent ?
                                <>

                                    <div>

                                        <button id="verify-button" className="reg-action" onClick={() => verifyRegOtp()}
                                            disabled={isVerifyclicked}
                                        >{isVerifyclicked ? "Verifying" : "Verify me"}</button>
                                        <button id="resend-button" className="reg-action" onClick={() => resendOtp()}>Resend OTP</button>

                                    </div>

                                </>
                                :
                                <button className="form--submit" onClick={() => clickSubmitcheck()} disabled={
                                    dynamicInput.userEmail.length === 0 ||
                                    // dynamicInput.userPassword.length < 8 ||
                                    dynamicInput.userPhone.length <= 9 ||
                                    isSubmitClicked
                                }> {isSubmitClicked ? 'Submitting...' : 'Submit'}
                                </button>
                        }


                        <br />
                        <p className="login-p">Already have an account?<span className="login-span"><NavLink to="/signin" style={{ textDecoration: "none" }}>Sign In</NavLink></span>
                        </p>
                    </div>




                    <br />

                    {/* <video autoPlay loop muted className="test-video">
                                <source src={require("../videos/555.mp4")} />
                            </video> */}






                    {/* <div className="container-two" >
                        <div className="login-box" ref={formRef}>

                            <div className="login-heading" data-aos="fade-up" duration="700">
                                <h2 className="acc-ajst letters-color-acc" >Create your Shubadinam account</h2>
                            </div>


                            <div className="email-pass-cnt">

                                <div className="email-input" data-aos="fade-right">

                                    <OverlayTrigger 
                                        placement={getTooltipPlacement()}
                                        overlay={<Tooltip id="tooltip-id" >Provide your active email address so that verification mail and Janma dinam reminders can be sent.</Tooltip>}>

                                        <input className="input-font" type="email" name="userEmail" onChange={inputHandle} onKeyPress={handleKeyPress} placeholder="&nbsp;&nbsp;Email" />

                                    </OverlayTrigger>


                                    {errorForm.userEmail && <p className="error-text">This input field is mandatory</p>}

                                </div>
                                <div className="phone-input" data-aos="fade-right">

                                    <OverlayTrigger
                                        placement={getTooltipPlacement()}
                                        overlay={<Tooltip id="tooltip-id" >Provide your active phone number so that Janma dinam reminders can be sent.</Tooltip>}>

                                        <input className="input-font" type="text" name="userPhone" onChange={inputHandle} onKeyPress={handleKeyPress} placeholder="&nbsp;&nbsp;Phone Number" />

                                    </OverlayTrigger>

                                    {errorForm.userPhone && <p className="error-text">This input field is mandatory</p>}

                                </div>

                                <div className="password-input-style" data-aos="fade-right">
                                    <OverlayTrigger
                                        placement={getTooltipPlacement()}
                                        overlay={<Tooltip id="tooltip-id" >Password must contain min 8 letters</Tooltip>}>
                                        <input className="input-font-pass" type={eyeIcon ? "password" : "text"} name="userPassword" onChange={inputHandle} onKeyPress={handleKeyPress} placeholder="&nbsp;&nbsp;Password" />
                                    </OverlayTrigger>
                                    <button className="eye-btn eye-container-singupsignin" onClick={() => eyeOpenClose(!eyeIcon)}>
                                        {
                                            eyeIcon ?
                                                <img src="https://icons.veryicon.com/png/o/miscellaneous/hekr/action-hide-password.png" onClick={() => eyeOpenClose(false)} width="20px" height="20px" />
                                                :
                                                <img src="https://cdn-icons-png.flaticon.com/512/6866/6866733.png" onClick={() => eyeOpenClose(true)} width="20px" height="20px" />
                                        }
                                    </button>
                                </div>

                            </div>


                            <div className="forgot-password">
                                <NavLink to="/forgotpassword">
                                    <label className="letters-color-forgotpassword">Forget Password ?</label>
                                </NavLink>
                            </div>



                            {errorForm.userPassword && <p className="error-text">This input field is mandatory</p>}


                            <div className="sumbit-button" data-aos="fade-right">
                                <button type="submit" className="sumbit-font login-sub-button" onClick={() => clickSubmit()} disabled={
                                    dynamicInput.userEmail.length === 0 ||
                                    dynamicInput.userPassword.length === 0 ||
                                    dynamicInput.userPhone.length === 0 ||
                                    isSubmitClicked
                                }> {isSubmitClicked ? 'Submitting...' : 'Submit'}</button>

                            </div>

                            <div className="new-signup">
                                <div className="new-here letters-color-newhere">Already have an account ?</div>
                                <div className="sign-up">

                                    <button className="open-btn signout-btn" onClick={togglePopup}>Sign In</button>

                                    <HomeSignInComponent></HomeSignInComponent>
                                </div>

                            </div>
                            <div className="back-to-home">
                                <button className="back-home" onClick={() => backtoHome()}> Back To Home </button>
                            </div>
                        </div>
                    </div> */}

                </div>




                <FooterComponent></FooterComponent>

            </div>
        </div >



    );
};


export default RegisterPage;