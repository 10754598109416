/* global ShopifyBuy */ // Inform ESLint that ShopifyBuy is a global variable

import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Grid, useMediaQuery } from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { Button } from 'react-bootstrap';

const categories = [
    { name: 'Nicey', img: require("../images/shop/nicey_shop.webp"), link: "https://shop.shubadinam.com/collections/nicey" },
    { name: 'Caring', img: require("../images/shop/caring_shop.webp"), link: "https://shop.shubadinam.com/collections/caring" },
    { name: 'Kiddos', img: require("../images/shop/kiddos_shop.webp"), link: "https://shop.shubadinam.com/collections/kiddos" },
    { name: 'Utility', img: require("../images/shop/utility_shop.webp"), link: "https://shop.shubadinam.com/collections/utility" },
    { name: 'Self Gifts', img: require("../images/shop/myself_shop.webp"), link: "https://shop.shubadinam.com/collections/self-gift" },
    { name: 'Gift Cards', img: require("../images/shop/giftcard_shop.webp"), link: "https://shop.shubadinam.com/collections/gift-cards" },

];

const ShopComponent = () => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(max-width:900px)');
    const [products, setProducts] = useState([]); // State to hold fetched products

    useEffect(() => {
        // Load the Shopify Buy Button script
        const scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';

        const loadScript = () => {
            const script = document.createElement('script');
            script.async = true;
            script.src = scriptURL;
            script.onload = () => {
                fetchProducts(); // Fetch products after script is loaded
            };
            (document.head || document.body).appendChild(script);
        };

        const fetchProducts = async () => {
            const client = ShopifyBuy.buildClient({
                domain: '67cc47-90.myshopify.com',
                storefrontAccessToken: '184085a79bdc9a41b82921e03a10e1b0',
            });

            // Replace 'your-collection-id' with your actual collection ID
            const collectionId = 'gid://shopify/Collection/295204192308';

            try {
                const collection = await client.collection.fetchWithProducts(collectionId);

                const formattedProducts = collection.products.map((product) => ({
                    name: product.title,
                    img: product.images[0]?.src || 'https://via.placeholder.com/150',
                    link: product.onlineStoreUrl,
                }));
                console.log(collection.products);
                setProducts(formattedProducts);
            } catch (error) {
                console.error("Error fetching products from collection:", error);
            }
        };


        // Load the script
        if (window.ShopifyBuy && window.ShopifyBuy.UI) {
            fetchProducts();
        } else {
            loadScript();
        }

        // Cleanup function
        return () => {
            const existingScript = document.querySelector(`script[src="${scriptURL}"]`);
            if (existingScript) {
                existingScript.remove();
            }
        };
    }, []);

    return (
        <Box sx={{ padding: '20px' }}>
            {/* Header Section */}
            <Grid container justifyContent="space-around" alignItems="center" direction={{ xs: 'column', md: 'row' }}>
                <Grid item>
                    <Typography sx={{ color: '#05195e', fontSize: "35px", fontFamily: 'Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif', fontWeight: 900, textTransform: "capitalize", ml: 2 }}>
                        Giftings
                    </Typography>
                </Grid>
                <Box sx={{ width: "100%", pl: "75%" }}>
                    <NavLink to="https://shop.shubadinam.com/collections/all" target='_blank'>View All</NavLink>
                </Box>
            </Grid>

            {/* Categories Row */}
            <Grid container spacing={3} justifyContent="center" sx={{ marginTop: '20px' }}>
                {categories.map((category, index) => (
                    <Grid item xs={6} sm={4} md={2} key={index} textAlign="center">
                        <Link to={category.link} target='_blank' style={{ textDecoration: "none" }}>
                            <Box
                                sx={{
                                    cursor: "pointer",
                                    position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: '10px',
                                    borderRadius: '12px',
                                    transition: 'transform 0.3s ease',
                                    '&:hover': {
                                        transform: 'scale(1.02)',
                                    },
                                }}
                            >
                                <img
                                    src={category.img}
                                    alt={category.name}
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        margin: '0 auto',
                                        borderRadius: '50%',
                                    }}
                                />
                                <Typography variant="h6" sx={{ marginTop: '10px', color: '#020332' }}>
                                    {category.name}
                                </Typography>
                            </Box>
                        </Link>
                    </Grid>
                ))}
            </Grid>

            {/* Product Carousel Row */}
            <Box sx={{ marginTop: '40px' }}>
                <Typography variant="h5" sx={{ marginBottom: '20px', textAlign: 'center', color: '#4b769f' }}>
                    Recommended to you
                </Typography>

                <Splide
                    options={{
                        type: 'none',
                        perPage: isSmallScreen ? 2 : isMediumScreen ? 2 : 5,
                        gap: '30px',
                        pagination: false,
                        arrows: !isSmallScreen,
                    }}
                    style={{ height: "300px", width: "100%" }}
                    className='login-shop-splide'
                >
                    {products.map((product, index) => (
                        <SplideSlide key={index}>
                            <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: "pointer", }}>
                                <Box
                                    component={NavLink}
                                    to={product.link} // Navigate to the product link
                                    target="_blank" // Opens in a new tab
                                    rel="noopener noreferrer" // Security best practice for external links
                                    sx={{
                                        width: '150px',
                                        height: '150px',
                                        overflow: 'hidden',
                                        borderRadius: '10px',
                                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: "pointer",
                                    }}
                                >
                                    <img
                                        src={product.img}
                                        alt={product.name}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </Box>
                                <Typography variant="body5" sx={{ marginTop: '10px', marginBottom: "10px" }}>
                                    {product.name}
                                </Typography>

                                <Button href={product.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', backgroundColor: '#0f548c' }}>
                                    Buy Now
                                </Button>
                            </Box>
                        </SplideSlide>

                    ))}
                </Splide>
            </Box>
        </Box>
    );
};

export default ShopComponent;
