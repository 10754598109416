import React, { useState, useEffect } from 'react';
import LandingHeader from "../components/LandingHeader";
import Top20px from "../screens/Top20px";
import FooterComponent from "../components/footer";
import { Box, Typography, Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrown } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from "@fortawesome/fontawesome-free"
import StarsIcon from '@mui/icons-material/Stars';

const HistoryPage = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const [bookings, setBookings] = useState([]);
    const [isPremium, setPremium] = useState([]);

    const [totalspent, setTotalSpent] = useState([])


    useEffect(() => {
        const localId = localStorage.getItem("SD_Id") || localStorage.getItem("premiumVerified");
        const url = `https://backend.shubadinam.com/gethistory?localId=${localId}`;

        axios.get(url)
            .then(response => {
                // console.log(response.data);
                const { purchaseDetails, uniqueBookings, homamNames } = response.data;

                const amountsArray = purchaseDetails.map(detail => ((detail.amount) / 100));
                setTotalSpent(amountsArray);

                // Combine data from purchaseDetails and uniqueBookings
                const combinedData = uniqueBookings.map((booking, index) => {
                    const purchase = purchaseDetails.find(p => p.orderId === booking.order_id);
                    return {
                        ...booking,
                        ...purchase,
                        homamName: homamNames[index] || ''
                    };
                });


                setBookings(combinedData);
            })
            .catch(error => {
                // console.log(error);
            });
    }, []);


    useEffect(() => {
        const localId = localStorage.getItem("SD_Id") || localStorage.getItem("premiumVerified");
        const url = `https://backend.shubadinam.com/getisPremium?localId=${localId}`;

        axios.get(url)
            .then(response => {
                // console.log(response.data);

                setPremium(response.data);
                const newTotalSpent = response.data[0].amount;
                setTotalSpent(prevAmounts => [...prevAmounts, (newTotalSpent / 100)]);



            })
            .catch(error => {
                // console.log(error);
            });
    }, []);
    // console.log(totalspent);


    const spentSum = totalspent.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue);
    }, 0);

    return (
        <div className='homam-container'>
            <LandingHeader />
            <Top20px />
            <div className='homam-body'>
                <div className='homam-title'>
                    <h5 style={{ fontSize: "larger" }}>Payment History</h5>
                    <h5 style={{ fontSize: "larger" }}>
                        {/* Total = &#8377; <span style={{ color: "green" }}>  {spentSum} </span>/- */}
                    </h5>
                </div>
                <br />
                {
                    isPremium.length === 0 && bookings.length === 0 ? (
                        <Typography variant="h6" align="center" sx={{ mt: 2 }}>
                            Your history is empty.
                        </Typography>
                    ) :
                        isMobile ? (
                            // Mobile view using cards
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>


                                {

                                    isPremium.map((premium, index) => (
                                        <Paper key={index} sx={{ padding: 3, boxShadow: 3, backgroundColor: 'aliceblue' }}>
                                            <Typography variant="h6" gutterBottom sx={{ color: "#0004a8" }}>
                                                Premium
                                                {/* <FontAwesomeIcon icon={faCrown} style={{ color: "goldenrod", marginLeft: "20px" }} /> */}
                                                <StarsIcon sx={{ color: "goldenrod", marginLeft: "20px" }} />
                                            </Typography>
                                            <Grid container spacing={2} sx={{ marginTop: 1 }}>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1">
                                                        <strong>Name:</strong> {premium.userName}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1">
                                                        <strong>E-mail:</strong> {premium.userEmail}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1">
                                                        <strong>Phone:</strong> {premium.userPhone}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1">
                                                        <strong>Amount:</strong> Rs. {premium.amount / 100}/-
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1">
                                                        <strong>Purchased On:</strong> {new Date(premium.entry_time).toLocaleDateString("en-GB", { dateStyle: "short" })}{' - '}
                                                        {new Date(premium.entry_time).toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit', hour12: true })}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body1">
                                                        <strong>Invoice Id:</strong> {premium.orderId}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    ))
                                }



                                {bookings.slice().reverse().map((booking, index) => (
                                    <Paper key={index} sx={{ padding: 3, boxShadow: 3, backgroundColor: '#f9f9f9' }}>
                                        <Typography variant="h6" gutterBottom sx={{ color: "#064f27" }}>
                                            {booking.homamName}
                                        </Typography>
                                        <Grid container spacing={2} sx={{ marginTop: 1 }}>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body1">
                                                    <strong>Name:</strong> {booking.accountHolderName}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body1">
                                                    <strong>E-mail:</strong> {booking.accountHolderEmail}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body1">
                                                    <strong>Phone:</strong> {booking.accountHolderPhone}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body1">
                                                    <strong>Amount:</strong> Rs. {booking.amount / 100}/-
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body1">
                                                    <strong>Purchased On:</strong> {new Date(booking.entry_time).toLocaleDateString("en-GB", { dateStyle: "short" })}{' - '}
                                                    {new Date(booking.entry_time).toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit', hour12: true })}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body1">
                                                    <strong>Invoice Id:</strong> {booking.orderId}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                ))}
                            </Box>
                        ) : (
                            // Desktop view using table
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>



                                        <TableRow>
                                            <TableCell sx={{ width: 100 }}>S.no</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>E-mail</TableCell>
                                            <TableCell sx={{ width: 150 }}>Phone</TableCell>
                                            <TableCell>Homam Name</TableCell>
                                            <TableCell sx={{ width: 100 }}>Amount</TableCell>
                                            <TableCell>Invoice Id</TableCell>
                                            <TableCell>Purchased On</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {isPremium.map((premuim, index) => (
                                            <TableRow key={premuim.id} sx={{ backgroundColor: "aliceblue" }}>
                                                <TableCell><StarsIcon /></TableCell>
                                                <TableCell>{premuim.userName}</TableCell>
                                                <TableCell>{premuim.userEmail}</TableCell>
                                                <TableCell>{premuim.userPhone}</TableCell>
                                                <TableCell>
                                                    {/* <FontAwesomeIcon icon={faCrown} style={{ marginRight: "10px", color: "goldenrod" }} /> */}
                                                    Premium</TableCell>
                                                <TableCell>Rs. {premuim.amount / 100}/-</TableCell>
                                                <TableCell>{premuim.orderId}</TableCell>
                                                <TableCell>
                                                    {new Date(premuim.entry_time).toLocaleDateString("en-GB", { dateStyle: "short" })}{'- '}
                                                    {new Date(premuim.entry_time).toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit', hour12: true })}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {bookings.slice().reverse().map((booking, index) => (
                                            <TableRow key={booking.id}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{booking.accountHolderName}</TableCell>
                                                <TableCell>{booking.accountHolderEmail}</TableCell>
                                                <TableCell>{booking.accountHolderPhone}</TableCell>
                                                <TableCell>{booking.homamName}</TableCell>
                                                <TableCell>Rs. {booking.amount / 100}/-</TableCell>
                                                <TableCell>{booking.orderId}</TableCell>
                                                <TableCell>
                                                    {new Date(booking.entry_time).toLocaleDateString("en-GB", { dateStyle: "short" })}{'- '}
                                                    {new Date(booking.entry_time).toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit', hour12: true })}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )
                }
                <br />
            </div>
            <FooterComponent />
        </div>
    );
};

export default HistoryPage;
