import React, { useContext, useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useRef } from "react";
import axios from "axios";
import DataSharingContext from "../context/data-sharing-context";
import FooterComponent from "../components/footer";
import {
    CitySelect,
    CountrySelect,
    StateSelect,
    LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import ProfilePremiumDialog from "../components/ProfilePremiumDialog";
import ScrollToTop from "react-scroll-to-top";
import Top20px from "../../src/screens/Top20px"
import Top10px from "../../src/screens/Top10px"

import Stack from '@mui/material/Stack';
import PremiumAlert from "./Premium/PremiumAlert";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Swal from "sweetalert2";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const SingupScreen = () => {


    const defaultCountry = {
        id: 101,
        name: "India",
        capital: "New Delhi",
        currency: "INR",
        currency_name: "Indian rupee",
        currency_symbol: "₹",
        emoji: "🇮🇳",
        iso2: "IN",
        iso3: "IND",
        latitude: "20.00000000",
        longitude: "77.00000000",
        native: "भारत",
        numeric_code: "356",
        phone_code: 91,
        region: "Asia",
        subregion: "Southern Asia",
        tld: ".in"
    };


    const [changesMade, setChangesMade] = useState(false);

    // console.log(dynamicState);

    // useEffect(() => {
    //     const handleBeforeUnload = (event) => {
    //         if (changesMade) {
    //             event.preventDefault();
    //             sessionStorage.clear();
    //             localStorage.clear()
    //         }
    //     };

    //     window.addEventListener('beforeunload', handleBeforeUnload);

    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };
    // }, [changesMade]);

    const [premEmail, setPremEmail] = useState('');
    const [securedId, setsecuredId] = useState('');

    const [sumbitClicked, setsumbitClicked] = useState(false);


    // let emailPass;

    const [dynamicState, DynamicFunction] = useState({
        userName: "",
        userEmail: premEmail,
        userGender: "",
        userCountry: defaultCountry.name,
        userState: "",
        userCity: "",
        userRelation: "Myself",
        userCalender: "",
        userMonth: "",
        nakshatraTithi: "",
        userNakshathra: "",
        userPaksham: "",
        userTithi: "",
        userDateTime: "",
        calenderCode: "",
        sdinamId: securedId
    })


    const [premiumSuccess, setPremiumSuccess] = useState(false);

    const navigate = useNavigate();

    const [emailPass, setEmailPass] = useState(null);


    useEffect(() => {
        const sentMail = localStorage.getItem("fkey");
        if (sentMail) {
            const decodedEmail = atob(sentMail).replace(/"/g, '').trim();
            setPremEmail(decodedEmail);
        }

        const emailPassEncoded = localStorage.getItem("mainMPP");
        if (emailPassEncoded) {
            setEmailPass(JSON.parse(atob(emailPassEncoded)));
        } else {
            setEmailPass(null);
        }

    }, [])




    useEffect(() => {

        const localId = localStorage.getItem('premiumVerified');
        // const secureid = localStorage.getItem("SD_Id");

        // if (secureid) {
        //     setsecuredId(secureid);
        // }




        if (localId) {

            const premiumChecker = () => {


                // console.log(localId);
                const url = `https://backend.shubadinam.com/check/ispremium?localId=${localId}`

                axios.get(url)
                    .then((response) => {
                        // console.log(response);
                        setPremEmail(response.data.userEmail);
                        setsecuredId(localId);
                        setPremiumSuccess(true)

                        if (response.data.message === "non-premium") {
                            localStorage.removeItem("premiumVerified")
                            setPremiumSuccess(false)
                        }
                    })
                    .catch((error) => {
                        // console.log(error);
                        // localStorage.removeItem("premiumVerified")
                        // setPremiumSuccess(false)
                    })
            }

            premiumChecker()
        }


        const alreadyUser = sessionStorage.getItem('profileauth');

        if (!alreadyUser) {
            navigate("/");
        } else {
            const timer = setTimeout(() => {
                sessionStorage.removeItem('profileauth');
                navigate("/");
            }, 10 * 60 * 1000); // 10 minutes in milliseconds


            return () => clearTimeout(timer);
        }
    }, [dynamicState]);


    useEffect(() => {
        DynamicFunction(prevState => ({
            ...prevState,
            userEmail: premEmail,
            sdinamId: securedId
        }));
    }, [premEmail, securedId]);

    const [countryid, setCountryid] = useState(defaultCountry.id);
    const [stateid, setstateid] = useState(0);
    const [cityid, setCityid] = useState(0);






    const context = useContext(DataSharingContext);



    const [queryResult, setQueryResult] = useState([]);

    const [dynamicArray, setDynamicArray] = useState([])


    const [calender, SetCalender] = useState("")

    const [month, SetMonth] = useState("")

    const [nakshathra, SetNakshathra] = useState("")

    const [nakTithi, SetNakTithi] = useState()

    const [Pakshams, SetPaksham] = useState([])

    const [tithiname, SettithiName] = useState([])

    const [months, SetMonths] = useState([])
    const [nakshathras, SetNakshathras] = useState([])
    const [nakTithis, SetNakTithis] = useState([])


    const Calender2 = [
        {
            name: "Any"
        },

        {
            name: "Tamil",
            calenderCode: 1,
            month: [{
                name: "CHITHIRAI/சித்திரை",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]


            },
            {
                name: "VAIGAASI/வைகாசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]


            },
            {
                name: "AANI/ஆனி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "AADI/ஆடி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "AAVANI/ஆவணி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "PURATTASI/புரட்டாசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "AYPPASI/ஐப்பசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "KARTHIGAI/கார்த்திகை",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "MARGAZHI/மார்கழி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "THAI/தை",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "MAASI/மாசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "PANGUNI/பங்குனி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            }

            ]
        },
        {
            name: "Malayalam",
            calenderCode: 2,
            month: [
                {
                    name: "MEENAM/മീനം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]



                },
                {
                    name: "MEDAM/മെഡാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "EDAVAM/എദാവം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "MITHUNAM/മിഥുനം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "KARKADAKAM/കാർകിഡകാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "CHINGAM/ചിങ്കാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "KANNI/കാനി",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "THULAM/തുലാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "VRISHCHIKAM/വ്രിഷിക്കാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "DHANU/ഭാനു",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "MAKARAM/മകരം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "KUMBHAM/കുംഹാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                }
            ]
        },
        {
            name: "Telugu",
            calenderCode: 3,
            month: [
                {
                    name: "CHAITHRAMU/చైత్రము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]
                },
                {
                    name: "VAISAAKHAMU/వైశాఖము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "JYESTHAMU/జ్యేష్ఠము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "ASHADHAMU/ఆషాఢము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                // {
                //     name: "Sravanamu (Adhik)/శ్రావణము",
                //     nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                //     Paksham: [
                //         {
                //             name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                //             tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                //         },
                //         {
                //             name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                //             tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                //         }
                //     ]

                // },
                {
                    name: "SRAVANAMU/శ్రావణము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "BHADHRAPADAMU/భాద్రపదము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "AASVAYUJAMU/ఆశ్వయుజము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "KARTHIKAMU/కార్తీకము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "MAARGASEERSHAMU/మార్గశీర్షము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "PUSHYAMU/పుష్యము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "MAKHAMU/మఖము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]
                },
                {
                    name: "PHALGUNAMU/ఫాల్గుణము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]
                }
            ]
        },
        {
            name: "Kannada",
            calenderCode: 4,
            month: [{
                name: "CHAITRA/ಚೈತ್ರ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "VAISHAKHA/ವೈಶಾಖ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "JYESHTHA/ಜ್ಯೇಷ್ಠ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "ASHADHA/ಆಷಾಢ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "SAHRAVANA/ಶ್ರಾವಣ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "BHADRAPADA/ಭಾದ್ರಪದ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "ASHWEJA/ಆಶ್ವೇಜ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "KARTIKA/ಕಾರ್ತೀಕ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "MARGASHIRSHA/ಮಾರ್ಗಶೀರ್ಷ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "PAUSHA/ಪೌಷಾ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "MAGHA/ಮಾಘ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "PHALGUNA/ಫಲ್ಗುಣ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            ]
        },
        {
            name: "Hindi",
            calenderCode: 5,
            month: [
                {
                    name: "CHAITRA/चैत्र",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "VAISAKHA/वैशाख",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "ASHADHA/आषाढ़",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                // {
                //     name: "SHRAVANA(Adhik)/श्रावण",
                //     nakshathira:
                //         [
                //             "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                //     Paksham: [
                //         {
                //             name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                //             tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                //         },
                //         {
                //             name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                //             tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                //         }
                //     ]
                // },
                {
                    name: "SHRAVANA/श्रावण",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "BHADRAPADA/भाद्रपद",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "ASHVIN/अश्विन",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "KATIKA/कार्तिक",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "MARGASIRSA/मार्गशीर्ष",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },

                {
                    name: "PAUSHA/पौष",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "MAGH/माघ",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "PHALGUNA/फाल्गुन",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                }
            ]
        },
        {
            name: "Gujarati",
            calenderCode: 6,
            month: [
                {
                    name: "CHAITRA/ચૈત્ર",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]




                },
                {
                    name: "VAISHAKHA/વૈશાખા",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "JETHA/જેઠા",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                // {
                //     name: "SHRAVANA adhik/શ્રાવણ",
                //     nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                //     Paksham: [
                //         {
                //             name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                //             tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                //         },
                //         {
                //             name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                //             tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                //         }
                //     ]


                // },
                {
                    name: "SHRAVANA/શ્રાવણ",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "BHADRAVO/ભાદરવો",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "ASO/આસો",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "KARKAT/કારતક",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "MAGSHAR/માગશર",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "POSH/પોષ",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "MAHA/મહા",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "PHAGUNA/ફાગુના",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },

            ]
        },
        {
            name: "Bengali",
            calenderCode: 7,
            month: [
                {
                    name: "BOISHAKH/বৈশাখ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]



                },
                {
                    name: "JOISHTHO/জ্যৈষ্ঠ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "ASHARH/আষাঢ়",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "SHRABON/শ্রাবণ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "BHADRO/ভাদ্র",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "ASHSHIN/আশ্বিন",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "KARTIK/কার্তিক",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "AGRAHAYAN/অগ্রহায়ণ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "POUSH/পৌষ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "MAGH/মাঘ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "FALGUN/ফাল্গুন",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "CHAITRA/চৈত্র",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },

            ]
        },
        {
            name: "Marathi",
            calenderCode: 8,
            month: [
                {
                    name: "CHAITRA/चैत्र",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "VAISAKHA/वैशाख",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "JYESTHA/ज्येष्ठा",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "ASHADHA/आषाढ़",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "SHRAVANA/श्रावण",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "BHADRAPADA/भाद्रपद",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "ASHVIN/अश्विन",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "KATIKA/कार्तिक/चैत्र",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "MARGASIRSA/मार्गशीर्ष",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "PAUSHA/पौष",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "MAGH/माघ",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "PHALGUNA/फाल्गुन",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
            ]
        }



    ]


    const Calender = [

        {
            name: "Tamil",
            calenderCode: 1,
            month: [{
                name: "CHITHIRAI/சித்திரை",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]


            },
            {
                name: "VAIGAASI/வைகாசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]


            },
            {
                name: "AANI/ஆனி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "AADI/ஆடி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "AAVANI/ஆவணி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "PURATTASI/புரட்டாசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "AYPPASI/ஐப்பசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "KARTHIGAI/கார்த்திகை",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "MARGAZHI/மார்கழி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "THAI/தை",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "MAASI/மாசி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            },
            {
                name: "PANGUNI/பங்குனி",
                nakshathira: ["ASHWINI/அஸ்வினி", "BHARANI/பரணி", "KRITTIKA/கிருத்திகை", "ROHINI/ரோகிணி", "MRIGHASIRA/மிருகசிரம்", "ARDRA/திருவாதிரை", "PUNARVASU/புனர்பூசம்", "PUSHYA/பூசம்", "ASHLESHA/ஆயில்யம்", "MAGHA/மகம்", "PURVA PHALGUNI/பூரம்", "UTTARA PHALGUNI/உத்திரம்", "HASTA/ஹஸ்தம்", "CHITRA/சித்திரை", "SWATI/சுவாதி", "VISHAKA/விசாகம்", "ANURADHA/அனுஷம்", "JYESTHA/கேட்டை", "MOOLA/மூலம்", "PURVASHADA/பூராடம்", "UTTARASHADA/உத்திராடம்", "SHARAVAN/திருவோணம்", "DHANISHTA/அவிட்டம்", "SHATABISHA/சதயம்", "PURVABHADRA/பூரட்டாதி", "UTTARABHADRA/உத்திரட்டாதி", "REVATI/ரேவதி"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/சுக்ல பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "PURNIMA/பௌர்ணமி"],
                    },
                    {
                        name: "KRISHNA PAKSA/கிருஷ்ண பக்ஷம்",
                        tithiNames: ["PRATHAMA/பிரதமை", "DWITIYA/துவிதியை", "TRITIYA/திருதியை", "CHARTURTHI/சதுர்த்தி", "PANCHAMI/பஞ்சமி", "SHASHTHI/ஷஷ்டி", "SAPTAMI/ஸப்தமி", "ASHTAMI/அஷ்டமி", "NAVAMI/நவமி", "DASAMI/தசமி", "EKADASI/ஏகாதசி", "DVADASI/துவாதசி", "TRAYODASI/த்ரயோதசி", "CHATURDASI/சதுர்த்தசி", "AMAVASYA/அமாவாசை"]

                    }
                ]

            }

            ]
        },
        {
            name: "Malayalam",
            calenderCode: 2,
            month: [
                {
                    name: "MEENAM/മീനം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]



                },
                {
                    name: "MEDAM/മെഡാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "EDAVAM/എദാവം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "MITHUNAM/മിഥുനം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "KARKADAKAM/കാർകിഡകാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "CHINGAM/ചിങ്കാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "KANNI/കാനി",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "THULAM/തുലാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "VRISHCHIKAM/വ്രിഷിക്കാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "DHANU/ഭാനു",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "MAKARAM/മകരം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                },
                {
                    name: "KUMBHAM/കുംഹാം",
                    nakshathira: ["ASHWINI/അശ്വനി", "BHARANI/ഭരണി", "KRITTIKA/കൃത്തിക", "ROHINI/രോഹിണി", "MRIGHASIRA/മൃഗശിര", "ARDRA/അർദ്ധ", "PUNARVASU/പൂർണ്ണവാസു", "PUSHYA/പൂഷ്യ", "ASHLESHA/അശ്വതി", "MAGHA/മകം", "PURVA PHALGUNI/പുർവഫാൽഗുണി", "UTTARA PHALGUNI/ഉത്തരാഫാൽഗുണി", "HASTA/ഹസ്ത", "CHITRA/ചിത", "SWATI/സ്വാതി", "VISHAKA/വിശാഖം", "ANURADHA/അനുരാധ", "JYESTHA/തിരുവാതിര", "MOOLA/മൂലം", "PURVASHADA/പുർവാഷാഢ", "UTTARASHADA/ഉത്തരാഷാഢ", "SHARAVAN/ശ്രാവണം", "DHANISHTA/ധനിഷ്ഠ", "SHATABISHA/ചിത്തിര", "PURVABHADRA/പുർവാഭദ്ര", "UTTARABHADRA/ഉത്തരാഭദ്ര", "REVATI/രേവതി"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/വെളുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "PURNIMA/പൂർണ്ണിമ"],
                        },
                        {
                            name: "KRISHNA PAKSHA/കറുത്ത പക്ഷം",
                            tithiNames: ["PRATHAMA/പ്രതിപദം", "DWITIYA/ദ്വിതീയം", "TRITIYA/തൃതീയം", "CHARTURTHI/ചതുർത്ഥം", "PANCHAMI/പഞ്ചമ", "SHASHTHI/ഷോഡശി", "SAPTAMI/സപ്തമി", "ASHTAMI/അഷ്ടമി", "NAVAMI/നവമി", "DASAMI/ദശമി", "EKADASI/ഏകാദശി", "DVADASI/ദ്വാദശി", "TRAYODASI/ത്രിദശി", "CHATURDASI/ചതുർദശി", "AMAVASYA/അമാവാസി"]

                        }
                    ]

                }
            ]
        },
        {
            name: "Telugu",
            calenderCode: 3,
            month: [
                {
                    name: "CHAITHRAMU/చైత్రము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]
                },
                {
                    name: "VAISAAKHAMU/వైశాఖము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "JYESTHAMU/జ్యేష్ఠము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "ASHADHAMU/ఆషాఢము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                // {
                //     name: "Sravanamu (Adhik)/శ్రావణము",
                //     nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                //     Paksham: [
                //         {
                //             name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                //             tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                //         },
                //         {
                //             name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                //             tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                //         }
                //     ]

                // },
                {
                    name: "SRAVANAMU/శ్రావణము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "BHADHRAPADAMU/భాద్రపదము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "AASVAYUJAMU/ఆశ్వయుజము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "KARTHIKAMU/కార్తీకము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "MAARGASEERSHAMU/మార్గశీర్షము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "PUSHYAMU/పుష్యము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]

                },
                {
                    name: "MAKHAMU/మఖము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]
                },
                {
                    name: "PHALGUNAMU/ఫాల్గుణము",
                    nakshathira: ["ASHWINI/అశ్వయుజు", "BHARANI/భరణి", "KRITTIKA/కృత్తిక", "ROHINI/రోహిణి", "MRIGHASIRA/మృగశిర", "ARDRA/ఆర్ద్ర", "PUNARVASU/పునర్వసు", "PUSHYA/పుష్య", "ASHLESHA/ఆశ్లేష", "MAGHA/మఘ", "PURVA PHALGUNI/పూర్వ ఫల్గుణి", "UTTARA PHALGUNI/ఉత్తర ఫల్గుణి", "HASTA/హస్త", "CHITRA/చిత్ర", "SWATI/స్వాతి", "VISHAKA/విశాఖ", "ANURADHA/అనురాధ", "JYESTHA/జ్యేష్ఠ", "MOOLA/మూల", "PURVASHADA/పూర్వాషాఢ", "UTTARASHADA/ఉత్తరాషాఢ", "SHARAVAN/శ్రావణ", "DHANISHTA/ధనిష్ఠ", "SHATABISHA/శతభిష", "PURVABHADRA/పూర్వభాద్ర", "UTTARABHADRA/ఉత్తరభాద్ర", "REVATI/రేవతి"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/శుక్ల పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "PURNIMA/పౌర్ణమి"],
                        },
                        {
                            name: "KRISHNA PAKSHA/కృష్ణ పక్షం",
                            tithiNames: ["PRATHAMA/పాడ్యమి", "DWITIYA/విదియ", "TRITIYA/తదియ", "CHARTURTHI/చవితి", "PANCHAMI/పంచమి", "SHASHTHI/షష్టి", "SAPTAMI/సప్తమి", "ASHTAMI/అష్టమి", "NAVAMI/నవమి", "DASAMI/దశమి", "EKADASI/ఏకాదశి", "DVADASI/ద్వాదశి", "TRAYODASI/త్రయోదశి", "CHATURDASI/చతుర్ధశి", "AMAVASYA/అమావాస్య"]

                        }
                    ]
                }
            ]
        },
        {
            name: "Kannada",
            calenderCode: 4,
            month: [{
                name: "CHAITRA/ಚೈತ್ರ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "VAISHAKHA/ವೈಶಾಖ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "JYESHTHA/ಜ್ಯೇಷ್ಠ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "ASHADHA/ಆಷಾಢ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "SAHRAVANA/ಶ್ರಾವಣ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "BHADRAPADA/ಭಾದ್ರಪದ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "ASHWEJA/ಆಶ್ವೇಜ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "KARTIKA/ಕಾರ್ತೀಕ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "MARGASHIRSHA/ಮಾರ್ಗಶೀರ್ಷ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "PAUSHA/ಪೌಷಾ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "MAGHA/ಮಾಘ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            {
                name: "PHALGUNA/ಫಲ್ಗುಣ",
                nakshathira: ["ASHWINI/ಅಶ್ವಿನಿ", "BHARANI/ಭರಣಿ", "KRITTIKA/ಕೃತ್ತಿಕಾ", "ROHINI/ರೋಹಿಣಿ", "MRIGHASIRA/ಮೃಘಾಸಿರ", "ARDRA/ಆರ್ದ್ರಾ", "PUNARVASU/ಪುನರ್ವಸು", "PUSHYA/ಪುಷ್ಯ", "ASHLESHA/ಆಶ್ಲೇಷಾ", "MAGHA/ಮಾಘ", "PURVA PHALGUNI/ಪೂರ್ವ ಫಲ್ಗುಣಿ", "UTTARA PHALGUNI/ಉತ್ತರ ಫಲ್ಗುಣಿ", "HASTA/ಹಸ್ತ", "CHITRA/ಚಿತ್ರಾ", "SWATI/ಸ್ವಾತಿ", "VISHAKA/ವಿಶಾಕ", "ANURADHA/ಅನುರಾಧಾ", "JYESTHA/ಜ್ಯೇಷ್ಠ", "MOOLA/ಮೂಲಾ", "PURVASHADA/ಪೂರ್ವಾಷಾಡ", "UTTARASHADA/ಉತ್ತರಾಷಾಢ", "SHARAVAN/ಶರವಣ", "DHANISHTA/ಧನಿಷ್ಠಾ", "SHATABISHA/ಶತಬಿಷಾ", "PURVABHADRA/ಪೂರ್ವಾಭದ್ರ", "UTTARABHADRA/ಉತ್ತರಾಭಾದ್ರ", "REVATI/ರೇವತಿ"],
                Paksham: [
                    {
                        name: "SHUKLA PAKSHA/ಶುಕ್ಲ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "PURNIMA/ಪೌರ್ಣಿಮಿ"],
                    },
                    {
                        name: "KRISHNA PAKSHA/ಕೃಷ್ಣ ಪಕ್ಷ",
                        tithiNames: ["PRATHAMA/ಪ್ರಥಮ", "DWITIYA/ದ್ವಿತಿ", "TRITIYA/ತ್ರಿತೀಯ", "CHARTURTHI/ಚತುರ್ಥಿ", "PANCHAMI/ಪಂಚಮಿ", "SHASHTHI/ಷಷ್ಠಿ", "SAPTAMI/ಸಪ್ತಮಿ", "ASHTAMI/ಅಷ್ಟಮಿ", "NAVAMI/ನವಮಿ", "DASAMI/ದಶಮಿ", "EKADASI/ಏಕಾದಶಿ", "DVADASI/ದ್ವಾದಶಿ", "TRAYODASI/ತ್ರಯೋದಶಿ", "CHATURDASI/ಚತುರ್ದಶಿ", "AMAVASYA/ಅಮಾವಾಸ್ಯೆ"]

                    }
                ]


            },
            ]
        },
        {
            name: "Hindi",
            calenderCode: 5,
            month: [
                {
                    name: "CHAITRA/चैत्र",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "VAISAKHA/वैशाख",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "ASHADHA/आषाढ़",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                // {
                //     name: "SHRAVANA(Adhik)/श्रावण",
                //     nakshathira:
                //         [
                //             "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                //     Paksham: [
                //         {
                //             name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                //             tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                //         },
                //         {
                //             name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                //             tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                //         }
                //     ]
                // },
                {
                    name: "SHRAVANA/श्रावण",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "BHADRAPADA/भाद्रपद",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "ASHVIN/अश्विन",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "KATIKA/कार्तिक",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "MARGASIRSA/मार्गशीर्ष",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },

                {
                    name: "PAUSHA/पौष",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "MAGH/माघ",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                },
                {
                    name: "PHALGUNA/फाल्गुन",
                    nakshathira:
                        [
                            "ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशिरा", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/अश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वफाल्गुनी", "UTTARA PHALGUNI/उत्तराफाल्गुनी", "HASTA/हस्त", "CHITRA/चित्रा", "SWATI/स्वाति", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूल", "PURVASHADA/पूर्वाषाढ़", "UTTARASHADA/उत्तराषाढ़", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/शुक्ल पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            name: "KRISHNA PAKSHA/कृष्ण पक्ष",
                            tithiNames: ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्टी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]

                        }
                    ]
                }
            ]
        },
        {
            name: "Gujarati",
            calenderCode: 6,
            month: [
                {
                    name: "CHAITRA/ચૈત્ર",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]




                },
                {
                    name: "VAISHAKHA/વૈશાખા",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "JETHA/જેઠા",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                // {
                //     name: "SHRAVANA adhik/શ્રાવણ",
                //     nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                //     Paksham: [
                //         {
                //             name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                //             tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                //         },
                //         {
                //             name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                //             tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                //         }
                //     ]


                // },
                {
                    name: "SHRAVANA/શ્રાવણ",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "BHADRAVO/ભાદરવો",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "ASO/આસો",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "KARKAT/કારતક",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "MAGSHAR/માગશર",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "POSH/પોષ",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "MAHA/મહા",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },
                {
                    name: "PHAGUNA/ફાગુના",
                    nakshathira: ["ASHWINI/અશ્વિની", "BHARANI/ભરણી", "KRITTIKA/કૃત્તિકા", "ROHINI/રોહિણી", "MRIGHASIRA/મૃગશીરા", "ARDRA/આર્દ્રા", "PUNARVASU/પુનર્વસુ", "PUSHYA/પુષ્ય", "ASHLESHA/અશ્લેષા", "MAGHA/મઘા", "PURVA PHALGUNI/પૂર્વ ફાલ્ગુની", "UTTARA PHALGUNI/ઉત્તર ફાલ્ગુની", "HASTA/હસ્ત", "CHITRA/ચિત્રા", "SWATI/સ્વાતિ", "VISHAKA/વિશાખા", "ANURADHA/અનુરાધા", "JYESTHA/જ્યેષ્ઠા", "MOOLA/મૂળ", "PURVASHADA/પૂર્વ શાડ", "UTTARASHADA/ઉત્તર શાડ", "SHRAVANA/શ્રાવણ", "DHANISHTA/ધનિષ્ઠા", "SHATABISHA/શતભિષા", "PURVABHADRA/પૂર્વભાદ્ર", "UTTARABHADRA/ઉત્તરભાદ્ર", "REVATI/રેવતી"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/શુક્લ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "PURNIMA/પૂર્ણિમા"],
                        },
                        {
                            name: "KRISHNA PAKSHA/કૃષ્ણ પક્ષ",
                            tithiNames: ["PRATHAMA/પ્રતિપદા", "DWITIYA/દ્વિતીયા", "TRITIYA/ત્રીતીયા", "CHARTURTHI/ચતુર્થી", "PANCHAMI/પંચમી", "SHASHTHI/ષષ્ઠી", "SAPTAMI/સપ્તમી", "ASHTAMI/આષ્ટમી", "NAVAMI/નવમી", "DASAMI/દશમી", "EKADASI/એકાદશી", "DVADASI/દ્વાદશી", "TRAYODASI/ત્રયોદશી", "CHATURDASI/ચતુર્દશી", "AMAVASYA/આમાસ્યા"]

                        }
                    ]


                },

            ]
        },
        {
            name: "Bengali",
            calenderCode: 7,
            month: [
                {
                    name: "BOISHAKH/বৈশাখ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]



                },
                {
                    name: "JOISHTHO/জ্যৈষ্ঠ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "ASHARH/আষাঢ়",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "SHRABON/শ্রাবণ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "BHADRO/ভাদ্র",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "ASHSHIN/আশ্বিন",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "KARTIK/কার্তিক",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "AGRAHAYAN/অগ্রহায়ণ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "POUSH/পৌষ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "MAGH/মাঘ",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "FALGUN/ফাল্গুন",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },
                {
                    name: "CHAITRA/চৈত্র",
                    nakshathira: ["ASHWINI/অশ্বিনী", "BHARANI/ভরণী", "KRITTIKA/কৃত্তিকা", "ROHINI/রোহিণী", "MRIGHASIRA/মৃগশিরা", "ARDRA/আর্দ্রা", "PUNARVASU/পুনর্বসু", "PUSHYA/পুষ্য", "ASHLESHA/অশ্লেষা", "MAGHA/মঘা", "PURVA PHALGUNI/পূর্ব ফাল্গুনী", "UTTARA PHALGUNI/উত্তর ফাল্গুনী", "HASTA/হস্ত", "CHITRA/চিত্রা", "SWATI/স্বাতী", "VISHAKA/বিশাখা", "ANURADHA/অনুরাধা", "JYESTHA/জ্যেষ্ঠা", "MOOLA/মূল", "PURVASHADA/পূর্বষাঢ়", "UTTARASHADA/উত্তরষাঢ়", "SHARAVAN/শ্রাবণ", "DHANISHTA/ধনিষ্ঠা", "SHATABISHA/শতভিষা", "PURVABHADRA/পূর্বভাদ্র", "UTTARABHADRA/উত্তরভাদ্র", "REVATI/রেবতী"],
                    Paksham: [
                        {
                            name: "SHUKLA PAKSHA/শুক্ল পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "PURNIMA/পূর্ণিমা"],
                        },
                        {
                            name: "KRISHNA PAKSHA/কৃষ্ণ পক্ষ",
                            tithiNames: ["PRATHAMA/প্রতিপদ", "DWITIYA/দ্বিতীয়া", "TRITIYA/তৃতীয়া", "CHARTURTHI/চতুর্থী", "PANCHAMI/পঞ্চমী", "SHASHTHI/ষষ্ঠী", "SAPTAMI/সপ্তমী", "ASHTAMI/অষ্টমী", "NAVAMI/নবমী", "DASAMI/দশমী", "EKADASI/একাদশী", "DVADASI/দ্বাদশী", "TRAYODASI/ত্রয়োদশী", "CHATURDASI/চতুর্দশী", "AMAVASYA/অমাবস্যা"]

                        }
                    ]
                },

            ]
        },
        {
            name: "Marathi",
            calenderCode: 8,
            month: [
                {
                    name: "CHAITRA/चैत्र",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "VAISAKHA/वैशाख",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "JYESTHA/ज्येष्ठा",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "ASHADHA/आषाढ़",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "SHRAVANA/श्रावण",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "BHADRAPADA/भाद्रपद",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "ASHVIN/अश्विन",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "KATIKA/कार्तिक/चैत्र",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "MARGASIRSA/मार्गशीर्ष",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "PAUSHA/पौष",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "MAGH/माघ",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
                {
                    name: "PHALGUNA/फाल्गुन",
                    nakshathira: ["ASHWINI/अश्विनी", "BHARANI/भरणी", "KRITTIKA/कृत्तिका", "ROHINI/रोहिणी", "MRIGHASIRA/मृगशीर्ष", "ARDRA/आर्द्रा", "PUNARVASU/पुनर्वसु", "PUSHYA/पुष्य", "ASHLESHA/आश्लेषा", "MAGHA/मघा", "PURVA PHALGUNI/पूर्वा फाल्गुनी", "UTTARA PHALGUNI/उत्तर फाल्गुनी", "HASTA/हस्ता", "CHITRA/चित्रा", "SWATI/स्वाती", "VISHAKA/विशाखा", "ANURADHA/अनुराधा", "JYESTHA/ज्येष्ठा", "MOOLA/मूळा", "PURVASHADA/पूर्वाषाढा", "UTTARASHADA/उत्तराषाढा", "SHARAVAN/श्रावण", "DHANISHTA/धनिष्ठा", "SHATABISHA/शतभिषा", "PURVABHADRA/पूर्वाभाद्र", "UTTARABHADRA/उत्तराभाद्र", "REVATI/रेवती"],
                    Paksham: [
                        {
                            "name": "SHUKLA PAKSHA/शुक्ल पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "PURNIMA/पूर्णिमा"]
                        },
                        {
                            "name": "KRISHNA PAKSHA/कृष्ण पक्ष",
                            "tithiNames": ["PRATHAMA/प्रतिपदा", "DWITIYA/द्वितीया", "TRITIYA/तृतीया", "CHARTURTHI/चतुर्थी", "PANCHAMI/पंचमी", "SHASHTHI/षष्ठी", "SAPTAMI/सप्तमी", "ASHTAMI/अष्टमी", "NAVAMI/नवमी", "DASAMI/दशमी", "EKADASI/एकादशी", "DVADASI/द्वादशी", "TRAYODASI/त्रयोदशी", "CHATURDASI/चतुर्दशी", "AMAVASYA/अमावस्या"]
                        }
                    ]
                },
            ]
        }



    ]





    const handleCalender = (event) => {
        setChangesMade(true);
        if (event.target.value === "Any") {
            DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value });
            return;
        }


        const calender = Calender.filter((cal, val) => {
            return cal.name == event.target.value
        });
        DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value, calenderCode: calender[0].calenderCode })
        SetCalender(event.target.value)
        SetMonths(Calender.find(clr => clr.name === event.target.value).month)
    }

    const handleMonth = (event) => {
        setChangesMade(true);
        DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value })
        SetMonth(event.target.value)
        SetNakshathras(months.find(mth => mth.name === event.target.value).nakshathira)
        SetNakTithis(months.find(tth => tth.name === event.target.value).Paksham)

    }
    const handleNakTithi = (event) => {
        setChangesMade(true);
        DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value })
        SetNakTithi(event.target.value)
    }

    const handleNakshathra = (event) => {
        setChangesMade(true);
        DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value })
        SetNakshathra(event.target.value)
    }

    const handlePaksham = (event) => {
        setChangesMade(true);
        DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value })
        SetPaksham(event.target.value)
        SettithiName(nakTithis.find(pks => pks.name === event.target.value).tithiNames)
    }

    const handleTithi = (event) => {
        setChangesMade(true);
        DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value })
    }

    const handleInput = (event) => {
        setChangesMade(true);
        DynamicFunction({ ...dynamicState, [event.target.name]: event.target.value })
    }

    const { userName, userEmail, userGender, userCalender, userMonth, nakshatraTithi, userNakshathra, userPaksham, userTithi } = dynamicState

    const signInHome = () => {

        const isCheckPassed = checkFields(); // Call checkFields and get its return value

        if (isCheckPassed) {


            // console.log(dynamicState);

            const code = localStorage.getItem("code")

            setDynamicArray([...dynamicArray, { userName, userEmail, userGender, userCalender, userMonth, nakshatraTithi, userNakshathra, userPaksham, userTithi }])
            // context.globalFunction([...dynamicArray, dynamicState]);

            const URL = "https://backend.shubadinam.com/add/userprofile"

            const dataToSend = emailPass && emailPass !== null && emailPass !== undefined
                ? { ...dynamicState, emailPass: emailPass }
                : dynamicState;

            // console.log(emailPass);

            // const dataToSend = { ...dynamicState, emailPass: JSON.parse(emailPass) };

            axios.post(URL, dataToSend)
                .then((response) => {
                    const addUser = response.data
                    let impId = addUser.insertedId;

                    let secureId = addUser.secureId;

                    const stringifyUserInfo = JSON.stringify(dynamicState.userEmail);
                    localStorage.setItem("isAuthenticated", btoa(stringifyUserInfo));
                    const stringifyForeignKey = JSON.stringify(dynamicState.userEmail);
                    localStorage.setItem("fkey", btoa(stringifyForeignKey));
                    localStorage.setItem("SD_Id", secureId);
                    // console.log(addUser);
                    addUserForm(impId)
                })
                .catch((error) => {
                    // console.log(error);
                    if (error.response.status === 401) {
                        Swal.fire({
                            title: 'Already Registered.',
                            text: error.response.data.message,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                        });
                    } else {
                        Swal.fire({
                            title: 'Oops !',
                            text: "Something unexpected happen ! Please try again.",
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                        });
                    }
                    navigate("/")
                })

            const addUserForm = (impId) => {

                // const URL = `https://backend.shubadinam.com/list/userprofile?userEmail=${dynamicState.userEmail}&userMonth=${dynamicState.userMonth}&nakshatraTithi=${dynamicState.nakshatraTithi}&userNakshathra=${dynamicState.userNakshathra}&userPaksham=${dynamicState.userPaksham}&userTithi=${dynamicState.userTithi}&userCalender=${dynamicState.userCalender}&impId=${impId}`;

                const sdId = localStorage.getItem("SD_Id");


                const URL = `https://backend.shubadinam.com/list/userprofile?userEmail=${dynamicState.userEmail}&userMonth=${dynamicState.userMonth}&nakshatraTithi=${dynamicState.nakshatraTithi}&userNakshathra=${dynamicState.userNakshathra}&userPaksham=${dynamicState.userPaksham}&userTithi=${dynamicState.userTithi}&userDateTime=${dynamicState.userDateTime}&userCalender=${dynamicState.userCalender}&impId=${impId}&secureId=${sdId}`;

                axios.get(URL)
                    .then((response) => {
                        const getData = response.data
                        localStorage.setItem('myDataKey2', JSON.stringify(getData));

                        // const stringifyUserInfo = JSON.stringify(dynamicState.userEmail);
                        // localStorage.setItem("isAuthenticated", btoa(stringifyUserInfo));
                        // localStorage.setItem("email", dynamicState.userEmail);
                        // sessionStorage.removeItem("profileauth")
                        localStorage.removeItem("code")
                        localStorage.setItem('homeauth', 'true')
                        navigate("/dashboard_thankyou");

                        // setQueryResult(getData)
                        // console.log(getData)
                        context.globalFunction(getData)
                        localStorage.setItem('myDataKey2', JSON.stringify(getData));
                        window.location.reload();


                    })
                    .catch((error) => {
                        // console.log(error);
                    })
            }

        }
    }


    const nakshatraCheck = [
        'userName',
        'userEmail',
        'userGender',
        'userCountry',
        'userState',
        'userRelation',
        'nakshatraTithi',
        'userCalender',
        'userMonth',
        'userNakshathra'
    ];
    const tithiCheck = [
        'userName',
        'userEmail',
        'userGender',
        'userCountry',
        'userState',
        'userRelation',
        'nakshatraTithi',
        'userCalender',
        'userMonth',
        'userPaksham',
        'userTithi',
    ];

    const dateinputcheck = [
        'userName',
        'userEmail',
        'userGender',
        'userCity',
        'userCountry',
        'userState',
        'userRelation',
        'nakshatraTithi',
        'userCalender',
        'userDateTime',
    ];


    // const checkFields = () => {


    //     if (dynamicState) {
    //         const excludedFields = ['sdinamId', 'userDateTime', 'userCity', 'userPaksham', 'userTithi', 'userNakshathra'];

    //         for (const [key, value] of Object.entries(dynamicState)) {
    //             if (!excludedFields.includes(key) && !value) {
    //                 alert(`Please fill '${key}' all the fields!`);
    //                 console.log("log 1");
    //                 // return false;
    //             }
    //         }
    //     }

    //     if (dynamicState && dynamicState.userDateTime) {

    //         for (let field of dateinputcheck) {
    //             if (dynamicState[field] === "") {
    //                 alert(`Please fill out all the fields!`);
    //                 console.log("log 2");
    //                 // return;
    //             }
    //         }
    //     }


    //     if (dynamicState && dynamicState.nakshatraTithi === "Nakshatra") {

    //         for (let field of nakshatraCheck) {
    //             if (dynamicState[field] === "") {
    //                 alert(`Please fill out all the fields!`);
    //                 // return;
    //                 console.log("log 3");
    //             }
    //         }
    //     }


    //     if (dynamicState && dynamicState.nakshatraTithi === "Tithi") {
    //         for (let field of tithiCheck) {
    //             if (dynamicState[field] === "") {
    //                 alert(`Please fill out all the fields!`);
    //                 // return;
    //                 console.log("log ");
    //             }
    //         }
    //     }
    //     setsumbitClicked(true)

    // };
    const checkFields = () => {
        let fielderrors = [];

        if (dynamicState) {
            const excludedFields = ['sdinamId', 'userDateTime', 'userCity', 'userPaksham', 'userTithi', 'userNakshathra'];

            for (const [key, value] of Object.entries(dynamicState)) {
                if (!excludedFields.includes(key) && !value) {
                    fielderrors.push(`Please fill '${key}' field!`);

                }
            }
        }

        if (dynamicState && dynamicState.userDateTime) {
            for (let field of dateinputcheck) {
                if (dynamicState[field].trim() === "") {
                    fielderrors.push(`Please fill out the '${field}' field!`);
                }
            }
        }

        if (dynamicState && dynamicState.nakshatraTithi === "Nakshatra") {
            for (let field of nakshatraCheck) {
                if (dynamicState[field].trim() === "") {
                    fielderrors.push(`Please fill out the '${field}' field!`);
                }
            }
        }

        if (dynamicState && dynamicState.nakshatraTithi === "Tithi") {
            for (let field of tithiCheck) {
                if (dynamicState[field].trim() === "") {
                    fielderrors.push(`Please fill out the '${field}' field!`);
                }
            }
        }

        if (fielderrors.length > 0) {
            // console.log(errors.join("\n"));
            alert("Please fill out all the fields !")
            return false;
        } else {
            setsumbitClicked(true);
            return true;
        }
    };


    // const signInHome = () => {
    //     console.log(dynamicState);
    // }
    const stateSelectRef = useRef(null);
    const [autofilledState, setAutofilledState] = useState("");



    useEffect(() => {
    }, [dynamicArray]);

    const [isPremiumClicked, setPremiumClicked] = useState(false)

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY || window.pageYOffset;
            const scrollPercent = (scrollY / window.innerHeight) * 100;

            if (scrollPercent > 10) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array ensures that this effect runs only once


    const [activeButton, setActiveButton] = useState(1); // State to track active button
    const [isPremiumSelected, setPremiumSelected] = useState(false); // State to track active button


    const handleButtonClick = (buttonNumber) => {
        setActiveButton(buttonNumber);
        if (buttonNumber === 2) {

            setPremiumSelected(true)
        }
        else {
            setPremiumSelected(false)

        }

    };

    const [isPremium, setPremium] = useState(true)

    const handleDateTime = (e) => {
        setChangesMade(true);
        DynamicFunction({ ...dynamicState, [e.target.name]: e.target.value })
    }

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };





    return (
        <div className="overall-container-profile">
            <HelmetProvider>
                <Helmet>
                    <title>Profile </title>
                    <meta name="description" content="Profile  - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <ScrollToTop smooth />

            <div className="overall-percent-signup">

                {
                    sumbitClicked &&
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>

                }


                <div className={isScrolled ? 'signup-title-scrolled' : "signup-title"}>
                    <div className="prof-logo">
                        <img style={{ width: "140px" }} src={require("../images/New Shubadinam Logo/Shubadinam White.png")} width="100" height="30" onContextMenu={(e) => e.preventDefault()} />
                    </div>
                    <div className="prof-title">
                        <h1 className="letters-color-header">Ready To Connect With Your Cosmic Heritage</h1>
                    </div>
                </div>
                <Top20px />
                <Top10px />
                <div className="signup-switch">
                    <div className="switch-container">
                        <div className="nakshatra-option">
                            <button
                                className={activeButton === 1 ? 'nakshatra-btn-active' : 'nakshatra-btn-inactive'}
                                onClick={() => handleButtonClick(1)}
                            >
                                Find By Indian Calendar
                            </button>

                        </div>
                        <div className="date-option">



                            <button
                                className={`date-btn ${activeButton === 2 ? 'date-btn-active' : 'date-btn-inactive'}`}
                                onClick={() => handleButtonClick(2)}
                            >
                                👑
                                Find By English Calendar
                            </button>
                        </div>
                    </div>


                </div>
                {
                    isPremiumSelected ?
                        <div className="signup-title-heading">
                            <h4 style={{ textAlign: "center", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", padding: "20px" }}> Use this Nakshatra/Tithi finder Assistance to get the details as per English Date of Birth.  </h4>
                        </div>
                        :

                        <div className="signup-title-heading">
                            <h4 style={{ textAlign: "center", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> Fill Janma Nakshatra/ Tithi details to get reminders. </h4>
                        </div>
                }


                {
                    isPremiumSelected ?
                        <>
                            {
                                // premium selected so it shows both nakshatra and date picker
                                premiumSuccess ?
                                    <>
                                        <div className="signup-content">

                                            <div className="signup-box-image">

                                            </div>
                                            <div className="signup-box">
                                                {/* <div className="title-profile">
                                                    <h2 className="letters-color-prf-head">Profile</h2>
                                                </div> */}
                                                <div className="title-name">
                                                    <div className="key-title letters-color">Name:</div>
                                                    <div className="value-input">
                                                        <input className="singup-input-box" type="text" name="userName" onChange={handleInput} />
                                                    </div>
                                                </div>
                                                <div className="title-email">
                                                    <div className="key-title letters-color" >Email:</div>
                                                    <div className="value-input">
                                                        <input className="singup-input-box" type="email" name="userEmail" placeholder="please enter user email" value={premEmail} />
                                                    </div>
                                                </div>
                                                <div className="title-gender">
                                                    <div className="key-title letters-color">Gender:</div>
                                                    <div className="value-input">
                                                        <select defaultValue="" className="singup-input-box" name="userGender" onChange={handleInput}>
                                                            <option disabled value="" className="placeholder-select">Select Gender</option>
                                                            <option value="male">Male</option>
                                                            <option value="female">Female</option>
                                                            <option value="others">Others</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="title-relation">
                                                    <div className="key-title letters-color">Relation:</div>
                                                    <div className="value-input">
                                                        <input className="singup-input-box" type="text" name="userRelation" value=" Myself" readOnly />
                                                    </div>
                                                </div>

                                                <div className="title-nakshatra-tithi">
                                                    <div className="key-title letters-color">What do you celebrate:</div>
                                                    <div className="value-input">
                                                        <select defaultValue={""} className="singup-input-box" onChange={handleNakTithi} name="nakshatraTithi" >
                                                            <option disabled value="">--Nakshatra/Tithi--</option>
                                                            <option value="Nakshatra">Nakshatra</option>
                                                            <option value="Tithi">Tithi</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="title-calender">
                                                    <div className="key-title letters-color">Panchang:</div>
                                                    <div className="value-input">
                                                        <select defaultValue={""} className="singup-input-box" onChange={handleCalender} name="userCalender" >
                                                            <option disabled value={""}>--Panchang--</option>
                                                            {
                                                                (isPremiumSelected && isPremium)
                                                                    ? Calender2.map((clr, index) => (
                                                                        <option key={index} value={clr.name} data-calendercode={clr.calenderCode}>{clr.name}</option>
                                                                    ))
                                                                    : Calender.map((clr, index) => (
                                                                        <option key={index} value={clr.name} data-calendercode={clr.calenderCode}>{clr.name}</option>
                                                                    ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="title-gender">
                                                    <div className="key-title letters-color">Birth Country:</div>
                                                    <div className="value-input">
                                                        <CountrySelect
                                                            defaultValue={dynamicState.userCountry === "India" ? defaultCountry : ""}

                                                            onChange={(e) => {
                                                                setCountryid(e.id);
                                                                // console.log(e);
                                                                DynamicFunction(prevState => ({
                                                                    ...prevState,
                                                                    userCountry: e.name
                                                                }));
                                                            }}
                                                            onTextChange={(e) => {
                                                                DynamicFunction(prevState => ({
                                                                    ...prevState,
                                                                    userCountry: e.target.value
                                                                }));
                                                            }}
                                                            name="userCountry"
                                                            placeHolder="Select Country"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="title-gender">
                                                    <div className="key-title letters-color">Birth State:</div>
                                                    <div className="value-input">
                                                        <StateSelect
                                                            countryid={countryid}
                                                            onChange={(e) => {
                                                                setstateid(e.id);
                                                                // console.log(e);
                                                                DynamicFunction(prevState => ({
                                                                    ...prevState,
                                                                    userState: e.name
                                                                }));
                                                            }}
                                                            onTextChange={(e) => {
                                                                DynamicFunction(prevState => ({
                                                                    ...prevState,
                                                                    userState: e.target.value
                                                                }));
                                                                // console.log(e.target.value);
                                                            }}
                                                            name="userState"
                                                            placeHolder="Select State"
                                                        />

                                                    </div>
                                                </div>

                                                <div className="title-gender">
                                                    <div className="key-title letters-color">Birth City:</div>
                                                    <div className="value-input">
                                                        <CitySelect
                                                            countryid={countryid}
                                                            stateid={stateid}
                                                            onChange={(e) => {
                                                                setCityid(e.id);
                                                                // console.log(e);
                                                                DynamicFunction(prevCity => ({
                                                                    ...prevCity,
                                                                    userCity: e.name
                                                                }));
                                                            }}
                                                            onTextChange={(e) => {
                                                                DynamicFunction(prevCity => ({
                                                                    ...prevCity,
                                                                    userCity: e.target.value
                                                                }));
                                                                // console.log(e.target.value);
                                                            }}
                                                            name="userCity"
                                                            placeHolder="Select City"
                                                        />


                                                    </div>
                                                </div>



                                                {
                                                    //  is premium show date picker 
                                                    isPremium ?
                                                        <>
                                                            <div className="title-name">
                                                                <div className="key-title letters-color">Birth Date & Time:</div>
                                                                <div className="value-input">
                                                                    <input className="singup-input-box" type='datetime-local' onChange={(e) => handleDateTime(e)} placeholder="--Select Date & Time" name="userDateTime" ></input>
                                                                </div>
                                                            </div>

                                                        </>
                                                        :
                                                        //  is premium show nakshatra selector 

                                                        <>

                                                            <div className="title-nakshatra-tithi">
                                                                <div className="key-title letters-color">What do you celebrate:</div>
                                                                <div className="value-input">
                                                                    <select defaultValue={""} className="singup-input-box" onChange={handleNakTithi} name="nakshatraTithi" >
                                                                        <option disabled value="">--Nakshatra/Tithi--</option>
                                                                        <option value="Nakshatra">Nakshatra</option>
                                                                        <option value="Tithi">Tithi</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="title-calender">
                                                                <div className="key-title letters-color">Panchang:</div>
                                                                <div className="value-input">
                                                                    <select defaultValue={""} className="singup-input-box" onChange={handleCalender} name="userCalender" >
                                                                        <option disabled value={""}>--Panchang--</option>
                                                                        {
                                                                            Calender.map((clr, index) => (
                                                                                <option key={index} value={clr.name} data-calendercode={clr.calenderCode}>{clr.name}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="title-month">
                                                                <div className="key-title letters-color">Month:</div>
                                                                <div className="value-input">
                                                                    <select defaultValue={""} className="singup-input-box" onChange={handleMonth} name="userMonth" >
                                                                        <option disabled value="">--Month--</option>
                                                                        {
                                                                            months.map((mth, index) => (
                                                                                <option key={index} value={mth.name}>{mth.name}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>


                                                            {
                                                                nakTithi && nakTithi === "Nakshatra" ?



                                                                    <div className="title-nakshathra">
                                                                        <div className="key-title letters-color">Nakshatra:</div>
                                                                        <div className="value-input">
                                                                            <select defaultValue={""} className="singup-input-box" onChange={handleNakshathra} name="userNakshathra">
                                                                                <option disabled value={""}>--Nakshatra--</option>
                                                                                {
                                                                                    nakshathras.map((nakshathra, index) => (
                                                                                        <option key={index} value={nakshathra}>{nakshathra}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div></div>
                                                            }


                                                            {
                                                                nakTithi && nakTithi === "Tithi" ?
                                                                    <div className="nakandtithi">
                                                                        <div className="title-Paksham">
                                                                            <div className="key-title letters-color">Paksha:</div>
                                                                            <div className="value-input">
                                                                                <select defaultValue={""} className="singup-input-box" onChange={handlePaksham} name="userPaksham">
                                                                                    <option disabled value={""}>--Paksha--</option>
                                                                                    {
                                                                                        nakTithis.map((Paksham, index) => (
                                                                                            <option key={index} value={Paksham.name}>{Paksham.name}</option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                        <div className="title-Tithiname">
                                                                            <div className="key-title letters-color">Tithi:</div>
                                                                            <div className="value-input">
                                                                                <select defaultValue={""} className="singup-input-box" onChange={handleTithi} name="userTithi">
                                                                                    <option disabled value={""}>--Tithi--</option>
                                                                                    {
                                                                                        tithiname.map((tithi, index) => (
                                                                                            <option key={index} value={tithi}>{tithi}</option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div></div>
                                                            }

                                                            <br />

                                                            <span style={{ fontSize: '14px', color: '#666', fontStyle: 'italic' }}>
                                                                (Note: Please fill out all the fields to continue.) *
                                                            </span>


                                                            <br />
                                                            <label className="letters-color profile-buy-link">If you don't know the above details ,
                                                                {/* <NavLink onClick={() => handleButtonClick(2)}>Click here</NavLink> */}
                                                                <ProfilePremiumDialog />
                                                            </label>
                                                            <br />


                                                        </>
                                                }



                                                <div className="title-signup-btn">
                                                    <button
                                                        onClick={() => signInHome()}
                                                        className="signup-btn-style"
                                                        disabled={
                                                            dynamicState.userName.trim() === "" ||
                                                            dynamicState.userEmail.trim() === "" ||
                                                            dynamicState.userGender.trim() === "" ||
                                                            dynamicState.userCountry.trim() === "" ||
                                                            dynamicState.userState.trim() === "" ||
                                                            dynamicState.userRelation.trim() === "" ||
                                                            dynamicState.userCalender.trim() === "" ||
                                                            dynamicState.nakTithi.trim() === "" ||
                                                            dynamicState.userDateTime.trim() === ""
                                                        }

                                                    >{sumbitClicked ? "Submitting" : "Submit"}</button>
                                                </div>


                                            </div>


                                        </div>
                                    </>
                                    :
                                    <PremiumAlert />
                            }

                        </>

                        :
                        // non premium only nakshatra selector
                        <>
                            <div className="signup-content">

                                {
                                    isPremiumClicked &&
                                    // <>
                                    <ProfilePremiumDialog />
                                    // </>
                                }

                                <div className="signup-box-image">

                                </div>
                                <div className="signup-box">
                                    {/* <div className="title-profile">
                                        <h2 className="letters-color-prf-head">Profile</h2>
                                    </div> */}
                                    <div className="title-name">
                                        <div className="key-title letters-color">Name:</div>
                                        <div className="value-input">
                                            <input className="singup-input-box" type="text" name="userName" onChange={handleInput} />
                                        </div>
                                    </div>
                                    <div className="title-email">
                                        <div className="key-title letters-color" >Email:</div>
                                        <div className="value-input">
                                            <input className="singup-input-box" type="email" name="userEmail" placeholder="please enter user email" value={premEmail} />
                                        </div>
                                    </div>
                                    <div className="title-gender">
                                        <div className="key-title letters-color">Gender:</div>
                                        <div className="value-input">
                                            <select defaultValue="" className="singup-input-box" name="userGender" onChange={handleInput}>
                                                <option disabled value="" className="placeholder-select">Select Gender</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="others">Others</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="title-gender">
                                        <div className="key-title letters-color">Country:</div>
                                        <div className="value-input">
                                            <CountrySelect
                                                defaultValue={dynamicState.userCountry === "India" ? defaultCountry : ""}

                                                onChange={(e) => {
                                                    setCountryid(e.id);
                                                    // console.log(e);
                                                    DynamicFunction(prevState => ({
                                                        ...prevState,
                                                        userCountry: e.name
                                                    }));
                                                }}
                                                onTextChange={(e) => {
                                                    DynamicFunction(prevState => ({
                                                        ...prevState,
                                                        userCountry: e.target.value
                                                    }));
                                                }}
                                                name="userCountry"
                                                placeHolder="Select Country"
                                            />
                                        </div>
                                    </div>
                                    <div className="title-gender">
                                        <div className="key-title letters-color">State:</div>
                                        <div className="value-input">
                                            <StateSelect
                                                countryid={countryid}
                                                onChange={(e) => {
                                                    setstateid(e.id);
                                                    // console.log(e);
                                                    DynamicFunction(prevState => ({
                                                        ...prevState,
                                                        userState: e.name
                                                    }));
                                                }}
                                                onTextChange={(e) => {
                                                    DynamicFunction(prevState => ({
                                                        ...prevState,
                                                        userState: e.target.value
                                                    }));
                                                    // console.log(e.target.value);
                                                }}
                                                name="userState"
                                                placeHolder="Select State"
                                            />

                                        </div>
                                    </div>





                                    <div className="title-relation">
                                        <div className="key-title letters-color">Relation:</div>
                                        <div className="value-input">
                                            <input className="singup-input-box" type="text" name="userRelation" value=" Myself" readOnly />
                                        </div>
                                    </div>

                                    <div className="title-nakshatra-tithi">
                                        <div className="key-title letters-color">What do you celebrate:</div>
                                        <div className="value-input">
                                            <select defaultValue={""} className="singup-input-box" onChange={handleNakTithi} name="nakshatraTithi" >
                                                <option disabled value="">--Nakshatra/Tithi--</option>
                                                <option value="Nakshatra">Nakshatra</option>
                                                <option value="Tithi">Tithi</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="title-calender">
                                        <div className="key-title letters-color">Panchang:</div>
                                        <div className="value-input">
                                            <select defaultValue={""} className="singup-input-box" onChange={handleCalender} name="userCalender" >
                                                <option disabled value={""}>--Panchang--</option>
                                                {
                                                    Calender.map((clr, index) => (
                                                        <option key={index} value={clr.name} data-calendercode={clr.calenderCode}>{clr.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="title-month">
                                        <div className="key-title letters-color">Month:</div>
                                        <div className="value-input">
                                            <select defaultValue={""} className="singup-input-box" onChange={handleMonth} name="userMonth" >
                                                <option disabled value="">--Month--</option>
                                                {
                                                    months.map((mth, index) => (
                                                        <option key={index} value={mth.name}>{mth.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>


                                    {/* <div className="title-nakshatra-tithi">
        <div className="key-title letters-color">Nakshatra/Tithi:</div>
        <div className="value-input">
            <select defaultValue={""} className="singup-input-box" onChange={handleNakTithi} name="nakshatraTithi" >
                <option disabled value="">--Nakshatra/Tithi--</option>
                <option value="Nakshatra">Nakshatra</option>
                <option value="Tithi">Tithi</option>
            </select>
        </div>
    </div> */}


                                    {
                                        nakTithi && nakTithi === "Nakshatra" ?

                                            <div className="title-nakshathra">
                                                <div className="key-title letters-color">Nakshatra:</div>
                                                <div className="value-input">
                                                    <select defaultValue={""} className="singup-input-box" onChange={handleNakshathra} name="userNakshathra">
                                                        <option disabled value={""}>--Nakshatra--</option>
                                                        {
                                                            nakshathras.map((nakshathra, index) => (
                                                                <option key={index} value={nakshathra}>{nakshathra}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            :
                                            <div></div>
                                    }


                                    {
                                        nakTithi && nakTithi === "Tithi" ?
                                            <div className="nakandtithi">
                                                <div className="title-Paksham">
                                                    <div className="key-title letters-color">Paksha:</div>
                                                    <div className="value-input">
                                                        <select defaultValue={""} className="singup-input-box" onChange={handlePaksham} name="userPaksham">
                                                            <option disabled value={""}>--Paksha--</option>
                                                            {
                                                                nakTithis.map((Paksham, index) => (
                                                                    <option key={index} value={Paksham.name}>{Paksham.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="title-Tithiname">
                                                    <div className="key-title letters-color">Tithi:</div>
                                                    <div className="value-input">
                                                        <select defaultValue={""} className="singup-input-box" onChange={handleTithi} name="userTithi">
                                                            <option disabled value={""}>--Tithi--</option>
                                                            {
                                                                tithiname.map((tithi, index) => (
                                                                    <option key={index} value={tithi}>{tithi}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div></div>
                                    }

                                    <br />

                                    <span style={{ fontSize: '14px', color: '#666', fontStyle: 'italic' }}>
                                        (Note: Please fill out all the fields to continue.) *
                                    </span>


                                    <br />
                                    <label className="letters-color profile-buy-link">If you don’t know the above details, you can use English Date of Birth to find the Janma Dinam details.
                                        {/* <ProfilePremiumDialog /> */}
                                        <NavLink style={{ textDecoration: "none" }} onClick={() => handleButtonClick(2)}> Click here to know more.</NavLink>
                                    </label>
                                    <br />



                                    <div className="title-signup-btn">
                                        <button onClick={() => signInHome()} className="signup-btn-style"
                                        // disabled={
                                        //     dynamicState.userName.trim() === "" ||
                                        //     dynamicState.userCountry.trim() === "" ||
                                        //     dynamicState.userState.trim() === "" ||
                                        //     dynamicState.userCountry.trim() === ""
                                        // }

                                        >{sumbitClicked ? "Submitting" : "Submit"}</button>
                                    </div>




                                </div>


                            </div>
                        </>
                }





                <br />
                {/* <div className="empty-container-footer-profile"> */}
                <FooterComponent></FooterComponent>
                {/* </div> */}
            </div>


        </div >
    )
}
export default SingupScreen;