import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import axios from "axios";
import FooterComponent from "../components/footer";
import SecondDataSharing from "../context/second-data-sharing";

import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTop from "react-scroll-to-top";
import Top10px from "./Top10px";
import shubadinamLogo from '../images/New Shubadinam Logo/Shubadinam Star logo.png';


const CheckoutPage = () => {
  const [data, setData] = useState(null);

  const tableTwoData = useContext(SecondDataSharing);
  // console.log(tableTwoData);

  const Navigate = useNavigate();

  const signOut = () => {
    setSignOut(false);
    sessionStorage.clear()
    localStorage.clear();
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("fkey");
    localStorage.removeItem("homeauth")
    Navigate("/");
  };

  const logoBtn = () => {
    Navigate("/");
  };

  // MENU BAR

  const [isOpenMenu, setOpenMenu] = useState(false);

  const [dashsignOut, setSignOut] = useState(false);

  const [selectedPack, setSelectedPack] = useState('SD FREE'); // State to track the selected pack

  const handleSelectPack = (pack) => {
    setSelectedPack(pack);
  };

  const buttonStyleFree = {
    border: selectedPack === 'SD FREE' ? '2px solid black' : 'none',
    color: selectedPack === 'SD FREE' ? '#ffcc75' : '#fff', // Golden color if selected, black otherwise
    // Add other common styles here
  };

  const buttonStylePremium = {
    border: selectedPack === 'SD PREMIUM' ? '2px solid black' : 'none',
    color: selectedPack === 'SD PREMIUM' ? '#ffcc75' : '#fff', // Golden color if selected, black otherwise
  };

  const handleButtonClick = () => {

    let rupees;
    // Access the selectedPack value here
    // console.log('Selected Pack:', selectedPack);

    if (selectedPack === "SD PREMIUM") {
      Navigate("/checkout");
    }

  };

  const userkeyid = localStorage.getItem("userkeyid")
  const uniquserid = atob(userkeyid)

  // const clickPayButton = () => {
  //   const url = `https://backend.shubadinam.com/pay?uniquserid=${uniquserid}`
  //   // Navigate(url);
  //   axios.get(url)
  //     .then((response) => {
  //       const redirectTo = response.data.redirectTo;
  //       // Log the redirect URL (optional)
  //       console.log('Redirecting to:', redirectTo);

  //       // Perform the client-side redirect
  //       window.location.href = redirectTo;
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     })


  //   // try {
  //   //   const response =  axios.get('/pay'); // Replace with the correct endpoint
  //   //   window.location.href = response.data.data.instrumentResponse.redirectInfo.url;
  //   // } catch (error) {
  //   //   // setPaymentStatus('Error processing payment');
  //   //   console.error('Payment Error:', error);
  //   // }

  // }

  const [orderId, setOrderId] = useState(null);
  const [transactionId, setTransactionId] = useState(null);


  const createOrder = async () => {
    try {
      const response = await fetch('https://backend.shubadinam.com/create-order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (data.id) {
        // console.log(data);
        setOrderId(data.id);
        setTransactionId(data.receipt);
        handlePayment(data.id);
        //  transactionId = data.receipt;

        // if (data.redirect) {
        //   window.location.href = data.redirect;
        // }
      } else {
        alert('Failed to create order');
      }
    } catch (error) {
      // console.error('Error:', error.message);
      alert('Failed to fetch data. Check the console for details.');
    }
  };

  // useEffect(() => {
  //   createOrder();
  // }, []); 
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      document.getElementById('rzp-button1').addEventListener('click', createOrder);
    };
  }, [orderId]);

  const handlePayment = (orderId) => {
    console.log(orderId);
    if (orderId) {
      const options = {
        key: 'rzp_live_gp41aILMbhUm19', // Replace with your Razorpay key
        amount: 50000,
        currency: 'INR',
        order_id: orderId,
        name: 'Shubadinam Name',
        description: 'Test Transaction',
        image: shubadinamLogo,
        prefill: {
          name: 'Official Guy',
          email: 'gaurav.kumar@example.com',
          contact: '9000090000',
        },
        handler: function (response) {
          // console.log(response);

          const url = `https://backend.shubadinam.com/razorpayresponse`

          axios.post(url, {
            paymentId: response.razorpay_payment_id, orderId: response.razorpay_order_id
          })
            .then((response) => {
              // const paymentId= response.razorpay_payment_id
              // console.log(response);
              if (response.status) {
                // console.log('====================================');
                // console.log("yes");
                // console.log('====================================');
                alert(transactionId)
              } else {

              }
            })
        },
        notes: {
          address: 'Razorpay Corporate Office',
        },
        theme: {
          color: '#3399cc',
        },
      };

      const rzp1 = new window.Razorpay(options);

      rzp1.on('payment.success', (response) => {
        alert(`Payment Successful. Payment ID: ${response.razorpay_payment_id}`);
      });

      rzp1.on('payment.error', (response) => {
        alert(`Payment Failed. Error: ${response.error.description}`);
      });

      rzp1.open();
    } else {
      alert('Order ID is missing. Please try again.');
    }
  };



  return (
    <div className="overall-container-home">
      <div className="overall-percent-home">
        <ScrollToTop smooth />

        <div className="container-header-profile">
          <div className="container-1">
            {/* MENU BAR CONDITION */}

            <div className={`menu-bar-container ${isOpenMenu ? "open" : ""}`}>
              {/* <Hamburger toggled={isOpenMenu} toggle={setOpenMenu}></Hamburger> */}

              {isOpenMenu && (
                <div className="menu-bar-cnt">
                  {dashsignOut ? (
                    <div className="menu-bar-items" data-aos="fade-up">
                      <NavLink
                        to="/"
                        className={({ isActive }) =>
                          isActive ? "menu-link hightlight-link" : "menu-link"
                        }
                      >
                        Home
                      </NavLink>
                    </div>
                  ) : (
                    <div className="menu-bar-items" data-aos="fade-up">
                      <NavLink
                        to="/dashboard"
                        className={({ isActive }) =>
                          isActive ? "menu-link hightlight-link" : "menu-link"
                        }
                      >
                        Home
                      </NavLink>
                    </div>
                  )}

                  <div className="menu-bar-items" data-aos="fade-up">
                    <NavLink
                      to="/dashboard"
                      className={({ isActive }) =>
                        isActive ? "menu-link hightlight-link" : "menu-link"
                      }
                    >
                      Dashboard
                    </NavLink>
                  </div>

                  <div className="menu-bar-items" data-aos="fade-up">
                    <NavLink
                      to="/about-us"
                      className={({ isActive }) =>
                        isActive ? "menu-link hightlight-link" : "menu-link"
                      }
                    >
                      About Us
                    </NavLink>
                  </div>

                  <div className="dropdown menu-bar-items" data-aos="fade-up">
                    <NavLink to="/blogs">
                      {" "}
                      <button className="dropbtn">Blogs</button>
                    </NavLink>
                    {/* <p className="dropbtn">Blogs</p> */}
                    <div className="dropdown-content">
                      <NavLink to="/nakshatra-foods">1. Nakshatra Food</NavLink>
                      <NavLink to="/nakshatra-characteristics">
                        2. Nakshatra Characteristics
                      </NavLink>
                      <NavLink to="/nakshatra-deities">
                        3. Nakshatra Deity
                      </NavLink>
                      <NavLink to="/nakshatra-likes-dislikes">
                        4. Nakshatra Likes and Dislikes
                      </NavLink>
                      <NavLink to="/tithi-foods">5. Tithi Food</NavLink>
                      <NavLink to="/tithi-characteristics">
                        6. Tithi Characteristics
                      </NavLink>
                      <NavLink to="/tithi-deities">7. Tithi Deity</NavLink>
                      <NavLink to="/tithi-likes-dislikes">
                        8. Tithi Likes and Dislikes
                      </NavLink>
                      <NavLink to="/meanings-of-janma-nakshatra">
                        9. Meanings of Janma Nakshatra
                      </NavLink>
                    </div>
                  </div>

                  <div className="menu-bar-items">
                    <NavLink
                      to="/faq"
                      className={({ isActive }) =>
                        isActive ? "menu-link hightlight-link" : "menu-link"
                      }
                    >
                      FAQ
                    </NavLink>
                  </div>

                  <div className="menu-bar-items">
                    <NavLink
                      className="home-signout"
                      to="/"
                      onClick={() => signOut()}
                    >
                      Sign Out
                    </NavLink>
                  </div>

                  {/* <div className="menu-bar-items" data-aos="fade-up">
                                            <label className="reg-btn-menu" onClick={() => scrollToSection()}>Register</label>
                                        </div>
                                        <div className="menu-bar-items">
                                            <label className="reg-btn-menu open-btn" onClick={() => siginbackReg()}>Sign In</label>
                                        </div> */}
                </div>
              )}
            </div>

            <div
              className="wordpress"
              onContextMenu={(e) => e.preventDefault()}
            >
              <img
                className="logo-fit-header"
                src={require("../images/New Shubadinam Logo/Shubadinam White.png")}
                width="100%"
                height="100%"
                onClick={() => logoBtn()
                }
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>

            <div className="headers">
              {/* <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "link hightlight-link" : "link"
                }
              >
                Home
              </NavLink> */}

              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  isActive ? "link hightlight-link" : "link"
                }
              >
                Dashboard
              </NavLink>

              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "link hightlight-link" : "link"
                }
              >
                Shop
              </NavLink>



              <NavLink
                to="/faq"
                className={({ isActive }) =>
                  isActive ? "link hightlight-link" : "link"
                }
              >
                FAQ
              </NavLink>

              {/* <NavLink
                to="/services"
                className={({ isActive }) =>
                  isActive ? "link hightlight-link" : "link"
                }
              >
                SD PREMIUM 👑
              </NavLink> */}

              {/* <div className="my-profile"> */}
              <NavLink
                to="/my-profile"
                className={({ isActive }) =>
                  isActive ? "link hightlight-link" : "link"
                }
              >
                My Profile
              </NavLink>
              {/* </div> */}

              {/* <NavLink
                to="/shop"
                className={({ isActive }) =>
                  isActive ? "link hightlight-link" : "link"
                }
              >
                Shop
              </NavLink> */}
            </div>

            {/* <div className="my-profile">
              <NavLink
                to="/my-profile"
                className={({ isActive }) =>
                  isActive ? "link hightlight-link" : "link"
                }
              >
                My Profile
              </NavLink>
            </div> */}

            <div className="login-out">
              <NavLink
                to="/"
                onClick={() => signOut()}
                style={{ textDecoration: "none", color: "white" }}
              >
                Sign Out
              </NavLink>
            </div>
          </div>
        </div>

        <Top10px />

        <div className="services-container">
          <br />

          <div className="services-container-content">
            <div className="service-cnt-left">
              <div className="service-logo">
                {/* <img src={require("../images/New Shubadinam Logo/Shubadinam Blue.png")} /> */}
              </div>
              <div className="subscription-lines">
                <label className="subscription-content">Subscribe Now and<br /> Start Celebrating</label>
              </div>
              <div className="loop-container">
                <div className="loop-video-container">
                  <div className="loop-video-content" >
                    {/* <Top10px /> */}
                    <img src={require("../images/boxesImages/food2.jpg")} /> <br />
                    <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/deity1.png")} /> <br />
                    <img src={require("../images/boxesImages/food1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/food2.jpg")} /> <br />
                    <img src={require("../images/boxesImages/likes11.jpg")} /> <br />
                    <img src={require("../images/boxesImages/deity2.png")} /> <br />
                    <img src={require("../images/boxesImages/char22.jpg")} /> <br />
                    <img src={require("../images/boxesImages/likes22.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char22.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/deity1.png")} /> <br />
                    <img src={require("../images/boxesImages/food1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/food2.jpg")} /> <br />
                    <img src={require("../images/boxesImages/likes11.jpg")} /> <br />
                    <img src={require("../images/boxesImages/deity2.png")} /> <br />
                    <img src={require("../images/boxesImages/char22.jpg")} /> <br />
                    <img src={require("../images/boxesImages/likes22.jpg")} />



                  </div>

                </div>
                <div className="loop-video-container2">
                  <div className="loop-video-content2" >
                    {/* <Top10px /> */}
                    <img src={require("../images/Shubadinam Blue.png")} />
                    <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char22.jpg")} />



                  </div>

                </div>
                <div className="loop-video-container">
                  <div className="loop-video-content" >
                    {/* <Top10px /> */}
                    <img src={require("../images/Shubadinam Blue.png")} />
                    <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char22.jpg")} />



                  </div>

                </div>
              </div>




            </div>
            <div className="check-cnt-right">


              <button id="rzp-button1">Pay Now</button>

              {/* <button onClick={() => clickPayButton()}>PAY BUTTON</button> */}

            </div>
          </div>

        </div>

        <br />
        <br />

        <FooterComponent></FooterComponent>
      </div>
    </div>
  );
};

export default CheckoutPage;
