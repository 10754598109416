import React, { useEffect } from "react";
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from "react-scroll-to-top";
import Top10px from "./Top10px";
import Top20px from "./Top20px";
import BottomSpace from "./BottomSpace"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HomamEvents from "../components/HomamEvents";
import LandingHeader from "../components/LandingHeader";
const AllHomams = () => {


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    return (

        <div>
            <ScrollToTop smooth />
            <HelmetProvider>
                <Helmet>
                    <title>All Services </title>
                    <meta name="description" content="Services  - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <div className="overall-container-terms">

                <div className="overall-percent">
                    <LandingHeader></LandingHeader>
                    <Top10px />
                    {/* <Top20px/> */}
                    <br />
                    <h5 style={{ padding: "50px", textAlign: "center" }}>All Services</h5>
                    <div className="center-container-homam">

                        <HomamEvents />

                    </div>
                    <BottomSpace />
                    <FooterComponent></FooterComponent>

                </div>
            </div>
        </div>

    )
};

export default AllHomams;