import React, { useState, useEffect, useRef } from "react";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, fa1, fa2, fa3, faUser, faL } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from "@fortawesome/fontawesome-free"

import ScrollToTop from "react-scroll-to-top";

import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

import FooterComponent from "../components/footer";
import HomeSignInComponent from "../components/home-signin";
import TestimonialScreen from "./testimonials";
import Swal from 'sweetalert2';
// import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import Top10px from "./Top10px";
import LandingHeader from "../components/LandingHeader";
import { Helmet, HelmetProvider } from 'react-helmet-async';


import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import HomamEvents from "../components/HomamEvents";
import HomamEventPhone from "../components/HomamEventPhone";
import DashboardHeader from "../components/DashboardHeader";
import ShopComponent from "../Shopee/ShopComponent";




AOS.init({
    duration: 1000, // Notice the single quotes around '2s'
});



const LoginScreen = () => {

    const [dashsignOut, setSignOut] = useState(false)

    const [isHomeauth, setisHomeauth] = useState(false)


    useEffect(() => {
        AOS.refresh();
    }, []);

    useEffect(() => {
        const auth = localStorage.getItem("homeauth");
        if (auth) {
            setSignOut(true)
            setisHomeauth(true)
        }
    }, []);




    //  verify user

    // const clickHome = () => {
    //     const auth = localStorage.getItem("isAuthenticated");
    //     if (!auth) {
    //         Swal.fire({
    //             title: '',
    //             text: 'New to Shubadinam.com! Please Sign up or Log in.',
    //             icon: 'warning',
    //             confirmButtonText: 'Ok',
    //         }).then((result) => {
    //             if (result.isConfirmed) {
    //                 navigate("/");
    //             }
    //         });
    //     } else {
    //         navigate("/home");
    //     }
    // }



    const [signupcontent, SetSignupContent] = useState(false)



    const clientId = "516265231821-9n4dbqkgm349bl3a76j75lgeu19vv71h.apps.googleusercontent.com"

    // const clientSecret = "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";

    const navigate = useNavigate();

    const sectionRef = useRef(null);

    const howitworks = useRef(null)


    const [offset, setOffset] = useState(0);
    const [inView, setInView] = useState(false);

    const handleScroll = () => {
        setOffset(window.scrollY);

        // Check if the content is in view
        const element = document.querySelector('.parallax-content');
        if (element) {
            const rect = element.getBoundingClientRect();
            setInView(rect.top < window.innerHeight);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const [backendError, SetBackendError] = useState([])


    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [flag, SetFlag] = useState(false)
    const [name, SetName] = useState("")

    const [dynamicInput, DynamicInputFunction] = useState({
        userEmail: "",
        userPassword: "",
        userPhone: "",
    })

    const [errorForm, ErrorFormFunction] = useState({
        userEmail: false,
        userPassword: false,
        userPhone: false
    })

    const [eyeIcon, UpdateEyeIcon] = useState(true)

    const eyeOpenClose = (icon) => {
        UpdateEyeIcon(icon)
    }

    const inputHandle = (event) => {
        DynamicInputFunction({ ...dynamicInput, [event.target.name]: event.target.value })
        setEmail(dynamicInput.userEmail)
    }

    const onSuccess = (response) => {
        // SetName(response.profileObj);
        // console.log("success", response);
        SetFlag(true)
    }

    const onFailure = (err) => {
        // console.log(err);
    }







    const [isSubmitClicked, setIsSubmitClicked] = useState(false);




    const clickSubmit = () => {

        if (!isSubmitClicked) {
            setIsSubmitClicked(true);

            const link = "https://backend.shubadinam.com/upload/mailpass";
            axios.post(link, dynamicInput)
                .then((response) => {


                    const loggingUser = response.data.data2;
                    const loggingRelation = response.data.data1;

                    if (response.data.errors) {
                        SetBackendError(response.data.errors)

                        const invalidmailPass = response.data.errors[0].msg
                        // console.log(invalidmailPass)

                        Swal.fire({
                            title: 'Invalid',
                            text: invalidmailPass,
                            icon: 'error',
                            confirmButtonText: 'Got it!', // Change the confirm button text
                            confirmButtonColor: '#3085d6', // Change the confirm button color
                            background: '#f5f5f5', // Change the background color
                            customClass: {
                                container: 'my-custom-container-class', // Add a custom container class
                                title: 'my-custom-title-class', // Add a custom title class
                                content: 'my-custom-content-class', // Add a custom content class
                                confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                            },
                        });




                    } else {
                        SetBackendError([])
                        const verifySuccess = response.data.success
                        console.log(verifySuccess)
                        Swal.fire({
                            title: 'Email Verification',
                            text: verifySuccess,
                            icon: 'success',
                            confirmButtonText: 'Got it!', // Change the confirm button text
                            confirmButtonColor: '#3085d6', // Change the confirm button color
                            background: '#f5f5f5', // Change the background color
                            customClass: {
                                container: 'my-custom-container-class', // Add a custom container class
                                title: 'my-custom-title-class', // Add a custom title class
                                content: 'my-custom-content-class', // Add a custom content class
                                confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                            },
                        });
                        const stringifyUserInfo = JSON.stringify(dynamicInput);
                        // localStorage.setItem("isAuthenticated", btoa(stringifyUserInfo));
                        const stringifyForeignKey = JSON.stringify(dynamicInput.userEmail);
                        // localStorage.setItem("fkey", btoa(stringifyForeignKey));
                        // console.log(dynamicInput)

                        // console.log(loggingUser);
                        // console.log(loggingRelation);
                        localStorage.setItem('myDataKey', JSON.stringify(loggingRelation));
                        localStorage.setItem('myDataKey2', JSON.stringify(loggingUser));

                        // window.location.reload();

                    }

                    setIsSubmitClicked(false);

                })
                .catch((error) => {
                    // console.log(error);


                    if (error.message === "Network Error") {
                        // const msg1 = error.response.data.message
                        Swal.fire({
                            title: 'Under Maintanance',
                            text: 'Please login after sometime!',
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                        })
                    } else {
                        const userExist = error.response.data

                        Swal.fire({
                            title: 'Invalid User',
                            text: userExist,
                            icon: 'warning',
                            confirmButtonText: 'Ok', // Change the confirm button text
                            confirmButtonColor: '#3085d6', // Change the confirm button color
                            background: '#f5f5f5', // Change the background color
                            customClass: {
                                container: 'my-custom-container-class', // Add a custom container class
                                title: 'my-custom-title-class', // Add a custom title class
                                content: 'my-custom-content-class', // Add a custom content class
                                confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                            },
                        });
                    }

                    setIsSubmitClicked(false);

                    // alert(error.response.data)
                });

        }

        // const stringifyUserInfo = JSON.stringify(dynamicInput);
        // localStorage.setItem("isAuthenticated", btoa(stringifyUserInfo));
        // localStorage.setItem("email", dynamicInput.userEmail);
        console.log(dynamicInput)



        ErrorFormFunction({
            ...errorForm,
            userEmail: dynamicInput.userEmail === "" ? true : false,
            userPassword: dynamicInput.userPassword === "" ? true : false,
            userPhone: dynamicInput.userPhone === "" ? true : false
        })
    }


    const clickSubmitbtn = () => {
        // console.log(dynamicInput);

        const link = "https://backend.shubadinam.com/logged/user"
        axios.post(link, dynamicInput)
            .then((response) => {
                const loggingUser = response.data.data2;
                const loggingRelation = response.data.data1;

                console.log(response.data);
                if (response.data.errors) {
                    SetBackendError(response.data.errors)


                    const invalidmailPass = response.data.errors[0].msg
                    // console.log(invalidmailPass)

                    Swal.fire({
                        title: 'Invalid',
                        text: invalidmailPass,
                        icon: 'error',
                        confirmButtonText: 'Got it!', // Change the confirm button text
                        confirmButtonColor: '#3085d6', // Change the confirm button color
                        background: '#f5f5f5', // Change the background color
                        customClass: {
                            container: 'my-custom-container-class', // Add a custom container class
                            title: 'my-custom-title-class', // Add a custom title class
                            content: 'my-custom-content-class', // Add a custom content class
                            confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                        },
                    });

                    // alert(response.data.errors[0].msg)




                } else {
                    SetBackendError([])
                    if (response.data.success === "Success") {

                        navigate("/dashboard");


                        const stringifyUserInfo = JSON.stringify(dynamicInput);
                        localStorage.setItem("isAuthenticated", btoa(stringifyUserInfo));
                        // localStorage.setItem("email", dynamicInput.userEmail);
                        const stringifyForeignKey = JSON.stringify(dynamicInput.userEmail);
                        localStorage.setItem("fkey", btoa(stringifyForeignKey));

                        // console.log(loggingUser);
                        // console.log(loggingRelation);
                        localStorage.setItem('myDataKey', JSON.stringify(loggingRelation));
                        localStorage.setItem('myDataKey2', JSON.stringify(loggingUser));

                        window.location.reload();

                        // navigate("/home");
                        // alert("yes")

                        // console.log(response.data);




                    } else {
                        // console.log(response.data);
                        alert("No records existed")
                    }
                }
            })
            .catch((error) => {

                const noRecords = error.response.data.message;
                const wrongpass = error.response.data.message;

                console.error('Error:', error)

                if (error.response.data.norecords === "Failure") {
                    Swal.fire({
                        title: 'Invalid',
                        // text: "We are currently unavailable.Please try again after sometime.",
                        // text: "No records existed.Please Sign up/Log in after sometime."    backendstop: "backend stop",
                        text: noRecords,
                        icon: 'warning',
                        confirmButtonText: 'Got it!', // Change the confirm button text
                        confirmButtonColor: '#3085d6', // Change the confirm button color
                        background: '#f5f5f5', // Change the background color
                        customClass: {
                            container: 'my-custom-container-class', // Add a custom container class
                            title: 'my-custom-title-class', // Add a custom title class
                            content: 'my-custom-content-class', // Add a custom content class
                            confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                        },
                    });
                }
                else if (error.response.data.passfailure === "Failure") {

                    Swal.fire({
                        title: 'Invalid',
                        // text: "We are currently unavailable.Please try again after sometime.",
                        // text: "No records existed.Please Sign up/Log in after sometime."    backendstop: "backend stop",
                        text: wrongpass,
                        icon: 'warning',
                        confirmButtonText: 'Got it!', // Change the confirm button text
                        confirmButtonColor: '#3085d6', // Change the confirm button color
                        background: '#f5f5f5', // Change the background color
                        customClass: {
                            container: 'my-custom-container-class', // Add a custom container class
                            title: 'my-custom-title-class', // Add a custom title class
                            content: 'my-custom-content-class', // Add a custom content class
                            confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                        },
                    });
                }
                else {
                    Swal.fire({
                        title: 'Invalid',
                        text: "We are currently unavailable.Please Sign up/Log in after sometime.",
                        icon: 'warning',
                        confirmButtonText: 'Got it!', // Change the confirm button text
                        confirmButtonColor: '#3085d6', // Change the confirm button color
                        background: '#f5f5f5', // Change the background color
                        customClass: {
                            container: 'my-custom-container-class', // Add a custom container class
                            title: 'my-custom-title-class', // Add a custom title class
                            content: 'my-custom-content-class', // Add a custom content class
                            confirmButton: 'my-custom-confirm-button-class' // Add a custom confirm button class
                        },
                    });
                }
            });
    }





    const handleMultipleClicks = () => {
        scrollToSection()
        togglePopup()

    }

    const scrollToSection = () => {

        navigate("/pricing")

        // if (sectionRef.current) {
        //     sectionRef.current.scrollIntoView({ behavior: 'smooth' });
        //     setIsOpen(false)
        //     SetSignupContent(true)
        //     setOpenMenu(false)
        // }
    };
    const siginbackReg = () => {
        navigate("/signin")
        // if (sectionRef.current) {
        //     sectionRef.current.scrollIntoView({ behavior: 'smooth' });
        //     setIsOpen(true)
        //     SetSignupContent(false)
        //     setOpenMenu(false)

        // }
    };

    const clickHow = () => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            SetSignupContent(false)
            setIsOpen(false)
        }
    };

    const clickSignparallax = () => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            SetSignupContent(true)
        }

    };



    const [isOpen, setIsOpen] = useState(false);
    const [scrollThresholdPercentage, setScrollThresholdPercentage] = useState(10); // 10% initial threshold


    const togglePopup = () => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            setIsOpen(!isOpen);
        }
        // setIsOpen(!isOpen);
    };

    // const handleMouseLeave = () => {
    //     setIsOpen(false); // Set dynamic variable to false on mouse leave
    // };

    useEffect(() => {
        const handleScrollfade = () => {
            // Calculate the scroll position threshold based on screen width
            const screenWidth = window.innerWidth;
            let scrollThreshold;

            if (screenWidth > 1300) {
                // For screens wider than 1300px, set a different threshold
                scrollThreshold = 3000;
            } else {
                // For screens 1300px or narrower, use a different threshold
                scrollThreshold = 2000; // You can adjust this value
            }

            if (window.scrollY > scrollThreshold) {
                setIsOpen(false);
            }
        };

        window.addEventListener('scroll', handleScrollfade);

        return () => {
            window.removeEventListener('scroll', handleScrollfade);
        };
    }, []);





    // /////////////COUNT DOWN and CELEBRATION EFFECT//////////////////

    const [inAugrate, SetInAugrate] = useState(true) // **********important dont forget to change this false to activate inaugrate

    const [countdown, setCountdown] = useState(5);

    const [celebrate, setCelebrate] = useState(false);



    const startCountdown = () => {
        if (countdown > 0) {
            startCountdownRecursive(countdown);
        }
    };

    const startCountdownRecursive = (currentCount) => {
        if (currentCount > 0) {
            const countdownTimeout = setTimeout(() => {
                setCountdown(currentCount - 1);
                startCountdownRecursive(currentCount - 1);
            }, 1000);
        } else {
            SetInAugrate(true)
            localStorage.setItem("inaugrated", "true")
            localStorage.setItem("celebration", "true")

            const celebrationKey = localStorage.getItem('celebration'); // Replace with your specific key

            if (celebrationKey) {
                // If the key is found in local storage, trigger the celebration
                setCelebrate(true);

                // Remove the key from local storage after 4 seconds
                setTimeout(() => {
                    localStorage.removeItem('celebration'); // Replace with your specific key
                    setCelebrate(false);
                }, 4000);
            }
        }
    }

    useEffect(() => {
        if (localStorage.getItem("inaugrated")) {
            SetInAugrate(true)
        }
        // window.scrollTo(0, 0); // Scroll to the top when component mounts

    }, [])

    // ///////////////////COUNT DOWN END and CEKEBRATION EFFECT END//////////////


    // MENU BAR

    const [isOpenMenu, setOpenMenu] = useState(false);


    // ENTER BUTTON

    const formRef = useRef(null);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !isSubmitClicked) {
            e.preventDefault(); // Prevent form submission
            // Trigger the submit button click
            formRef.current.querySelector('.sumbit-font').click();
        }
    };

    // dashboard signout
    const signuptoSignout = () => {
        setSignOut(false)
        localStorage.removeItem("isAuthenticated")
        localStorage.removeItem("homeauth")
        localStorage.removeItem("fkey")
        // Navigate("/")
    }

    const clickDashboard = () => {
        navigate("/dashboard")
    }

    const blogsNakshatra = () => {
        navigate(`/blogs-nakshatra`)
    }

    const blogsTithi = () => {
        navigate(`/blogs-tithi`)
    }

    const [howContainer, setHowContainer] = useState(false)


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setHowContainer(true);
            } else {
                setHowContainer(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);








    return (
        <div className="overall-container">
            <HelmetProvider>
                <Helmet>
                    <title>Shubadinam</title>
                    <meta name="description" content="Shubadinam - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <ScrollToTop smooth />

            {celebrate && (
                <div className="celebration-background">
                    <Confetti
                        width={window.innerWidth}
                        height={window.innerHeight}
                        colors={['#F44336', '#2196F3', '#FFEB3B', '#4CAF50', '#9C27B0']} // Customize colors
                    />
                </div>
            )}

            {/* to get the normal application delete the starred parts(***) */}

            {/* inaugration start*/}
            {
                inAugrate ?// ***

                    //    main content start 
                    <div className="overall-percent">

                        <Top10px />
                        <LandingHeader />
                        <div className="double-container-banner">

                            <div className="text-overlay">

                                {/* new lines */}

                                <p className="text-design" style={{}} >Birthdays are Special <br /><span className="text2"
                                    style={{ color: 'black', fontFamily: 'auto', fontWeight: "300" }}
                                > Make it Impactful... Make it a Shubadinam!</span></p>


                                <p className="text-design2">{howContainer && "Find and"} Get Reminders to celebrate the  Janma Nakshatra /
                                    Janma Tithi as per Indian Calendar.</p>
                                {
                                    isHomeauth ? (
                                        <button className="howbtn" onClick={() => clickDashboard()}>Dashboard</button>
                                    )
                                        :
                                        (
                                            <button className="howbtn" onClick={() => scrollToSection()}>Sign Up</button>
                                        )
                                }


                                {/* </div> */}
                            </div>

                        </div>

                        <div className="double-container-steps" ref={sectionRef}>

                            {
                                howContainer ?
                                    (
                                        <>
                                            <div className="container-howitworks">
                                                <div className="how-heading">
                                                    <h1 className="hw-head">How Shubadinam Works?</h1>
                                                    <h1 className="hw-head2">Setup and Forget in 3 <span className="text-danger" style={{ fontWeight: 500 }}>Easy</span> steps:</h1>
                                                </div>
                                                <div className="how-1">
                                                    <img src={require("../images/Phone_Template_Picture6.png")} onContextMenu={(e) => e.preventDefault()} />
                                                    <div className="how-content-ph">
                                                        <div className="how-ph-head">
                                                            <h4 style={{ padding: "20px", textAlign: "center", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>Register</h4>
                                                        </div>
                                                        <div className="how-ph-cnt">
                                                            <p className="how-cnt-para1" style={{ fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> Register for <span className="text-danger" style={{ fontWeight: 500, fontSize: '20px' }}>FREE</span> by easily creating an account using your email. It only takes a few minutes.</p>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="how-1">
                                                    <img src={require("../images/Phone_Template_Picture7.png")} onContextMenu={(e) => e.preventDefault()} />
                                                    <div className="how-content-ph">
                                                        <div className="how-ph-head">
                                                            <h4 style={{ padding: "20px", textAlign: "center", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>Enter your details</h4>
                                                        </div>
                                                        <div className="how-ph-cnt" >
                                                            <p className="how-cnt-para1" style={{ fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>Enter your Birth Star/Tithi information.{" "}Add Unlimited Janma dinam details of Family and Friends.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="how-1">
                                                    <img src={require("../images/Phone_Template_Picture8.png")} onContextMenu={(e) => e.preventDefault()} />
                                                    <div className="how-content-ph">
                                                        <div className="how-ph-head">
                                                            <h4 style={{ padding: "20px", textAlign: "center", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>Enjoy your day!</h4>
                                                        </div>
                                                        <div className="how-ph-cnt">
                                                            <p className="how-cnt-para1" style={{ fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>The moments that lead up to birthdays are always the best. Never miss out on your day with our duly reminders.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="how-lines">
                                                    <p style={{ textAlign: "center", fontSize: "larger", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>
                                                        Celebrate the Janma Dinam of your dear ones in the Indian Way.
                                                        <span style={{ fontWeight: "700" }}> Register Now!</span>
                                                    </p>
                                                </div>

                                                <div className="reg-btn-container">
                                                    <button className="reg-btn-new" style={{ width: "30%", height: "100%", fontFamily: 'Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif' }} onClick={() => scrollToSection()}>Register</button>
                                                </div>

                                            </div>
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <div className="container-howitworks">

                                                <div className="how-heading">
                                                    <h1 className="hw-head">How Shubadinam Works?</h1>
                                                    <h1 className="hw-head2">Setup and Forget in 3 <span className="text-danger" style={{ fontWeight: 500 }}>Easy</span> steps:</h1>
                                                </div>

                                                <img src={require("../images/3 steps.png")} onContextMenu={(e) => e.preventDefault()} />
                                                <div className="how-container-new">
                                                    <div className="how-cnt-lines-new">
                                                        <h4 style={{ padding: "20px", textAlign: "center" }}>Register</h4>
                                                        <p className="how-cnt-para1"> Register for <span className="text-danger" style={{ fontWeight: 500, fontSize: '20px' }}>FREE</span> by easily creating an account using your email. It only takes a few minutes.</p>
                                                    </div>
                                                    <div className="how-cnt-lines-new">
                                                        <h4 style={{ padding: "20px", textAlign: "center" }}>Enter your details</h4>
                                                        <p className="how-cnt-para1">Enter your Birth Star/Tithi information.{" "}Add Unlimited Janma dinam details of Family and Friends.</p>
                                                    </div>
                                                    <div className="how-cnt-lines-new">
                                                        <h4 style={{ padding: "20px", textAlign: "center" }}>Enjoy your day!</h4>
                                                        <p className="how-cnt-para1">The moments that lead up to birthdays are always the best.Never miss out on your day with our duly reminders.</p>
                                                    </div>

                                                </div>

                                                <div className="how-lines">

                                                    <p style={{ textAlign: "center", fontSize: "larger", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>
                                                        Celebrate the Janma dinam of your dear ones in the Indian Way.
                                                        <span className="text-danger" style={{ fontWeight: "700" }}> Register Now!</span>
                                                    </p>

                                                </div>


                                                <div className="reg-btn-container">
                                                    <button className="reg-btn-new" style={{ width: "25%", height: "100%", fontFamily: 'Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif' }} onClick={() => scrollToSection()}>Register</button>
                                                </div>

                                            </div>
                                        </>
                                    )
                            }



                        </div>
                        <br />
                        <div className="container-sky-celeb">
                            <div className="events-title">
                                <span style={{
                                    color: '#05195e', fontSize: "35px", fontFamily: 'Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif', fontWeight: 900, textTransform: "capitalize",
                                }}>Our Services</span>
                                {
                                    howContainer ?
                                        <h6 style={{ textAlign: "end", width: "100%", paddingRight: "20px" }}><NavLink to="/more-services" >View All</NavLink></h6>
                                        :
                                        <NavLink to="/more-services">View All</NavLink>
                                }

                            </div>

                            <div className="event-list">
                                {
                                    howContainer ?
                                        <HomamEventPhone />
                                        :

                                        <HomamEvents />
                                }

                            </div>
                        </div>

                        <ShopComponent />

                        <div className="double-container-boxes" >

                            <div className="db-box-cnt1">
                                <span style={{
                                    color: '#05195e', fontSize: "35px", fontFamily: 'Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif', fontWeight: 900
                                }}>From Our Blogs</span>

                            </div>
                            <div className="db-box-cnt2">
                                {/* <CenteredSlider /> */}

                                <Card sx={{ maxWidth: 345, height: { xs: "auto", md: "auto" }, mt: 2, }} onClick={(e) => blogsNakshatra()}>
                                    <CardActionArea sx={{ border: "none !important" }}>
                                        <CardMedia
                                            component="img"
                                            height="160"
                                            src={require("../images/boxesImages/food1.jpg")} alt="shubadinam image"
                                            onContextMenu={(e) => e.preventDefault()} />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                Nakshatra Blogs
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Unlocking Destiny: Understanding Janma Nakshatra's Influence. Explore the cosmic blueprint shaping personalities with Janma Nakshatra.
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary">
                                                Click Read More to know more
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions sx={{ justifyContent: "center" }}>
                                        <Button size="small" sx={{ backgroundColor: "#121347 !important", color: "white !important" }}>
                                            Read more
                                        </Button>
                                    </CardActions>
                                </Card>
                                <label style={{ width: "50px" }} />

                                <Card sx={{ maxWidth: 345, height: { xs: "auto", md: "auto" }, mt: 2 }} onClick={(e) => blogsTithi()}>
                                    <CardActionArea sx={{ border: "none !important" }}>
                                        <CardMedia
                                            component="img"
                                            height="160"
                                            src={require("../images/boxesImages/deity2.png")} alt="shubadinam image" loading="lazy" onContextMenu={(e) => e.preventDefault()} />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                Tithi Blogs
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Decoding Destiny: Unraveling Janma Tithi's Significance. Discover the profound impact of Janma Tithi on life's journey in just two lines.
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.secondary">
                                                Click Read More to know more
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions sx={{ justifyContent: "center" }}>
                                        <Button size="small" sx={{ backgroundColor: "#121347 !important", color: "white !important" }}>
                                            Read more
                                        </Button>
                                    </CardActions>
                                </Card>
                            </div>



                        </div>

                        <br />
                        <div className="testimonial-container">

                            <div className="db-box-cnt1">
                                <span style={{
                                    color: '#05195e', fontSize: "35px", fontFamily: 'Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif', fontWeight: 900, textTransform: "capitalize"
                                }}>What people say</span>

                            </div>
                            <div className="db-box-cnt2">
                                <TestimonialScreen />
                            </div>



                        </div>
                        <br />

                        <div className="about-startup">
                            <div className="about-title">
                                <span style={{
                                    color: '#05195e', fontSize: "35px", fontFamily: 'sans-serif', fontWeight: 900, textTransform: "capitalize"
                                }}>About Us</span>

                            </div>
                            <div className="about-lines">
                                <p className="letters-color-abtlines" >Shubadinam stands as a humble offering toward the noble cause of Retaining and Reclaiming the profound cultural legacy of Hinduism. Our mission is to illuminate the importance of celebrating Birth Stars, known as Janma Nakshatra / Janma Tithi. We firmly believe that <NavLink to="/about-us">read more</NavLink>.</p>

                            </div>
                        </div>

                        <FooterComponent></FooterComponent>

                    </div>
                    /* main content end */

                    // ***
                    :
                    <div className="overall-percent-inaugration">
                        <div className="inaug-header">
                            <img className="inaug-logo" src={require("../images/New Shubadinam Logo/Shubadinam White.png")} onContextMenu={(e) => e.preventDefault()} />
                        </div>
                        <div className="inaug-cnt">

                            <div>
                                <p className="inaug-cnt-para">🎉Get ready to celebrate your Birthday's with your Janma Nakshatra and Janma Tithi.🎉🎊🥳</p>
                            </div>
                            <div>
                                <h1 className="letters-color-countdown">Countdown: {countdown}</h1>
                                <button className="inaug-btn" onClick={() => startCountdown()}>Let's Celebrate</button>

                            </div>


                        </div>
                    </div>
            }
            {/* *** */}




        </div >
    )
}
export default LoginScreen;