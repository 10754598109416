import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useRef } from "react";
import Swal from "sweetalert2";
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import SecondDataSharing from "../context/second-data-sharing";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faTrashCan,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons"; // Replace with the correct FontAwesome icon import

import "bootstrap/dist/css/bootstrap.min.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { isDate } from "moment";
import Dashboard from "./Dashboard";

import ScrollToTop from "react-scroll-to-top";
import HomeSideRail from "../AdsFolder/HomeSideRail";
import HomeBannerAd from "../AdsFolder/HomeBannerAd";
import SiderBar from "../components/SiderBar";
import Top10px from "./Top10px";
import ProfileHeader from "../components/ProfileHeader";

const Services = () => {
  const [data, setData] = useState(null);

  const tableTwoData = useContext(SecondDataSharing);
  // console.log(tableTwoData);

  const Navigate = useNavigate();

  const signOut = () => {
    setSignOut(false);
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("fkey");
    localStorage.removeItem("homeauth")
    Navigate("/");
  };

  const logoBtn = () => {
    Navigate("/");
  };

  // MENU BAR

  const [isOpenMenu, setOpenMenu] = useState(false);

  const [dashsignOut, setSignOut] = useState(false);

  const [selectedPack, setSelectedPack] = useState('SD FREE'); // State to track the selected pack

  const handleSelectPack = (pack) => {
    setSelectedPack(pack);
  };

  const buttonStyleFree = {
    border: selectedPack === 'SD FREE' ? '2px solid black' : 'none',
    color: selectedPack === 'SD FREE' ? '#ffcc75' : '#fff', // Golden color if selected, black otherwise
    // Add other common styles here
  };

  const buttonStylePremium = {
    border: selectedPack === 'SD PREMIUM' ? '2px solid black' : 'none',
    color: selectedPack === 'SD PREMIUM' ? '#ffcc75' : '#fff', // Golden color if selected, black otherwise
  };

  const handleButtonClick = () => {

    let rupees;
    // Access the selectedPack value here
    console.log('Selected Pack:', selectedPack);

    if (selectedPack === "SD PREMIUM") {
      Navigate("/checkout");
    }

  };


  return (
    <div className="overall-container-home">
      <div className="overall-percent-home">
        <ScrollToTop smooth />

        <div className="container-header-profile">
          <ProfileHeader />
        </div>

        <Top10px />

        <div className="services-container">
          <br />

          <div className="services-container-content">
            <div className="service-cnt-left">
              <div className="service-logo">
                {/* <img src={require("../images/New Shubadinam Logo/Shubadinam Blue.png")} /> */}
              </div>
              <div className="subscription-lines">
                <label className="subscription-content">Subscribe Now and<br /> Start Celebrating</label>
              </div>
              <div className="loop-container">
                <div className="loop-video-container">
                  <div className="loop-video-content" >
                    {/* <Top10px /> */}
                    <img src={require("../images/boxesImages/food2.jpg")} /> <br />
                    <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/deity1.png")} /> <br />
                    <img src={require("../images/boxesImages/food1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/food2.jpg")} /> <br />
                    <img src={require("../images/boxesImages/likes11.jpg")} /> <br />
                    <img src={require("../images/boxesImages/deity2.png")} /> <br />
                    <img src={require("../images/boxesImages/char22.jpg")} /> <br />
                    <img src={require("../images/boxesImages/likes22.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char22.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/deity1.png")} /> <br />
                    <img src={require("../images/boxesImages/food1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/food2.jpg")} /> <br />
                    <img src={require("../images/boxesImages/likes11.jpg")} /> <br />
                    <img src={require("../images/boxesImages/deity2.png")} /> <br />
                    <img src={require("../images/boxesImages/char22.jpg")} /> <br />
                    <img src={require("../images/boxesImages/likes22.jpg")} />



                  </div>

                </div>
                <div className="loop-video-container2">
                  <div className="loop-video-content2" >
                    {/* <Top10px /> */}
                    <img src={require("../images/Shubadinam Blue.png")} />
                    <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char22.jpg")} />



                  </div>

                </div>
                <div className="loop-video-container">
                  <div className="loop-video-content" >
                    {/* <Top10px /> */}
                    <img src={require("../images/Shubadinam Blue.png")} />
                    <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char1.jpg")} /> <br />
                    <img src={require("../images/boxesImages/char22.jpg")} />



                  </div>

                </div>
              </div>




            </div>
            <div className="service-cnt-right">
              <div className="service-right-overlay">



                <table class="service-provider">
                  <colgroup>
                    <col style={{ width: '50%' }} />
                    <col style={{ width: '25%' }} />
                    <col style={{ width: '25%' }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th><h5></h5></th>
                      <th>SD FREE</th>
                      <th>SD PREMIUM</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>tick</td>
                      <td>✅</td>
                      <td>❌</td>
                    </tr>
                    <tr>
                      <td>tick</td>
                      <td>✅</td>
                      <td>❌</td>
                    </tr>
                    <tr>
                      <td>tick</td>
                      <td>✅</td>
                      <td>❌</td>
                    </tr>
                    <tr>
                      <td>tick</td>
                      <td>✅</td>
                      <td>❌</td>
                    </tr>
                    <tr>
                      <td>tick</td>
                      <td>✅</td>
                      <td>❌</td>
                    </tr>
                    <tr>
                      <td>tick</td>
                      <td>✅</td>
                      <td>❌</td>
                    </tr>
                    <tr>
                      <td>tick</td>
                      <td>✅</td>
                      <td>❌</td>
                    </tr>
                    <tr>
                      <td>tick</td>
                      <td>✅</td>
                      <td>❌</td>
                    </tr>
                    <tr>
                      <td>tick</td>
                      <td>✅</td>
                      <td>❌</td>
                    </tr>
                    <tr>
                      <td>tick</td>
                      <td>✅</td>
                      <td>❌</td>
                    </tr>
                    <tr>
                      <td>tick</td>
                      <td>✅</td>
                      <td>❌</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="premium-pack">
                <div className="sd-packs">
                  <div className="sd-free-container">
                    <button className="sd-free-button" onClick={() => handleSelectPack('SD FREE')} style={buttonStyleFree}> SD FREE <br /><span style={{ color: '#fff' }}>Starting at ₹ 0</span> </button>
                  </div>
                  <div className="sd-premium-container">
                    <button className="sd-premium-button" onClick={() => handleSelectPack('SD PREMIUM')} style={buttonStylePremium}> SD PREMIUM <br /><span style={{ color: '#fff' }}> ₹ 500/year</span></button>
                  </div>
                </div>

                <div className="sd-packs-continue">
                  <button onClick={() => handleButtonClick()}>Continue with {selectedPack}</button>
                </div>

              </div>
            </div>
          </div>

        </div>

        <br />
        <br />

        <FooterComponent></FooterComponent>
      </div>
    </div>
  );
};

export default Services;
