import React, { useState, useEffect } from 'react';

const HomamslotCard = ({ selectedOption, handleOptionChange, ticketCount, serviceName, onEventDate, choosedDate, onAmountChange }) => {

    useEffect(() => {
        const amount = ticketCount * (serviceName === "Surya-Namaskaram" ? 125 : 250);
        onAmountChange(amount);
    }, [ticketCount, serviceName, onAmountChange]);

    const dates = [
    ];


    if (choosedDate) {
        dates.push(choosedDate);
    }



    // Logic to populate dates based on serviceName
    // if (serviceName === 'Avahanti-Homam') {
    //     if (choosedDate) {
    //         dates.push(choosedDate);
    //     }
    //     //  else {
    //     //     dates.push(
    //     //         '2024-07-03',
    //     //         '2024-07-10',
    //     //         '2024-07-17',
    //     //         '2024-07-1',
    //     //     );
    //     // }


    // } else if (serviceName === 'Navagraha-Homam') {
    //     if (choosedDate) {
    //         dates.push(choosedDate);
    //     }
    //     //  else {
    //     //     dates.push(
    //     //         '2024-07-03',
    //     //         '2024-07-10',
    //     //         '2024-07-17',
    //     //         '2024-07-1',
    //     //     );
    //     // }
    // } else if (serviceName === 'Ganapathi Homam') {
    //     console.log('====================================');
    //     console.log('"fsf');
    //     console.log('====================================');
    //     if (choosedDate) {
    //         dates.push(choosedDate);
    //     }
    //     //  else {
    //     //     dates.push(
    //     //         '2024-07-03',
    //     //         '2024-07-10',
    //     //         '2024-07-17',
    //     //         '2024-07-1',
    //     //     );
    //     // }
    // } else if (serviceName === 'Surya-Namaskaram') {
    //     if (choosedDate) {
    //         dates.push(choosedDate);
    //     }
    //     //  else {
    //     //     dates.push(
    //     //         '2024-07-03',
    //     //         '2024-07-10',
    //     //         '2024-07-17',
    //     //         '2024-07-1',
    //     //     );
    //     // }
    // }


    const handleEventDate = (value) => {
        // Handle event date logic here
        onEventDate(value); // Call the parent component's handler
    };


    return (
        <>
            {dates.map((eventDate, index) => (
                <label key={index} className={`homam-slot-card ${selectedOption === `service${index}` ? 'selected' : ''}`} onClick={() => handleEventDate(eventDate)}>
                    <div className="homam-slot-card-content">

                        <div className={`homam-slot-content-left ${selectedOption === `service${index}` ? 'selected' : ''}`}>

                            <h6 style={{ padding: "10px", color: "white" }}>25</h6>
                            <p className={`homam-slot-availability ${selectedOption === `service${index}` ? 'selected' : ''}`}>
                                Available</p>

                        </div>

                        <div className="homam-slot-details">
                            <div className={`homam-slot-service-name ${selectedOption === `service${index}` ? 'selected' : ''}`}>
                                {new Date(eventDate).toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" })}
                            </div>
                            <div className={`homam-slot-person-count ${selectedOption === `service${index}` ? 'selected' : ''}`}> {ticketCount} person</div>
                            <div className={`homam-slot-amount ${selectedOption === `service${index}` ? 'selected' : ''}`}> &#8377; {ticketCount * (serviceName === "Surya-Namaskaram" ? 125 : 250)} /-</div>
                            <span style={{ fontSize: "small", color: "#FF6347" }}>(Click here to continue)</span>

                        </div>

                        <div>
                            <div className="homam-slot-radio-group">
                                <input
                                    type="radio"
                                    name="homam-slot"
                                    value={`service${index}`}
                                    checked={selectedOption === `service${index}`}
                                    onChange={handleOptionChange}
                                />
                            </div>
                        </div>
                    </div>

                </label>
            ))}
        </>
    );
};

export default HomamslotCard;