import React, { useState, useEffect, useRef } from "react";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import { } from '@fortawesome/free-regular-svg-icons'
import { } from "@fortawesome/fontawesome-free"


import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles


import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import HistoryIcon from '@mui/icons-material/History';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { ListItem, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { blue, pink } from "@mui/material/colors";
import LiveTvIcon from '@mui/icons-material/LiveTv';
import YouTubeIcon from '@mui/icons-material/YouTube';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';



AOS.init({
    duration: 1000, // Notice the single quotes around '2s'
});



const LandingHeader = () => {

    const [dashsignOut, setSignOut] = useState(false)
    const [isLesser992, setisLesser992] = useState(false);

    useEffect(() => {
        AOS.refresh();
    }, []);

    useEffect(() => {
        const auth = localStorage.getItem("isAuthenticated") || localStorage.getItem("homeauth");
        if (auth) {
            setSignOut(true)
        }
    }, []);




    useEffect(() => {

        const handleResize = () => {
            if (window.innerWidth < 992) {
                setisLesser992(true);
            } else {
                setisLesser992(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);




    const [signupcontent, SetSignupContent] = useState(false)



    const clientId = "516265231821-9n4dbqkgm349bl3a76j75lgeu19vv71h.apps.googleusercontent.com"

    // const clientSecret = "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";

    const navigate = useNavigate();

    const sectionRef = useRef(null);

    const howitworks = useRef(null)


    const [offset, setOffset] = useState(0);
    const [inView, setInView] = useState(false);

    const handleScroll = () => {
        setOffset(window.scrollY);

        // Check if the content is in view
        const element = document.querySelector('.parallax-content');
        if (element) {
            const rect = element.getBoundingClientRect();
            setInView(rect.top < window.innerHeight);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const [backendError, SetBackendError] = useState([])


    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [flag, SetFlag] = useState(false)
    const [name, SetName] = useState("")

    const [eyeIcon, UpdateEyeIcon] = useState(true)



    const [isSubmitClicked, setIsSubmitClicked] = useState(false);


    const handleMultipleClicks = () => {
        scrollToSection()
        togglePopup()

    }

    const scrollToSection = () => {

        navigate("/register")

        // if (sectionRef.current) {
        //     sectionRef.current.scrollIntoView({ behavior: 'smooth' });
        //     setIsOpen(false)
        //     SetSignupContent(true)
        //     setOpenMenu(false)
        // }
    };


    const [isOpen, setIsOpen] = useState(false);
    const [scrollThresholdPercentage, setScrollThresholdPercentage] = useState(10); // 10% initial threshold


    const togglePopup = () => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            setIsOpen(!isOpen);
        }
        // setIsOpen(!isOpen);
    };

    // const handleMouseLeave = () => {
    //     setIsOpen(false); // Set dynamic variable to false on mouse leave
    // };

    useEffect(() => {
        const handleScrollfade = () => {
            // Calculate the scroll position threshold based on screen width
            const screenWidth = window.innerWidth;
            let scrollThreshold;

            if (screenWidth > 1300) {
                // For screens wider than 1300px, set a different threshold
                scrollThreshold = 3000;
            } else {
                // For screens 1300px or narrower, use a different threshold
                scrollThreshold = 2000; // You can adjust this value
            }

            if (window.scrollY > scrollThreshold) {
                setIsOpen(false);
            }
        };

        window.addEventListener('scroll', handleScrollfade);

        return () => {
            window.removeEventListener('scroll', handleScrollfade);
        };
    }, []);








    // MENU BAR

    const [isOpenMenu, setOpenMenu] = useState(false);


    // ENTER BUTTON

    const formRef = useRef(null);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !isSubmitClicked) {
            e.preventDefault(); // Prevent form submission
            // Trigger the submit button click
            formRef.current.querySelector('.sumbit-font').click();
        }
    };

    // dashboard signout
    const signuptoSignout = () => {
        sessionStorage.clear()
        localStorage.clear();
        setSignOut(false)
        navigate("/")
        window.location.reload();


    }

    // const clickDashboard=()=>{
    //     Navigate("/home")
    // }




    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

    const handleMobileNavToggle = () => {
        setIsMobileNavOpen(!isMobileNavOpen);
    };

    const handleDropdownClick = (e) => {
        e.preventDefault();
        if (isMobileNavOpen && e.target.nextElementSibling) {
            e.target.nextElementSibling.classList.toggle('dropdown-active');
        }
    };


    const handleRouting = (page) => {
        // window.location.href = `/${page}`; // Set the URL to navigate to
        navigate(`/${page}`)
    }


    return (
        <>
            {/* ======= Header ======= */}
            <header id="header" className={`fixed-top d-flex align-items-center header-mobile-open`}>
                <div className="container d-flex justify-content-between align-items-center">
                    <div className="logo">
                        <NavLink to="/">
                            <img src={require("../images/New Shubadinam Logo/Shubadinam White.png")} onContextMenu={(e) => e.preventDefault()} />
                        </NavLink>
                    </div>
                    <nav id="navbar" className={`navbar ${isMobileNavOpen ? 'navbar-mobile' : ''}`}>
                        <ul>


                            {/* <li>{
                                dashsignOut ?
                                    <>
                                        <NavLink to="/dashboard" className="reg-btn open-btn"><span style={{ borderBottom: "1px solid white" }}>Dashboard</span></NavLink>
                                    </>
                                    :
                                    <NavLink to="/" className={({ isActive }) => isActive ? "active" : "link"}>Home</NavLink>
                            }
                            </li> */}



                            {/* <li><NavLink to="/about-us" className={({ isActive }) => isActive ? "active" : ""}>About Us</NavLink></li> */}

                            {/* <li><NavLink to="/pricing">Premium</NavLink></li> */}
                            {/* <li><NavLink to="/shop">Shop</NavLink></li> */}
                            {/* <li><NavLink to="/about-us" >Shop</NavLink></li> */}


                            {/* {
                                isLesser768 ?
                                    <>

                                        <li><NavLink to="/faq">FAQs</NavLink></li>
                                        <li><NavLink to="/Blogs">Blogs</NavLink></li>
                                        <li><NavLink to="/why-us">Why Shubadinam</NavLink></li>

                                    </>
                                    :
                                    <li className="dropdown">
                                        <a href="#" onClick={handleDropdownClick}><span>Info</span> <i className="bi bi-chevron-down" /></a>
                                        <ul>
                                            <li className="dropdown">
                                                <a href="#" onClick={handleDropdownClick}><span>Deep Drop Down</span> <i className="bi bi-chevron-right" /></a>
                                                <ul>
                                                    <li><a href="#">Deep Drop Down 1</a></li>
                                                    <li><a href="#">Deep Drop Down 2</a></li>
                                                    <li><a href="#">Deep Drop Down 3</a></li>
                                                    <li><a href="#">Deep Drop Down 4</a></li>
                                                    <li><a href="#">Deep Drop Down 5</a></li>
                                                </ul>
                                            </li>
                                            <li><NavLink to="/faq">FAQs</NavLink></li>
                                            <li><NavLink to="/Blogs">Blogs</NavLink></li>
                                            <li><NavLink to="/why-us">Why Us</NavLink></li>
                                        </ul>

                                    </li>
                            } */}
                            {/* <li><NavLink to="/contact-us">Contact Us</NavLink></li> */}
                            {/* 
                            {
                                dashsignOut ?
                                    <li><a href="/" onClick={() => signuptoSignout()}>Sign Out</a></li>
                                    :
                                    <>

                                        <li><NavLink to="/signin">Sign In</NavLink></li>
                                    </>
                            } */}


                            {
                                isLesser992 ?
                                    <>
                                        {
                                            dashsignOut ?
                                                <>
                                                    <NavLink to="/dashboard" className="reg-btn open-btn"><span style={{ borderBottom: "1px solid white" }}>Dashboard</span></NavLink>
                                                    <NavLink to="/shubadinam-tv">Shubadinam TV<YouTubeIcon sx={{ mr: "50%" }} fontSize="small" color="error" /></NavLink>
                                                </>
                                                :
                                                <li><NavLink to="/" className={({ isActive }) => isActive ? "active" : "link"}>Home</NavLink></li>
                                        }

                                        {/* <li><NavLink to="/about-us" className={({ isActive }) => isActive ? "active" : ""}>About Us</NavLink></li> */}
                                        <li><NavLink to="/pricing">Premium</NavLink></li>
                                        <li><NavLink to="/virtual-pooja-seva">Virtual Pooja</NavLink></li>
                                        <li> <a href="https://shop.shubadinam.com/" target="_blank" rel="noopener noreferrer">Gift Shoppe</a></li>
                                        {/* <li><NavLink to="/faq">FAQs</NavLink></li> */}
                                        {/* <li><NavLink to="/Blogs">Blogs</NavLink></li> */}


                                        {
                                            dashsignOut ?
                                                <>
                                                    <li><NavLink to="/homam-bookings">My Bookings<EventAvailableRoundedIcon sx={{ mr: "50%" }} fontSize="small" /></NavLink></li>
                                                    <li><NavLink to="/homam-bookings-history">Payment History<HistoryIcon sx={{ mr: "50%" }} fontSize="small" /></NavLink></li>
                                                </>
                                                :
                                                <li> <NavLink to="/shubadinam-tv">Shubadinam TV<YouTubeIcon sx={{ mr: "50%" }} fontSize="small" color="error" /></NavLink></li>
                                        }


                                        <li className="dropdown">
                                            <a href="#" onClick={handleDropdownClick}><span>Info</span> <i className="bi bi-chevron-down" /></a>
                                            <ul>
                                                <li className="dropdown">
                                                    {/* <a href="#" onClick={handleDropdownClick}><span>Deep Drop Down</span> <i className="bi bi-chevron-right" /></a> */}
                                                    <ul>
                                                        <li><a href="#">Deep Drop Down 1</a></li>
                                                        <li><a href="#">Deep Drop Down 2</a></li>
                                                        <li><a href="#">Deep Drop Down 3</a></li>
                                                        <li><a href="#">Deep Drop Down 4</a></li>
                                                        <li><a href="#">Deep Drop Down 5</a></li>
                                                    </ul>
                                                </li>
                                                <li><NavLink to="/about-us">About Us</NavLink></li>
                                                <li><NavLink to="/faq">FAQs</NavLink></li>
                                                <li><NavLink to="/Blogs">Blogs</NavLink></li>
                                                <li><NavLink to="/why-us">Why Shubadinam</NavLink></li>
                                                <li><NavLink to="/contact-us">Contact Us</NavLink></li>

                                            </ul>

                                        </li>








                                        {/* <li><NavLink to="/why-us">Why Shubadinam</NavLink></li> */}
                                        {/* <li><NavLink to="/contact-us">Contact Us</NavLink></li> */}

                                        {/* <li><NavLink to="/homam-bookings">My Bookings<EventAvailableRoundedIcon sx={{ mr: "50%" }} fontSize="small" /></NavLink></li> */}
                                        {/* <li><NavLink to="/homam-bookings-history">Payment History<HistoryIcon sx={{ mr: "50%" }} fontSize="small" /></NavLink></li> */}

                                        {dashsignOut ?
                                            <li><a href="/" onClick={() => signuptoSignout()}>Sign Out</a></li>
                                            :
                                            <li><NavLink to="/signin" >Sign In</NavLink></li>
                                        }

                                    </>

                                    :

                                    <>
                                        <li>{
                                            dashsignOut ?
                                                <ul>
                                                    <li><NavLink to="/dashboard" className="reg-btn open-btn"><span style={{ borderBottom: "1px solid white" }}>Dashboard</span></NavLink></li>
                                                    <li> <NavLink to="/shubadinam-tv">Shubadinam TV<YouTubeIcon sx={{ mr: "50%" }} fontSize="small" color="error" /></NavLink></li>
                                                </ul>
                                                :
                                                <ul>

                                                    <NavLink to="/" className={({ isActive }) => isActive ? "active" : ""}>Home</NavLink>
                                                    <li> <NavLink to="/shubadinam-tv">Shubadinam TV<YouTubeIcon sx={{ mr: "50%" }} fontSize="small" color="error" /></NavLink></li>
                                                </ul>
                                        }
                                        </li>

                                        <li><a href="/pricing">Premium</a></li>
                                        <li><a href="/virtual-pooja-seva">Virtual Pooja</a></li>
                                        <li> <a href="https://shop.shubadinam.com/" target="_blank" rel="noopener noreferrer">Gift Shoppe</a></li>

                                        <li className="dropdown">
                                            <a href="#" onClick={handleDropdownClick}><span>Info</span> <i className="bi bi-chevron-down" /></a>
                                            <ul>
                                                <li className="dropdown">
                                                    {/* <a href="#" onClick={handleDropdownClick}><span>Deep Drop Down</span> <i className="bi bi-chevron-right" /></a> */}
                                                    <ul>
                                                        <li><a href="#">Deep Drop Down 1</a></li>
                                                        <li><a href="#">Deep Drop Down 2</a></li>
                                                        <li><a href="#">Deep Drop Down 3</a></li>
                                                        <li><a href="#">Deep Drop Down 4</a></li>
                                                        <li><a href="#">Deep Drop Down 5</a></li>
                                                    </ul>
                                                </li>
                                                <li><NavLink to="/about-us">About Us</NavLink></li>
                                                <li><NavLink to="/faq">FAQs</NavLink></li>
                                                <li><NavLink to="/Blogs">Blogs</NavLink></li>
                                                <li><NavLink to="/why-us">Why Us</NavLink></li>
                                                <li><NavLink to="/contact-us">Contact Us</NavLink></li>

                                            </ul>

                                        </li>



                                        {
                                            dashsignOut ?


                                                <li className="dropdown" style={{ marginLeft: "25px", cursor: "pointer" }}>
                                                    <Avatar
                                                        onClick={handleDropdownClick}
                                                        sx={{ width: 28, height: 28, color: "#020332", backgroundColor: "aliceblue" }} // Adjust size here
                                                    />
                                                    <ul className="d-header-menu">

                                                        <li>


                                                            <MenuItem onClick={() => (handleRouting("my-profile"))}>
                                                                <ListItemIcon>
                                                                    <AccountBoxIcon fontSize="small" />
                                                                </ListItemIcon>
                                                                My Profile
                                                            </MenuItem>
                                                            <MenuItem onClick={() => (handleRouting("homam-bookings"))}>
                                                                <ListItemIcon>
                                                                    <EventAvailableRoundedIcon fontSize="small" />
                                                                </ListItemIcon>
                                                                My Bookings
                                                            </MenuItem>
                                                            <MenuItem onClick={() => (handleRouting("homam-bookings-history"))}>
                                                                <ListItemIcon>
                                                                    <HistoryIcon fontSize="small" />
                                                                </ListItemIcon>
                                                                Payment History
                                                            </MenuItem>
                                                            <Divider />
                                                            <MenuItem onClick={() => signuptoSignout()}>
                                                                <ListItemIcon>
                                                                    <Logout fontSize="small" />
                                                                </ListItemIcon>
                                                                Sign Out
                                                            </MenuItem>
                                                        </li>
                                                    </ul>

                                                </li>

                                                :
                                                <NavLink to="/signin" className={({ isActive }) => isActive ? "active" : ""}>Sign In</NavLink>
                                        }


                                    </>
                            }

                        </ul>
                        <i className="bi bi-list mobile-nav-toggle" onClick={handleMobileNavToggle} />
                    </nav>
                </div >
            </header >
            {/* End Header */}
        </>
    );
};

export default LandingHeader;