import React from 'react';
import axios from 'axios';

const PaymentPhone = () => {

    return (
        <div>
            
        </div>
    );
};

export default PaymentPhone;