import React, { useState, useEffect, useRef } from 'react';
import { NavLink, Navigate, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut, Pie, Bar, Bubble, Scatter, HorizontalBar, StackedBar, Mixed, Radar, Line, PolarArea } from 'react-chartjs-2';
import ScrollToTop from 'react-scroll-to-top';

import CountUp from 'react-countup';


import { Grid, Card, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link, Breadcrumbs, Box } from '@mui/material';
import { Group as GroupIcon, AccountBox as AccountBoxIcon, Stars as StarsIcon } from '@mui/icons-material';


ChartJS.register(ArcElement, Tooltip, Legend);



const AdminDashboard = ({ setSelectedNavItem }) => {

    const navigate = useNavigate()

    useEffect(() => {
        if (!sessionStorage.getItem("adminId")) {
            navigate("/admin");
        }
    }, [navigate])


    const [userData, setUserData] = useState([]);
    const [newUser, setNewuser] = useState([]);
    const [premUser, setpremUser] = useState([]);


    useEffect(() => {

        const url = `https://backend.shubadinam.com/adminpage/analyseusers`

        axios.get(url)
            .then(response => setUserData(response.data))
            .catch(error => console.error('Error fetching user data:', error));


        const getTodayUsers = `https://backend.shubadinam.com/adminpage/newusers`;

        axios.get(getTodayUsers)
            .then(response => setNewuser(response.data.setNewuser))
            .catch(error => console.error('Error fetching user data:', error));



    }, []);


    const totalUsers = userData.length;
    console.log(totalUsers);




    // Chart data
    const chartData = {
        type: 'bar',
        labels: ['Total Users'],
        datasets: [

            {
                label: 'Customers',
                data: [totalUsers, 2000 - totalUsers], // Assuming a total of 100 users
                backgroundColor: ['#36A2EB', '#FFCE56'], // You can customize colors

            },
        ],
    };

    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true,
            },
            x: {
                beginAtZero: true,

            }
        },
    };



    const [users, setUsers] = useState([]);


    useEffect(() => {
        const url = `https://backend.shubadinam.com/get/nonpremiumusers`

        axios.get(url)
            .then((response) => {
                // console.log(response.data);
                setUsers(response.data)
            })
            .catch((error) => {
                // console.log(error);
            })
    }, []);
    useEffect(() => {
        const url = `https://backend.shubadinam.com/get/premiumusers`

        axios.get(url)
            .then((response) => {
                // console.log(response.data);
                setpremUser(response.data.length)
            })
            .catch((error) => {
                // console.log(error);
            })
    }, []);

    function formatDateTime(dateTimeString) {
        const date = new Date(dateTimeString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${day}-${month}-${year} / ${hours}:${minutes}`;
    }

    const handleClick = () => {
        // Call the function passed from the parent component to update selectedNavItem
        // setSelectedNavItem(false);
        // Navigate to the non-premium users page
        navigate('/admin-database/total-customers');
    };



    return (

        <main>
            <ScrollToTop smooth />
            <div className="container-fluid px-4">
                <Typography variant="h4" gutterBottom>Dashboard</Typography>
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 4 }}>
                    <Typography color="textPrimary">Dashboard</Typography>
                </Breadcrumbs>

                <Grid container spacing={3} className="admin-dash-cards">
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ backgroundColor: '#ef5350', color: 'white', p: 3 }}>
                            <NavLink to="/admin-database/total-customers" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <Typography variant="h6" className="tip">Total Customers</Typography>
                                <Typography variant="h4" className="second-text">
                                    <GroupIcon sx={{ mr: 1 }} />
                                    <CountUp start={0} end={totalUsers} duration={2} />
                                </Typography>
                            </NavLink>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ backgroundColor: '#42a5f5', color: 'white', p: 3 }}>
                            <Typography variant="h6" className="tip">Customers Today</Typography>
                            <Typography variant="h4" className="second-text">
                                <AccountBoxIcon sx={{ mr: 1 }} />
                                <CountUp start={0} end={newUser} duration={2} />
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ backgroundColor: '#66bb6a', color: 'white', p: 3 }}>
                            <NavLink to="/admin-database/premiumusers" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <Typography variant="h6" className="tip">Premium Customers</Typography>
                                <Typography variant="h4" className="second-text">
                                    <StarsIcon sx={{ mr: 1 }} />
                                    <CountUp start={0} end={premUser} duration={2} />
                                </Typography>
                            </NavLink>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container spacing={3} sx={{ mt: 3 }}>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <Typography variant="h6" sx={{ p: 2 }}>
                                <i className="fas fa-chart-area me-1" /> Area Chart
                            </Typography>
                            <div className="card-body">
                                <Bar data={chartData} options={chartOptions} />
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card >
                            <Typography variant="h6" sx={{ p: 2 }}>
                                <i className="fas fa-chart-bar me-1" /> Detailed Report
                            </Typography>
                            <div className="card-body" style={{ padding: 20 }}>
                                <Typography variant="h6">
                                    Total Number of Users: <span style={{ color: "green", paddingLeft: "10px" }}>
                                        <CountUp start={0} end={totalUsers} duration={2} />
                                    </span>
                                </Typography>
                                <Typography variant="h6">
                                    Number of Users Today: <span style={{ color: "blue", paddingLeft: "10px" }}>
                                        <CountUp start={0} end={newUser} duration={2} />
                                    </span>
                                </Typography>
                            </div>
                        </Card>
                    </Grid>
                </Grid>

                <Card sx={{ mt: 3 }}>
                    <Typography variant="h6" sx={{ p: 2 }}>
                        <i className="fas fa-table me-1" />
                        <NavLink to="/admin-database/total-customers" onClick={handleClick} style={{ textDecoration: 'none', color: 'inherit' }}>
                            Total Users
                        </NavLink>
                    </Typography>
                    <Box sx={{ maxHeight: 400, }}>
                        <TableContainer component={Paper}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>S.No</TableCell>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Phone</TableCell>
                                        <TableCell>Signed On</TableCell>
                                        <TableCell>Country/State</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((user, index) => (
                                        <TableRow key={index}>
                                            <TableCell sx={{ overflow: "auto" }}>{index + 1}</TableCell>
                                            <TableCell sx={{ overflow: "auto" }}>{user.uniqueId}</TableCell>
                                            <TableCell sx={{ overflow: "auto" }}>{user.userEmail}</TableCell>
                                            <TableCell sx={{ overflow: "auto" }}>{user.userPhone}</TableCell>
                                            <TableCell sx={{ overflow: "auto" }}>
                                                {new Date(user.entry_time).toLocaleString('en-GB', {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true,
                                                    timeZone: 'UTC'  // This ensures the time is shown in UTC
                                                })}
                                            </TableCell>
                                            <TableCell sx={{ overflow: "auto" }}>{user.userCountry}/{user.userState}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Link component={NavLink} to="/admin-database/total-customers" sx={{ display: 'block', textAlign: 'right', p: 2 }}>
                        Click here to view &gt;&gt;
                    </Link>
                </Card>
            </div>
        </main>
    );
};

export default AdminDashboard;